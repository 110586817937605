var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", { staticClass: "el-icon-document" }, [
                _vm._v("待办任务"),
              ]),
              _c("el-tag", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("发起人:" + _vm._s(_vm.startUser)),
              ]),
              _c("el-tag", [_vm._v("任务节点:" + _vm._s(_vm.taskName))]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "top" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "表单信息", name: "1" } },
                [
                  _c("el-col", { attrs: { span: 16, offset: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "test-form" },
                      [
                        _c("parser", {
                          key: new Date().getTime(),
                          ref: "parser",
                          attrs: { "form-conf": _vm.variablesData },
                          on: { submit: _vm.submitForm },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "15%",
                          "margin-bottom": "20px",
                          "font-size": "14px",
                        },
                      },
                      [
                        !_vm.formKeyExist
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-edit-outline",
                                  type: "success",
                                  size: "mini",
                                },
                                on: { click: _vm.handleComplete },
                              },
                              [_vm._v("审批 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-refresh-left",
                              size: "mini",
                              type: "primary",
                            },
                            on: { click: _vm.handleReturn },
                          },
                          [_vm._v("退回")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-circle-close",
                              size: "mini",
                              type: "primary",
                            },
                            on: { click: _vm.handleReject },
                          },
                          [_vm._v("驳回上一节点 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "流转记录", name: "2" } },
                [
                  _c("el-col", { attrs: { span: 16, offset: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(_vm.flowRecordList, function (item, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  icon: _vm.setIcon(item.finishTime),
                                  color: _vm.setColor(item.finishTime),
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "font-weight": "700" } },
                                  [_vm._v(_vm._s(item.taskName))]
                                ),
                                _c(
                                  "el-card",
                                  {
                                    attrs: {
                                      "body-style": { padding: "10px" },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-descriptions",
                                      {
                                        staticClass: "margin-top",
                                        attrs: {
                                          column: 1,
                                          size: "small",
                                          border: "",
                                        },
                                      },
                                      [
                                        item.assigneeName
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-user",
                                                    }),
                                                    _vm._v("办理人"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.assigneeName) +
                                                    " "
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    attrs: {
                                                      type: "info",
                                                      size: "mini",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.deptName)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.candidate
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-user",
                                                    }),
                                                    _vm._v("候选办理"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.candidate) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              "label-class-name": "my-label",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("i", {
                                                staticClass: "el-icon-date",
                                              }),
                                              _vm._v("接收时间"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(item.createTime) +
                                                " "
                                            ),
                                          ],
                                          2
                                        ),
                                        item.finishTime
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-date",
                                                    }),
                                                    _vm._v("处理时间"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.finishTime) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.duration
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-time",
                                                    }),
                                                    _vm._v("耗时"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.duration) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.comment
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-tickets",
                                                    }),
                                                    _vm._v("处理意见"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.comment.comment
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "流程图", name: "3" } },
                [_c("flow", { attrs: { flowData: _vm.flowData } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.completeTitle,
                visible: _vm.completeOpen,
                "append-to-body": "",
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.completeOpen = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { ref: "taskForm", attrs: { model: _vm.taskForm } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "targetKey" } },
                    [
                      _vm.checkSendUser
                        ? _c("flow-user", {
                            attrs: { checkType: _vm.checkType },
                            on: { handleUserSelect: _vm.handleUserSelect },
                          })
                        : _vm._e(),
                      _vm.checkSendRole
                        ? _c("flow-role", {
                            on: { handleRoleSelect: _vm.handleRoleSelect },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "处理意见",
                        "label-width": "80px",
                        prop: "comment",
                        rules: [
                          {
                            required: true,
                            message: "请输入处理意见",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入处理意见",
                        },
                        model: {
                          value: _vm.taskForm.comment,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskForm, "comment", $$v)
                          },
                          expression: "taskForm.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.completeOpen = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.taskComplete },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.returnTitle,
                visible: _vm.returnOpen,
                width: "40%",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.returnOpen = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "taskForm",
                  attrs: { model: _vm.taskForm, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        rules: [
                          {
                            required: true,
                            message: "请选择退回节点",
                            trigger: "blur",
                          },
                        ],
                        label: "退回节点",
                        prop: "targetKey",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.taskForm.targetKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskForm, "targetKey", $$v)
                            },
                            expression: "taskForm.targetKey",
                          },
                        },
                        _vm._l(_vm.returnTaskList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            },
                            [_vm._v(_vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退回意见",
                        prop: "comment",
                        rules: [
                          {
                            required: true,
                            message: "请输入意见",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "50%" },
                        attrs: { type: "textarea", placeholder: "请输入意见" },
                        model: {
                          value: _vm.taskForm.comment,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskForm, "comment", $$v)
                          },
                          expression: "taskForm.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.returnOpen = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.taskReturn },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.rejectTitle,
                visible: _vm.rejectOpen,
                width: "40%",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.rejectOpen = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "taskForm",
                  attrs: { model: _vm.taskForm, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "驳回意见",
                        prop: "comment",
                        rules: [
                          {
                            required: true,
                            message: "请输入意见",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "50%" },
                        attrs: { type: "textarea", placeholder: "请输入意见" },
                        model: {
                          value: _vm.taskForm.comment,
                          callback: function ($$v) {
                            _vm.$set(_vm.taskForm, "comment", $$v)
                          },
                          expression: "taskForm.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.rejectOpen = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.taskReject },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }