import request from '@/utils/request'

// 查询任务列表
export function listTask(query) {
  return request({
    url: '/bpm/bpmTask',
    method: 'get',
    params: query
  })
}

// 查询全局任务列表
export function enableTaskList(query) {
  return request({
    url: '/bpm/bpmTask/enableTaskList',
    method: 'get',
    params: query
  })
}

// 批量引入全局任务
export function insertBatch(data) {
  return request({
    url: '/bpm/bpmProjectTask/insertBatch',
    method: 'post',
    data: data
  })
}

// 查询任务详细
export function getTask(id) {
  return request({
    url: '/bpm/bpmTask/' + id,
    method: 'get'
  })
}

// 新增任务
export function addTask(data) {
  return request({
    url: '/bpm/bpmTask',
    method: 'post',
    data: data
  })
}

// 修改任务
export function updateTask(data) {
  return request({
    url: '/bpm/bpmTask',
    method: 'put',
    data: data
  })
}

// 删除任务
export function delTask(id) {
  return request({
    url: '/bpm/bpmTask/?idList='+id ,
    method: 'delete',
  })
}



// 查询项目任务列表
export function listItemTask(query) {
  return request({
    url: '/bpm/bpmProjectTask',
    method: 'get',
    params: query
  })
}

// 查询项目任务详细
export function getItemTask(id) {
  return request({
    url: '/bpm/bpmProjectTask/' + id,
    method: 'get'
  })
}

// 新增项目任务
export function addItemTask(data) {
  return request({
    url: '/bpm/bpmProjectTask',
    method: 'post',
    data: data
  })
}

// 修改项目任务
export function updateItemTask(data) {
  return request({
    url: '/bpm/bpmProjectTask',
    method: 'put',
    data: data
  })
}

// 删除项目任务
export function delItemTask(id) {
  return request({
    url: '/bpm/bpmProjectTask?idList='+id,
    method: 'delete',
  })
}

export function listItemTasks(query) {
  return request({
    url: '/bpm/bpmProjectTask/tasks',
    method: 'get',
    params: query
  })
}
