import request from '@/utils/request'

// 查询字段列表
export function fieldList(query) {
  return request({
    url: '/bpm/form/field/page',
    method: 'get',
    params: query
  })
}

export function fieldSearch(query) {
  return request({
    url: '/bpm/form/field/search',
    method: 'get',
    params: query
  })
}

// 查询字段详细
export function getField(query) {
  return request({
    url: '/bpm/form/field/get',
    method: 'get',
    params: query
  })
}

// 新增字段
export function addField(data) {
  return request({
    url: '/bpm/form/field/save',
    method: 'post',
    data: data
  })
}

// 修改字段
export function updateField(data) {
  return request({
    url: '/bpm/form/field/update',
    method: 'put',
    data: data
  })
}

// 删除字段
export function delField(query) {
  return request({
    url: '/bpm/form/field/delete',
    method: 'delete',
    params: query
  })
}
