var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            width: "980px",
            "modal-append-to-body": false,
            "append-to-body": true,
          },
          on: { open: _vm.onOpen, close: _vm.onClose },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "识别字段" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.value))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "是否为参数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.searchType ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "是否为选中字段" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.showType ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "模块" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "选择模块",
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: scope.row.moduleId,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "moduleId", $$v)
                          },
                          expression: "scope.row.moduleId",
                        },
                      },
                      _vm._l(_vm.optionsList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.moduleId },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "映射字段" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "选择映射字段",
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: scope.row.__vModel__,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "__vModel__", $$v)
                          },
                          expression: "scope.row.__vModel__",
                        },
                      },
                      _vm._l(
                        _vm.getOptions(scope.row.moduleId),
                        function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.__config__.label,
                              value: item.__vModel__,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEnsure } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }