var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "muti-instance",
          attrs: {
            title: "多实例配置",
            visible: _vm.dialogVisible,
            width: "600px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1, size: "mini", border: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "使用说明" } }, [
                _vm._v(
                  "按照BPMN2.0规范的要求，用于为每个实例创建执行的父执行，会提供下列变量:"
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "nrOfInstances" } },
                [_vm._v("实例总数。")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "nrOfActiveInstances" } },
                [
                  _vm._v(
                    "当前活动的（即未完成的），实例数量。对于顺序多实例，这个值总为1。"
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "nrOfCompletedInstances" } },
                [_vm._v("已完成的实例数量。")]
              ),
              _c("el-descriptions-item", { attrs: { label: "loopCounter" } }, [
                _vm._v("给定实例在for-each循环中的index。"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c("x-form", {
                ref: "xForm",
                attrs: { config: _vm.formConfig },
                model: {
                  value: _vm.formData,
                  callback: function ($$v) {
                    _vm.formData = $$v
                  },
                  expression: "formData",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }