<template>
  <textarea :id="tinymceId" style="visibility: hidden" @input="onInput" />
</template>

<script>
import loadTinymce from '@/utils/loadTinymce'
import { plugins, toolbar } from './config'
import { debounce } from 'throttle-debounce'

let num = 1

export default {
  props: {
    id: {
      type: String,
      default: () => {
        num === 10000 && (num = 1)
        return `tinymce${+new Date()}${num++}`
      }
    },
    value: {
      default: ''
    }
  },
  watch: {
    // value(newVal) {
    //   // 如果父组件的 value 变化，更新 TinyMCE 内容
    //   console.log(newVal,'newVal');
    //   this.editor.setContent(newVal);
    // }
  },
  data() {
    return {
      tinymceId: this.id,
      editor:null
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.remove(); // 销毁 editor 实例
    }
  },
  mounted() {
    loadTinymce(tinymce => {
      // eslint-disable-next-line global-require
      require('./zh_CN')
      let conf = {
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        menubar: 'file edit insert view format table',
        plugins,
        toolbar,
        height: 300,
        branding: false,
        object_resizing: false,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true,
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 56px', // 工具栏自定义字体大小选项
        font_formats: "微软雅黑='微软雅黑'; 宋体='宋体'; 黑体='黑体'; 仿宋='仿宋'; 楷体='楷体'; 隶书='隶书'; 幼圆='幼圆'; 方正舒体='方正舒体'; 方正姚体='方正姚体'; 等线='等线'; 华文彩云='华文彩云'; 华文仿宋='华文仿宋'; 华文行楷='华文行楷'; 华文楷体='华文楷体'; 华文隶书='华文隶书'; Andale Mono=andale mono,times; Arial=arial; Arial Black=arial black;avant garde; Book Antiqua=book antiqua;palatino; Comic Sans MS=comic sans ms; Courier New=courier new;courier; Georgia=georgia; Helvetica=helvetica; Impact=impact;chicago; Symbol=symbol; Tahoma=tahoma;arial; sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms; Verdana=verdana;geneva; Webdings=webdings; Wingdings=wingdings", // 工具栏自定义字体选项
        toolbar_sticky: true, // 粘性工具栏 默认false (在向下滚动网页直到不再可见编辑器时，将工具栏和菜单停靠在屏幕顶部)
        images_upload_handler: function (blobInfo, success, failure) {
          let formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          
          fetch(process.env.VUE_APP_BASE_API + "/file/upload", {
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then(result => {
            console.log(result,'resultresultresultresult')
            if(result.code == 200){
              success(result.data.url);
            }
          })
          .catch(error => {
            console.error('Error uploading image: ', error);
            failure('Upload failed');
          });
        }
      }
      conf = Object.assign(conf, this.$attrs)
      conf.init_instance_callback = editor => {
        this.editor = editor
        if (this.value) editor.setContent(this.value)
        this.vModel(editor)
      }
      tinymce.init(conf)
    })
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    onInput(event) {
      // 在这里处理 TinyMCE 的输入变化
      const content = event.target.value; // 获取 TinyMCE 的内容
      this.$emit('input', content); // 触发 input 事件
    },
    vModel(editor) {
      // 控制连续写入时setContent的触发频率
      const debounceSetContent = debounce(250, editor.setContent)
      this.$watch('value', (val, prevVal) => {
        if (editor && val !== prevVal && val !== editor.getContent()) {
          if (typeof val !== 'string') val = val.toString()
          debounceSetContent.call(editor, val)
        }
      })

      editor.on('change keyup undo redo', () => {
        this.$emit('input', editor.getContent())
      })
    },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (tinymce) {
        tinymce.destroy()
      }
    },
    insertValueAtCursor(msg) {
      if (this.editor) {
        // 获取当前光标位置
        const cursorPos = this.editor.selection.getRng();
        
        // 在光标位置插入值
        this.editor.selection.setContent(msg); // 使用 setContent 插入文本

        // 获取编辑器当前内容，并更新 value
        const updatedValue = this.editor.getContent();
        this.$emit('input', updatedValue); // 更新父组件的 value

        console.log(this.editor, this.editor.selection.getRng());
      } else {
          console.error("Tinymce编辑器未找到");
      }
      return

        // 获取Tinymce编辑器实例
        const editor = window.tinymce.get(this.tinymceId);

        if (editor) {
            // 获取当前光标位置
            const cursorPos = editor.selection.getRng();

            // 在光标位置插入值
            editor.selection.setNode(editor.getDoc().createTextNode(msg), cursorPos);
        } else {
            console.error("Tinymce编辑器未找到");
        }
    },
  }
}
</script>
