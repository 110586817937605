var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("x-form", {
        ref: "xForm",
        attrs: { config: _vm.formConfig },
        scopedSlots: _vm._u([
          {
            key: "executionListener",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { value: _vm.executionListenerLength } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogName = "executionListenerDialog"
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _vm.dialogName === "executionListenerDialog"
        ? _c("executionListenerDialog", {
            attrs: { element: _vm.element, modeler: _vm.modeler },
            on: { close: _vm.finishExecutionListener },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }