var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: !_vm.isRequestCompleted,
              expression: "!isRequestCompleted",
            },
          ],
        },
        [
          _c("div", { staticClass: "head-title" }, [
            _vm._v(_vm._s(_vm.projectData.projectName)),
          ]),
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm.isRequestCompleted
            ? _c("mparser", {
                ref: "parser",
                attrs: {
                  "form-conf": _vm.formConf,
                  recordStatus: _vm.projectData.recordStatus,
                },
                on: {
                  openDataDialog: _vm.openDataDialog,
                  getData: _vm.getData,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                width: "50%",
                margin: "50px auto",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.isSave, size: "medium", type: "info" },
                  on: { click: _vm.stagingForms },
                },
                [_vm._v("暂 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.submitPros },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectFillData", {
        ref: "fillData",
        on: { select: _vm.handleSelectData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-tip" }, [
      _c("span", [_vm._v("办理流程")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-boxs" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "step" }, [
          _c("img", {
            attrs: {
              src: "https://oss.71go.com/qyg/xcx/mtasksend1.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "step" }, [
          _c("img", {
            attrs: {
              src: "https://oss.71go.com/qyg/xcx/mtasksend3.png",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "step" }, [
          _c("img", {
            attrs: {
              src: "https://oss.71go.com/qyg/xcx/mtasksend2.png",
              alt: "",
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "bot" }, [
        _c("div", { staticClass: "title" }, [_vm._v("填写资料")]),
        _c("div", { staticClass: "title" }, [_vm._v("确认提交")]),
        _c("div", { staticClass: "title" }, [_vm._v("审核办理")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label-tip" }, [
      _c("span", [_vm._v("填写资料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }