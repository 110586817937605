<template>
  <div>
    <x-form ref="xForm" v-model="formData" :config="formConfig">
      <!-- <template #formInfo>
        <el-button size="small" @click="showForm">编辑</el-button>
      </template> -->
      <template #checkSingleUser>
        <el-input placeholder="请选择人员" class="input-with-select" v-model="checkValues">
          <template slot="append">
            <!--指定用户-->
            <el-button style="padding-left: 7px" icon="el-icon-user" @click="singleUserCheck" />
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!--选择表达式-->
            <!-- <el-button style="padding-right: 7px" icon="el-icon-postcard" @click="singleExpCheck('assignee')" /> -->
          </template>
        </el-input>
      </template>
      <template #checkMultipleUser>
        <el-input placeholder="请选择候选用户" class="input-with-select" v-model="checkValues">
          <template slot="append">
            <!--候选用户-->
            <el-button style="padding-left: 7px" icon="el-icon-user" @click="multipleUserCheck" />
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!--选择表达式-->
            <!-- <el-button style="padding-right: 7px" icon="el-icon-postcard" @click="singleExpCheck('candidateUsers')" /> -->
          </template>
        </el-input>
      </template>
      <template #checkRole>
        <el-input placeholder="请选择候选角色" class="input-with-select" v-model="checkValues">
          <template slot="append">
            <!--候选角色-->
            <el-button style="padding-left: 7px" icon="el-icon-user" @click="multipleRoleCheck" />
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!--选择表达式-->
            <!-- <el-button style="padding-right: 7px" icon="el-icon-postcard" @click="singleExpCheck('candidateGroups')" /> -->

          </template>
        </el-input>
      </template>
      <template #taskIds>
        <el-badge :value="formData.taskIds.split(',').length">
          <el-button size="small" @click="choseTaskId()">编辑</el-button>
        </el-badge>
        <!-- <el-select v-model="formData.taskIds">
          <el-option v-for="dict in taskList" :key="dict.id" :label="dict.taskName" :value="dict.id" />
        </el-select> -->
      </template>
      <template #overStatus>
        <el-select v-model="formData.overStatus">
          <el-option key="0" label="否" value="0" />
          <el-option key="1" label="是" value="1" />
        </el-select>
      </template>

      <template #executionListener>
        <el-badge :value="executionListenerLength">
          <el-button size="small" @click="dialogName = 'executionListenerDialog'">编辑</el-button>
        </el-badge>
      </template>
      <template #taskListener>
        <el-badge :value="taskListenerLength">
          <el-button size="small" @click="dialogName = 'taskListenerDialog'">编辑</el-button>
        </el-badge>
      </template>
      <template #multiInstance>
        <el-badge :is-dot="hasMultiInstance">
          <el-button size="small" @click="dialogName = 'multiInstanceDialog'">编辑</el-button>
        </el-badge>
      </template>
    </x-form>
    <executionListenerDialog v-if="dialogName === 'executionListenerDialog'" :element="element" :modeler="modeler" @close="finishExecutionListener" />
    <taskListenerDialog v-if="dialogName === 'taskListenerDialog'" :element="element" :modeler="modeler" @close="finishTaskListener" />
    <multiInstanceDialog v-if="dialogName === 'multiInstanceDialog'" :element="element" :modeler="modeler" @close="finishMultiInstance" />
    <!--选择人员-->
    <el-dialog title="选择人员" :visible.sync="userVisible" width="60%" :close-on-press-escape="false" :show-close="false">
      <flow-user :checkType="checkType" :selectValues="selectValues" @handleUserSelect="handleUserSelect"></flow-user>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkUserComplete">确 定</el-button>
      </span>
    </el-dialog>
    <!--选择表达式-->
    <el-dialog title="选择表达式" :visible.sync="expVisible" width="60%" :close-on-press-escape="false" :show-close="false">
      <flow-exp :selectValues="selectValues" @handleSingleExpSelect="handleSingleExpSelect"></flow-exp>
      <span slot="footer" class="dialog-footer">
        <el-button @click="expVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkExpComplete">确 定</el-button>
      </span>
    </el-dialog>
    <!--选择角色-->
    <el-dialog title="选择候选角色" :visible.sync="roleVisible" width="60%" :close-on-press-escape="false" :show-close="false">
      <flow-role :checkType="checkType" :selectValues="selectValues" @handleRoleSelect="handleRoleSelect"></flow-role>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkRoleComplete">确 定</el-button>
      </span>
    </el-dialog>
    <!--绑定任务-->
    <el-dialog title="绑定任务" :visible.sync="taskVisible" width="60%" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
      <el-table
        ref="multipleTable"
        :data="taskListNew"
        tooltip-effect="dark"
        style="width: 100%"
        v-model="taskIds"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55"
          align="center">
        </el-table-column>
        <el-table-column
          prop="taskName"
          label="任务名称"
          align="center"
          >
        </el-table-column>
        <el-table-column
          label="字段配置"
          align="center"
          width="400">
          <template slot-scope="scope">
            <el-button type="primary" @click="showForm(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="delTask(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="taskVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="checkTask">确 定</el-button>
      </span>
    </el-dialog>
    <!--编辑表单-->
    <el-dialog title="编辑表单字段" :visible.sync="formVisible" width="60%" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
      <el-table
        class="edit-form-field"
        :data="formLists"
        :row-class-name="customRowClassName"
        style="width: 100%;margin-bottom: 20px;"
        row-key="renderKey"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="labelName"
          label="字段名"
          >
        </el-table-column>
        <el-table-column
          label="字段配置"
          width="400">
          <template slot-scope="scope">
            <!-- <div>{{scope.row.checkList}}</div> -->
            <el-checkbox-group v-model="scope.row.checkList" @input="handleCheckboxChange($event,scope.row)">
              <el-checkbox :label="2" @change="changes($event,scope.row,2)">查看</el-checkbox>
              <el-checkbox :label="3" @change="changes($event,scope.row,3)">编辑</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixinPanel from "../../common/mixinPanel";
import executionListenerDialog from "./property/executionListener";
import taskListenerDialog from "./property/taskListener";
import multiInstanceDialog from "./property/multiInstance";
import { commonParse, userTaskParse } from "../../common/parseElement";
import { StrUtil } from "@/utils/StrUtil";
import FlowUser from "@/components/flow/User1";
import FlowRole from "@/components/flow/Role1";
import FlowExp from "@/components/flow/Expression";
import { listAllForm, setConfig, getConfig } from "@/api/flowable/form";
import { delItemTask,getItemTask } from "@/api/system/task";
import { log } from 'console';
export default {
  components: {
    executionListenerDialog,
    taskListenerDialog,
    multiInstanceDialog,
    FlowUser,
    FlowRole,
    FlowExp,
  },
  mixins: [mixinPanel],
  props: {
    users: {
      type: Array,
      required: true,
    },
    formDatas: {
      type: Array,
      required: true,
    },
    taskList: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    exps: {
      type: Array,
      required: true,
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userTypeOption: [
        { label: "指定人员", value: "assignee" },
        { label: "候选人员", value: "candidateUsers" },
        { label: "候选角色", value: "candidateGroups" },
        { label: "发起人", value: "promoter" },
      ],
      dialogName: "",
      executionListenerLength: 0,
      taskListenerLength: 0,
      hasMultiInstance: false,
      userVisible: false,
      roleVisible: false,
      expVisible: false,
      formData: {},
      assignee: null,
      candidateUsers: null,
      candidateGroups: null,
      // 选类型
      checkType: "single",
      // 选中的值
      checkValues: null,
      // 数据回显
      selectValues: null,
      // 用户列表
      userList: this.users,
      // 角色列表
      groupList: this.groups,
      // 表达式列表
      expList: this.exps,
      // 表达式类型
      expType: null,
      // 表单列表
      formList: [],

      taskListNew:[],
      taskVisible:false, // 绑定任务
      taskIds:[],
      formVisible:false, // 字段配置
      defaultProps: {
        children: 'children',
        label: 'labelName'
      },
      formLists:[],
      formListsOld:[],
      forms:{
        taskId:null,
        viewable:[],
        editable:[],
        struct:null
      },
    };
  },
  computed: {
    formConfig() {
      const _this = this;
      return {
        inline: false,
        item: [
          {
            xType: "input",
            name: "id",
            label: "节点 id",
            disabled:true,
            rules: [{ required: true, message: "Id 不能为空" }],
          },
          {
            xType: "input",
            name: "name",
            label: "节点名称",
            rules: [{ required: true, message: "节点名称不能为空" }],
          },
          {
            xType: "input",
            name: "documentation",
            label: "节点描述",
          },
          {
            xType: "slot",
            name: "taskIds",
            label: "选择任务",
            rules: [{ required: true, message: '任务选择不能为空' }],
          },
          {
            xType: "slot",
            name: "overStatus",
            label: "是否完结",
            rules: [{ required: true, message: '请选择是否完结' }],
          },
          // {
          //   xType: "slot",
          //   name: "formInfo",
          //   label: "编辑表单",
          // },
          {
            xType: "select",
            name: "userType",
            label: "用户类型",
            // clearable: true,
            dic: _this.userTypeOption,
            // rules: [{ required: true, message: '用户类型不能为空' }],
            show: !!_this.showConfig.userType,
          },

          {
            xType: "slot",
            name: "checkSingleUser",
            label: "指定人员",
            // rules: [{ required: true, message: '指定人员不能为空' }],
            show:
              !!_this.showConfig.assignee &&
              _this.formData.userType === "assignee",
          },
          {
            xType: "slot",
            name: "checkMultipleUser",
            label: "候选人员",
            // rules: [{ required: true, message: '候选人员不能为空' }],
            show:
              !!_this.showConfig.candidateUsers &&
              _this.formData.userType === "candidateUsers",
          },
          {
            xType: "slot",
            name: "checkRole",
            label: "候选角色",
            // rules: [{ required: true, message: '候选角色不能为空' }],
            show:
              !!_this.showConfig.candidateGroups &&
              _this.formData.userType === "candidateGroups",
          },
          // {
          //   xType: "slot",
          //   name: "executionListener",
          //   label: "执行监听器",
          // },
          // {
          //   xType: "slot",
          //   name: "taskListener",
          //   label: "任务监听器",
          //   show: !!_this.showConfig.taskListener,
          // },
          // {
          //   xType: "slot",
          //   name: "multiInstance",
          //   label: "多实例",
          // },
          // {
          //   xType: "switch",
          //   name: "async",
          //   label: "异步",
          //   activeText: "是",
          //   inactiveText: "否",
          //   show: !!_this.showConfig.async,
          // },
          // {
          //   xType: "input",
          //   name: "priority",
          //   label: "优先级",
          //   show: !!_this.showConfig.priority,
          // },
          // {
          //   xType: 'input',
          //   name: 'formKey',
          //   label: '表单标识key',
          //   show: !!_this.showConfig.formKey
          // },
          // {
          //   xType: "select",
          //   name: "formKey",
          //   label: "表单标识key",
          //   clearable: true,
          //   dic: { data: _this.formList, label: "formName", value: "formId" },
          //   show: !!_this.showConfig.formKey,
          // },
          // {
          //   xType: "input",
          //   name: "skipExpression",
          //   label: "跳过表达式",
          //   show: !!_this.showConfig.skipExpression,
          // },
          // {
          //   xType: "switch",
          //   name: "isForCompensation",
          //   label: "是否为补偿",
          //   activeText: "是",
          //   inactiveText: "否",
          //   show: !!_this.showConfig.isForCompensation,
          // },
          // {
          //   xType: "switch",
          //   name: "triggerable",
          //   label: "服务任务可触发",
          //   activeText: "是",
          //   inactiveText: "否",
          //   show: !!_this.showConfig.triggerable,
          // },
          // {
          //   xType: "switch",
          //   name: "autoStoreVariables",
          //   label: "自动存储变量",
          //   activeText: "是",
          //   inactiveText: "否",
          //   show: !!_this.showConfig.autoStoreVariables,
          // },
          // {
          //   xType: "input",
          //   name: "ruleVariablesInput",
          //   label: "输入变量",
          //   show: !!_this.showConfig.ruleVariablesInput,
          // },
          // {
          //   xType: "input",
          //   name: "rules",
          //   label: "规则",
          //   show: !!_this.showConfig.rules,
          // },
          // {
          //   xType: "input",
          //   name: "resultVariable",
          //   label: "结果变量",
          //   show: !!_this.showConfig.resultVariable,
          // },
          // {
          //   xType: "switch",
          //   name: "exclude",
          //   label: "排除",
          //   activeText: "是",
          //   inactiveText: "否",
          //   show: !!_this.showConfig.exclude,
          // },
          // {
          //   xType: "input",
          //   name: "class",
          //   label: "类",
          //   show: !!_this.showConfig.class,
          // },
          // {
          //   xType: "datePicker",
          //   type: "datetime",
          //   valueFormat: "yyyy-MM-ddTHH:mm:ss",
          //   name: "dueDate",
          //   label: "到期时间",
          //   show: !!_this.showConfig.dueDate,
          // },
        ],
      };
    },
  },
  watch: {
    "formData.userType": function (val, oldVal) {
      if (StrUtil.isNotBlank(oldVal)) {
        delete this.element.businessObject.$attrs[`flowable:${oldVal}`];
        delete this.formData[oldVal];
        // 清除已选人员数据
        this.checkValues = "";
        this.selectValues = null;
        // 删除xml中已选择数据类型节点
        delete this.element.businessObject.$attrs[`flowable:dataType`];
      }
      // 写入userType节点信息到xml
      this.updateProperties({ "flowable:userType": val });
    },
    "formData.async": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:async": val });
      }
    },
    "formData.taskIds": function (val) {
      if (StrUtil.isNotBlank(val)) {
        console.log(val,'valvalval');
        this.updateProperties({ "flowable:taskIds": val });
      }
    },
    "formData.overStatus": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:overStatus": val });
      }
    },
    "formData.dueDate": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:dueDate": val });
      }
    },
    "formData.formKey": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:formKey": val });
      } else {
        // 删除xml中已选择表单信息
        delete this.element.businessObject[`formKey`];
      }
    },
    "formData.priority": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:priority": val });
      }
    },
    "formData.skipExpression": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:skipExpression": val });
      } else {
        delete this.element.businessObject.$attrs[`flowable:skipExpression`];
      }
    },
    "formData.isForCompensation": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ isForCompensation: val });
      }
    },
    "formData.triggerable": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:triggerable": val });
      }
    },
    "formData.class": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:class": val });
      }
    },
    "formData.autoStoreVariables": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:autoStoreVariables": val });
      }
    },
    "formData.exclude": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:exclude": val });
      }
    },
    "formData.ruleVariablesInput": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:ruleVariablesInput": val });
      }
    },
    "formData.rules": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:rules": val });
      }
    },
    "formData.resultVariable": function (val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({ "flowable:resultVariable": val });
      }
    },
  },
  created() {
    
    if(this.formDatas.length) {
      console.log(this.formDatas,"formDatas");
      this.formDatas.forEach(item => {
        let obj = item.__config__
        obj.labelName = obj.componentName
        obj.checkList = []
        obj.children.forEach(item1 => {
          item1.renderKey = item1.__config__.renderKey
          item1.labelName = item1.__config__.label
          item1.checkList = []
        })
        this.formLists.push(obj)
      })
      this.formListsOld = JSON.parse(JSON.stringify(this.formLists))
      console.log(this.formDatas,"formDatas111",this.formLists,this.formListsOld);
    }
    let cache = commonParse(this.element);
    cache = userTaskParse(cache);
    this.formData = cache;
    console.log(this.formData,'this.formData',this.taskList);
    this.taskListNew = JSON.parse(JSON.stringify(this.taskList))
    if(this.formData.taskIds) {
      this.formData.taskIds.split(',').forEach(item => {
        getItemTask(item).then((response) => {
          if(response.data) {
            this.taskListNew.push(response.data);
          }
        });
      })
      console.log(this.taskListNew,'111',this.taskList);
    }
    this.computedExecutionListenerLength();
    this.computedTaskListenerLength();
    this.computedHasMultiInstance();
    // 人员信息回显
    this.checkValuesEcho();
    // 加载表单列表
    // this.getListForm();
  },
  methods: {
    restForm() {
      this.forms = {
        taskId:null,
        viewable:[],
        editable:[],
        struct:null
      }
    },
    customRowClassName({ row, rowIndex }) {
      if (row.children) {
        return 'parent-row'; // 添加自定义样式类名
      }
      return ''; // 不需要额外样式时返回空字符串
    },
    // 展示绑定任务弹窗
    choseTaskId() {
      console.log(this.taskListNew,'this.taskListNew');
      this.taskVisible = true
      this.getSelection()
    },
    getSelection() {
      this.$nextTick(() => {
        this.taskIds = this.formData.taskIds.split(',')
        const multipleTable = this.$refs.multipleTable;
        this.taskListNew.forEach(rowData => {
          if(this.formData.taskIds.split(',').indexOf(rowData.id) != -1) {
            multipleTable.toggleRowSelection(rowData, true);
          } else {
            multipleTable.toggleRowSelection(rowData, false);
          }
        });
      })
      console.log(this.formData.taskIds.split(','));
    },
    handleSelectionChange(selection) {
      console.log(selection);
      let list = []
      selection.forEach(item => {
        list.push(item.id)
      })
      this.taskIds = list
    },
    // 确定勾选任务
    checkTask() {
      let result = this.taskListNew.filter(itemA => this.taskIds.includes(itemA.id));
      this.taskIds = result.map(item => item.id);
      if(!this.taskIds.length) {
        this.$modal.msgError("请选择任务！");
        return false
      }
      this.formData.taskIds = this.taskIds.join(',')
      let nameList = []
      console.log(this.taskListNew,'this.taskListNewthis.taskListNew',this.taskIds,this.formData.taskIds);
      this.formData.taskIds.split(',').forEach(item => {
        nameList.push(this.taskListNew.filter(item1 => item1.id == item)[0].taskName)
      })
      if(!this.formData.name) {
        this.formData.name = nameList.join(',');
      }
      // 更新任务列表，过滤已选列表
      let list = this.taskListNew.filter(item => !this.taskIds.includes(item.id))
      this.$emit('updateTask',list)
      console.log(list,'listlistlistlist');
      this.taskVisible = false
    },
    // 展示表单字段设置弹窗
    showForm(row) {
      console.log(row,'row');
      this.restForm()
      this.formVisible = true
      this.forms.taskId = row.id
      getConfig({taskId:this.forms.taskId,projectId:this.projectId}).then(res => {
        if(res.data?.setting) {
          console.log(JSON.parse(res.data.setting),'111');
          let list1 = JSON.parse(JSON.stringify(this.formListsOld));
          let list2 = JSON.parse(res.data.setting).struct;

          list1.forEach(item => {
            let matchingItem = list2.find(item1 => item.moduleId === item1.moduleId);
            if (matchingItem) {
              item.checkList = matchingItem.checkList || [];
              item.children.forEach(item2 => {
                let matchingChild = matchingItem.children.find(item3 => item2.__vModel__ === item3.__vModel__);
                if (matchingChild) {
                  item2.checkList = matchingChild.checkList || [];
                }
              });
            }
          });
          this.formLists = list1;
        } else {
          this.formLists = JSON.parse(JSON.stringify(this.formListsOld))
        }
        console.log(JSON.parse(res.data.setting).struct,this.formLists,'11111111111');
      })
    },
    // 删除任务
    delTask(row) {
      console.log(row,'删除11');
      this.$modal
        .confirm('是否确认删除任务名称为"' + row.taskName + '"的数据项？')
        .then(function () {
          return delItemTask(row.id);
        })
        .then(() => {
          this.$modal.msgSuccess("删除成功");
          if(this.formData.taskIds.split(',').indexOf(row.id) != -1) {
            let list1 = this.formData.taskIds.split(',').filter(item => item != row.id)
            this.formData.taskIds = list1.length ? list1.join(',') : null
            let list2 = this.formData.name.split(',').filter(item => item != row.taskName)
            this.formData.name = list2.join(',')
            console.log(list1,list2,'000000000000000000',this.formData);
          }
          this.taskListNew = this.taskListNew.filter(item => item.id != row.id)
          this.getSelection()
        })
        .catch(() => {});
    },
    // 确认提交字段设置
    checkForm() {
      this.forms.viewable = []
      this.forms.editable = []
      console.log(this.formLists,'formListsformListsformLists');
      this.formLists.forEach(item => {
        item.children.forEach(item1 => {
          item1.checkList.forEach(item2 => {
            if(item2 == 2) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.viewable.push(param)
            }
            if(item2 == 3) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.editable.push(param)
            }
          })
        })
      })
      this.forms.struct = this.formLists
      setConfig({taskId:this.forms.taskId,projectId:this.projectId,setting:JSON.stringify(this.forms)}).then(res => {
        this.formVisible = false
      })
      console.log(this.forms,'this.forms');
    },
    // 字段父节点设置
    changes(e,row,i) {
      if(row.children) {
        console.log(e,row);
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          if(item.renderKey == row.renderKey) {
            indexs = index
            items = JSON.parse(JSON.stringify(item))
          }
        })
        if(e) {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) == -1) {
              item1.checkList.push(i)
            }
          })
        } else {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) != -1) {
              let index = item1.checkList.indexOf(i)
              console.log(i,'iiii');
              item1.checkList.splice(index,1)
            }
          })
        }
        this.$set(this.formLists,indexs,items)
      }
    },
    // 字段子节点设置
    handleCheckboxChange(checkedValues,row) {
      console.log(checkedValues,row,'checkedValues');
      if(!row.children) {
        row.checkList = checkedValues;
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          item.children.forEach((item1,index1) => {
            if (item1.renderKey == row.renderKey) {
              console.log(item1,'item1item1');
              this.$set(item1,'checkList',row.checkList)
              indexs = index
              items = item
            }
          })
        })
        this.$set(this.formLists,indexs,items)
        this.formLists.forEach((item,index) => {
          let list1 = JSON.parse(JSON.stringify(item.checkList))
          let isTrue1 = true
          let isTrue2 = true
          item.children.forEach(item1 => {
            if(item1.checkList.indexOf(2) == -1) {
              isTrue1 = false
            }
            if(item1.checkList.indexOf(3) == -1) {
              isTrue2 = false
            }
          })
          console.log(isTrue1,'isTrue1');
          if(isTrue1) {
            if(list1.indexOf(2) == -1) {
              list1.push(2)
            }
          } else {
            if(list1.indexOf(2) != -1) {
              let index = list1.indexOf(2)
              list1.splice(index,1)
            }
          }
          if(isTrue2) {
            if(list1.indexOf(3) == -1) {
              list1.push(3)
            }
          } else {
            if(list1.indexOf(3) != -1) {
              let index = list1.indexOf(3)
              list1.splice(index,1)
            }
          }
          this.$set(this.formLists[index],'checkList',list1)
        })
      }
      console.log(this.formLists,'this.formLists');
      this.$forceUpdate();
    },
    computedExecutionListenerLength() {
      this.executionListenerLength =
        this.element.businessObject.extensionElements?.values?.filter(
          (item) => item.$type === "flowable:ExecutionListener"
        ).length ?? 0;
    },
    computedTaskListenerLength() {
      this.taskListenerLength =
        this.element.businessObject.extensionElements?.values?.filter(
          (item) => item.$type === "flowable:TaskListener"
        ).length ?? 0;
    },
    computedHasMultiInstance() {
      if (this.element.businessObject.loopCharacteristics) {
        this.hasMultiInstance = true;
      } else {
        this.hasMultiInstance = false;
      }
    },
    // 获取表单信息
    getListForm() {
      listAllForm().then((res) => {
        res.data.forEach((item) => {
          item.formId = item.formId.toString();
        });
        this.formList = res.data;
      });
    },
    // 设计器右侧表单数据回显
    checkValuesEcho() {
      const that = this;
      const attrs = that.element.businessObject.$attrs;
      const businessObject = that.element.businessObject;
      console.log(that.element.businessObject, "this.element.businessObject");
      if(this.formData.overStatus == undefined || this.formData.overStatus == '') {
        this.formData.overStatus = '0'
      }
      // 指定用户
      if (attrs.hasOwnProperty("flowable:assignee")) {
        const val = attrs["flowable:assignee"];
        console.log(val,that.userList,'that.expListthat.expListthat.expList',attrs["flowable:dataType"]);
        // 查找是否动态指定人员(选中流程表达式)
        if (attrs["flowable:dataType"] === "dynamic") {
          this.checkValues = that.expList.find(
            (item) => item.expression == val
          ).name;
          this.selectValues = that.expList.find(
            (item) => item.expression == val
          ).id;
        } else {
          this.checkValues = that.userList.find(
            (item) => item.userId == val
          ).nickName;
          let param = that.userList.find(
            (item) => item.userId == val
          )
          this.selectValues = that.userList.find(
            (item) => item.userId == val
          ).userId;
          this.selectValues = Number(this.selectValues)
          console.log(typeof this.selectValues,'this.selectValues',param);
        }
        // 候选用户
      } else if (attrs.hasOwnProperty("flowable:candidateUsers")) {
        const val = attrs["flowable:candidateUsers"];
        if (attrs["flowable:dataType"] === "dynamic") {
          this.checkValues = that.expList.find(
            (item) => item.expression == val
          ).name;
          this.selectValues = that.expList.find(
            (item) => item.expression == val
          ).id;
        } else {
          const newArr = that.userList.filter((i) =>
            val.split(",").includes(i.userId)
          );
          this.checkValues = newArr.map((item) => item.nickName).join(",");
          this.selectValues = newArr.map((item) => item.userId).join(",");
          console.log(newArr,'newArrnewArrnewArrnewArr',this.checkValues,this.selectValues);
        }
      } else if (
        businessObject.hasOwnProperty("candidateGroups") ||
        attrs.hasOwnProperty("flowable:candidateGroups")
      ) {
        // 候选角色信息
        const val =
          businessObject["candidateGroups"] ||
          attrs["flowable:candidateGroups"];
        if (attrs["flowable:dataType"] === "dynamic") {
          this.checkValues = that.expList.find(
            (item) => item.expression == val
          ).name;
          this.selectValues = that.expList.find(
            (item) => item.expression == val
          ).id;
        } else {
          const newArr = that.groupList.filter((i) =>
            val.split(",").includes(i.roleId)
          );
          this.checkValues = newArr.map((item) => item.roleName).join(",");
          this.selectValues = newArr.map((item) => item.roleId).join(",");
        }
        console.log(this.selectValues,'this.selectValuesthis.selectValues');
      }
    },
    finishExecutionListener() {
      if (this.dialogName === "executionListenerDialog") {
        this.computedExecutionListenerLength();
      }
      this.dialogName = "";
    },
    finishTaskListener() {
      if (this.dialogName === "taskListenerDialog") {
        this.computedTaskListenerLength();
      }
      this.dialogName = "";
    },
    finishMultiInstance() {
      if (this.dialogName === "multiInstanceDialog") {
        this.computedHasMultiInstance();
      }
      this.dialogName = "";
    },
    /*单选人员*/
    singleUserCheck() {
      this.userVisible = true;
      this.checkType = "single";
    },
    /*多选人员*/
    multipleUserCheck() {
      this.userVisible = true;
      this.checkType = "multiple";
    },
    /*单选角色*/
    singleRoleCheck() {
      this.roleVisible = true;
      this.checkType = "single";
    },
    /*多选角色*/
    multipleRoleCheck() {
      this.roleVisible = true;
      this.checkType = "multiple";
    },
    /*单选表达式*/
    singleExpCheck(expType) {
      this.expVisible = true;
      this.expType = expType;
    },
    // 选中表达式
    handleSingleExpSelect(selection) {
      this.deleteFlowAttar();
      this.updateProperties({ "flowable:dataType": "dynamic" });
      if ("assignee" === this.expType) {
        this.updateProperties({ "flowable:assignee": selection.expression });
      } else if ("candidateUsers" === this.expType) {
        this.updateProperties({
          "flowable:candidateUsers": selection.expression,
        });
      } else if ("candidateGroups" === this.expType) {
        this.updateProperties({
          "flowable:candidateGroups": selection.expression,
        });
      }
      this.checkValues = selection.name;
      this.expType = null;
    },
    // 用户选中数据
    handleUserSelect(selection) {
      const that = this;
      if (selection) {
        that.deleteFlowAttar();
        that.updateProperties({ "flowable:dataType": "fixed" });
        if (selection instanceof Array) {
          let list = that.selectValues ? that.selectValues.split(',') : []
          let list1 = that.checkValues ? that.checkValues.split(',') : []
          selection.forEach(item => {
            if (item.checked) {
              if (list.indexOf(item.userId.toString()) === -1) {
                list.push(item.userId.toString())
                list1.push(item.nickName)
              }
            } else {
              let index1 = list.indexOf(item.userId.toString())
              if (index1 !== -1) {
                list.splice(index1, 1)
                list1.splice(index1, 1)
              }
            }
          })
          that.selectValues = list.join(',')
          that.checkValues = list1.join(',')
          console.log(that.selectValues,'更新',that.checkValues);
          that.updateProperties({
            "flowable:candidateUsers": that.selectValues,
          });
        } else {
          that.updateProperties({ "flowable:assignee": selection.userId });
          that.checkValues = selection.nickName;
        }
      }
    },
    // 角色选中数据
    handleRoleSelect(selection, name) {
      const that = this;
      if (selection) {
        that.deleteFlowAttar();
        that.updateProperties({ "flowable:dataType": "fixed" });
        if (selection instanceof Array) {
          let list = that.selectValues ? that.selectValues.split(',') : []
          let list1 = that.checkValues ? that.checkValues.split(',') : []
          selection.forEach(item => {
            if (item.checked) {
              if (list.indexOf(item.roleId.toString()) === -1) {
                list.push(item.roleId.toString())
                list1.push(item.roleName)
              }
            } else {
              let index1 = list.indexOf(item.roleId.toString())
              if (index1 !== -1) {
                list.splice(index1, 1)
                list1.splice(index1, 1)
              }
            }
          })
          that.selectValues = list.join(',')
          that.checkValues = list1.join(',')
          console.log(that.selectValues,'更新',that.checkValues);
          that.updateProperties({ "flowable:candidateGroups": that.selectValues });
        } else {
          that.updateProperties({ "flowable:candidateGroups": selection });
          that.checkValues = name;
        }
      }
    },
    /*用户选中赋值*/
    checkUserComplete() {
      this.userVisible = false;
      this.checkType = "";
    },
    /*候选角色选中赋值*/
    checkRoleComplete() {
      this.roleVisible = false;
      this.checkType = "";
    },
    /*表达式选中赋值*/
    checkExpComplete() {
      this.expVisible = false;
    },
    // 删除节点
    deleteFlowAttar() {
      delete this.element.businessObject.$attrs[`flowable:dataType`];
      delete this.element.businessObject.$attrs[`flowable:assignee`];
      delete this.element.businessObject.$attrs[`flowable:candidateUsers`];
      delete this.element.businessObject.$attrs[`flowable:candidateGroups`];
    },
  },
};
</script>

<style lang="scss">
  .el-table .parent-row {
    background-color: #e7e7e7;
  }
  .edit-form-field {
    .el-table__body-wrapper {
      max-height: 520px;
      overflow-y: auto;
    }
  }
</style>
