<template>
  <div class="container">
    <div class="left-board" v-if="!showlr">
      <div class="logo-wrapper">
        <div class="logo">
          <img :src="logo" alt="logo"> Form Generator
        </div>
      </div>
      <el-scrollbar class="left-scrollbar">
        <div class="components-list">
          <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
            <div class="components-title">
              <svg-icon icon-class="component"/>
              {{ item.title }}
            </div>
            <draggable
                :clone="cloneComponent"
                :group="{ name: 'componentsGroup', pull: 'clone', put: false }"
                :list="item.list"
                :sort="false"
                class="components-draggable"
                draggable=".components-item"
                @end="onEnd"
            >
              <div
                  v-for="(element, index) in item.list"
                  :key="index"
                  class="components-item"
                  @click="addComponent(element)"
              >
                <div class="components-body">
                  <svg-icon :icon-class="element.__config__.tagIcon"/>
                  {{ element.__config__.label || element.__config__.labelName }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <div :class="['center-board',showlr ? 'center-boards' : '']">
      <div class="action-bar" v-if="!showlr">
        <el-button icon="el-icon-edit" type="text" @click="setRules">
          设置业务规则
        </el-button>
        <el-button class="delete-btn" icon="el-icon-delete" type="text" @click="empty">
          清空
        </el-button>
      </div>
      <el-scrollbar class="center-scrollbar">
        <el-row class="center-board-row" :gutter="formConf.gutter">
          <el-form
            :size="formConf.size"
            :label-position="formConf.labelPosition"
            :disabled="formConf.disabled"
            :label-width="formConf.labelWidth + 'px'"
          >
            <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup">
              <draggable-item
                  v-for="(item, index) in drawingList"
                  :key="item.renderKey"
                :drawing-list="drawingList"
                  :current-item="item"
                :index="index"
                :active-id="activeId"
                :form-conf="formConf"
                @activeItem="activeFormItem"
                @copyItem="drawingItemCopy"
                @deleteItem="drawingItemDelete"
              />
            </draggable>
            <div v-show="!drawingList.length" class="empty-info">
              从左侧拖入或点选组件进行表单设计
            </div>
          </el-form>
        </el-row>
      </el-scrollbar>
    </div>

    <right-panel
      v-if="!showlr"
      :active-data="activeData"
      :form-conf="formConf"
      :show-field="!!drawingList.length"
      @tag-change="tagChange"
      @fetch-data="fetchData"
      :drawingList="drawingList"
      :isModule="true"
    />

    <form-drawer
        :visible.sync="drawerVisible"
        :form-data="formData"
        size="100%"
        :generate-conf="generateConf"
    />
    <json-drawer
        size="60%"
        :visible.sync="jsonDrawerVisible"
        :json-str="JSON.stringify(formData)"
        @refresh="refreshJson"
    />
    <code-type-dialog
      :visible.sync="dialogVisible"
      title="选择生成类型"
      :show-file-name="showFileName"
      @confirm="generate"
    />
    <input id="copyNode" type="hidden">
    <!--表单配置详情-->
    <el-dialog :title="formTitle" :visible.sync="formOpen" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="表单名称" prop="formName">
          <el-input v-model="form.formName" placeholder="请输入表单名称" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <set-rules ref="setRules" :formLists="formLists" :rulesDatas="rulesDatas" @conRules="conRules"></set-rules>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {debounce} from 'throttle-debounce'
import {saveAs} from 'file-saver'
import ClipboardJS from 'clipboard'
import render from '@/components/render/render'
import FormDrawer from './FormDrawer'
import JsonDrawer from './JsonDrawer'
import RightPanel from './RightPanel'
import {formConf, inputComponents, layoutComponents, selectComponents} from '@/utils/generator/config'
import {beautifierConf, deepClone, isObjectObject, titleCase} from '@/utils/index'
import {cssStyle, makeUpHtml, vueScript, vueTemplate} from '@/utils/generator/html'
import {makeUpJs} from '@/utils/generator/js'
import {makeUpCss} from '@/utils/generator/css'
import drawingDefault from '@/utils/generator/drawingDefault'
import logo from '@/assets/logo/logo.png'
import CodeTypeDialog from './CodeTypeDialog'
import DraggableItem from './DraggableItems'
import {getDrawingList, getFormConf, getIdGlobal, saveDrawingList, saveIdGlobal} from '@/utils/db'
import loadBeautifier from '@/utils/loadBeautifier'
import {addForm, getForm, updateForm} from "@/api/flowable/form";
import { bpmProjectForm } from "@/api/system/project";
import SetRules from './setRules.vue'
let beautifier
const emptyActiveData = {style: {}, autosize: {}}
let oldActiveId
let tempActiveData
const idGlobal = getIdGlobal()

export default {
  components: {
    draggable,
    render,
    FormDrawer,
    JsonDrawer,
    RightPanel,
    CodeTypeDialog,
    DraggableItem,
    SetRules
  },
  props:{
    formcont: {
      type: Object,
      default() {
        return null
      }
    },
    formId: {
      type: Number,
      default() {
        return null
      }
    },
    moduleLayout: {
      type: String,
      default() {
        return null
      }
    },
    showlr: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      logo,
      idGlobal,
      formConf,
      inputComponents,
      selectComponents,
      layoutComponents,
      labelWidth: 100,
      drawingList: drawingDefault,
      drawingData: {},
      activeId: drawingDefault[0].formId,
      drawerVisible: false,
      formData: {},
      dialogVisible: false,
      jsonDrawerVisible: false,
      generateConf: null,
      showFileName: false,
      activeData: drawingDefault[0],
      saveIdGlobalDebounce: debounce(340, saveIdGlobal),
      leftComponents: [
        {
          title: '输入型组件',
          list: inputComponents
        },
        {
          title: '选择型组件',
          list: selectComponents
        },
        // {
        //   title: '布局型组件',
        //   list: layoutComponents
        // }
      ],
      formOpen: false,
      formTitle: "",
      // 表单参数
      form: {
        formId: null,
        formName: null,
        formContent: null,
        remark: null
      },
      // 表单校验
      rules: {},
      projectId:'',

      rulesDatas:[],
      formLists:[],
      formListsOld:[],
    }
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line func-names
    'activeData.__config__.label': function (val, oldVal) {
      if (
        this.activeData.placeholder === undefined
          || !this.activeData.__config__.tag
        || oldActiveId !== this.activeId
      ) {
        return
      }
      this.activeData.placeholder = this.activeData.placeholder.replace(oldVal, '') + val
    },
    activeId: {
      handler(val) {
        oldActiveId = val
      },
      immediate: true
    },
    drawingList: {
      handler(val) {
        if (val.length === 0) this.idGlobal = 100
      },
      deep: true
    },
    idGlobal: {
      handler(val) {
        this.saveIdGlobalDebounce(val)
      },
      immediate: true
    }
  },
  created() {
    console.log(this.formcont,'variablesDatavariablesData',this.formId);
    // this.projectId = this.$route.query.projectId
  },
  mounted() {
    const that = this;
    this.activeFormItem(that.drawingList[0])
    that.drawingList = [];
    if(that.formcont) {
      that.formConf = JSON.parse(JSON.stringify(that.formcont))
      that.drawingList = that.formConf.fields;
      console.log(that.formcont,'variablesDatavariablesData',that.formConf.fields);
      console.log(that.drawingList,'that.drawingList');
    }
    loadBeautifier(btf => {
      beautifier = btf
    })
    const clipboard = new ClipboardJS('#copyNode', {
      text: trigger => {
        const codeStr = this.generateCode()
        this.$notify({
          title: '成功',
          message: '代码已复制到剪切板，可粘贴。',
          type: 'success'
        })
        return codeStr
      }
    })
    clipboard.on('error', e => {
      this.$message.error('代码复制失败')
    })
  },
  methods: {
    // 设置业务规则
    setRules() {
      if(!this.subCheck()) {
        return false
      }
      this.formLists = []
      console.log(this.drawingList,'this.drawingList111',this.formConf);
      this.drawingList.forEach(item => {
        let obj = item.__config__
        console.log(item.__config__,'item.__config__');
        if(obj.layout == "tsSubform" &&( obj.defaultValue.length && obj.defaultValue)) {
          obj.children.forEach(item2 => {
            item2.__config__.defaultValue = []
          })
          obj.defaultValue.forEach((item1,index1) => {
            let list = []
            obj.children.forEach(item2 => {
              let indexs = Object.keys(item1).indexOf(item2.__vModel__)
              if(indexs != -1) {
                item2.__config__.defaultValue.push(item1[Object.keys(item1)[indexs]])
              }
            })
            console.log(list,'listlist');
          })
        }
        obj.children.forEach(item1 => {
          item1.renderKey = item1.__config__.renderKey
          item1.labelName = item1.__config__.label
        })
        this.formLists.push(obj)
      })
      if(this.formConf.rulesForm) {
        this.rulesDatas = this.formConf.rulesForm
      }
      console.log(this.rulesDatas,this.formConf,'1111');
      this.$refs.setRules.rulesOpen = true
    },
    conRules(value) {
      this.formConf.rulesForm = value
      console.log(value,'rulesDatarulesData',this.formConf);
    },
    setObjectValueReduce(obj, strKeys, data) {
      const arr = strKeys.split('.')
      arr.reduce((pre, item, i) => {
        if (arr.length === i + 1) {
          pre[item] = data
        } else if (!isObjectObject(pre[item])) {
          pre[item] = {}
        }
        return pre[item]
      }, obj)
    },
    setRespData(component, resp) {
      const {dataPath, renderKey, dataConsumer} = component.__config__
      if (!dataPath || !dataConsumer) return
      const respData = dataPath.split('.').reduce((pre, item) => pre[item], resp)

      // 将请求回来的数据，赋值到指定属性。
      // 以el-tabel为例，根据Element文档，应该将数据赋值给el-tabel的data属性，所以dataConsumer的值应为'data';
      // 此时赋值代码可写成 component[dataConsumer] = respData；
      // 但为支持更深层级的赋值（如：dataConsumer的值为'options.data'）,使用setObjectValueReduce
      this.setObjectValueReduce(component, dataConsumer, respData)
      const i = this.drawingList.findIndex(item => item.__config__.renderKey === renderKey)
      if (i > -1) this.$set(this.drawingList, i, component)
    },
    fetchData(component) {
      const {dataType, method, url} = component.__config__
      if (dataType === 'dynamic' && method && url) {
        // this.setLoading(component, true)
        // this.$axios({
        //   method,
        //   url
        // }).then(resp => {
        //   this.setLoading(component, false)
        //   this.setRespData(component, resp.data)
        // })
      }
    },
    setLoading(component, val) {
      const {directives} = component
      if (Array.isArray(directives)) {
        const t = directives.find(d => d.name === 'loading')
        if (t) t.value = val
      }
    },
    activeFormItem(currentItem) {
      this.activeData = currentItem
      this.activeId = currentItem.__config__.formId
    },
    onEnd(obj) {
      console.log('11111',this.drawingList);
      this.drawingList = this.drawingList.filter(item => item.__config__)
      this.formConf.fields = this.drawingList
      this.drawingList.forEach((item) => {
        if (item.__config__.rulesForm && item.__config__.rulesForm.length) {
          item.__config__.rulesForm.forEach((item1) => {
            if (this.formConf.rulesForm) {
              if (
                !this.formConf.rulesForm.some((item2) => item2.id === item1.id)
              ) {
                this.formConf.rulesForm.push(item1);
              }
            } else {
              this.formConf.rulesForm = [];
              this.formConf.rulesForm.push(item1);
            }
          });
        }
      });
      if (obj.from !== obj.to) {
        this.fetchData(tempActiveData)
        this.activeData = tempActiveData
        this.activeId = this.idGlobal
      }
    },
    addComponent(item) {
      const clone = this.cloneComponent(item)
      this.fetchData(clone)
      this.drawingList.push(clone)
      this.activeFormItem(clone)
    },
    cloneComponent(origin) {
      const clone = deepClone(origin)
      const config = clone.__config__
      config.span = this.formConf.span // 生成代码时，会根据span做精简判断
      this.createIdAndKey(clone)
      clone.placeholder !== undefined && (clone.placeholder += config.label)
      tempActiveData = clone
      return tempActiveData
    },
    createIdAndKey(item) {
      console.log('进急你积极能年年红',item);
      const config = item.__config__
      config.formId = ++this.idGlobal
      config.renderKey = `${config.formId}${+new Date()}` // 改变renderKey后可以实现强制更新组件
      if (config.layout === 'colFormItem') {
        if(!item.__vModel__) {
          item.__vModel__ = ''
          item.__config__.label = '请绑定字段'
        }
      } else if (config.layout === 'rowFormItem' || config.layout === 'tsSubform') {
        config.componentName =  config.labelName ? config.labelName : `row${this.idGlobal}`
        !Array.isArray(config.children) && (config.children = [])
        delete config.label // rowFormItem无需配置label属性
      }
      if (Array.isArray(config.children)) {
        config.children = config.children.map(childItem => this.createIdAndKey(childItem))
      }
      return item
    },
    AssembleFormData() {
      this.formData = {
        ...this.formConf
      }
      this.formData.fields = deepClone(this.drawingList);
    },
    generate(data) {
      const func = this[`exec${titleCase(this.operationType)}`]
      this.generateConf = data
      func && func(data)
    },
    execRun(data) {
      this.AssembleFormData()
      this.drawerVisible = true
    },
    execDownload(data) {
      const codeStr = this.generateCode()
      const blob = new Blob([codeStr], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, data.fileName)
    },
    execCopy(data) {
      document.getElementById('copyNode').click()
    },
    empty() {
      this.$confirm('确定要清空所有组件吗？', '提示', { type: 'warning' }).then(
        () => {
          this.drawingList = []
          this.idGlobal = 100
        }
      )
    },
    drawingItemCopy(item, list) {
      let clone = deepClone(item)
      clone = this.createIdAndKey(clone)
      list.push(clone)
      this.activeFormItem(clone)
    },
    drawingItemDelete(index, list) {
      list.splice(index, 1)
      this.$nextTick(() => {
        const len = this.drawingList.length
        if (len) {
          this.activeFormItem(this.drawingList[len - 1])
        }
      })
    },
    generateCode() {
      const { type } = this.generateConf
      this.AssembleFormData()
      const script = vueScript(makeUpJs(this.formData, type))
      const html = vueTemplate(makeUpHtml(this.formData, type))
      const css = cssStyle(makeUpCss(this.formData))
      return beautifier.html(html + script + css, beautifierConf.html)
    },
    showJson() {
      this.AssembleFormData()
      this.jsonDrawerVisible = true
    },
    download() {
      this.dialogVisible = true
      this.showFileName = true
      this.operationType = 'download'
    },
    run() {
      this.dialogVisible = true
      this.showFileName = false
      this.operationType = 'run'
    },
    copy() {
      this.dialogVisible = true
      this.showFileName = false
      this.operationType = 'copy'
    },
    tagChange(newTag) {
      newTag = this.cloneComponent(newTag);
      const newData =  {
        ...newTag,
        "__config__": {
            ...newTag.__config__,
            "label": this.activeData.__config__.label,
            "span": this.activeData.__config__.span,
            // "regList": this.activeData.__config__.regList || [],
            "renderKey": this.activeData.__config__.renderKey,
            "formId": this.activeData.__config__.formId,
            "required": this.activeData.__config__.required,
            "tag": newTag.__config__.tag,
            "tagIcon": newTag.__config__.tagIcon,
        },
        "labelName": this.activeData.labelName,
        "originalName": this.activeData.originalName,
        "placeholder": this.activeData.placeholder,
        "disabled": this.activeData.disabled,
        "renderKey": this.activeData.renderKey,
        "__vModel__": this.activeData.__vModel__
      };
      if(newTag.__config__.defaultValue instanceof Array) {
        this.activeData.__config__.defaultValue = []
      }
      this.activeData = newData
      this.updateDrawingList(newData, this.drawingList)
    },
    updateDrawingList(newTag, list) {
      const index = list.findIndex(item => item.__config__.formId === this.activeId)
      if (index > -1) {
        list.splice(index, 1, newTag)
      } else {
        list.forEach(item => {
          if (Array.isArray(item.__config__.children)) this.updateDrawingList(newTag, item.__config__.children)
        })
      }
    },
    refreshJson(data) {
      this.drawingList = deepClone(data.fields)
      delete data.fields
      this.formConf = data
    },
    // 判断表单是否符合正常格式
    subCheck() {
      let isTrue = true
      let isFiled = this.drawingList.every(
        (obj) => obj.__config__ && obj.__config__.children
      );
      if (!isFiled) {
        this.$modal.msgError("请删除模块外的字段");
        isTrue = false;
        return false
      }
      console.log("drawingList", this.drawingList);
      let nameList = []
      let isBind = true
      this.drawingList.forEach(item => {
        item.__config__.children.forEach(item1 => {
          if(!item1.__vModel__) {
            isBind = false
            nameList.push(item.__config__.componentName || item.__config__.labelName)
          }
        })
      })
      console.log(nameList,'namenamename');
      if (!isBind) {
        this.$modal.msgError(`请确定  “${nameList.join('、')}”  模块内的字段都已完成绑定！`);
        isTrue = false;
      }
      return isTrue
    },
    /** 表单基本信息 */
    handleForm(){
      if(!this.subCheck()) {
        return false
      }
      this.formData = {
        ...this.formConf
      }
      this.formData.fields = deepClone(this.drawingList);
      console.log(this.formData,'this.formDatathis.formData');
      if(this.formConf.rulesForm && this.formConf.rulesForm.length) {
        this.formConf.rulesForm.forEach((item,index) => {
          item.id = this.formId + '_' + index
        })
      }
      this.formData.fields[0].__config__.rulesForm = this.formConf.rulesForm
      console.log(this.formData.fields[0],'this.formData.fields[0]this.formData.fields[0]',this.moduleLayout);
      if(this.moduleLayout == 'tsSubform') {
        this.formData.fields[0].__config__.allowMultiple = true
      } else {
        this.formData.fields[0].__config__.allowMultiple = false
      }
      this.$emit('buildForm',this.formData.fields[0])
      // this.form.formContent = JSON.stringify(this.formData);
      // this.formOpen = true;
      // this.formTitle = "添加表单";
    },
    // 表单重置
    reset() {
      this.form = {
        formId: null,
        formName: null,
        formContent: null,
        remark: null
      };
      this.resetForm("form");
    },
    // 取消按钮
    cancel() {
      this.formOpen = false;
      this.reset();
    },
    /** 保存表单信息 */
    submitForm(){
      this.$refs["form"].validate(valid => {
        if (valid) {
          // if (this.form.formId != null) {
          //   updateForm(this.form).then(response => {
          //     this.$modal.msgSuccess("修改成功");
          //     if(this.projectId) {
          //       bpmProjectForm({projectId:this.projectId,formId:this.form.formId}).then(res => {})
          //       const obj = { path: "/project/addProject", query: { proId: this.projectId,step:'2'} };
          //       this.$tab.closeOpenPage(obj);
          //     } else {
          //       const obj = { path: "/flowable/form", query: { t: Date.now()} };
          //       this.$tab.closeOpenPage(obj);
          //     }
              
          //   });
          //   // const obj = { path: "/flowable/form", query: { t: Date.now()} };
          //   // this.$tab.closeOpenPage(obj);
          // } else {
            addForm(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              if(this.projectId) {
                bpmProjectForm({projectId:this.projectId,formId:response.data.formId}).then(res => {})
                const obj = { path: "/project/addProject", query: { projectId: this.projectId,step:'2'} };
                this.$tab.closeOpenPage(obj);
              } else {
                const obj = { path: "/flowable/form", query: { t: Date.now()} };
                this.$tab.closeOpenPage(obj);
              }
            });
          // }
          this.drawingList = []
          this.idGlobal = 100
          this.open = false;

          // 绑定项目
          
          
        }
      });
    }
  }
}
</script>

<style lang='scss'>
.editor-tabs{
  background: #121315;
  .el-tabs__header{
    margin: 0;
    border-bottom-color: #121315;
    .el-tabs__nav{
      border-color: #121315;
    }
  }
  .el-tabs__item{
    height: 32px;
    line-height: 32px;
    color: #888a8e;
    border-left: 1px solid #121315 !important;
    background: #363636;
    margin-right: 5px;
    user-select: none;
  }
  .el-tabs__item.is-active{
    background: #1e1e1e;
    border-bottom-color: #1e1e1e!important;
    color: #fff;
  }
  .el-icon-edit{
    color: #f1fa8c;
  }
  .el-icon-document{
    color: #a95812;
  }
}

// home
.right-scrollbar {
  .el-scrollbar__view {
    padding: 12px 18px 15px 15px;
  }
}
.left-scrollbar .el-scrollbar__wrap {
  box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.center-tabs{
  .el-tabs__header{
    margin-bottom: 0!important;
  }
  .el-tabs__item{
    width: 50%;
    text-align: center;
  }
  .el-tabs__nav{
    width: 100%;
  }
}
.reg-item{
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
  .close-btn{
    position: absolute;
    right: -6px;
    top: -6px;
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    font-size: 12px;
    &:hover{
      background: rgba(210, 23, 23, 0.5)
    }
  }
  & + .reg-item{
    margin-top: 18px;
  }
}
.action-bar{
  & .el-button+.el-button {
    margin-left: 15px;
  }
  & i {
    font-size: 20px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}

.custom-tree-node{
  width: 100%;
  font-size: 14px;
  .node-operation{
    float: right;
  }
  i[class*="el-icon"] + i[class*="el-icon"]{
    margin-left: 6px;
  }
  .el-icon-plus{
    color: #409EFF;
  }
  .el-icon-delete{
    color: #157a0c;
  }
}

.left-scrollbar .el-scrollbar__view{
  overflow-x: hidden;
}

.el-rate{
  display: inline-block;
  vertical-align: text-top;
}
.el-upload__tip{
  line-height: 1.2;
}

$selectedColor: #f6f7ff;
$lighterBlue: #409EFF;

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.components-list {
  padding: 8px;
  box-sizing: border-box;
  height: 100%;
  .components-item {
    display: inline-block;
    width: 48%;
    margin: 1%;
    transition: transform 0ms !important;
  }
}
.components-draggable{
  padding-bottom: 20px;
}
.components-title{
  font-size: 14px;
  color: #222;
  margin: 6px 2px;
  .svg-icon{
    color: #666;
    font-size: 18px;
  }
}

.components-body {
  padding: 8px 10px;
  background: $selectedColor;
  font-size: 12px;
  cursor: move;
  border: 1px dashed $selectedColor;
  border-radius: 3px;
  .svg-icon{
    color: #777;
    font-size: 15px;
  }
  &:hover {
    border: 1px dashed #787be8;
    color: #787be8;
    .svg-icon {
      color: #787be8;
    }
  }
}

.left-board {
  width: 260px;
  position: absolute;
  left: 0;
  top: 0;
  height: 80vh;
}
.left-scrollbar{
  height: calc(80vh - 42px);
  overflow: hidden;
}
.center-scrollbar {
  height: calc(80vh - 42px);
  overflow: hidden;
  border-left: 1px solid #f1e8e8;
  border-right: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.center-board {
  height: 80vh;
  width: auto;
  margin: 0 350px 0 260px;
  box-sizing: border-box;
}
.center-boards {
  margin: 0 !important;
}
.empty-info{
  position: absolute;
  top: 46%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #ccb1ea;
  letter-spacing: 4px;
}
.action-bar{
  position: relative;
  height: 42px;
  text-align: right;
  padding: 0 15px;
  box-sizing: border-box;;
  border: 1px solid #f1e8e8;
  border-top: none;
  border-left: none;
  .delete-btn{
    color: #F56C6C;
  }
}
.logo-wrapper{
  position: relative;
  height: 42px;
  background: #fff;
  border-bottom: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.logo{
  position: absolute;
  left: 12px;
  top: 6px;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
  > img{
    width: 30px;
    height: 30px;
    vertical-align: top;
  }
  .github{
    display: inline-block;
    vertical-align: sub;
    margin-left: 15px;
    > img{
      height: 22px;
    }
  }
}

.center-board-row {
  padding: 12px 12px 15px 12px;
  box-sizing: border-box;
  & > .el-form {
    // 69 = 12+15+42
    height: calc(80vh - 69px);
  }
}
.drawing-board {
  height: 100%;
  position: relative;
  .components-body {
    padding: 0;
    margin: 0;
    font-size: 0;
  }
  .sortable-ghost {
    position: relative;
    display: block;
    overflow: hidden;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 3px;
      background: rgb(89, 89, 223);
      z-index: 2;
    }
  }
  .components-item.sortable-ghost {
    width: 100%;
    height: 60px;
    background-color: $selectedColor;
  }
  .active-from-item {
    & > .el-form-item{
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy{
      display: none;
    } 
    & > .drawing-item-delete{
      display: initial;
    }
    & > .component-name{
      color: $lighterBlue;
    }
  }
  .el-form-item{
    margin-bottom: 15px;
  }
}
.drawing-item{
  position: relative;
  cursor: move;
  &.unfocus-bordered:not(.activeFromItem) > div:first-child  {
    border: 1px dashed #ccc;
  }
  .el-form-item{
    padding: 12px 10px;
  }
}
.drawing-row-item{
  position: relative;
  cursor: move;
  box-sizing: border-box;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 50px 28px;
  margin-bottom: 15px;
  .drawing-row-item {
    margin-bottom: 2px;
  }
  .el-col{
    margin-top: 22px;
  }
  .el-form-item{
    margin-bottom: 0;
  }
  .drag-wrapper{
    display: table;
    width: 100%;
  }
  &.active-from-item{
    border: 1px dashed $lighterBlue;
  }
  .component-name{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #bbb;
    display: inline-block;
    padding: 0 6px;
  }
}
.drawing-item, .drawing-row-item{
  &:hover {
    & > .el-form-item{
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy{
      display: none;
    } 
    & > .drawing-item-delete{
      display: initial;
    }
  }
  & > .drawing-item-copy, & > .drawing-item-delete{
    display: none;
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid;
    cursor: pointer;
    z-index: 1;
  }
  & > .drawing-item-copy{
    right: 56px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover{
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-delete{
    right: 24px;
    border-color: #F56C6C;
    color: #F56C6C;
    background: #fff;
    &:hover{
      background: #F56C6C;
      color: #fff;
    }
  }
}
.drawing-row-items{
  position: relative;
  cursor: move;
  box-sizing: border-box;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 0 2px;
  margin-bottom: 15px;
  .drawing-row-item {
    margin-bottom: 2px;
  }
  .el-col{
    margin-top: 22px;
  }
  .el-form-item{
    margin-bottom: 0;
  }
  .drag-wrapper{
     display: table;
     width: 100%;
  }
  &.active-from-item{
    border: 1px dashed $lighterBlue;
  }
  .component-name{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #bbb;
    display: inline-block;
    padding: 0 6px;
  }
}
.drawing-item, .drawing-row-items{
  &:hover {
    & > .el-form-item{
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy, & > .drawing-item-delete{
      display: initial;
    }
  }
  & > .drawing-item-copy, & > .drawing-item-delete{
    display: none;
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid;
    cursor: pointer;
    z-index: 1;
  }
  & > .drawing-item-copy{
    right: 56px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover{
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-delete{
    right: 24px;
    border-color: #F56C6C;
    color: #F56C6C;
    background: #fff;
    &:hover{
      background: #F56C6C;
      color: #fff;
    }
  }
}
</style>
