var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeData.__config__.tag === "ts-sub-form"
    ? _c(
        "div",
        [
          _c("el-divider", [_vm._v("子表单设置")]),
          _c(
            "el-form",
            { attrs: { size: "small", "label-width": "90px" } },
            [
              _vm.activeData.addButton !== undefined
                ? _c(
                    "el-form-item",
                    { attrs: { label: "添加按钮" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.activeData.addButton,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeData, "addButton", $$v)
                          },
                          expression: "activeData.addButton",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeData.deleteButton !== undefined
                ? _c(
                    "el-form-item",
                    { attrs: { label: "删除按钮" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.activeData.deleteButton,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeData, "deleteButton", $$v)
                          },
                          expression: "activeData.deleteButton",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeData.canEdit !== undefined
                ? _c(
                    "el-form-item",
                    { attrs: { label: "可编辑" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.activeData.canEdit,
                          callback: function ($$v) {
                            _vm.$set(_vm.activeData, "canEdit", $$v)
                          },
                          expression: "activeData.canEdit",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "绑定组件" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.moduleChange },
                      model: {
                        value: _vm.activeData.__config__.labelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.activeData.__config__, "labelId", $$v)
                        },
                        expression: "activeData.__config__.labelId",
                      },
                    },
                    _vm._l(_vm.fieldComList, function (item) {
                      return _c("el-option", {
                        key: item.moduleId,
                        attrs: { label: item.name, value: item.moduleId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addSubFormData },
            },
            [_vm._v(" 添加演示数据 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.settingSubFormData },
            },
            [_vm._v(" 设置子表单 ")]
          ),
          _c("el-divider"),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加演示数据,效果仅在运行后有效",
                visible: _vm.openAddSubFormData,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openAddSubFormData = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.activeData.__config__.defaultValue,
                    border: "",
                    "destroy-on-close": true,
                  },
                },
                [
                  _vm._l(
                    _vm.activeData.__config__.children,
                    function (column, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: column.__config__.label,
                          align: "center",
                          prop: column.__vModel__,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value:
                                        _vm.activeData.__config__.defaultValue[
                                          scope.$index
                                        ][column.__vModel__],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__
                                            .defaultValue[scope.$index],
                                          column.__vModel__,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.defaultValue[scope.$index][column.__vModel__]",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }
                  ),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "120px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    plain: true,
                                    type: "danger",
                                    icon: "el-icon-delete-solid",
                                    data: scope.row,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.activeData.__config__.defaultValue.splice(
                                        scope.$index,
                                        1
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      988941746
                    ),
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "left" },
                      attrs: { size: "small", icon: "el-icon-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.ts_add_rows(
                            _vm.activeData.__config__.defaultValue
                          )
                        },
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "设置子表单",
                visible: _vm.openSettingSubFormData,
                "before-close": _vm.handleCloseSetting,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openSettingSubFormData = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "align-items": "center",
                  },
                },
                _vm._l(
                  _vm.activeData.__config__.children,
                  function (column, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "margin-right": "10px" } },
                      [
                        _c("div", [_vm._v("第" + _vm._s(index + 1) + "列")]),
                        _c("el-input", {
                          attrs: { size: "small", placeholder: "请输入" },
                          model: {
                            value:
                              _vm.activeData.__config__.children[index].width,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.activeData.__config__.children[index],
                                "width",
                                $$v
                              )
                            },
                            expression:
                              "activeData.__config__.children[index].width",
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }