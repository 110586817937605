var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("x-form", {
        ref: "xForm",
        attrs: { config: _vm.formConfig },
        scopedSlots: _vm._u([
          {
            key: "taskIds",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.choseTaskId()
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "executionListener",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { value: _vm.executionListenerLength } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogName = "executionListenerDialog"
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _vm.dialogName === "executionListenerDialog"
        ? _c("executionListenerDialog", {
            attrs: { element: _vm.element, modeler: _vm.modeler },
            on: { close: _vm.finishExecutionListener },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑表单字段",
            visible: _vm.formVisible,
            width: "60%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "edit-form-field",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.formLists,
                "row-class-name": _vm.customRowClassName,
                "row-key": "renderKey",
                "default-expand-all": "",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "labelName", label: "字段名" },
              }),
              _c("el-table-column", {
                attrs: { label: "字段配置", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-checkbox-group",
                          {
                            on: {
                              input: function ($event) {
                                return _vm.handleCheckboxChange(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.checkList,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "checkList", $$v)
                              },
                              expression: "scope.row.checkList",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 2 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 3 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }