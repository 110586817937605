var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "app_con" }, [
        _c("div", { staticClass: "main_con" }, [
          _c("div", { ref: "topDocument", staticClass: "top" }, [
            _c("div", { staticClass: "head1" }, [
              _c(
                "div",
                { staticStyle: { flex: "1", "text-align": "right" } },
                [
                  ![2, 4].includes(_vm.projectData.refundStatus)
                    ? [
                        _vm.projectData.projectTaskId
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-bottom": "6px" },
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.submitPros },
                              },
                              [_vm._v("提 交")]
                            )
                          : _vm._e(),
                        _vm.projectData.refundStatus != 6 &&
                        _vm.projectData.projectTaskId
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-bottom": "6px" },
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  plain: "",
                                },
                                on: { click: _vm.rejectOpen },
                              },
                              [_vm._v("驳 回")]
                            )
                          : _vm._e(),
                        _vm.projectData.isStartHandleBy
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-bottom": "6px" },
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickButton(2)
                                  },
                                },
                              },
                              [_vm._v("作 废")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.projectData.refundType == 0
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "6px" },
                          attrs: { size: "mini", type: "success", plain: "" },
                          on: { click: _vm.showForm },
                        },
                        [_vm._v("预 览")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "6px" },
                      attrs: { size: "mini", plain: "" },
                      on: { click: _vm.goBack },
                    },
                    [_vm._v("关 闭")]
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "code" }, [
              _vm._v("项目标题：" + _vm._s(_vm.projectData.recordTitle) + " "),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "conli" }, [
                _vm._v("项目编号："),
                _c("span", [_vm._v(_vm._s(_vm.projectData.refundCode))]),
              ]),
              _c("div", { staticClass: "conli" }, [
                _vm._v("发起人："),
                _c("span", [_vm._v(_vm._s(_vm.projectData.createBy))]),
              ]),
              _c("div", { staticClass: "conli" }, [
                _vm._v("项目名称："),
                _c("span", [_vm._v(_vm._s(_vm.projectData.recordName))]),
              ]),
              _c("div", { staticClass: "conli" }, [
                _vm._v("客户编码："),
                _c("span", [_vm._v(_vm._s(_vm.projectData.customerNo))]),
              ]),
              _c(
                "div",
                {
                  staticClass: "conli",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _vm._v("办理状态："),
                  _c(
                    "span",
                    [
                      _c("dict-tag", {
                        attrs: {
                          options: _vm.dict.type.bpm_record_status,
                          value: _vm.projectData.refundStatus,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "conli" }, [
                _vm._v("项目发起时间："),
                _c("span", [_vm._v(_vm._s(_vm.projectData.refundStartTime))]),
              ]),
              _vm.projectData.tagList && _vm.projectData.tagList.length
                ? _c(
                    "div",
                    { staticClass: "conli" },
                    [
                      _vm._v("项目标签："),
                      _vm._l(_vm.projectData.tagList, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: {
                              "font-size": "12px",
                              color: "#1890ff",
                              "margin-left": "5px",
                            },
                          },
                          [_vm._v(_vm._s(item.tagValue))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isRequestCompleted,
                  expression: "isRequestCompleted",
                },
              ],
              ref: "bottom",
              staticClass: "bottom",
              style: { height: "calc(100vh - " + _vm.topHeight + "px)" },
            },
            [
              _c("div", { staticClass: "form-box" }, [
                _c("div", { staticClass: "form-right" }, [
                  _c(
                    "div",
                    { staticClass: "refundInfo" },
                    [
                      _vm._l(_vm.refundInfo, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "module-title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "formBox" },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "queryForm",
                                  refInFor: true,
                                  attrs: {
                                    size: "small",
                                    inline: true,
                                    "label-width": "160px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        item.formInfo,
                                        function (form, idx) {
                                          return [
                                            form.isShow
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    key: idx,
                                                    style: {
                                                      width:
                                                        form.width == 1
                                                          ? "100%"
                                                          : "49%",
                                                    },
                                                    attrs: {
                                                      label: form.label,
                                                      prop: "name",
                                                    },
                                                  },
                                                  [
                                                    form.type == "input"
                                                      ? _c("el-input", {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            disabled: [
                                                              "orderSn",
                                                              "orderSource",
                                                              "customerNo",
                                                              "accountStatus",
                                                            ].includes(
                                                              form.value
                                                            )
                                                              ? true
                                                              : !(
                                                                  _vm
                                                                    .projectData
                                                                    .nodeName ==
                                                                    "开始" &&
                                                                  _vm
                                                                    .projectData
                                                                    .projectTaskId
                                                                ),
                                                            placeholder:
                                                              "请输入" +
                                                              form.label,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.projectData[
                                                                form.value
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectData,
                                                                form.value,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectData[form.value]",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    form.type == "select"
                                                      ? _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled: !(
                                                                _vm.projectData
                                                                  .nodeName ==
                                                                  "开始" &&
                                                                _vm.projectData
                                                                  .projectTaskId
                                                              ),
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择" +
                                                                form.label,
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.choseTaskChanges,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.projectData[
                                                                  form.value
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.projectData,
                                                                    form.value,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "projectData[form.value]",
                                                            },
                                                          },
                                                          _vm._l(
                                                            form.list,
                                                            function (it, ix) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: ix,
                                                                  attrs: {
                                                                    label:
                                                                      it.lable,
                                                                    value:
                                                                      it.value,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    form.type == "radio"
                                                      ? _c(
                                                          "el-radio-group",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                form.value ==
                                                                "refundType"
                                                                  ? true
                                                                  : !(
                                                                      _vm
                                                                        .projectData
                                                                        .nodeName ==
                                                                        "开始" &&
                                                                      _vm
                                                                        .projectData
                                                                        .projectTaskId
                                                                    ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeRadio(
                                                                  form.value,
                                                                  _vm
                                                                    .projectData[
                                                                    form.value
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.projectData[
                                                                  form.value
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.projectData,
                                                                    form.value,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "projectData[form.value]",
                                                            },
                                                          },
                                                          [
                                                            _vm._l(
                                                              _vm.dict.type[
                                                                form.dict
                                                              ],
                                                              function (
                                                                it,
                                                                ix
                                                              ) {
                                                                return [
                                                                  (
                                                                    form.dict ==
                                                                      "order_refund_account_type" &&
                                                                    it.value ==
                                                                      2
                                                                      ? _vm
                                                                          .projectData
                                                                          .refundRoad ==
                                                                        1
                                                                      : true
                                                                  )
                                                                    ? _c(
                                                                        "el-radio",
                                                                        {
                                                                          key: ix,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                it.value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              it.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    form.type == "textarea"
                                                      ? _c("el-input", {
                                                          attrs: {
                                                            disabled: !(
                                                              _vm.projectData
                                                                .nodeName ==
                                                                "开始" &&
                                                              _vm.projectData
                                                                .projectTaskId
                                                            ),
                                                            type: "textarea",
                                                            rows: 2,
                                                            placeholder:
                                                              "请输入内容",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.projectData[
                                                                form.value
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.projectData,
                                                                form.value,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "projectData[form.value]",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "queryForm",
                              attrs: {
                                size: "small",
                                inline: true,
                                "label-width": "160px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                  },
                                },
                                [
                                  _vm.projectData.refundConfirmUrl
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { width: "49%" },
                                          attrs: {
                                            label: "退款确认书",
                                            prop: "refundConfirmUrl",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { color: "#3278fe" },
                                              attrs: {
                                                target: "_bank",
                                                href: _vm.projectData
                                                  .refundConfirmUrl,
                                              },
                                            },
                                            [_vm._v("查看退款确认书")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.projectData.accountConfirmUrl
                                    ? _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { width: "49%" },
                                          attrs: {
                                            label: "付款凭证",
                                            prop: "accountConfirmUrl",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { color: "#3278fe" },
                                              attrs: {
                                                target: "_bank",
                                                href: _vm.projectData
                                                  .accountConfirmUrl,
                                              },
                                            },
                                            [_vm._v("查看付款凭证")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "refundInfo" },
                    [
                      _c("div", { staticClass: "module-title" }, [
                        _vm._v("退款产品"),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                            "row-class-name": _vm.getRowClass,
                            "header-cell-style": {
                              background: "#f2f3f5",
                              color: "#7a7e83",
                            },
                            data: _vm.projectData.list,
                            "expand-row-keys": _vm.expandRowKeys,
                            "row-key": _vm.getRowKey,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return props.row.refundRecordList &&
                                      props.row.refundRecordList.length
                                      ? [
                                          _c(
                                            "el-table",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                padding: "0 10px",
                                                "box-sizing": "border-box",
                                              },
                                              attrs: {
                                                data: props.row
                                                  .refundRecordList,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "recordCode",
                                                  label: "关联工单号",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#1890ff",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.tosend(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .recordCode
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "isInvalid",
                                                  label: "工单是否作废",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.getHaveHandle(),
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeInvalid(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .isInvalid,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "isInvalid",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.isInvalid",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: "1",
                                                                  },
                                                                },
                                                                [_vm._v("作废")]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: "2",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "不作废"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "address",
                                                  label: "作废原因",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "90%",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.getHaveHandle(),
                                                              placeholder:
                                                                "请输入作废原因",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeInvalid(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .invalidDesc,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "invalidDesc",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.invalidDesc",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : undefined
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productId",
                              width: "60",
                              label: "产品ID",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productName",
                              label: "产品名称",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productDesc",
                              label: "产品规格",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productPrice",
                              label: "实收金额",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(scope.row.productPrice) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "退款金额", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.refundProductPrice)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "数量",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.refundNum)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "产品主体", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.productSubject) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "应扣成本",
                              align: "center",
                              prop: "refundCostPrice",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.projectData.nodeName &&
                                    (_vm.projectData.nodeName.indexOf("财务") >
                                      -1 ||
                                      _vm.projectData.nodeName.indexOf("上级") >
                                        -1) &&
                                    _vm.projectData.projectTaskId
                                      ? _c("el-input-number", {
                                          staticStyle: { width: "90%" },
                                          attrs: { size: "mini", step: 1 },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCostPrice(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.refundCostPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "refundCostPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.refundCostPrice",
                                          },
                                        })
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(scope.row.refundCostPrice)
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "实退金额",
                              align: "center",
                              prop: "refundRealityPrice",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.refundRealityPrice)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.projectData.nodeName == "开始" &&
                                    _vm.projectData.projectTaskId
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delItem(scope.$index)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "refundInfo" }, [
                    _c("div", { staticClass: "module-title" }, [
                      _vm._v("审批核定"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "formBox1" },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              size: "small",
                              inline: true,
                              "label-width": "190px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款核定", required: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !(
                                        _vm.projectData.nodeName &&
                                        _vm.projectData.nodeName.indexOf(
                                          "上级"
                                        ) > -1 &&
                                        _vm.projectData.projectTaskId
                                      ),
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.projectData.refundCheckRatify,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.projectData,
                                          "refundCheckRatify",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "projectData.refundCheckRatify",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "下错产品",
                                        value: "下错产品",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "更换产品",
                                        value: "更换产品",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "暂不操作",
                                        value: "暂不操作",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "无法操作",
                                        value: "无法操作",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否扣除违约金或人力成本",
                                      required: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: !(
                                            _vm.projectData.nodeName &&
                                            _vm.projectData.nodeName.indexOf(
                                              "上级"
                                            ) > -1 &&
                                            _vm.projectData.projectTaskId
                                          ),
                                        },
                                        model: {
                                          value: _vm.projectData.isContractCost,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.projectData,
                                              "isContractCost",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "projectData.isContractCost",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { class: ["wb_Box", _vm.showProcess ? "" : "wb_box0"] }, [
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.checkTask },
                  model: {
                    value: _vm.taskStatus,
                    callback: function ($$v) {
                      _vm.taskStatus = $$v
                    },
                    expression: "taskStatus",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "审批进度", name: "0" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "step-box" },
                [
                  _c(
                    "el-steps",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.taskStatus == 0,
                          expression: "taskStatus == 0",
                        },
                      ],
                      attrs: { direction: "vertical", active: _vm.taskActive },
                    },
                    _vm._l(_vm.taskData, function (item, index) {
                      return _c("el-step", {
                        key: index,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "ds_box" }, [
                                    _vm._v(
                                      " " + _vm._s(item.taskInternalName) + " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "description",
                              fn: function () {
                                return [
                                  item.taskList && item.taskList.length
                                    ? _c(
                                        "div",
                                        { staticClass: "description_item" },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(
                                              item.taskList,
                                              function (child, childIndex) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: childIndex,
                                                    style: {
                                                      color:
                                                        child.status === 0 &&
                                                        index == _vm.taskActive
                                                          ? "#303133"
                                                          : "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "5px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "task_box",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "align-items":
                                                                    "center",
                                                                  "min-width":
                                                                    "160px",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      child.taskName
                                                                    )
                                                                  ),
                                                                ]),
                                                                child.taskName !=
                                                                "结束"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0 3px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "|"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                child.taskHandleBy &&
                                                                child
                                                                  .taskHandleBy
                                                                  .length > 8
                                                                  ? _c(
                                                                      "el-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "item",
                                                                        attrs: {
                                                                          effect:
                                                                            "light",
                                                                          content:
                                                                            child.taskHandleBy,
                                                                          placement:
                                                                            "top-start",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "max-width":
                                                                                  "140px",
                                                                                overflow:
                                                                                  "hidden",
                                                                                "text-overflow":
                                                                                  "ellipsis",
                                                                                "white-space":
                                                                                  "nowrap",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                child.taskHandleBy
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "max-width":
                                                                              "140px",
                                                                            overflow:
                                                                              "hidden",
                                                                            "text-overflow":
                                                                              "ellipsis",
                                                                            "white-space":
                                                                              "nowrap",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            child.taskHandleBy
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        margin:
                                                                          "0 3px",
                                                                      },
                                                                  },
                                                                  [_vm._v("|")]
                                                                ),
                                                                _c("dict-tag", {
                                                                  attrs: {
                                                                    options:
                                                                      _vm.dict
                                                                        .type[
                                                                        "bpm_record_task_type"
                                                                      ],
                                                                    value:
                                                                      child.taskStatusType,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c("dict-tag", {
                                                              attrs: {
                                                                options:
                                                                  _vm.dict.type[
                                                                    "bpm_record_task_over_status"
                                                                  ],
                                                                value:
                                                                  child.taskStatus,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dsjb_box",
                                                          },
                                                          [
                                                            item.taskList &&
                                                            item.taskList
                                                              .length > 0 &&
                                                            child.taskName !=
                                                              "结束"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#86909c",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "备注：" +
                                                                        _vm._s(
                                                                          item
                                                                            .taskList[
                                                                            item
                                                                              .taskList
                                                                              .length -
                                                                              1
                                                                          ]
                                                                            .remark
                                                                        )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            child.taskLogList &&
                                                            child.taskLogList
                                                              .length > 0
                                                              ? _c(
                                                                  "el-popover",
                                                                  {
                                                                    attrs: {
                                                                      placement:
                                                                        "left-start",
                                                                      width:
                                                                        "650",
                                                                      trigger:
                                                                        "hover",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          slot: "reference",
                                                                          type: "text",
                                                                        },
                                                                        slot: "reference",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "查看记录"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-table",
                                                                      {
                                                                        attrs: {
                                                                          data: child.taskLogList,
                                                                          height:
                                                                            "300",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "100",
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "任务状态",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "dict-tag",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                options:
                                                                                                  _vm
                                                                                                    .dict
                                                                                                    .type[
                                                                                                    "bpm_record_task_type"
                                                                                                  ],
                                                                                                value:
                                                                                                  scope
                                                                                                    .row
                                                                                                    .taskStatusType,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "100",
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "任务名称",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              scope
                                                                                                .row
                                                                                                .taskName
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "100",
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "操作人员",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              scope
                                                                                                .row
                                                                                                .taskHandleBy
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "150",
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "处理时间",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              scope
                                                                                                .row
                                                                                                .createTime
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "备注",
                                                                                prop: "remark",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    child.taskType ==
                                                                      4 &&
                                                                    child.createId ==
                                                                      _vm.userId &&
                                                                    child.status ==
                                                                      0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                margin:
                                                                                  "10px 0",
                                                                                display:
                                                                                  "Flex",
                                                                                "align-items":
                                                                                  "center",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-right":
                                                                                      "20px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    size: "mini",
                                                                                    plain:
                                                                                      "",
                                                                                    type: "danger",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.cancelSubtask(
                                                                                        child.taskLogList
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "取消子任务"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " 仅自己创建的子任务可取消: 取消之后不可恢复/操作: 请确认无误后操作: "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        item.taskList &&
                                                        item.taskList.length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#86909c",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .taskList[
                                                                      item
                                                                        .taskList
                                                                        .length -
                                                                        1
                                                                    ]
                                                                      .taskFinishTime
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "description_item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "task_box" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                    "min-width": "160px",
                                                  },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("div", [_vm._v("普通")]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 3px",
                                                      },
                                                    },
                                                    [_vm._v("|")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.taskInternalName
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.showOpinion
                                                          ? ""
                                                          : "| 待处理"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("dict-tag", {
                                                attrs: {
                                                  options:
                                                    _vm.dict.type[
                                                      "bpm_record_task_over_status"
                                                    ],
                                                  value: 0,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.taskStatus == 1,
                          expression: "taskStatus == 1",
                        },
                      ],
                      staticClass: "record-list",
                    },
                    _vm._l(_vm.recordList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "record-li" },
                        [
                          _c(
                            "div",
                            { staticClass: "record-title" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/date.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(_vm._s(item.createTime)),
                              ]),
                              _c("dict-tag", {
                                attrs: {
                                  options: _vm.dict.type["record_sub_type"],
                                  value: item.recordType,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c("span", { staticClass: "sp1" }, [
                                _vm._v(_vm._s(item.createBy)),
                              ]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "left",
                                    width: item.recordType == 0 ? "530" : "830",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: { data: item.logs, height: "600" },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "100",
                                          align: "center",
                                          label: "模块",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          scope.row.fieldName.split(
                                                            "-"
                                                          )[0],
                                                        placement: "top-start",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "spa1",
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.fieldName.split(
                                                                "-"
                                                              )[0]
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "100",
                                          align: "center",
                                          label: "字段",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          scope.row.fieldName.split(
                                                            "-"
                                                          )[1],
                                                        placement: "top-start",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "spa1",
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.fieldName.split(
                                                                "-"
                                                              )[1]
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      item.recordType != 0 && item.beforeValue
                                        ? _c("el-table-column", {
                                            attrs: {
                                              width: "300",
                                              align: "center",
                                              label: "更改前",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row.beforeValue.indexOf(
                                                        "["
                                                      ) != -1 &&
                                                      scope.row.beforeValue.indexOf(
                                                        "oss"
                                                      ) != -1
                                                        ? _c("div", [
                                                            _c("div", {
                                                              staticClass:
                                                                "spa1",
                                                              staticStyle: {
                                                                width: "300px",
                                                                color: "blue",
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.getValues(
                                                                      scope.row
                                                                        .beforeValue
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ])
                                                        : _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content:
                                                                  scope.row
                                                                    .beforeValue,
                                                                placement:
                                                                  "top-start",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "spa1",
                                                                  staticStyle: {
                                                                    width:
                                                                      "300px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      scope.row
                                                                        .beforeValue
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      item.afterValue
                                        ? _c("el-table-column", {
                                            attrs: {
                                              width: "300",
                                              align: "center",
                                              label: "更改后",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row.afterValue.indexOf(
                                                        "["
                                                      ) != -1 &&
                                                      scope.row.afterValue.indexOf(
                                                        "oss"
                                                      ) != -1
                                                        ? _c("div", [
                                                            _c("div", {
                                                              staticClass:
                                                                "spa1",
                                                              staticStyle: {
                                                                width: "300px",
                                                                color: "blue",
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.getValues(
                                                                      scope.row
                                                                        .afterValue
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ])
                                                        : _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content:
                                                                  scope.row
                                                                    .afterValue,
                                                                placement:
                                                                  "top-start",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "spa1",
                                                                  staticStyle: {
                                                                    width:
                                                                      "300px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      scope.row
                                                                        .afterValue
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sp2",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        "更新" +
                                          _vm._s(item.logs.length) +
                                          "个字段"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择当前办理任务",
            visible: _vm.choseTask,
            width: "40%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.choseTask = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "choseTasks",
              attrs: {
                model: _vm.choseTaskform,
                rules: _vm.choseTaskrules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择办理任务", prop: "id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择子任务" },
                      on: { change: _vm.choseTaskChanges },
                      model: {
                        value: _vm.choseTaskform.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.choseTaskform, "id", $$v)
                        },
                        expression: "choseTaskform.id",
                      },
                    },
                    _vm._l(_vm.choseTaskList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.taskName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choseTaskComplete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.taskTle, visible: _vm.tasksave, width: "520px" },
          on: {
            "update:visible": function ($event) {
              _vm.tasksave = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "当前任务" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.nextTask,
                      callback: function ($$v) {
                        _vm.nextTask = $$v
                      },
                      expression: "nextTask",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.taskLabel } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "60", "show-word-limit": "" },
                    model: {
                      value: _vm.taskTips,
                      callback: function ($$v) {
                        _vm.taskTips = $$v
                      },
                      expression: "taskTips",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "ts_footer", staticStyle: {} }, [
                _c("div", { staticClass: "tsf_tipe" }, [
                  _vm._v("提示：" + _vm._s(_vm.taskRemark)),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.tasksave = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.subConformity },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "showboxs",
          attrs: {
            title: "表单内容预览",
            visible: _vm.showForms,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showForms = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", {
              ref: "contentToExport",
              attrs: { id: "apps" },
              domProps: { innerHTML: _vm._s(_vm.showHtmls) },
            }),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showForms = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.printPdf } },
                [_vm._v("打 印")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择驳回的节点",
            visible: _vm.choseRejectTask,
            width: "30%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.choseRejectTask = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "choseTasks",
              attrs: {
                model: _vm.choseTaskform,
                rules: _vm.choseTaskrules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择驳回任务", prop: "id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "请选择子任务" },
                      on: { change: _vm.choseTaskChanges },
                      model: {
                        value: _vm.choseTaskform.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.choseTaskform, "id", $$v)
                        },
                        expression: "choseTaskform.id",
                      },
                    },
                    _vm._l(_vm.choseTaskList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.taskName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: "4" },
                    model: {
                      value: _vm.choseTaskform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.choseTaskform, "remark", $$v)
                      },
                      expression: "choseTaskform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choseRejectComplete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.taskTle,
            visible: _vm.taskSumitDia,
            width: "520px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.taskSumitDia = $event
            },
          },
        },
        [
          _vm.checkData.productUserList && _vm.checkData.productUserList.length
            ? _c(
                "el-table",
                {
                  staticClass: "tableName",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.checkData.productUserList },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "产品专员" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择产品专员",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.choseUsername(
                                        $event,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.userId,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "userId", $$v)
                                    },
                                    expression: "scope.row.userId",
                                  },
                                },
                                _vm._l(_vm.checkData.sysUsers, function (item) {
                                  return _c("el-option", {
                                    key: item.userId,
                                    attrs: {
                                      label: item.nickName,
                                      value: item.userId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      32719568
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "productName", label: "产品名称" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批意见" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: "4" },
                    model: {
                      value: _vm.projectData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectData, "remark", $$v)
                      },
                      expression: "projectData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.taskSumitDia = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }