<template>
  <el-dialog center :visible.sync="dialogVisible" title="选择数据" @close="handleClose" >
    <el-table :data="brList">
      <el-table-column width="200px" label="项目标题" prop="recordTitle"> <template slot-scope="scope"> {{scope.row.recordTitle}}</template></el-table-column>
      <el-table-column width="200px" label="项目名称" prop="recordName"> <template slot-scope="scope">{{scope.row.recordName}}</template></el-table-column>
      <el-table-column width="200px" label="项目编号" prop="recordCode"> <template slot-scope="scope">{{scope.row.recordCode}}</template></el-table-column>

      <el-table-column width="200px" label="提交状态" prop="submitState">
        <template slot-scope="scope">
          {{scope.row.submitState?'成功':'失败'}}
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="goInitiate(1)">去发起</el-button>
      <el-button type="primary" @click="goInitiate(2)">查看我发起的</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      brList: [],
    };
  },

  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    openDialog(data) {
      this.dialogVisible = true;
      this.brList = data;
    },
    goInitiate(index) {
      let link;
      index == 1
        ? (link = "/business/sub")
        : (link = "/business/deliver/schedule?vwTable=vw_deliver_list");
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>