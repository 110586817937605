<template>
  <div>
    <el-dialog title="设置业务规则" :visible.sync="rulesOpen" width="800px" append-to-body :close-on-click-modal="false">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">添加规则</el-button>
        </el-col>
      </el-row>
      <!-- <el-table :data="rulesData" style="width: 800px">
        <el-table-column label="规则字段名称" align="center">
          <template slot-scope="scope">
            {{ getName(scope.row.condition) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="setCom(scope.row,scope.$index)">关联规则</el-button>
            <el-button type="danger" size="mini" @click="delCom(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table> -->
      <div style="width:100%;line-height: 46px;display: flex;align-items: center;justify-content: space-around;margin: 6px 0;background-color: #f8f8f9;border-bottom:1px solid #dfe6ec;">
        <div style="width: 100px;text-align: center;"></div>
        <div style="width: 480px;text-align: center;">规则名称</div>
        <div style="width: 180px;text-align: center;">操作</div>
      </div>
      <div>
        <draggable :list="rulesData" :animation="340" group="selectItem" handle=".option-drag">
          <div style="width:100%;display: flex;align-items: center;justify-content: space-around;margin: 12px 0;" v-for="(item, index) in rulesData" :key="index" class="select-item">
            <div class="select-line-icon option-drag" style="width: 100px;text-align: center;">
              <i style="font-size:20px;cursor:move;" class="el-icon-s-operation" />
            </div>
            <div style="width: 480px;text-align: center;">{{ getName(item.condition) }}</div>
            <div class="close-btn select-line-icon" style="width: 166px;display: flex;align-items: center;justify-content: space-around;">
              <el-button type="primary" size="small" @click="setCom(item,index)">关联规则</el-button>
              <el-button type="danger" size="mini" @click="delCom(index)">删除</el-button>
            </div>
          </div>
        </draggable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRules">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="isUpdate ? '修改业务规则':'添加业务规则'" :visible.sync="rulesOpens" width="800px" append-to-body :close-on-click-modal="false">
      <div>
        <div class="title">条件：<el-button type="text" icon="el-icon-plus" plain size="mini" @click="conditionAdd">添加条件</el-button></div>
        <div class="conbox" v-for="(item,index) in condition" :key="index">
          <el-select v-model="item.moduleId" @change="moduleChange($event,index)" filterable placeholder="请选择模块">
            <el-option
              v-for="item in formLists"
              :key="item.moduleId"
              :label="item.componentName"
              :value="item.moduleId">
            </el-option>
          </el-select>
          <el-select v-model="item.__vModel__" @change="fieldChange($event,index)" filterable placeholder="请选择字段">
            <el-option
              v-for="item in item.fieldList"
              :key="item.__vModel__"
              :label="item.labelName+ (item.originalName ? ('(' + item.originalName + ')') : item.labelTips)"
              :value="item.__vModel__">
            </el-option>
          </el-select>
          包含
          <el-select v-if="item.options.length" v-model="item.conValue" multiple @change="valueChange" placeholder="请选择">
            <el-option
              v-for="item in item.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input v-else v-model="item.conValue" placeholder="请输入内容"></el-input>
          <el-button type="danger" size="mini" @click="delCon(index)">删除</el-button>
        </div>
        <div>
          <div class="title">执行动作：<el-button type="text" icon="el-icon-plus" plain size="mini" @click="actionAdd">添加动作</el-button></div>
          <div class="conbox" v-for="(item,index) in activeData" :key="index">
            <el-select v-model="item.moduleId" @change="moduleChanges($event,index)" filterable placeholder="请选择模块">
              <el-option
                v-for="item in formLists"
                :key="item.moduleId"
                :label="item.componentName"
                :value="item.moduleId">
              </el-option>
            </el-select>
            <el-select v-model="item.__vModel__" @change="fieldChanges($event,index)" filterable placeholder="请选择字段">
              <el-option
                v-for="item in item.fieldList"
                :key="item.__vModel__"
                :label="item.labelName + (item.originalName ? ('(' + item.originalName + ')') : item.labelTips)"
                :value="item.__vModel__">
              </el-option>
            </el-select>
            <span v-if="item.actionValue != 2">需要</span>
            <el-select v-model="item.actionValue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input v-if="item.actionValue == 2" v-model="item.conValue" placeholder="请输入内容"></el-input>
            <el-button type="danger" size="mini" @click="delAct(index)">删除</el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="rulesOpens = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {treeSelect} from "@/api/system/group";
import {addProject, updateProject} from "@/api/system/project";
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  props:{
    formLists: {
      type: Array,
      default() {
        return []
      }
    },
    rulesDatas: {
      type: Array,
      default() {
        return []
      }
    },
  },
  watch:{
    rulesDatas: {
      handler(val) {
        console.log(val,'valllll',this.rulesData);
        this.rulesData = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
    },
    formLists: {
      handler(val) {
        this.formListAll = JSON.parse(JSON.stringify(val))
        console.log(val,'valllll',this.formListAll);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isUpdate:false,
      updateIndex:null,
      formListAll:[],
      rulesOpen:false,
      rulesOpens:false,
      options:[
        {value:1,label:'显示'},
        {value:0,label:'隐藏'},
        {value:2,label:'等于'},
      ],
      rulesData:[],
      condition:[],
      activeData:[],
    };
  },
  created() {
    
  },
  methods: {
    getName(list) {
      let name = []
      list.forEach(item => {
        name.push(item.field)
      });
      return name.join(',')
    },
    customRowClassName({ row, rowIndex }) {
      if (row.children) {
        return 'parent-row'; // 添加自定义样式类名
      }
      return ''; // 不需要额外样式时返回空字符串
    },
    handleAdd() {
      this.isUpdate = false
      this.condition = []
      this.activeData = []
      this.rulesOpens = true
    },
    setCom(row,i) {
      this.isUpdate = true
      this.updateIndex = i
      this.condition = row.condition
      this.activeData = row.activeData
      this.rulesOpens = true
    },
    delCom(index) {
      this.rulesData.splice(index,1)
    },
    // 添加条件
    conditionAdd() {
      let param = {
        moduleId:null,
        fieldList:[],
        __vModel__:null,
        options:[],
        conValue:null,
      }
      this.condition.push(param)
    },
    // 模块切换
    moduleChange(value,index) {
      console.log(value,index);
      this.$set(this.condition[index],'__vModel__',null)
      this.$set(this.condition[index],'conValue',null)
      this.$set(this.condition[index],'field',null)
      this.$set(this.condition[index],'options',[])
      let items = this.formLists.find(item => item.moduleId == value)
      this.$set(this.condition[index],'fieldList',items?.children?.map(item => ({ __vModel__: item.__vModel__, labelName: item.labelName,originalName:item.originalName,__slot__:item.__slot__ })) || [])
      console.log(this.condition,'1111');
    },
    // 字段切换
    fieldChange(value,index) {
      console.log(value,index,this.condition);
      let items = this.condition[index].fieldList.find(item => item.__vModel__ == value)
      this.$set(this.condition[index],'conValue',null)
      this.$set(this.condition[index],'field',items.labelName)
      this.$set(this.condition[index],'options',items.__slot__?.options || [])
    },
    valueChange(value) {
      console.log(value,'valuevalue');
    },
    delCon(index) {
      this.condition.splice(index,1)
    },
    // 添加动作
    actionAdd() {
      let param = {
        moduleId:null,
        fieldList:[],
        __vModel__:null,
        actionValue:null,
      }
      this.activeData.push(param)
    },
    moduleChanges(value,index) {
      console.log(value,index);
      this.$set(this.activeData[index],'__vModel__',null)
      this.$set(this.activeData[index],'actionValue',null)
      this.$set(this.activeData[index],'field',null)
      let items = this.formLists.find(item => item.moduleId == value)
      this.$set(this.activeData[index],'fieldList',items?.children?.map(item => ({ __vModel__: item.__vModel__, labelName: item.labelName,originalName:item.originalName,__slot__:item.__slot__ })) || [])
      console.log(this.activeData,'1111');
    },
    fieldChanges(value,index) {
      console.log(value,index,this.activeData);
      let items = this.activeData[index].fieldList.find(item => item.__vModel__ == value)
      this.$set(this.activeData[index],'actionValue',null)
      this.$set(this.activeData[index],'field',items.labelName)
    },
    delAct(index) {
      this.activeData.splice(index,1)
    },
    submitForm() {
      console.log(this.condition,this.activeData);
      if(this.isUpdate) {
        this.$set(this.rulesData[this.updateIndex],'condition',this.condition)
        this.$set(this.rulesData[this.updateIndex],'activeData',this.activeData)
      } else {
        this.rulesData.push({condition:this.condition,activeData:this.activeData,})
      }
      this.rulesOpens = false
    },
    submitRules() {
      this.rulesOpen = false
      this.$emit('conRules',this.rulesData)
    },
    cancel() {
      this.rulesOpen = false
    },
  },
};
</script>

<style lang="scss" scoped>
.step_con {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.form-group {
  margin-right: 20px;
  margin-bottom: 20px;
}

.conbox {
  display:flex;
  justify-content:space-around;
  align-items:center;
  margin:6px;
  .el-select {
    width: 200px;
  }
  .el-input {
    width: 200px;
  }
}
</style>
