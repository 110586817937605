var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", { staticClass: "el-icon-document" }, [
                _vm._v("已发任务"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "top" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "表单信息", name: "1" } },
                [
                  _c("el-col", { attrs: { span: 16, offset: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "test-form" },
                      [
                        _c("parser", {
                          key: new Date().getTime(),
                          attrs: { "form-conf": _vm.variablesData },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "流转记录", name: "2" } },
                [
                  _c("el-col", { attrs: { span: 16, offset: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(_vm.flowRecordList, function (item, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  icon: _vm.setIcon(item.finishTime),
                                  color: _vm.setColor(item.finishTime),
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "font-weight": "700" } },
                                  [_vm._v(_vm._s(item.taskName))]
                                ),
                                _c(
                                  "el-card",
                                  {
                                    attrs: {
                                      "body-style": { padding: "10px" },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-descriptions",
                                      {
                                        staticClass: "margin-top",
                                        attrs: {
                                          column: 1,
                                          size: "small",
                                          border: "",
                                        },
                                      },
                                      [
                                        item.assigneeName
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-user",
                                                    }),
                                                    _vm._v("办理人"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.assigneeName) +
                                                    " "
                                                ),
                                                _c(
                                                  "el-tag",
                                                  {
                                                    attrs: {
                                                      type: "info",
                                                      size: "mini",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.deptName)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.candidate
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-user",
                                                    }),
                                                    _vm._v("候选办理"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.candidate) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              "label-class-name": "my-label",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("i", {
                                                staticClass: "el-icon-date",
                                              }),
                                              _vm._v("接收时间"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(item.createTime) +
                                                " "
                                            ),
                                          ],
                                          2
                                        ),
                                        item.finishTime
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-date",
                                                    }),
                                                    _vm._v("处理时间"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.finishTime) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.duration
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-time",
                                                    }),
                                                    _vm._v("耗时"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.duration) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.comment
                                          ? _c(
                                              "el-descriptions-item",
                                              {
                                                attrs: {
                                                  "label-class-name":
                                                    "my-label",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-tickets",
                                                    }),
                                                    _vm._v("处理意见"),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.comment.comment
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "流程图", name: "3" } },
                [_c("flow", { attrs: { flowData: _vm.flowData } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }