<template>
  <div id="app">
    <router-view />
    <theme-picker />

  </div>
</template>

<script>
import ThemePicker from "@/components/ThemePicker";

export default {
  name: "App",
  components: { ThemePicker },
  metaInfo() {
    return {
      title:
        this.$store.state.settings.dynamicTitle &&
        this.$store.state.settings.title,
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE;
      },
    };
  },
  created(){
    this.changeBrowserZoom(1);
  },
  methods: {
    changeBrowserZoom(zoomLevel) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      if (screenHeight > 800) {
        zoomLevel = 1;
      }
      if (devicePixelRatio == 1.5) {
        zoomLevel = 0.6;
      }
      if (devicePixelRatio == 1.25) {
        zoomLevel = 0.8;
      }
      const wholePage = document.documentElement;
      wholePage.style.zoom = zoomLevel;
    },
  }
};
</script>
<style scoped>
#app .theme-picker {
  display: none;
}

</style>
<style>
.custom-notify {
  color: #333; /* 修改文本颜色 */
  border-radius: 5px; /* 设置圆角 */
  line-height: 30px;
  width: 420px !important;
}
</style>
