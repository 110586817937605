export default {
  prepend(h, conf, key) {
    return <template slot="prepend">{conf.__slot__[key]}</template>
  },
  append(h, conf, key) {
    // 是否是自动生成编号组件
    if (conf.__config__.isAuto) {
      return <template slot="append"><span style="cursor: pointer;" data-moduleIds={conf.moduleIds} data-__vModel__={conf.__vModel__} data-id="667">{conf.__slot__[key]}</span> </template>
    }else{
      return <template slot="append"><span style="cursor: pointer;" data-moduleIds={conf.moduleIds} data-__vModel__={conf.__vModel__} data-id="666">{conf.__slot__[key]}</span> </template>
    }
  }
}
