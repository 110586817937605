import request from '@/utils/request_cloud';

// pdf转图片
export function pdfToPng(params) {
	return request({
		url: '/sm/simeng/api/contract/pdfToPng',
		method: 'get',
		params: params
	});
}

// 打包下载zip
export function downloadZip(data) {
	return request({
		url: '/sm/simeng/api/fileDownload/downloadZip',
		method: 'post',
		responseType: 'blob',
		data: data
	});
}