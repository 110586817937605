// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!bpmn-js/dist/assets/diagram-js.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!bpmn-js/dist/assets/bpmn-font/css/bpmn.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*左边工具栏以及编辑节点的样式*/\n.view-mode .el-header,\n.view-mode .el-aside,\n.view-mode .djs-palette,\n.view-mode .bjs-powered-by {\n  display: none;\n}\n.view-mode .el-loading-mask {\n  background-color: initial;\n}\n.view-mode .el-loading-spinner {\n  display: none;\n}\n.flow-containers {\n  width: 100%;\n  height: 100%;\n}\n.flow-containers .canvas {\n  width: 100%;\n  height: 100%;\n}\n.flow-containers .panel {\n  position: absolute;\n  right: 0;\n  top: 50px;\n  width: 300px;\n}\n.flow-containers .load {\n  margin-right: 10px;\n}\n.flow-containers .el-form-item__label {\n  font-size: 13px;\n}\n.flow-containers .djs-palette {\n  left: 0px !important;\n  top: 0px;\n  border-top: none;\n}\n.flow-containers .highlight.djs-shape .djs-visual > :nth-child(1) {\n  fill: green !important;\n  stroke: green !important;\n  fill-opacity: 0.2 !important;\n}\n.flow-containers .highlight.djs-shape .djs-visual > :nth-child(2) {\n  fill: green !important;\n}\n.flow-containers .highlight.djs-shape .djs-visual > path {\n  fill: green !important;\n  fill-opacity: 0.2 !important;\n  stroke: green !important;\n}\n.flow-containers .highlight.djs-connection > .djs-visual > path {\n  stroke: green !important;\n}\n.flow-containers .highlight-todo.djs-connection > .djs-visual > path {\n  stroke: orange !important;\n  stroke-dasharray: 4px !important;\n  fill-opacity: 0.2 !important;\n}\n.flow-containers .highlight-todo.djs-shape .djs-visual > :nth-child(1) {\n  fill: orange !important;\n  stroke: orange !important;\n  stroke-dasharray: 4px !important;\n  fill-opacity: 0.2 !important;\n}\n.flow-containers .overlays-div {\n  font-size: 10px;\n  color: red;\n  width: 100px;\n  top: -20px !important;\n}\n.edit-form-field .el-table__body-wrapper {\n  max-height: 520px;\n  overflow-y: auto;\n}", ""]);
// Exports
module.exports = exports;
