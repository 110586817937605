<template>
    <div class="app-container">
      <div class="app_con">
        <!--部门数据-->
        <div class="main_con">
          <div class="top" ref="topDocument">
            <div class="head1">
              <div style="flex:1;text-align:right;">
                <template v-if="![2,4].includes(projectData.refundStatus)">
                  <el-button style="margin-bottom:6px;" size="mini" type="primary" v-if="projectData.projectTaskId" @click="submitPros">提 交</el-button>
                  <el-button style="margin-bottom:6px;" size="mini" type="danger" v-if="projectData.refundStatus != 6 && projectData.projectTaskId" @click="rejectOpen" plain>驳 回</el-button>
                  <el-button style="margin-bottom:6px;" v-if="projectData.isStartHandleBy" size="mini" type="danger" @click="clickButton(2)">作 废</el-button>
                </template>
                <el-button style="margin-bottom:6px;" v-if="projectData.refundType == 0" size="mini" @click="showForm" type="success" plain>预 览</el-button>
                
                <el-button style="margin-bottom:6px;" size="mini" @click="goBack" plain>关 闭</el-button>
              </div>
            </div>
            <div class="code">项目标题：{{projectData.recordTitle}} </div>
            <div class="content">
              <div class="conli">项目编号：<span>{{projectData.refundCode}}</span></div>
              <div class="conli">发起人：<span>{{projectData.createBy}}</span></div>
              <div class="conli">项目名称：<span>{{projectData.recordName}}</span></div>
              <div class="conli">客户编码：<span>{{projectData.customerNo}}</span></div>
              <div class="conli" style="display:flex;align-items:center;">办理状态：<span>
                <dict-tag :options="dict.type.bpm_record_status" :value="projectData.refundStatus" /></span>
              </div>
              <div class="conli">项目发起时间：<span>{{projectData.refundStartTime}}</span></div>
              <div class="conli" v-if="projectData.tagList && projectData.tagList.length">项目标签：<el-tag v-for="(item,index) in projectData.tagList" :key="index"
                  style="font-size: 12px;color: #1890ff;margin-left:5px">{{item.tagValue}}</el-tag></div>
            </div>
          </div>
          <div v-loading="isRequestCompleted" class="bottom" ref="bottom" :style="{height: `calc(100vh - ${topHeight}px)`}">
            <div class="form-box">
              <div class="form-right">

                <div class="refundInfo">
                  <div v-for="(item,index) in refundInfo" :key="index">
                      <div class="module-title">{{item.title}}</div>
                      <div class="formBox">
                          <el-form ref="queryForm" size="small" :inline="true" label-width="160px">
                            <div style="display: flex;flex-wrap: wrap;">
                              <template v-for="(form,idx) in item.formInfo">
                                <el-form-item :label="form.label" prop="name" v-if="form.isShow" :style="{width: form.width == 1 ? '100%' : '49%'}" :key="idx">
                                    <el-input :disabled="['orderSn','orderSource','customerNo','accountStatus'].includes(form.value) ? true : !(projectData.nodeName == '开始' && projectData.projectTaskId)" v-if="form.type == 'input'" style="width: 100%" v-model="projectData[form.value]" :placeholder="`请输入${form.label}`" />
                                    <el-select :disabled="!(projectData.nodeName == '开始' && projectData.projectTaskId)" v-if="form.type == 'select'" style="width: 100%" v-model="projectData[form.value]" @change="choseTaskChanges" clearable :placeholder="`请选择${form.label}`">
                                      <el-option v-for="(it,ix) in form.list" :key="ix" :label="it.lable" :value="it.value">
                                      </el-option>
                                    </el-select>
                                    <el-radio-group :disabled="form.value == 'refundType' ? true : !(projectData.nodeName == '开始' && projectData.projectTaskId)" v-if="form.type == 'radio'" style="width: 100%" v-model="projectData[form.value]" @change="changeRadio(form.value,projectData[form.value])">
                                      <template v-for="(it,ix) in dict.type[form.dict]">
                                        <el-radio v-if="form.dict == 'order_refund_account_type' && it.value == 2 ? projectData.refundRoad == 1 : true" :key="ix" :label="it.value">{{it.label}}</el-radio>
                                      </template>
                                    </el-radio-group>
                                    <el-input :disabled="!(projectData.nodeName == '开始' && projectData.projectTaskId)" v-if="form.type == 'textarea'" type="textarea" :rows="2" placeholder="请输入内容" v-model="projectData[form.value]"></el-input>
                                </el-form-item>
                              </template>
                            </div>
                          </el-form>
                      </div>
                  </div>
                  <div>
                    <el-form ref="queryForm" size="small" :inline="true" label-width="160px">
                      <div style="display: flex;flex-wrap: wrap;">
                        <el-form-item v-if="projectData.refundConfirmUrl" label="退款确认书" style="width: 49%;" prop="refundConfirmUrl">
                          <a style="color:#3278fe" target="_bank" :href="projectData.refundConfirmUrl">查看退款确认书</a>
                        </el-form-item>
                        <el-form-item v-if="projectData.accountConfirmUrl" label="付款凭证" prop="accountConfirmUrl" style="width: 49%;">
                          <a style="color:#3278fe" target="_bank" :href="projectData.accountConfirmUrl">查看付款凭证</a>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
              </div>

                <!-- //退款产品 -->
                <div class="refundInfo">
                  <div class="module-title">退款产品</div>
  
                  <el-table show-summary :summary-method="getSummaries" :row-class-name="getRowClass" :header-cell-style="{ background: '#f2f3f5', color: '#7a7e83' }" :data="projectData.list" style="width: 100%;margin-top:10px;"
                  :expand-row-keys="expandRowKeys" :row-key="getRowKey">
                    <el-table-column type="expand">
                      <template slot-scope="props" v-if="props.row.refundRecordList && props.row.refundRecordList.length">
                        <el-table
                        :data="props.row.refundRecordList"
                        style="width: 100%;padding: 0 10px;box-sizing: border-box;">
                        <el-table-column prop="recordCode" label="关联工单号">
                          <template slot-scope="scope">
                            <div @click="tosend(scope.row)" style="color: #1890ff;cursor: pointer;">{{scope.row.recordCode}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="isInvalid" label="工单是否作废">
                          <template slot-scope="scope">
                            <el-radio-group :disabled="getHaveHandle()" @input="changeInvalid(scope.row)" v-model="scope.row.isInvalid">
                              <el-radio label="1">作废</el-radio>
                              <el-radio label="2">不作废</el-radio>
                            </el-radio-group>
                          </template>
                        </el-table-column>
                        <el-table-column prop="address" label="作废原因">
                          <template slot-scope="scope">
                            <el-input :disabled="getHaveHandle()" @input="changeInvalid(scope.row)" style="width: 90%" v-model="scope.row.invalidDesc" placeholder="请输入作废原因"></el-input>
                          </template>
                        </el-table-column>
                        <!-- <el-table-column label="操作" align="center">
                          <template slot-scope="scope">
                            <el-button :disabled="getHaveHandle()">删除</el-button>
                          </template>
                        </el-table-column> -->
                      </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column prop="productId" width="60" label="产品ID"></el-table-column>
                    <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>
                    <el-table-column prop="productDesc" label="产品规格" align="center"></el-table-column>
                    <el-table-column prop="productPrice" label="实收金额" align="center">
                      <template slot-scope="scope">
                        {{scope.row.productPrice}}
                      </template>
                    </el-table-column>
                    <el-table-column label="退款金额" align="center">
                      <template slot-scope="scope">
                        <!-- <el-input v-model="scope.row.refundProductPrice" @input="changeCostPrice($event,scope.row,'refundProductPrice')" v-if="projectData.nodeName == '开始' && projectData.projectTaskId"></el-input> -->
                        <div>{{scope.row.refundProductPrice}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="数量" align="center" width="80">
                      <template slot-scope="scope">
                        <!-- <el-input-number size="mini" :mini="1" v-if="(projectData.nodeName == '开始' && projectData.projectTaskId)" v-model="scope.row.refundNum" :min="1" :max="10"></el-input-number> -->
                        <div>{{scope.row.refundNum}}</div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="关联工单号" align="center">
                      <template slot-scope="scope">
                        <div v-if="projectData.isRecordType == 1" @click="tosend(scope.row)" style="color: #1890ff;cursor: pointer;">{{scope.row.recordCode}}</div>
                        <div v-else class="record-code" :style="{borderBottom: index < scope.row.refundRecordList.length - 1 ? '1px solid #dfe6ec' : ''}" v-for="(item,index) in scope.row.refundRecordList" :key="index" @click="tosend(item)">{{ item.recordCode }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="工单是否作废" align="center" v-if="projectData.isRecordType == 2">
                      <template slot-scope="scope">
                        <div :style="{borderBottom: index < scope.row.refundRecordList.length - 1 ? '1px solid #dfe6ec' : '',padding: '10px 0'}" v-for="(item,index) in scope.row.refundRecordList" :key="index">
                          <el-radio-group :disabled="!projectData.projectTaskId" v-model="item.isInvalid">
                            <el-radio label="1">作废</el-radio>
                            <el-radio label="2">不作废</el-radio>
                          </el-radio-group>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="作废原因" align="center" v-if="projectData.isRecordType == 2">
                      <template slot-scope="scope">
                        <div :style="{borderBottom: index < scope.row.refundRecordList.length - 1 ? '1px solid #dfe6ec' : '',padding: '10px 0'}" v-for="(item,index) in scope.row.refundRecordList" :key="index">
                          <el-input :disabled="!projectData.projectTaskId" v-model="item.invalidDesc" placeholder="请输入作废原因"></el-input>
                        </div>
                      </template>
                    </el-table-column> -->
                    <el-table-column label="产品主体" align="center">
                      <template slot-scope="scope">
                        {{scope.row.productSubject}}
                      </template>
                    </el-table-column>
                    <el-table-column label="应扣成本" align="center" prop="refundCostPrice">
                      <template slot-scope="scope">
                        <el-input-number size="mini" style="width: 90%" v-if="(projectData.nodeName && (projectData.nodeName.indexOf('财务') > -1 || projectData.nodeName.indexOf('上级') > -1) && projectData.projectTaskId)" @change="changeCostPrice(scope.row)" v-model="scope.row.refundCostPrice" :step="1"></el-input-number>
                        <!-- <el-input v-model.number="scope.row.refundCostPrice" @input="isNumAndPoint($event,scope.row,'refundCostPrice')" @blur="changeCostPrice(scope.row)" v-if="(projectData.nodeName && projectData.nodeName.indexOf('财务') > -1 && projectData.projectTaskId)"></el-input> -->
                        <div v-else>{{scope.row.refundCostPrice}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="实退金额" align="center" prop="refundRealityPrice">
                      <template slot-scope="scope">
                        <div>{{scope.row.refundRealityPrice}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                      <template slot-scope="scope">
                        <el-button type="danger" v-if="projectData.nodeName == '开始' && projectData.projectTaskId" @click="delItem(scope.$index)" size="small">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="refundInfo">
                    <div class="module-title">审批核定</div>
                    <div class="formBox1">
                        <el-form size="small" :inline="true" label-width="190px">
                          <el-form-item label="退款核定" required>
                              <el-select v-model="projectData.refundCheckRatify" :disabled="!(projectData.nodeName && projectData.nodeName.indexOf('上级') > -1 && projectData.projectTaskId)" clearable>
                                <el-option label="下错产品" value="下错产品"></el-option>
                                <el-option label="更换产品" value="更换产品"></el-option>
                                <el-option label="暂不操作" value="暂不操作"></el-option>
                                <el-option label="无法操作" value="无法操作"></el-option>
                              </el-select>
                          </el-form-item>
                          <div>
                            <el-form-item label="是否扣除违约金或人力成本" required>
                                <el-radio-group style="width: 100%" :disabled="!(projectData.nodeName && projectData.nodeName.indexOf('上级') > -1 && projectData.projectTaskId)" v-model="projectData.isContractCost">
                                  <el-radio :label="1">是</el-radio>
                                  <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                          </div>
                        </el-form>
                    </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
        <div :class="['wb_Box',showProcess?'':'wb_box0']">
          <div class="right">
            <el-tabs v-model="taskStatus" @tab-click="checkTask">
              <el-tab-pane label="审批进度" name="0"></el-tab-pane>
              <!-- <el-tab-pane label="日志" name="1"></el-tab-pane> -->
            </el-tabs>
            <div class="step-box">
              <el-steps direction="vertical" :active="taskActive" v-show="taskStatus == 0">
                <el-step v-for="(item,index) in taskData" :key="index">
                  <template #title>
                    <div class="ds_box">
                      {{item.taskInternalName}}
                    </div>
                  </template>
                  <template #description>
                    <div class="description_item" v-if="item.taskList && item.taskList.length">
                      <ul>
                      <li v-for="(child, childIndex) in item.taskList" :key="childIndex" :style="{ color: (child.status === 0 && index == taskActive) ? '#303133' : '' }">
                        <div style="margin-top:5px">
                          <div class="task_box">
                              <div style="display:flex;align-items:center;min-width: 160px;">
                                <div>{{child.taskName}}</div>
                                <div style="margin:0 3px;" v-if="child.taskName != '结束'">|</div> 
                                <el-tooltip v-if="child.taskHandleBy && child.taskHandleBy.length > 8" class="item" effect="light" :content="child.taskHandleBy" placement="top-start">
                                  <div style="max-width: 140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ child.taskHandleBy }}</div> 
                                </el-tooltip>
                                <div v-else style="max-width: 140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ child.taskHandleBy }}</div> 
                                <div style="margin:0 3px;">|</div>
                                <dict-tag :options="dict.type['bpm_record_task_type']" :value="child.taskStatusType" />

                              </div>
                            <dict-tag :options="dict.type['bpm_record_task_over_status']" :value="child.taskStatus" />
                          </div>

                          <div class="dsjb_box">
                            <div v-if="item.taskList && item.taskList.length>0 && child.taskName != '结束'" style="color:#86909c">备注：{{item.taskList[item.taskList.length-1].remark}}</div>

                            <el-popover v-if="child.taskLogList && child.taskLogList.length>0" placement="left-start" width="650" trigger="hover">
                              <el-button type="text" slot="reference">查看记录</el-button>

                              <el-table :data="child.taskLogList" height="300">

                                <el-table-column width="100" align="center" label="任务状态">
                                  <template slot-scope="scope">
                                    <dict-tag :options="dict.type['bpm_record_task_type']" :value="scope.row.taskStatusType" />
                                  </template>
                                </el-table-column>
                                <el-table-column width="100" align="center" label="任务名称">
                                  <template slot-scope="scope">
                                    {{scope.row.taskName}}
                                  </template>
                                </el-table-column>
                                <el-table-column width="100" align="center" label="操作人员">
                                  <template slot-scope="scope">
                                    {{scope.row.taskHandleBy}}
                                  </template>
                                </el-table-column>
                                <el-table-column width="150" align="center" label="处理时间">
                                  <template slot-scope="scope">
                                    {{scope.row.createTime}}
                                  </template>
                                </el-table-column>
                                <el-table-column align="center" label="备注" prop="remark"></el-table-column>
                              </el-table>
                              <div v-if="child.taskType == 4 && child.createId == userId && child.status == 0 " style="margin:10px 0;display:Flex;align-items: center;">
                                <el-button @click="cancelSubtask(child.taskLogList)" size="mini" plain style="margin-right:20px" type="danger">取消子任务</el-button>
                                仅自己创建的子任务可取消: 取消之后不可恢复/操作: 请确认无误后操作:
                              </div>

                            </el-popover>
                          </div>
                          <div v-if="item.taskList && item.taskList.length>0" style="color:#86909c">{{item.taskList[item.taskList.length-1].taskFinishTime}}</div>

                        </div>

                      </li>
                    </ul>
                    </div>
                    <div v-else class="description_item">
                      <div class="task_box">
                        <div slot="reference" style="display:flex;align-items:center;min-width: 160px;">
                          <div>普通</div>
                          <div style="margin:0 3px;">|</div> {{ item.taskInternalName }} <div style="margin:0 3px;"></div>
                          {{item.showOpinion ? '':'| 待处理'}}
                        </div>
                        <dict-tag :options="dict.type['bpm_record_task_over_status']" :value="0" />
                      </div>
                    </div>
                  </template>
                </el-step>
              </el-steps>
  
              <div class="record-list" v-show="taskStatus == 1">
                <div class="record-li" v-for="(item,index) in recordList" :key="index">
                  <div class="record-title">
                    <img src="@/assets/images/date.png" alt="">
                    <div class="date">{{ item.createTime }}</div>
                    <dict-tag :options="dict.type['record_sub_type']" :value="item.recordType" />
                  </div>
                  <div class="content">
                    <span class="sp1">{{item.createBy}}</span>
                    <el-popover placement="left" :width="item.recordType == 0 ? '530' : '830'" trigger="hover">
                      <el-table :data="item.logs" height="600">
                        <el-table-column width="100" align="center" label="模块">
                          <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="scope.row.fieldName.split('-')[0]" placement="top-start">
                              <div class="spa1" style="width: 100px;">{{ scope.row.fieldName.split('-')[0] }}</div>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                        <el-table-column width="100" align="center" label="字段">
                          <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="scope.row.fieldName.split('-')[1]" placement="top-start">
                              <div class="spa1" style="width: 100px;">{{ scope.row.fieldName.split('-')[1] }}</div>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                        <el-table-column width="300" align="center" label="更改前" v-if="item.recordType != 0 && item.beforeValue">
                          <template slot-scope="scope">
                            <div v-if="scope.row.beforeValue.indexOf('[') != -1 && scope.row.beforeValue.indexOf('oss') != -1">
                              <div class="spa1" style="width: 300px;color:blue;" v-html="getValues(scope.row.beforeValue)"></div>
                            </div>
                            <el-tooltip class="item" effect="dark" :content="scope.row.beforeValue" placement="top-start" v-else>
                              <div class="spa1" style="width: 300px;">{{ scope.row.beforeValue }}</div>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                        <el-table-column width="300" align="center" label="更改后" v-if="item.afterValue">
                          <template slot-scope="scope">
                            <div v-if="scope.row.afterValue.indexOf('[') != -1 && scope.row.afterValue.indexOf('oss') != -1">
                              <div class="spa1" style="width: 300px;color:blue;" v-html="getValues(scope.row.afterValue)"></div>
                            </div>
                            <el-tooltip class="item" effect="dark" :content="scope.row.afterValue" placement="top-start" v-else>
                              <div class="spa1" style="width: 300px;">{{ scope.row.afterValue }}</div>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                      </el-table>
                      <span slot="reference" class="sp2">更新{{item.logs.length}}个字段</span>
                    </el-popover>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
      <!--当前项目办理人多个任务时展示-->
      <el-dialog title="选择当前办理任务" :visible.sync="choseTask" width="40%" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <el-form ref="choseTasks" :model="choseTaskform" :rules="choseTaskrules" label-width="120px">
          <el-form-item label="选择办理任务" prop="id">
            <el-select v-model="choseTaskform.id" @change="choseTaskChanges" clearable placeholder="请选择子任务">
              <el-option v-for="item in choseTaskList" :key="item.id" :label="item.taskName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="choseTaskComplete">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="taskTle" :visible.sync="tasksave" width="520px">
        <el-form label-width="120px">
          <el-form-item label="当前任务">
            <el-input :disabled="true" v-model="nextTask"></el-input>
          </el-form-item>
          <el-form-item :label="taskLabel">
            <el-input v-model="taskTips" maxlength="60" show-word-limit></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <div class="ts_footer" style="display">
            <div class="tsf_tipe">提示：{{taskRemark}}</div>
            <div>
              <el-button @click="tasksave = false">取 消</el-button>
              <el-button type="primary" @click="subConformity">确 定</el-button>
            </div>
          </div>
        </span>
      </el-dialog>
  
      <el-dialog title="表单内容预览" :visible.sync="showForms" width="1000px" class="showboxs">
        <div>
          <div ref="contentToExport" id="apps" v-html="showHtmls"></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showForms = false">取 消</el-button>
          <el-button type="primary" @click="printPdf">打 印</el-button>
        </span>
      </el-dialog>
      <!-- 驳回 -->
      <el-dialog title="选择驳回的节点" :visible.sync="choseRejectTask" width="30%" :close-on-press-escape="false" :close-on-click-modal="false">
        <el-form ref="choseTasks" :model="choseTaskform" :rules="choseTaskrules" label-width="120px">
          <el-form-item label="选择驳回任务" prop="id">
            <el-select v-model="choseTaskform.id" style="width: 100%;" @change="choseTaskChanges" clearable placeholder="请选择子任务">
              <el-option v-for="item in choseTaskList" :key="item.id" :label="item.taskName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="驳回原因" prop="remark">
            <el-input v-model="choseTaskform.remark" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="choseRejectComplete">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 正常提交并驳回提交 -->
      <el-dialog :title="taskTle" :visible.sync="taskSumitDia" width="520px">
        <el-table v-if="checkData.productUserList && checkData.productUserList.length" class="tableName"
          :data="checkData.productUserList"
          style="width: 100%">
          <el-table-column prop="userName" label="产品专员">
            <template slot-scope="scope">
              <el-select v-model="scope.row.userId" @change="choseUsername($event,scope.row)" clearable placeholder="请选择产品专员">
                <el-option v-for="item in checkData.sysUsers" :key="item.userId" :label="item.nickName" :value="item.userId">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称">
          </el-table-column>
        </el-table>
        <el-form label-width="80px">
          <el-form-item label="审批意见">
            <el-input v-model="projectData.remark" type="textarea" rows="4"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="taskSumitDia = false">取 消</el-button>
          <el-button type="primary" @click="submitSave">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import Parser from "@/components/parser/Parser";
  import axios from "axios";
  import {
    abnormalLog,
    bpmRecordProject,
    bpmRecordProjectUpdate,
    recordLog,
    recordLogBatch,
  } from "@/api/flowable/todo";
  import { previewTemplateFile } from "@/api/system/config";
  import FlowUser from "@/components/flow/User";
  import FlowRole from "@/components/flow/Role";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import quote from "@/components/quote/index";
  import projectSchedules from "@/components/projectSchedules/index.vue";

  import { 
    getCommonProjectTask, 
    orderBillRefundProject, 
    getCommonProjectTaskNext, 
    getCommonProjectTaskBefore, 
    submitCommonProjectTaskNext, 
    updateOrderBillRefundProject,
    rejectCommonTask,
    invalidatedCommonTask,
    rejectSubmitCommonTask,
    removeRefundProjectById,
  } from '@/api/bpmCommonProjectTask'
  export default {
    name: "tasksend",
    components: {
      Parser,
    //   flow,
      FlowUser,
      FlowRole,
      quote,
      projectSchedules
    },
    dicts: [
      "bpm_record_task_over_status",
      "bpm_record_task_type",
      "bpm_record_status",
      "record_sub_type",
      "action_file_type",
      "order_refund_way",
      "order_refund_account_type"
    ],
    computed: {
      ...mapGetters(["sidebar"]),
    },
    data() {
      return {
        allPeopleMsg: null,
        preVisible: false,
        noticeType: false,
        allchecked: false,
        summarySync: false,
        nickName: this.$store.state.user.nickName,
        showProcess: true,
        taskRemark: "",
        taskLabel: "",
        clickType: null,
        taskTips: "",
        nextTask: "",
        taskTle: "审批意见",
        tasksave: false,
        process: null,
        tabStatus: 0,
        fromTab: "form-order",
        remark: null, // 项目备注
        projectForm: {
          name: this.$store.state.user.name,
          projectName: "sdagdsafgsdgsdf",
          date: "2023-11-30",
        },
        resetVisible:false,
        btnShow: false,
        btnShows: false,
        userId: null,
        nowUserId: null,
        // 模型xml数据
        flowData: {},
        flowDatas: {},
        activeName: "1", // 切换tab标签
        defaultProps: {
          children: "children",
          label: "label",
        },
        // 查询参数
        queryParams: {
          deptId: undefined,
        },
        // 遮罩层
        loading: true,
        recordId: null,
        taskId: null,
        choseTask: false,
        choseTaskList: [],
        choseTaskform: {},
        choseTaskforms: {},
        choseTaskrules: {
          id: [{ required: true, message: "任务不能为空", trigger: "blur" }],
          remark: [{ required: true, message: "驳回意见不能为空", trigger: "change" }],
        },
        chilTaskId: null,
        chilTaskName: null,
        deployId: "", // 流程定义编号
        procDefId: "", // 流程实例编号
        isRequestCompleted: false,
        formConf: {}, // 默认表单数据
        formConfOld: {},
        formConfOld1: {},
        formConfCopy: {},
        variables: [], // 流程变量数据
        formViewable:[],
        formEditable:[],
        taskTitle: null,
        taskOpen: false,
        checkSendUser: false, // 是否展示人员选择模块
        checkSendRole: false, // 是否展示角色选择模块
        checkType: "", // 选择类型
        checkValues: null, // 选中任务接收人员数据
        formData: {}, // 填写的表单数据,
        recordTitle: null,
        multiInstanceVars: "", // 会签节点,
        projectCode: null, //订单code合集
        orderProdId: null,
        orderList: [],
        tableData: [], // 订单数据
        taskStatus: 0,
        projectData: {
          id: null,
          recordTitle: null,
          recordName: null,
          recordCode: null,
        }, // 项目数据
        taskData: [], // 流程节点数据
        taskRecordss: [],
        taskItem: {},
        taskActive: null,
        flowOpen: false,
  
        rejOpen: false,
        rejform: {},
        rejforms: {
          taskId: null,
          viewable: [],
          editable: [],
          struct: null,
        },
        rejrules: {
          opinion: [
            { required: true, message: "任务节点不能为空", trigger: "blur" },
          ],
        },
  
        subList: [],
        ctaskOpen: false,
        taskform: {},
        taskItems: {},
        taskrules: {
          opinion: [
            { required: true, message: "任务节点不能为空", trigger: "blur" },
          ],
        },
        showHtmls: null,
        userVisible: false,
        userType: null,
        // 数据回显
        selectValues: null,
        selectionUser: {},
  
        // 驳回字段相关
        formLists: [],
        formListsOld: [],
        loading2: true,
        //下一流程节点列表相关
        appointOpen: false,
        appointData: [],
        overTask: [],
        taskUserList: [],
        handIndex: null,
        taskRecordsAll: [],
        rejectProFlag: null, // 是否允许修改产品
        proVisible: false,
        choseProVisible: false,
        // 产品列表加载相关
        loading1: true,
        total1: 0,
        queryParams1: {
          current: 1,
          size: 10,
          productName: null,
          productId: null,
          orderSn: null,
          customerPhone: null,
          customerNo: null,
          orderProdIds: [],
          projectCode: null,
        },
        tableDatas: [],
        selectProList: [], // 选择产品
  
        isRecord: false,
        recordList: [], //日志列表
        abnormalList: [], // 异常日志列表
  
        fileLoading: false,
        fileListss: [], //项目文件列表
        fileLists: [], //项目文件列表
        fileCheck: [],
  
        queryParams2: {
          current: 1,
          size: 10,
        },
        total2: 0,
  
        isOpened: null,
        multiProject: null,
        mpList: [],
        mpindex: 0,
        recordIdLink: [],
        ctTableData: [],
        linkKey: "",
        topHeight: "",
        isSave: false,
        newType: "1",
        showForms: false,
        formConfShow: {},
        nowTime: null,
  
        isTips:true, // 是否弹窗提示保存
  
        quoteList:[], // 复制的模块信息

        // 退款展示的信息
        refundInfo: [
            {
                title: '退款信息',
                formInfo: [
                    {
                        type: 'input',
                        label: '订单号',
                        value: 'orderSn',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '订单来源',
                        value: 'orderSource',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '客户编号',
                        value: 'customerNo',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '项目办理人名称',
                        value: 'customerName',
                        isShow: true,
                    },
                    {
                        type: 'radio',
                        label: '退单类型',
                        value: 'refundType',
                        dict: 'order_refund_way',
                        list: [
                          {label: '退款',value: '0'},
                          {label: '客户预存款',value: '1'},
                        ],
                        isShow: true,
                    },
                    {
                        type: 'select',
                        label: '退单原因',
                        value: 'refundReason',
                        list: [
                          {label: '外部政策变化导致无法办理或办理失败',value: '外部政策变化'},
                          {label: '承诺包过的产品实际未办理成功',value: '包过产品未通过'},
                          {label: '委托方主观放弃操作',value: '客户放弃办理'},
                          {label: '委托方条件不满足导致办理失败',value: '客户自身原因导致办理失败'},
                          {label: '协商取消操作委托事项',value: '转移货款操作其他项目'},
                          {label: '委托方多付款',value: '委托方多付款'},
                          {label: '其他（在详细说明内补充）',value: '其他'},
                        ],
                        isShow: true,
                    },
                    {
                        type: 'radio',
                        label: '收款方账户类型',
                        value: 'accountType',
                        dict: 'order_refund_account_type',
                        list: [
                          {label: '对公账号转账',value: '0'},
                          {label: '对私账号转账',value: '1'},
                          {label: '原路返回',value: '2'},
                        ],
                        isShow: true,
                    },
                    {
                        type: 'select',
                        label: '币种',
                        value: 'refundCurrency',
                        list: [
                          {label: '人民币',value: '人民币'},
                          {label: '港币',value: '港币'},
                          {label: '美元',value: '美元'},
                          {label: '欧元',value: '欧元'},
                          {label: '日元',value: '日元'},
                          {label: '其他（在退款事由中备注）',value: '其他'},
                        ],
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '收款人全称',
                        value: 'accountName',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '付款状态',
                        value: 'accountStatus',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '开户行',
                        value: 'accountBankName',
                        isShow: true,
                    },
                    {
                        type: 'textarea',
                        label: '退款详情',
                        value: 'refundDesc',
                        isShow: true,
                    },
                    {
                        type: 'input',
                        label: '银行账号',
                        value: 'accountNo',
                        isShow: true,
                    },
                ],
            }
        ],
        finishedList: [],
        choseRejectTask: false,
        taskSumitDia: false,
        checkData: {},
        approvalList: [],
        expandRowKeys: []
      };
    },
    beforeRouteEnter(to, from, next) {
      sessionStorage.setItem("componentData", JSON.stringify(from.fullPath));
      next();
    },
    async created() {
      this.userId = this.$store.state.user.id;
  
      this.$store.dispatch("app/closeSideBar", 1000);
    },
    mounted() {
      if (this.$refs.topDocument) {
        this.topHeight = this.$refs.topDocument.offsetHeight;
      }
      this.isRequestCompleted = true;
      this.initRefundInfo();
    },
    beforeDestroy() {
      if (!this.sidebar.opened) {
        this.$store.dispatch("app/toggleSideBar");
      }
      this.$off();
    },
    methods: {
      //相同关联工单号，同步操作
      changeInvalid(row){
        this.projectData.list.forEach(v => {
          if(v.refundRecordList && v.refundRecordList.length){
            v.refundRecordList.forEach(item => {
              if(item.recordCode == row.recordCode){
                item.isInvalid = row.isInvalid;
                item.invalidDesc = row.invalidDesc;
              }
            })
          }
        })
      },
      // 总计
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
          } else {
            if(['productPrice','refundCostPrice','refundRealityPrice'].includes(column.property)){
              const values = data.map(item => Number(item[column.property]));
              if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            }else{
              sums[index] = ''
            }
          }
        });
        return sums;
      },
      getHaveHandle(){
        return !((this.projectData.nodeName && this.projectData.nodeName.indexOf('操作') > -1) && this.projectData.projectTaskId)
      },
      getRowClass(row,rowIndex){
          if(row.row.refundRecordList.length==0){  //判断当前行是否有子数据或者根据实际情况设置
            return 'row-expand-cover'
        }
      },
      getRowKey(row) {
        // 返回每行的唯一键值，确保不重复
        return row.id;
      },
      tosend(row) {
        let url = this.$router.resolve({
          path: "/manage/tasksend",
          query: {
            recordId: row.recordId,
            isOpened: true,
          },
        }).href;
        localStorage.setItem(
          "previousPage",
          this.$route.fullPath
        );
        window.open(url, "_blank");
      },
      choseUsername(e,row){
        this.checkData.sysUsers.forEach(v => {
          if(e == v.userId){
            row.userName = v.nickName;
          }
        })
      },
      changeCostPrice(row){
        // value = this.isNumAndPoint(value)
        // value = value.replace(/[^\d\.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        // row[label] = value;
        // console.log(row[label],'row[label]')
        if(row.refundCostPrice){
          if(row.refundCostPrice > row.refundProductPrice){
            row.refundCostPrice = row.refundProductPrice
            this.$message.error('应扣成本金额不能大于退款金额')
          }
          row.refundRealityPrice = row.refundProductPrice - row.refundCostPrice;
        }else{
          row.refundRealityPrice = row.refundProductPrice;
        }
        this.$forceUpdate();
      },
      isNumAndPoint(n,row,label = '') {
          n = n.replace(/[^\d\.-]/g, "");  //清除“数字”和“.”以外的字符
          n = n.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
          n = n.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");  // 被吞了 看图
          n = n.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
          if (n.indexOf(".") < 0 && n != "" && n.indexOf("-") < 0) {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
              n = parseFloat(n);
          }
          row[label] = n;
      },
      changeRadio(type,value){
        if(type == 'refundType'){
          this.refundInfo[0].formInfo[6].isShow = value == 0;
          this.refundInfo[0].formInfo[8].isShow = value == 0;
          this.refundInfo[0].formInfo[10].isShow = value == 0;
          this.refundInfo[0].formInfo[12].isShow = value == 0;
          this.$forceUpdate();
        }
        if(type == 'accountType' && this.projectData.refundType == 0){
          this.refundInfo[0].formInfo[8].isShow = value != 2;
          this.refundInfo[0].formInfo[10].isShow = value != 2;
          this.refundInfo[0].formInfo[12].isShow = value != 2;
        }else{
          this.refundInfo[0].formInfo[8].isShow = false;
          this.refundInfo[0].formInfo[10].isShow = false;
          this.refundInfo[0].formInfo[12].isShow = false;
        }
      },
      commonRecordId(){
        let recordIds = [];
        this.projectData.list.forEach(v => {
          if(v.recordId){
            let list = v.recordId.split(',')
            recordIds.push(...list)
          }
        });
        return recordIds;
      },
      initRefundInfo(){
        this.finishedList = [];
        orderBillRefundProject(this.$route.query.projectBill).then(res => {
          if(res.code == 200){
            this.projectData = res.data;
            // 在这里预先设置需要展开的行的 key 数组
            this.expandRowKeys = this.projectData.list.map(row => row.id);
            this.changeRadio('refundType',this.projectData.refundType);
            this.changeRadio('accountType',this.projectData.accountType);
            // this.refundInfo[0].formInfo[9].isShow = this.projectData.refundStatus == 2;
            getCommonProjectTask({projectBill: this.$route.query.projectBill,taskType: this.projectData.commonTaskType}).then(res => {
              if(res.code == 200){
                this.isRequestCompleted = false;
                this.taskData = res.data;
                this.approvalList = [];
                res.data.forEach(v => {
                  if(v.showOpinion){
                    this.finishedList.push(v)
                  }
                  if(v.taskList && v.taskList.length){
                    v.taskList.forEach(s => {
                      if(s.taskLogList && s.taskLogList.length){
                        this.approvalList.push(...s.taskLogList);
                      }
                    })
                  }
                });
                console.log(this.finishedList,'this.finishedList')
                this.taskActive = this.finishedList.length > 0 ? this.finishedList.length - 1 : 0;
              }
            });
          }
        })
      },

      getIsShow(val){
        let item = ''
        if(this.finishedList && this.finishedList.length){
          item = this.finishedList.find(v => v.taskInternalName.indexOf(val) > -1)
        }
        return item ? true : false;
      },
      // 展示预览
      async showForm() {
        // this.formConfShow = JSON.parse(
        //   JSON.stringify(this.$refs.parser.submitForms())
        // );
        // this.formConfShow.fields.forEach((item) => {
        //   if (!item.__config__.allowMultiple) {
        //     let list = [];
        //     item.__config__.children.forEach((item1) => {
        //       if (item1.__slot__?.options && item1.__slot__?.options.length) {
        //         if (!Array.isArray(item1.__config__.defaultValue)) {
        //           let items = item1.__slot__.options.find(
        //             (item) => item.value === item1.__config__.defaultValue
        //           );
        //           // console.log(items,'itemsitems');
        //           if (items) {
        //             this.$set(item1.__config__, "defaultValueLabel", items.label);
        //           } else {
        //             this.$set(item1.__config__, "defaultValueLabel", "");
        //           }
        //         } else {
        //           if (
        //             item1.__config__.defaultValue &&
        //             item1.__config__.defaultValue.length
        //           ) {
        //             let list = [];
        //             item1.__config__.defaultValue.forEach((item) => {
        //               let items = item1.__slot__.options.find(
        //                 (item1) => item1.value === item
        //               );
        //               if (items) {
        //                 list.push(items.label);
        //               }
        //             });
        //             this.$set(
        //               item1.__config__,
        //               "defaultValueLabel",
        //               list.join(",")
        //             );
        //           } else {
        //             this.$set(item1.__config__, "defaultValueLabel", "");
        //           }
        //         }
        //       }
        //       if (
        //         item1.__config__.tag == "el-upload" &&
        //         !item1.__config__.isAuto
        //       ) {
        //         this.$set(item1, "isShow", false);
        //         // this.processArray(item1.__config__.defaultValue).then((res) => {
        //         //   item1.__config__.defaultValue = JSON.stringify(res);
        //         // });
        //       }
        //       list.push(item1.isShow);
        //     });
        //     item.__config__.showModule = list.some((value) => value === true);
        //   } else {
        //     if (!item.__config__.defaultValue?.length) {
        //       item.__config__.showModule = false;
        //     } else {
        //       item.__config__.defaultValue.forEach((item1) => {
        //         item1.children.forEach((item2) => {
        //           if (item2.__slot__?.options && item2.__slot__?.options.length) {
        //             if (!Array.isArray(item2.__config__.defaultValue)) {
        //               let items = item2.__slot__.options.find(
        //                 (item) => item.value === item2.__config__.defaultValue
        //               );
        //               // console.log(items,'itemsitems');
        //               if (items) {
        //                 this.$set(item2.__config__, "defaultValueLabel", items.label);
        //               } else {
        //                 this.$set(item2.__config__, "defaultValueLabel", "");
        //               }
        //             } else {
        //               if (
        //                 item2.__config__.defaultValue &&
        //                 item2.__config__.defaultValue.length
        //               ) {
        //                 let list = [];
        //                 item2.__config__.defaultValue.forEach((item) => {
        //                   let items = item2.__slot__.options.find(
        //                     (item2) => item2.value === item
        //                   );
        //                   if (items) {
        //                     list.push(items.label);
        //                   }
        //                 });
        //                 this.$set(
        //                   item2.__config__,
        //                   "defaultValueLabel",
        //                   list.join(",")
        //                 );
        //               } else {
        //                 this.$set(item2.__config__, "defaultValueLabel", "");
        //               }
        //             }
        //           }
        //           if (
        //             item2.__config__.tag == "el-upload" &&
        //             !item2.__config__.isAuto
        //           ) {
        //             this.$set(item2, "isShow", false);
        //           }
        //         });
        //       });
        //     }
        //   }
        // });
        // console.log(this.formConfShow.fields);
        // this.nowTime = null;
  
        this.showHtmls = await this.generateHTML(
          this.projectData,
          this.formConfShow
        );
        this.showForms = true;
      },
      getInitHtmlHead() {
        return `<html>
                    <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <title>退单流程-${this.projectData.accountName}+${this.projectData.customerNo}+${this.projectData.orderSn}</title>
                          <style>
                          </style>
                      </head>
              <body><div style="padding-top:60px; padding-bottom:60px;">`;
      },
      getThreeTableHtml(list){
        let html = '<table border="1" style="width:100%;margin:8px auto;border-collapse: collapse; table-layout: fixed; ">';
        list.forEach((item1, index1) => {
          // if (item1.isShow) {
            console.log(index1 % 3,index1,'index1 % 3 == 0')
            if(index1 == 0 || index1 % 3 == 0) {
              html += '<tr>'
            }
            html += `<td style="width:9%;white-space: pre-wrap; word-wrap: break-word;padding:6px;margin-right:6px;font-size:14px;color:#303030;background-color:#eeeeee;text-align: center;">${item1.field}</td>`
            if(index1 % 3 == 0) {
              html += `<td valign="center" style="width:13%;white-space: pre-wrap; word-wrap: break-word;font-size: 14px;color: #333;text-align: center;vertical-align: middle;">`
            }else if(index1 % 3 == 2) {
              html += `<td valign="center" style="width:17%;white-space: pre-wrap; word-wrap: break-word;font-size: 14px;color: #333;text-align: center;vertical-align: middle;">`
            }else{
              html += `<td valign="center" style="width:20%;white-space: pre-wrap; word-wrap: break-word;font-size: 14px;color: #333;text-align: center;vertical-align: middle;">`
            }
            let value = this.projectData[item1.value] || '---';
            html += value + `</td>`;
            if(index1 % 3 == 2) {
              html += '</tr>'
            }
        });
        html += "</table>";
        return html;
      },
      getOneTableHtml(list,name){
        let html = '<table border="1" style="width:100%;margin:8px auto;border-collapse: collapse; table-layout: fixed; ">';
        html += '<tr>'

        list.forEach((item1, index1) => {
          html += `<th style="white-space: pre-wrap; word-wrap: break-word;padding:6px;margin-right:6px;font-size:14px;color:#303030;background-color:#eeeeee;text-align: center;">${item1.field}</td>`
          if(index1 == list.length - 1){
            html += '</th>';
            if(name != 'approvalList'){
              this.projectData[name].forEach((v,i) => {
                html += '<tr>'
                list.forEach((item2, index2) => {
                  html += `<td style="white-space: pre-wrap;word-wrap: break-word;padding: 6px;text-align: center;">${v[item2.value] || String(v[item2.value]) == '0' ? v[item2.value] : ''}</td>`
                  if(index2 == list.length - 1){
                    html += '</tr>'
                  }
                });
                if(i == this.projectData[name].length -1){
                  html += "</table>";
                }
              })
            }else{
              this[name].forEach((v,i) => {
                html += '<tr>'
                list.forEach((item2, index2) => {
                  html += `<td style="white-space: pre-wrap;word-wrap: break-word;padding: 6px;text-align: center;">${v[item2.value] || ''}</td>`
                  if(index2 == list.length - 1){
                    html += '</tr>'
                  }
                });
                if(i == this[name].length -1){
                  html += "</table>";
                }
              })
            }
          }
        });
        return html;
      },
      getTableHtml(list){
        let html = '<table border="1" style="width:100%;margin:8px auto;border-collapse: collapse; table-layout: fixed; ">';
        list.forEach((item1, index1) => {
            if(item1.type == 1){
              html += `<tr><td style="width:20%;white-space: pre-wrap; word-wrap: break-word;padding:6px;margin-right:6px;font-size:14px;color:#303030;background-color:#eeeeee;">${item1.field}</td><td colspan="3" valign="center" style="white-space: pre-wrap; word-wrap: break-word;font-size: 14px;color: #333;text-align: center;vertical-align: middle; ">${this.commonHandle(item1.value)}</td></tr>`
            }else{
              if(item1.type == 'left') {
                html += '<tr>'
              }
              html += `<td style="width:20%;white-space: pre-wrap; word-wrap: break-word;padding:6px;margin-right:6px;font-size:14px;color:#303030;background-color:#eeeeee;">${item1.field}</td>`
              html += `<td valign="center" style="width:30%;  white-space: pre-wrap; word-wrap: break-word;font-size: 14px;color: #333;text-align: center;vertical-align: middle; ">`
              let value = this.commonHandle(item1.value);
              html += value + `</td>`;
              if(!item1.type) {
                html += '</tr>'
              }
            }
        });
        html += "</table>";
        return html;
      },
      commonHandle(value){
        if(value == 'refundType'){
          return this.projectData[value] == '0' ? '退款' : '客户预存款'
        }else if(value == 'accountType'){
          return this.projectData[value] == '0' ? '对公账号' : this.projectData[value] == '1' ? '对私账号' : '原路返回'
        }else{
          return this.projectData[value] || '---'
        }
      },
      generateHTML(projectData, formConfShow) {
        let lsit = [
          {
            componentName: '申请人信息',
            info: [
              {field: '发起人',value: 'createBy'},
              {field: '项目编号',value: 'refundCode'},
              {field: '订单来源',value: 'orderSource'},
              {field: '客户编号',value: 'customerNo'},
              {field: '订单号',value: 'orderSn'},
              {field: '发起时间',value: 'refundStartTime'},
              {field: '当前节点',value: 'nodeName'},
              {field: '付款单位',value: 'accountName'},
              {field: '结束时间',value: 'refundOverTime'},
            ]
          },
          {
            componentName: '退款信息',
            info: [
              {field: '付款单位',value: 'payDepart',type: 1},
              {field: '项目办理人名称',value: 'customerName',type: 1},
              {field: '退款原因',value: 'refundReason',type: 1},
              {field: '退款方式',value: 'refundType',type: 'left'},
              {field: '退款类型',value: 'accountType'},
              {field: '币种',value: 'refundCurrency',type: 'left'},
              {field: '实退金额',value: 'currentRealPrice'},
              {field: '收款人全称',value: 'accountName',type: 1},
              {field: '银行账号',value: 'accountNo',type: 'left'},
              {field: '开户行',value: 'accountBankName'},
            ]
          },
          {
            componentName: '产品信息',
            info: [
              {field: '产品名称',value: 'productName'},
              {field: '产品规格',value: 'productDesc'},
              {field: '退单数量',value: 'refundNum'},
              {field: '退款金额',value: 'refundProductPrice'},
              {field: '应扣成本',value: 'refundCostPrice'},
              {field: '实退金额',value: 'refundRealityPrice'},
              {field: '备注',value: 'remark'},
            ]
          },
          {
            componentName: '审批流程',
            info: [
              {field: '审批节点',value: 'taskName'},
              {field: '审批人',value: 'taskHandleBy'},
              {field: '审批意见',value: 'remark'},
              {field: '操作时间',value: 'createTime'},
            ]
          },
        ]
        if(this.projectData.refundType == 0 && this.projectData.accountType == 2){
          lsit[1].info = [
            {field: '付款单位',value: 'payDepart',type: 1},
            {field: '项目办理人名称',value: 'customerName',type: 1},
            {field: '退款原因',value: 'refundReason',type: 1},
            {field: '退款方式',value: 'refundType',type: 'left'},
            {field: '退款类型',value: 'accountType'},
            {field: '币种',value: 'refundCurrency',type: 'left'},
            {field: '实退金额',value: 'currentRealPrice'},
          ]
        }
        let html = '';
        html += this.getInitHtmlHead();
        html += `<div style="color: #FF681D;font-size: 22px;font-weight: bold;text-align: center;">退单流程-${this.projectData.refundCode}</div>`;
        let item = this.dict.type['order_refund_account_type'].find(v => v.value == this.projectData.accountType)
        html += `<div style="color: #FF681D;font-size: 22px;font-weight: bold;text-align: center;">${this.projectData.accountName}+${item.label}</div>`;
        lsit.forEach((item, index) => {
          html += '<div class="form-li" style="width:700px;margin:0 auto;margin-top: 20px;">';
          if (item.componentName) {
            html += `<div style="width:700px;margin-bottom: 10px;border-bottom:1px solid #FF681D;padding-bottom: 4px;"><div class="title" style="font-size:16px;font-weight: bold;color:#111;padding-left: 10px;height: 21px;box-sizing: border-box;overflow-y: hidden;">${item.componentName}</div></div>`;
          }
          if (item.info && item.info.length) {
            if(index == 0){
              html += this.getThreeTableHtml(item.info);
            }else if(index == 1){
              this.projectData.currentRealPrice = 0;
              this.projectData.list.forEach(v => {
                this.projectData.payDepart = v.productSubject;
                this.projectData.currentRealPrice += v.refundRealityPrice
              })
              html += this.getTableHtml(item.info)
            }else if(index == 2){
              html += this.getOneTableHtml(item.info,'list')
            }else{
              html += this.getOneTableHtml(item.info,'approvalList')
            }
          }
          html += "</div>";
        });
        return html;
      },
      async printPdf() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        let day = currentDate.getDate();
        day = day < 10 ? "0" + day : day;
        let hours = currentDate.getHours();
        hours = hours < 10 ? "0" + hours : hours;
        let minutes = currentDate.getMinutes();
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let nSecond = currentDate.getSeconds();
        nSecond = nSecond < 10 ? "0" + nSecond : nSecond;
        this.nowTime = `${year}/${month}/${day} ${hours}:${minutes}:${nSecond}`;
        let data = {
          isPreview: true,
          templateData: this.showHtmls,
          templateName: this.projectData.recordTitle,
          templateType: "html",
        };
        let response = await previewTemplateFile(data).then();
        const binaryData = [];
        binaryData.push(response.data);
        const contentType = response.headers['content-type'];
        this.pdfUrl = window.URL.createObjectURL(
          new Blob(binaryData, { type: contentType })
        );
        setTimeout(() => {
          loading.close();
        }, 1500);
        window.open(this.pdfUrl);
      },
      delItem(index) {
        if (this.projectData.list.length == 1) {
          this.$modal.msgError("只剩余一个产品");
          return false;
        }
        removeRefundProjectById(this.projectData.list[index].id).then(res => {
          if(res.code == 200){
            this.projectData.list.splice(index, 1);
          }
        })
      },
      subConformity() {
        switch (this.clickType) {
          case 2:
            this.cancelForm();
            break;
        }
      },
      clickButton(type) {
        this.clickType = type;
        this.nextTask = this.taskData[this.taskActive].taskInternalName;
        switch (this.clickType) {
          case 1:
            this.taskLabel = "锁定原因";
            this.taskRemark =
              "锁定后，项目将无法做任何流转操作，如需要，请联系上级解锁";
            this.taskTle = "锁定";
            this.tasksave = true;
            break;
          case 2:
            this.taskLabel = "作废说明";
            this.taskRemark =
              "作废后，项目中产品的办理数量将恢复，作废操作不可撤销，请确认后处理";
            this.taskTle = "作废";
            this.tasksave = true;
            break;
          case 3:
            this.taskLabel = "异常办结说明";
            this.taskRemark =
              "异常办结后，项目进度将自动计算为100%，且不可恢复，请确认清楚后处理";
            this.taskTle = "异常办结";
            this.tasksave = true;
            break;
  
          default:
            break;
        }
      },
      checkTask(tab) {
        console.log(tab.name, "tab");
        if (tab.name == "1" && !this.isRecord) {
          this.getRecord();
        }
      },
      /** 流程表单数据 */
      async getFlowFormDatas() {

      },
      // 项目办理多个任务选择
      choseTaskChanges(e) {
        this.choseTaskforms = this.choseTaskList.find((item) => item.id == e);
        console.log(this.choseTaskforms);
      },
      //驳回到指定节点 - 确定
      choseRejectComplete(){
        this.$refs["choseTasks"].validate((valid) => {
          if (valid) {
            if (this.choseTaskform.id) {
              let params = JSON.parse(JSON.stringify(this.choseTaskforms));
              params.id = this.projectData.projectTaskId;
              params.remark = this.choseTaskform.remark;
              params.recordIds = this.commonRecordId();
              params.taskType = this.projectData.commonTaskType;
              rejectCommonTask(params).then(res => {
                if(res.code == 200){
                  this.choseRejectTask = false;
                  this.$modal.msgSuccess(res.msg);
                  this.initRefundInfo();
                  this.goBack(700);
                }
              })
            }
          }
        });
      },
      // 项目办理多个任务确认
      choseTaskComplete() {
        this.$refs["choseTasks"].validate((valid) => {
          if (valid) {
            if (this.choseTaskforms.id) {
              this.taskId = this.choseTaskforms.id;
              this.chilTaskId = this.choseTaskforms.taskId;
              this.chilTaskName = this.choseTaskforms.taskName;
              this.getFlowFormDatas1();
              this.btnShow = true;
              this.choseTask = false;
            }
          }
        });
      },
      async getFlowFormDatas1() {

      },
      // 设置项目标题
      setTitle() {
        let form = this.$refs.parser.submitForms();
        let title = [];
        if (form.titleList) {
          // 项目标题设置相关
          form.titleList.forEach((item) => {
            form.fields.forEach((item1) => {
              if (item.moduleId == item1.__config__.moduleId) {
                item1.__config__.children.forEach((item2) => {
                  if (item.__vModel__ == item2.__vModel__) {
                    title.push(item2.__config__.defaultValue);
                  }
                });
              }
            });
          });
        }
        return title.join("+");
      },
      /** 返回页面 */
      goBack(time) {
        this.isTips = false
        // 关闭当前标签页并返回上个页面
        setTimeout(() => {
          if (this.isOpened) {
            let previousPage = localStorage.getItem("previousPage");
            if (previousPage) {
              this.$router.push({
                path: previousPage,
              });
              localStorage.removeItem("previousPage");
            } else {
              this.$router.push({
                path: "/deliver/projectRefund",
                query: { vwTable: "vw_order_refund_project_list" },
              });
            }
          } else {
            let prePage = JSON.parse(sessionStorage.getItem("componentData"));
            if (prePage) {
              this.$router.push({
                path: prePage,
              });
              sessionStorage.removeItem("componentData");
            } else {
              this.$router.go(-1);
            }
          }
        }, time);
        // let path
        // if(this.$route.path.indexOf('business') != -1) {
        //   path = "/business/deliver/schedule"
        // }
        // if(this.$route.path.indexOf('deliver') != -1) {
        //   path = "/deliver/todotask"
        // }
        // const obj = { path: path, query: { t: Date.now() } };
        // this.$tab.closeOpenPage(obj);
      },
      submitSave(){
        if(!this.projectData.remark) return this.$message.error('请输入审批意见')
        const loading = this.$loading({
          lock: true,
          text: '提交中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        if(this.projectData.refundStatus == 6){
          let prodIds = [];
          this.projectData.list.forEach(v => {
            prodIds.push(v.productId);
          })
          let projectData = JSON.parse(JSON.stringify(this.projectData));
          delete projectData.nodeName;
          delete projectData.refundStatus;
          projectData.recordIds = this.commonRecordId()
          rejectSubmitCommonTask({id: this.projectData.projectTaskId,projectBill: this.$route.query.projectBill,remark: this.projectData.remark,taskType: this.projectData.commonTaskType,prodIds: prodIds,recordIds: this.commonRecordId()}).then(res => {
            if(res.code == 200){
              updateOrderBillRefundProject(projectData).then(res1 => {
                if(res1.data){
                  this.taskSumitDia = false;
                  this.initRefundInfo();
                  this.goBack(700);
                  loading.close();
                }
              }).catch(err => {
                loading.close();
              })
            }
          }).catch(err => {
            loading.close();
          })
        }else{
          //提交
          this.taskSumitDia = true;
          let prodIds = [];
          this.projectData.list.forEach(v => {
            prodIds.push(v.productId);
          })
          let params = {
            id: this.checkData.id,
            projectBill: this.$route.query.projectBill,
            projectTaskId: this.checkData.task.id,
            nextNode: this.checkData.nextNode,
            remark: this.projectData.remark,
            taskType: this.projectData.commonTaskType,
            prodIds: prodIds
          }
          if(this.checkData && this.checkData.productUserList){
            params.productUserList = this.checkData.productUserList;
          }
          params.recordIds = this.commonRecordId()
          submitCommonProjectTaskNext(params).then(result => {
            if(result.code == 200){
              let projectData = JSON.parse(JSON.stringify(this.projectData));
              delete projectData.nodeName
              delete projectData.refundStatus;
              projectData.recordIds = this.commonRecordId()
              updateOrderBillRefundProject(projectData).then(res => {
                this.taskSumitDia = false;
                this.initRefundInfo();
                this.goBack(700);
                loading.close();
              })
            }else{
              loading.close();
            }
          }).catch(err => {
            loading.close();
          })
        }
      },
      // 提交项目
      async submitPros() {
        if(this.projectData.nodeName.indexOf('上级') > -1 && this.projectData.projectTaskId){
          if(!this.projectData.refundCheckRatify) return this.$message.error('请选择退款核定')
          if(![0,1].includes(this.projectData.isContractCost)) return this.$message.error('请选择是否扣除违约金或人力成本')
        }
        if(this.projectData.nodeName.indexOf('财务') > -1 && this.projectData.projectTaskId){
          let isHaveVal = false;
          let projectData = JSON.parse(JSON.stringify(this.projectData))
          projectData.list.forEach(v => {
            if(!(v.refundCostPrice || String(v.refundCostPrice) == 0)){
              isHaveVal = true;
            }
          })
          if(isHaveVal) return this.$message.error('应扣成本不能为空,请补充完整。')
        }
        if(this.projectData.nodeName.indexOf('操作') > -1 && this.projectData.projectTaskId){
          let isHaveVal = false;
          let projectData = JSON.parse(JSON.stringify(this.projectData))
          projectData.list.forEach(v => {
            if(v.refundRecordList){
              v.refundRecordList.forEach(s => {
                if(!s.isInvalid){
                  isHaveVal = 1;
                }
                if(s.isInvalid == 1 && !s.invalidDesc){
                  isHaveVal = 2;
                }
              })
            }
          })
          if(isHaveVal) return this.$message.error(isHaveVal == 1 ? '请选择工单是否作废' : '请输入作废原因')
        }
        this.projectData.remark = '';
        //驳回状态
        if(this.projectData.refundStatus == 6){
          this.taskSumitDia = true;
        }else{
          //校验
          let prodIds = [];
          this.projectData.list.forEach(v => {
            prodIds.push(v.productId);
          })
          getCommonProjectTaskNext({projectBill: this.$route.query.projectBill,taskType: this.projectData.commonTaskType,prodIds: prodIds,recordIds: this.commonRecordId()}).then(res => {
            //提交
            if(res.code == 200){
              this.checkData = res.data;
              this.taskSumitDia = true;
            }else{
              loading.close();
            }
          })
        }
      },
      // 获取提交记录
      getRecord() {
        recordLog({ recordId: this.recordId }).then((res) => {
          if (res.code == 200) {
            this.recordList = res.data;
          }
          this.isRecord = true;
        });
        abnormalLog({ recordId: this.recordId }).then((res) => {
          if (res.code == 200) {
            this.abnormalList = res.data;
          }
        });
      },
      getValues(value) {
        if (Array.isArray(JSON.parse(value)) && JSON.parse(value)) {
          let file = "";
          JSON.parse(value).forEach((item) => {
            file += `<a href="${item.url}" target="_blank" rel="noopener noreferrer">${item.name}</a>`;
          });
          console.log(JSON.parse(value), "111111");
          return file;
        } else {
          return value;
        }
      },
      // 保存提交记录
      commitRecord() {
        let list = [];
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.allowMultiple) {
            if (
              item.__config__.defaultValue &&
              item.__config__.defaultValue.length
            ) {
              item.__config__.defaultValue.forEach((item1, index1) => {
                item1.children.forEach((item2) => {
                  if (
                    item2.__config__.defaultValue instanceof Array ? JSON.stringify(item2.__config__.beforeValue) != JSON.stringify(item2.__config__.defaultValue) : item2.__config__.beforeValue != item2.__config__.defaultValue
                  ) {
                    let params = {
                      recordId: this.recordId,
                      recordType: this.projectData.recordStatus,
                      fieldName:
                        item.__config__.componentName +
                        "-" +
                        item2.__config__.label +
                        (index1 + 1),
                      fieldValue:
                        item.__config__.moduleId +
                        "-" +
                        item2.__vModel__ +
                        "-" +
                        (index1 + 1),
                    };
                    if (item2.__config__.beforeValue instanceof Array) {
                      params.beforeValue = item2.__config__.beforeValue.join(",");
                    } else {
                      params.beforeValue = item2.__config__.beforeValue;
                    }
                    if (item2.__config__.defaultValue instanceof Array) {
                      params.afterValue = item2.__config__.defaultValue.join(",");
                    } else {
                      params.afterValue = item2.__config__.defaultValue;
                    }
                    list.push(params);
                  }
                });
              });
            }
          } else {
            item.__config__.children.forEach((item1) => {
              if (
                item1.__config__.defaultValue instanceof Array ? JSON.stringify(item1.__config__.beforeValue) != JSON.stringify(item1.__config__.defaultValue) : item1.__config__.beforeValue != item1.__config__.defaultValue
              ) {
                let params = {
                  recordId: this.recordId,
                  recordType: this.projectData.recordStatus,
                  fieldName:
                    item.__config__.componentName + "-" + item1.__config__.label,
                  fieldValue: item.__config__.moduleId + "-" + item1.__vModel__,
                };
                if (item1.__config__.beforeValue instanceof Array) {
                  params.beforeValue = item1.__config__.beforeValue.join(",");
                } else {
                  params.beforeValue = item1.__config__.beforeValue;
                }
                if (item1.__config__.defaultValue instanceof Array) {
                  params.afterValue = item1.__config__.defaultValue.join(",");
                } else {
                  params.afterValue = item1.__config__.defaultValue;
                }
                list.push(params);
              }
            });
          }
        });
        console.log(list, "listlistlist");
        if (list.length) {
          recordLogBatch(list).then((res) => {});
        }
      },
      async stagingForm(i,form) {
        this.tableData.forEach((item) => {
          if (item.orderProduct) {
            item.handlePrice =
              (item.handleNumber * item.orderProduct.productPrice) /
              item.orderProduct.takeTotal;
          }
        });
        this.formConfCopy = form ? form : this.$refs.parser.submitForms();
  
        if(!i) {
          this.formConfCopy.fields.forEach(item => {
            if(item.__config__.children?.length) {
              item.__config__.children.forEach(item1 => {
                if(item1.__config__.dataType == "dynamic") {
                  const arr = item1.__config__.dataConsumer?.split(".");
                  arr.forEach(item2 => {
                    if(item1[item2].length) {
                      this.$set(item1,item2,[])
                    }
                  })
                }
              })
            }
          })
        }
  
        if(!form) {
          // 为子表的字段数据赋"beforeValue"值
          this.formConfOld.fields.forEach((item) => {
            const matchingField = this.formConfCopy.fields.find(
              (item1) =>
                item.__config__.allowMultiple &&
                item1.__config__.allowMultiple &&
                item.__config__.moduleId == item1.__config__.moduleId
            );
  
            if (matchingField) {
              item.__config__.defaultValue?.forEach((item2, index) => {
                const correspondingItem =
                  matchingField.__config__.defaultValue[index];
                if (correspondingItem) {
                  item2.children.forEach((item4, index2) => {
                    const correspondingChild = correspondingItem.children[index2];
                    if (
                      correspondingChild &&
                      item4.__vModel__ === correspondingChild.__vModel__
                    ) {
                      this.$set(
                        correspondingChild.__config__,
                        "beforeValue",
                        item4.__config__.defaultValue
                      );
                    }
                  });
                }
              });
            }
          });
        }
  
        if (this.projectData.recordStatus == 6 && !i) {
          this.formConfCopy.fields.forEach((item) => {
            if (item.__config__.allowMultiple && item.__config__.rejModule) {
              item.__config__.rejModule = false;
              item.__config__.rejectValues = null;
              item.__config__.rejectReasons = null;
            }
            item.__config__.children.forEach((items) => {
              if(items.isReject) {
                items.isReject = false;
                items.rejectValue = null;
                items.rejectReason = null;
              }
            });
          });
        }
  
        let res;
        if (this.projectData.id) {
          let params = {
            id: this.projectData.id,
            formId: this.projectData.formId,
            recordName: this.projectData.recordName,
            recordTitle: this.setTitle(),
            recordStatus: this.projectData.recordStatus,
            projectId: this.projectData.projectId,
            areaGroupId: this.projectData.areaGroupId,
            variables: this.formConfCopy,
            products: this.tableData,
            remark: this.remark,
            version: this.projectData.version,
          };
  
          try {
            res = await bpmRecordProjectUpdate(params);
          } catch (err) {
            this.isSave = false;
          }
        } else {
          let params = {
            recordName: this.projectData.projectVO.projectName,
            recordTitle: this.setTitle(),
            recordStatus: 0,
            projectId: this.projectData.projectVO.id,
            areaGroupId: this.projectData.projectVO.areaGroupId,
            variables: this.formConfCopy,
            products: this.tableData,
            remark: this.remark,
            linkKey: this.multiProject ? this.multiProject : this.linkKey,
          };
  
          try {
            res = await bpmRecordProject(params);
          } catch (err) {
            this.isSave = false;
          }
        }
        if (res && res.code == 200) {
          this.recordId = res.data.id;
          this.projectData.version = res.data.version;
          this.commitRecord();
          return res.data.id;
        } else {
          this.commitRecord();
          return false;
        }
      },
      rejectOpen() {
        getCommonProjectTaskBefore({projectBill: this.$route.query.projectBill,taskType: this.projectData.commonTaskType,recordIds: this.commonRecordId()}).then(res => {
          if(res.code == 200){
            this.choseRejectTask = true;
            this.choseTaskList = res.data;
          }
        })
      },
      // 作废项目
      async cancelForm() {
        if (!this.taskTips) {
          this.$modal.msgError("请输入作废原因");
          return false;
        }
        try {
          await invalidatedCommonTask({ projectBill: this.$route.query.projectBill,taskType: this.projectData.commonTaskType,remark: this.taskTips});
          this.goBack(700);
          this.$modal.msgSuccess("作废成功");
        } catch (error) {
          // 捕获并处理异常情况
          console.error(error);
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .wb_Box {
    width: 25%;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
  }
  .wb_box0 {
    width: 0;
  }
  .app-container {
    width: 100%;
    background: #f9f9f9;
    .app_con {
      display: flex;
      justify-content: space-between;
      .main_con {
        flex: 1;
        margin-right: 20px;
        min-width: 808px;
      }
    }
  }
  ::v-deep .el-row .el-col {
    padding-left: 8px;
  }
  ::v-deep .el-table__indent {
    display: none;
  }
  ::v-deep .el-input-number--medium {
    width: 130px;
  }
  .top {
    width: 100%;
    // height: 160px;
    background-color: #fff;
    padding: 20px 20px 0;
    .head1 {
      display: flex;
      align-items: center;
    }
    .code {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      min-width: 300px;
      margin-right: 30px;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .conli {
        display: flex;
        align-items: center;
        margin: 10px;
        margin-top: 15px;
        font-size: 15px;
        color: #555;
        &:not(:last-child) {
          margin-right: 30px;
        }
        margin-left: 0;
        span {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
  ::v-deep .el-tabs__nav {
    // margin-left: 20px;
  }
  ::v-deep .el-step__description {
    padding-right: 0;
  }
  .tab-top {
    margin: 10px 0 0;
    ::v-deep .el-tabs__header {
      margin-bottom: 2px !important;
    }
  }
  .bottom {
    width: 100%;
    height: calc(100vh - 250px);
    margin-top: 10px;
    background-color: #fff;
    padding: 20px;
    position: relative;
    .pick_up {
      line-height: 30px;
      position: absolute;
      width: 13px;
      height: 52px;
      color: #484141;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      z-index: 99;
      right: -18px;
      top: 0px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      cursor: pointer;
      background: #736f6f;
      img {
        width: 50px;
      }
    }
    .pick_up_active {
      right: -38px !important;
    }
    .form-box {
      height: 100%;
      display: flex;
      .form-tabs {
        // width: 150px;
      }
      .form-right {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        .order_tle {
          font-size: 14px;
          color: rgb(51, 51, 51);
          font-weight: 700;
        }
        .el-row {
          margin: 0 !important;
        }
      }
      .iform-order {
        flex: 1;
        // max-height: 300px;
        // overflow-y: auto;
        margin: 10px 20px;
        padding: 0 20px;
        line-height: 30px;
        .el-row {
          margin: 0 !important;
        }
      }
      .form-link {
        margin: 10px 20px;
        padding: 0 20px;
  
        .fl-box {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow-x: auto;
          padding-bottom: 5px;
          user-select: none;
          .fl-item {
            border: 1px solid #4577f6;
            flex-shrink: 0;
            margin-right: 20px;
            height: 160px;
            width: 280px;
            box-sizing: border-box;
            cursor: pointer;
            .fli_tle {
              display: flex;
              align-items: center;
              height: 20px;
              span {
                white-space: nowrap; /* 禁止换行 */
                overflow: hidden; /* 隐藏溢出部分 */
                text-overflow: ellipsis; /* 使用省略号表示溢出文本 */
                font-size: 15px;
                width: 100%px;
                font-weight: 600;
              }
            }
            .fli_name {
              font-size: 15px;
              margin-top: 5px;
              color: #505050;
              height: 65px;
              line-height: 30px;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 显示的行数 */
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .fli_tip {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  .right {
    width: 100%;
    height: calc(100vh - 130px);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  
    ::v-deep .step-box {
      height: 92%;
      overflow-y: auto;
      // min-width: 320px;
      .el-step__head.is-finish {
        color: #303133;
        border-color: #303133;
        .el-step__line {
          background-color: #303133;
        }
      }
      .el-step__title.is-finish {
        color: #303133;
      }
      .el-step__head.is-process {
        color: #1890ff;
        border-color: #1890ff;
      }
      .el-step__title.is-process {
        color: #1890ff;
      }
    }
  }
  .record-list {
    width: 100%;
    .record-li {
      // width: 86%;
      min-height: 72px;
      border-radius: 16px;
      background-color: #f5f7fa;
      padding: 8px;
      margin-bottom: 10px;
      .record-title {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          margin: 0 10px;
          font-size: 14px;
          color: #9e9e9e;
        }
        .date {
          flex: 1;
          margin-left: 6px;
          font-size: 12px;
          color: #9e9e9e;
        }
      }
      .content {
        display: flex;
        align-items: center;
        margin: 6px 0;
        .sp1 {
          // width: 80px;
          font-size: 14px;
          color: #999999;
        }
        .sp2 {
          margin-left: 8px;
          text-align: center;
          font-size: 14px;
          color: blue;
          cursor: pointer;
          text-decoration: underline;
        }
        .sp3 {
          margin-left: 8px;
          font-size: 14px;
          color: #999999;
          span {
            color: #666;
          }
        }
      }
    }
  }
  .el-table {
    ::v-deep .cell {
      padding: 0;
    }
    ::v-deep .spa1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .el-step {
    flex-basis: auto !important;
    // height: 120px;
  }
  ::v-deep .bjs-container {
    height: calc(100vh - 360px) !important;
  }
  .description_item {
    margin: 10px 0;
    background: #fafafa;
    border-radius: 10px;
    color: #86909c;
    .task_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #86909c;
    }
    ul {
      padding: 10px;
      margin: 0;
      list-style: none;
      li {
        box-sizing: border-box;
        padding: 5px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #ede8e8;
        }
      }
    }
  }
  
  .rej-box {
    ::v-deep .el-table__body-wrapper {
      height: 380px;
      overflow-y: auto;
    }
  }
  .ts_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tsf_tipe {
      font-size: 13px;
      color: #aba2a2;
      flex: 1;
      margin-right: 50px;
      text-align: left;
    }
  }
  
  .os_img {
    width: 30px;
    margin-left: 10px;
    transform: translateY(-2px);
  }
  .ds_box {
    display: flex;
    align-items: center;
  }
  .dsjb_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  ::v-deep .el-input,
  .is-disabled {
    .el-input__inner {
      color: #333;
    }
  }
  ::v-deep .el-range-editor input {
    color: #333;
  }
  ::v-deep .el-radio-group {
    .is-checked.is-disabled {
      .el-radio__inner {
        background-color: #409eff;
        border-color: #409eff;
      }
      .el-radio__label {
        color: #409eff;
      }
    }
  }
  ::v-deep .el-textarea,
  .is-disabled {
    .el-textarea__inner {
      color: #333;
    }
  }
  ::v-deep .el-checkbox-group {
    .is-checked.is-disabled {
      .el-checkbox__inner {
        background-color: #409eff;
        border-color: #409eff;
      }
      .el-checkbox__label {
        color: #409eff;
      }
    }
  }
  .fielTable {
    ::v-deep .el-table__body-wrapper {
      height: 500px;
      overflow-y: auto;
    }
  }
  .showboxs {
    ::v-deep .el-dialog__body {
      padding: 0;
    }
  }
  #apps {
    width: 1000px;
    height: 800px;
    overflow-y: auto;
    padding: 10px 20px;
  }
  #form-show {
    padding: 20px;
    height: 800px;
    overflow-y: auto;
  }
  .form-show {
    padding: 20px;
    .h1 {
      font-size: 20px;
      color: #333;
      line-height: 50px;
    }
    .form-li {
      .title {
        font-size: 16px;
        font-weight: 700;
        color: #333;
      }
      .chil-boxs {
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
      }
      .chil-li {
        width: 50%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        .sp1 {
          width: 160px;
          text-align: left;
          margin-right: 6px;
          font-size: 14px;
          color: #888;
        }
        .sp2 {
          flex: 1;
          font-size: 14px;
          color: #333;
        }
      }
      .chils-boxs {
        .chils-li {
          margin-top: 8px;
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          background-color: #f7f7f7;
          .chil-li {
            margin: 5px 0;
          }
        }
      }
    }
  }
  ::v-deep .el-table .row-expand-cover .cell .el-table__expand-icon {
  display: none;
}
  </style>
  
  <style lang="scss">
  body {
    background-color: #f0f2f5;
  }
  .module-title {
    font-size: 14px;
    color: #333;
    font-weight: 700;
    display: flex;
    align-items: center;
    .quote {
      width:28px;
      height:28px;
      cursor: pointer;
      margin-left:6px;
    }
  }
  .moudle-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding: 15px 15px 36px;
    background: #f7f7f7;
    .moudle-li {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 8px;
      font-size: 14px;
      .span1 {
        color: #999999;
        width: 160px;
        text-align: right;
      }
      .span2 {
        flex: 1;
        word-break: break-all;
        margin-left: 4px;
        color: #333;
      }
      .img {
        display: flex;
        img {
          width: 40px;
        }
      }
      .cona {
        width: 100px;
        text-align: center;
        span {
          width: 100%;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .module-form {
    display: inline-block;
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .form-box {
      padding: 10px 12px 20px;
      font-size: 14px;
      display: inline-block;
      // border: 1px dashed rgb(64, 158, 255);
      border-radius: 10px;
      position: fixed;
      z-index: 999;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 80vh;
      overflow-y: auto;
      .el-col {
        display: inline-block;
      }
      .form-title {
        display: flex;
        align-items: center;
        padding: 0 5px;
        height: 48px;
        border-bottom: 1px solid #dadada;
        margin-bottom: 28px;
        span {
          flex: 1;
          font-size: 16px;
          color: #333;
          font-weight: 700;
        }
        i {
          font-size: 20px;
          font-weight: 700;
          cursor: pointer;
        }
      }
      .form-boxs {
        display: inline-block;
      }
      .btnbox {
        width: 160px;
        margin: 0 auto;
      }
    }
  }
  .task-name {
    // color: #ff0000;
    .el-tag.el-tag--warning {
      // background-color: #333;
      // color: #fff;
    }
  }
  .pro_step {
    flex: 1;
  
    .el-steps--simple {
      background-color: #fff; /* 设置背景颜色为灰色 */
      padding: 13px 0px;
    }
    .is-process {
      color: #ff0000; /* 设置完成、错误和进行中状态的图标颜色为红色 */
    }
    /* 在样式表中设置关闭按钮的样式 */
    .el-step .close-icon {
      display: none; /* 初始状态下隐藏关闭按钮 */
      cursor: pointer;
      font-size: 14px;
    }
  
    .el-step:hover .close-icon {
      display: inline-block; /* 鼠标悬浮时显示关闭按钮 */
    }
  }
  
  .el-step__title {
    width: 200px;
    white-space: nowrap; /* 防止标题换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
  .circle-avatar {
    color: #fff; /* 字体颜色 */
    background-color: #33c553;
    font-size: 25px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
  }
  .description_item ::v-deep .el-table--border {
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
  }
  .refundInfo{
    border: 1px #a0bcee dashed;
    padding: 10px 20px;
    margin: 10px 27.5px;
    line-height: 30px;
    .formBox .el-form-item__content{
        width: calc(100% - 160px);
    }
  }
  .tableName{
    margin-bottom: 20px;
  }
  .record-code{
    padding: 10px 0;
    cursor: pointer;
    color: #1890ff;
  }
  </style>
  