var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bpmn-modeler", {
        ref: "refNode",
        attrs: {
          formName: _vm.formName,
          formDatas: _vm.formDatas,
          projectId: _vm.projectId,
          xml: _vm.xml,
          users: _vm.users,
          groups: _vm.groups,
          categorys: _vm.categorys,
          exps: _vm.exps,
          "is-view": false,
        },
        on: { save: _vm.save, showXML: _vm.showXML },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.xmlTitle,
            modal: false,
            direction: "rtl",
            visible: _vm.xmlOpen,
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.xmlOpen = $event
            },
          },
        },
        [
          _c("el-scrollbar", [
            _c(
              "pre",
              {
                directives: [
                  {
                    name: "highlight",
                    rawName: "v-highlight",
                    value: _vm.xmlData,
                    expression: "xmlData",
                  },
                ],
              },
              [_c("code", { staticClass: "xml" })]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }