<script>
import { log } from "console";
import { deepClone } from "../../utils/index";
import render from "../render/render.js";
import { isArray } from "util";
import Vue from "vue";
import { previewTemplateFile, getVModelData, getHKCompanyKycZipDirect, getToContractSign } from "@/api/system/config";
import { pdfToPng } from "@/api/system/cloud-config";
import { generateCode, getTempUrlFromText, sendTempUrlFromText } from "@/api/system/project";
import { isObjectObject } from "@/utils/index";
import request from "@/utils/request";
const ruleTrigger = {
  "el-input": "blur",
  "el-input-number": "blur",
  "el-select": "change",
  "el-radio-group": "change",
  "el-checkbox-group": "change",
  "el-cascader": "blur",
  "el-time-picker": "change",
  "el-date-picker": "change",
  "el-rate": "change",
};

const layouts = {
  colFormItem(h, scheme) {
    const recordStatus = this.recordStatus || 0;
    const originalEditable = this.originalEditable || [];
    if (scheme.dynamicVariables) {
      let isShow = !Object.values(scheme.dynamicVariables).every(
        (item) => item === false
      );

      // 避免重复设置属性值
      if (isShow !== scheme.isShow) {
        Vue.set(scheme, "isShow", isShow);

        if (!isShow && scheme.__config__.required) {
          this.$delete(
            this[this.formConf.formRules],
            scheme.moduleIds + "-" + scheme.__vModel__
          );
        }

        if (isShow) {
          if (recordStatus == 6 && originalEditable.length) {
            let isDisabled = originalEditable.some(
              (item2) =>
                scheme.__vModel__ === item2.__vModel__ &&
                scheme.moduleIds === item2.moduleId
            );
            Vue.set(scheme, "disabled", !isDisabled);
          }
          if (scheme.__config__.required) {
            this.buildRules1(scheme);
          }
        }

        // const configs = scheme.__config__;
        // const method = configs.method;
        // const url = configs.url;
        // const arr = configs.dataConsumer?.split(".");
        // let isReq = arr?.some(item => !scheme[item].length);
        // if(configs.dataType === "dynamic" && method && url && configs.dataConsumer && configs.dataPath && isShow && isReq) {
        //   this.$axios({
        //     method,
        //     url,
        //   }).then((resp) => {
        //     const respData = configs.dataPath?.split(".").reduce((pre, item) => pre[item], resp.data);
        //     arr.reduce((pre, item, i) => {
        //       if (arr.length === i + 1) {
        //         pre[item] = respData;
        //       } else if (!isObjectObject(pre[item])) {
        //         pre[item] = {};
        //       }
        //       return pre[item];
        //     }, scheme);
        //   });
        // }
      }
    }

    const config = scheme.__config__;
    const listeners = buildListeners.call(this, scheme);
    const isReject = scheme.isReject;

    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = "0";
    return (
      <el-col span={config.span} v-show={scheme.isShow}>
        <el-form-item
          label-width={labelWidth}
          ref={"i" + scheme.moduleIds + scheme.__vModel__}
          prop={scheme.moduleIds + "-" + scheme.__vModel__}
          label={config.showLabel ? config.label : ""}
          class={[
            isReject &&
            recordStatus == 6 &&
            (config.defaultValue instanceof Array
              ? JSON.stringify(config.defaultValue) ===
                JSON.stringify(config.rejectValue)
              : config.defaultValue === config.rejectValue)
              ? "isrejred"
              : "",
            "i" + scheme.moduleIds + scheme.__vModel__,
          ]}
        >
          <render conf={scheme} on={listeners} />
          {config.tag === "el-radio-group" && config.defaultValue === -1 ? (
            <el-input
              style="margin-top: 6px;"
              v-model={config.otherInput}
              placeholder="请输入"
              onInput={(event) => this.otherInput(event, scheme)}
            ></el-input>
          ) : (
            ""
          )}
          <div v-show={isReject && recordStatus === 6}>
            <div
              onClick={(event) => this.handleClicks(event)}
              style={
                scheme.isModify
                  ? "font-size:12px;color:red;"
                  : "font-size:12px;color:blue;"
              }
              v-show={
                config.defaultValue instanceof Array
                  ? JSON.stringify(config.defaultValue) ===
                    JSON.stringify(config.rejectValue)
                  : config.defaultValue === config.rejectValue
              }
            >
              <div>
                驳回原因：<span domPropsInnerHTML={scheme.rejectReason}></span>
              </div>
            </div>
            <span
              style="font-size:12px;color:blue;"
              v-show={
                config.defaultValue instanceof Array
                  ? JSON.stringify(config.defaultValue) !=
                    JSON.stringify(config.rejectValue)
                  : config.defaultValue != config.rejectValue
              }
            >
              已修改
            </span>
          </div>
          <span
            v-show={config.explain}
            style="font-size:12px;color:#ff8900;line-height:20px;display: block;"
          >
            说明：{config.explain}
          </span>
        </el-form-item>
      </el-col>
    );
  },
  rowFormItem(h, scheme) {
    let child = renderChildren.apply(this, arguments);
    scheme.__config__.showModule = scheme.__config__.children.some(
      (item) => item.isShow === true
    );
    const recordStatus = this.recordStatus || 0;
    if (scheme.type === "flex") {
      child = (
        <el-row
          type={scheme.type}
          justify={scheme.justify}
          align={scheme.align}
        >
          {child}
        </el-row>
      );
    }
    const isQuote =
      scheme.__config__.moduleIdList && scheme.__config__.moduleIdList.length;
    let identifyTypes = [];
    let labels = [
      "商品/服务小类",
      "商品/服务小类（中文）",
      "商品/服务小类（英文）",
    ];
    return (
      <el-col span={scheme.span}>
        <el-row
          class={"i" + scheme.__config__.moduleId}
          gutter={scheme.gutter}
          v-show={scheme.__config__.showModule}
        >
          <div class="module-title">
            <span class="title">{scheme.__config__.componentName}</span>
            <el-button
              onClick={() => this.addModules(scheme)}
              v-show={scheme.__config__.allowMultiple}
              style="margin:10px;"
              type="primary"
              size="mini"
              icon="el-icon-plus"
            >
              添加数据
            </el-button>
            <div
              style="flex:1;"
              v-show={scheme.__config__.rejModule && recordStatus === 6}
            >
              <div
                onClick={(event) => this.handleClicks(event)}
                v-show={
                  JSON.stringify(scheme.__config__.defaultValue) ==
                  scheme.__config__.rejectValues
                }
                style={
                  scheme.__config__.isModify
                    ? "font-size:12px;color:red;"
                    : "font-size:12px;color:blue;"
                }
              >
                驳回原因：
                <span
                  domPropsInnerHTML={scheme.__config__.rejectReasons}
                ></span>
              </div>
              <span
                style="font-size:12px;color:blue;"
                v-show={
                  JSON.stringify(scheme.__config__.defaultValue) !=
                  scheme.__config__.rejectValues
                }
              >
                已修改
              </span>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="此引用功能适用为当前项目中与其他模块含有3个以上相同字段的互相引用！"
              placement="top"
            >
              <el-button
                style="float: right;"
                size="mini"
                type="primary"
                icon="el-icon-copy-document"
                v-show={isQuote}
                onClick={() => this.quoteModules(scheme)}
                plain
              >
                引用数据
              </el-button>
            </el-tooltip>
          </div>
          {scheme.__config__.defaultValue?.map((item, index) => (
            <div class="moudle-box" key={index}>
              {item.children?.map((item1, index1) => (
                <div
                  class={
                    item1.__config__.span == 24
                      ? "moudle-li1"
                      : item1.__config__.span == 12
                      ? "moudle-li"
                      : item1.__config__.span == 8
                      ? "moudle-li2"
                      : "moudle-li"
                  }
                  key={index + "" + index1}
                  v-show={item1.isShow}
                >
                  <span
                    class="span1"
                    v-show={
                      identifyTypes.indexOf(item1.__config__.identifyType) ===
                        -1 && labels.indexOf(item1.__config__.label) === -1
                    }
                  >
                    {item1.__config__.label}:
                  </span>
                  {item1.__config__.tag == "el-upload" ? (
                    <div class="img">
                      {this.$options.methods
                        .getValue(item1)
                        .map((item2, index3) =>
                          item2.indexOf(".png") != -1 ||
                          item2.indexOf(".jpg") != -1 ? (
                            <a href={item2} target="_blank">
                              <img src={item2} alt="" key={index3} />
                            </a>
                          ) : (
                            <a href={item2} target="_blank" class="cona">
                              <img
                                src="https://oss.71go.com/qyg-pc/file.png"
                                alt=""
                                class="nosee"
                                key={index3}
                              />
                              <span>{item2}</span>
                            </a>
                          )
                        )}
                    </div>
                  ) : identifyTypes.indexOf(item1.__config__.identifyType) !=
                      -1 || labels.indexOf(item1.__config__.label) != -1 ? (
                    <table
                      border="1"
                      style="border-collapse: collapse;width:100%;border-color:#b1b1b1;}"
                    >
                      <tr>
                        <th style="background-color: #ccc;width:10%;">序号</th>
                        <th style="background-color: #ccc;width:60%;">
                          {item1.__config__.label}
                        </th>
                      </tr>
                      {item1.__config__.defaultValue
                        .split(";")
                        .map((item, index) => (
                          <tr>
                            <td style="padding:6px;text-align: center;">
                              {index + 1}
                            </td>
                            <td style="border-color:#fff;height:50px;padding:6px;text-align: center;word-wrap:break-word;overflow-wrap:break-word;overflow-y:auto;display: block;">
                              {item}
                            </td>
                          </tr>
                        ))}
                    </table>
                  ) : (
                    <span class="span2">
                      {this.$options.methods.getValue(item1)}
                    </span>
                  )}
                </div>
              ))}
              <el-button
                disabled={!scheme.__config__.editModule}
                onClick={() => this.updateModule(scheme, index)}
                v-show={scheme.__config__.allowMultiple}
                class="btn"
                style="position: absolute;right:90px;bottom:6px;display: none !important;"
                type="primary"
                size="mini"
                icon="el-icon-edit"
              >
                修改
              </el-button>
              <el-button
                disabled={!scheme.__config__.editModule}
                onClick={() => this.delModule(scheme, index)}
                v-show={scheme.__config__.allowMultiple}
                class="btn"
                style="position: absolute;right:10px;bottom:6px;display: none !important;"
                type="info"
                size="mini"
                icon="el-icon-delete"
              >
                删除
              </el-button>
            </div>
          ))}
          <div
            ref="moduleId"
            style={
              scheme.__config__.allowMultiple
                ? scheme.__config__.moduleShow
                  ? { display: "inline-block" }
                  : { display: "none" }
                : null
            }
            class={scheme.__config__.allowMultiple ? "module-form" : ""}
          >
            <div
              class={
                scheme.__config__.allowMultiple ? "form-box formBox" : "formBox"
              }
            >
              <div class="form-title" v-show={scheme.__config__.allowMultiple}>
                <span>{scheme.__config__.componentName}</span>
                <i
                  onClick={() => this.cleanModule(scheme)}
                  class="el-icon-close"
                ></i>
              </div>
              <div
                class={
                  scheme.__config__.allowMultiple
                    ? "form-boxs formBoxs"
                    : "formBoxs"
                }
              >
                {child}
              </div>
              <div class="btnbox">
                <el-button
                  onClick={() => this.cleanModule(scheme)}
                  v-show={scheme.__config__.allowMultiple}
                  type="info"
                  size="mini"
                  icon="el-icon-close"
                >
                  取消
                </el-button>
                <el-button
                  onClick={() => this.addModule(scheme)}
                  v-show={scheme.__config__.allowMultiple}
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  disabled={!scheme.__config__.editModule}
                >
                  添加
                </el-button>
              </div>
            </div>
          </div>
        </el-row>
      </el-col>
    );
  },
  raw(h, currentItem, index, list) {
    const config = currentItem.__config__;
    const child = renderChildren.apply(this, arguments);
    return (
      <render
        key={config.renderKey}
        conf={currentItem}
        onInput={(event) => {
          this.$set(config, "defaultValue", event);
        }}
      >
        {child}
      </render>
    );
  },
  tsSubform(h, scheme) {
    const { formConfCopy } = this;
    const config = scheme.__config__;
    const data = JSON.parse(JSON.stringify(config.children));
    data.forEach((item) => {
      item.config = {};
      item.config = {};
      item.config.label = item.__config__.label;
      item.config.formId = item.__config__.formId;
      item.config.align = item.__config__.align;
      item.config.optionType = item.__config__.optionType;
      item.config.width = item.__config__.width;
      item.config.tag = item.__config__.tag;
      item.config.prop = item.__vModel__;
      delete item.__config__;
      delete item.__vModel__;
    });
    const tableData = data;
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = "0";
    return (
      <el-col span={config.span}>
        <el-form-item
          label-width={labelWidth}
          label={config.showLabel ? config.label : ""}
        >
          <div
            class={scheme.__config__.moduleId}
            style="font-size:14px;color:#333;font-weight:700; "
          >
            {config.labelName}（子表）
          </div>
          <ts-sub-form
            addButton={scheme.addButton}
            canEdit={scheme.canEdit}
            deleteButton={scheme.deleteButton}
            disabled={formConfCopy.disabled}
            table-data={tableData}
            value={config.defaultValue}
          ></ts-sub-form>
        </el-form-item>
      </el-col>
    );
  },
};

function renderFrom(h) {
  const { formConfCopy } = this;
  return (
    <el-row class="boundary" gutter={formConfCopy.gutter}>
      <el-form
        size={formConfCopy.size}
        label-position={formConfCopy.labelPosition}
        disabled={formConfCopy.disabled}
        label-width={`${formConfCopy.labelWidth}px`}
        ref={formConfCopy.formRef}
        // model不能直接赋值 https://github.com/vuejs/jsx/issues/49#issuecomment-472013664
        props={{ model: this[formConfCopy.formModel] }}
        rules={this[formConfCopy.formRules]}
      >
        {renderFormItem.call(this, h, formConfCopy.fields)}
        {formConfCopy.formBtns && formBtns.call(this, h)}
      </el-form>
    </el-row>
  );
}

function formBtns(h) {
  return (
    <el-col>
      <el-form-item size="large">
        {/* <el-button type="primary" onClick={this.submitForm}>提交</el-button> */}
        {/* <el-button onClick={this.resetForm}>重置</el-button> */}
      </el-form-item>
    </el-col>
  );
}

function renderFormItem(h, elementList) {
  if (elementList) {
    return elementList.map((scheme) => {
      const config = scheme.__config__;
      const layout = layouts[config.layout];

      if (layout) {
        return layout.call(this, h, scheme);
      }
      throw new Error(`没有与${config.layout}匹配的layout`);
    });
  }
}

function renderChildren(h, scheme) {
  if (scheme.__config__) {
    const config = scheme.__config__;
    if (!Array.isArray(config.children)) return null;
    return renderFormItem.call(this, h, config.children);
  }
}

function setValues(event, config, scheme) {
  // console.log(event, config, scheme, "失焦事件");
  this.$nextTick(() => {
    if (config.tag === "el-input" && event.target.value) {
      let events = event.target.value.replace(
        /[^\u4e00-\u9fa5a-zA-Z0-9\,\。\；\，\！\、\：\？\“ ”\（\）\—\…\《 》]+/g,
        (c) => c.normalize("NFKC")
      );
      if (event.target.type === "number") {
        events = Number(events);
        if (scheme.conversion) {
          let name = this.digitUppercase(events);
          this.$set(scheme.__slot__, "append", name);
        }
      }
      this.$set(config, "defaultValue", events.trim());
      this.$set(
        this[this.formConf.formModel],
        scheme.moduleIds + "-" + scheme.__vModel__,
        events.trim()
      );
    }
    if (this.formConfCopy.rulesForm?.length && config.tag === "el-input") {
      // 关联字段校验逻辑处理
      this.formConfCopy.rulesForm.forEach((item, index) => {
        let isTrue = false;
        let dynamicVariables = {};
        item.condition.forEach((item1, index1) => {
          dynamicVariables["index" + index1] = false;
          this.formConfCopy.fields.forEach((item2) => {
            item2.__config__.children.forEach((item3) => {
              if (
                item1.moduleId == item3.moduleIds &&
                item1.__vModel__ == item3.__vModel__
              ) {
                if (Array.isArray(item3.__config__.defaultValue)) {
                  dynamicVariables["index" + index1] =
                    !item3.__config__.defaultValue.some((element) =>
                      item1.conValue.includes(element)
                    );
                } else {
                  if (!Array.isArray(item1.conValue)) {
                    item1.conValue = item1.conValue?.split(",");
                  }
                  if (
                    item1.conValue.indexOf(item3.__config__.defaultValue) == -1
                  ) {
                    dynamicVariables["index" + index1] = true;
                  } else {
                    dynamicVariables["index" + index1] = false;
                  }
                }
              }
            });
          });
        });
        isTrue = !Object.values(dynamicVariables).every(
          (item) => item === false
        );
        item.activeData.forEach((item1) => {
          this.formConfCopy.fields.forEach((item2) => {
            item2.__config__.children.forEach((item3) => {
              if (
                item1.moduleId == item3.moduleIds &&
                item1.__vModel__ == item3.__vModel__
              ) {
                if (!isTrue) {
                  if (item1.actionValue == 2) {
                    if (!item3.__config__.isValue) {
                      this.$set(
                        item3.__config__,
                        "defaultValue",
                        item1.conValue
                      );
                      this.$set(
                        this[this.formConf.formModel],
                        item3.moduleIds + "-" + item3.__vModel__,
                        item1.conValue
                      );
                      this.$set(item3.__config__, "isValue", true);
                    }
                  } else {
                    if (item3.dynamicVariables) {
                      item3.dynamicVariables.isShow = false;
                      item3.dynamicVariables["isShow" + index] =
                        item1.actionValue == 1 ? true : false;
                    } else {
                      let dynamicVariables1 = {};
                      dynamicVariables1["isShow" + index] =
                        item1.actionValue == 1 ? true : false;
                      this.$set(item3, "dynamicVariables", dynamicVariables1);
                    }
                  }
                } else {
                  if (item1.actionValue == 2) {
                    this.$set(item3.__config__, "isValue", false);
                  } else {
                    if (item3.dynamicVariables) {
                      item3.dynamicVariables.isShow = false;
                      item3.dynamicVariables["isShow" + index] =
                        item1.actionValue == 1 ? false : true;
                    } else {
                      let dynamicVariables1 = {};
                      dynamicVariables1["isShow" + index] =
                        item1.actionValue == 1 ? false : true;
                      this.$set(item3, "dynamicVariables", dynamicVariables1);
                    }
                  }
                }
              }
              // 校验不显示字段时赋值为空
              if (item3.dynamicVariables) {
                let isShow = !Object.values(item3.dynamicVariables).every(
                  (item4) => item4 === false
                );
                if (!isShow) {
                  if (item3.__config__.tag === "el-checkbox-group") {
                    Vue.set(
                      item3.__config__,
                      "defaultValue",
                      this.verifyAsEmpty(item3.__config__.defaultValues)
                        ? item3.__config__.defaultValues
                        : []
                    );
                  } else {
                    Vue.set(
                      item3.__config__,
                      "defaultValue",
                      this.verifyAsEmpty(item3.__config__.defaultValues)
                        ? item3.__config__.defaultValues
                        : ""
                    );
                  }
                }
              }
            });
          });
        });
      });
    }
    // 输入框输入后触发logo生成
    if (config.tag === "el-input" && config.isAuto) {
      let radioValue = 0;
      let radioLabel = null;
      let uploadList = [];
      // 取输入框绑定的值
      this.formConfCopy.fields.forEach((item, index) => {
        if (item.__config__.moduleId === scheme.moduleIds) {
          if (item.__config__.children && item.__config__.children.length) {
            item.__config__.children.forEach((childrenItem, childIndex) => {
              // 获取单选框 并判断是否是logo生成 是则取值
              if (
                childrenItem.__config__.tag === "el-radio-group" &&
                childrenItem.__config__.isAuto
              ) {
                // if (!childrenItem.__config__.defaultValue) {
                //   this.$message.error('请输入商标名称后生成')
                //   return
                // }
                radioValue = childrenItem.__config__.defaultValue;
                radioLabel = childrenItem.__config__.defaultValueLabel;
              }
            });
          }
        }
      });
      // 取引用字段绑定的值
      this.formConfCopy.fields.forEach((item, index) => {
        if (item.__config__.moduleId === scheme.moduleIds) {
          if (item.__config__.children && item.__config__.children.length) {
            item.__config__.children.forEach((childrenItem, childIndex) => {
              if (
                radioValue == 2 ||
                (radioLabel && radioLabel.includes("自动生成"))
              ) {
                // 获取上传框 并判断是否是logo生成 是则赋值
                if (
                  childrenItem.__config__.tag === "el-upload" &&
                  childrenItem.__config__.isAuto && event.target.value
                ) {
                  let text = event.target.value;
                  sendTempUrlFromText(text.normalize("NFKC"))
                    .then((res) => {
                      if (res.code == 200 && res.msg) {
                        uploadList.push({
                          name: res.msg,
                          url: res.msg,
                        });
                        let value = JSON.stringify(uploadList);
                        let target =
                          this.formConfCopy.fields[index].__config__.children[
                            childIndex
                          ].__config__;
                        let propertyKey = "defaultValue";
                        this.$set(target, propertyKey, value);
                        this.$set(
                          this.formData,
                          childrenItem.moduleIds +
                            "-" +
                            childrenItem.__vModel__,
                          value
                        );
                      } else {
                        let target =
                          this.formConfCopy.fields[index].__config__.children[
                            childIndex
                          ].__config__;
                        this.$set(target, "defaultValue", "[]");
                        this.$set(
                          this.formData,
                          childrenItem.moduleIds +
                            "-" +
                            childrenItem.__vModel__,
                          "[]"
                        );
                      }
                    })
                    .catch((err) => {
                      let target =
                        this.formConfCopy.fields[index].__config__.children[
                          childIndex
                        ].__config__;
                      this.$set(target, "defaultValue", "[]");
                      this.$set(
                        this.formData,
                        childrenItem.moduleIds + "-" + childrenItem.__vModel__,
                        "[]"
                      );
                    });
                }
              }
            });
          }
        }
      });
    }
  });
}

// 表单input事件赋值
function setValue(event, config, scheme) {
  // 手动记录光标位置
  const inputElement = document.activeElement;
  let cursorPos = 0;
  if (inputElement.tagName === "INPUT" || inputElement.tagName === "TEXTAREA") {
    cursorPos = inputElement.selectionStart; // 获取光标位置
  }
  this.$nextTick(() => {
    //级联选择器限制数量

    this.$set(config, "defaultValue", event);
    this.$set(
      this[this.formConf.formModel],
      scheme.moduleIds + "-" + scheme.__vModel__,
      event
    );
    if (config.tag === "el-cascader") {
      if (event.length > scheme.limit) {
        const truncatedEvent = event.slice(0, scheme.limit);
        this.$message.error("选择数量超过限制数量" + scheme.limit);
        this.$set(config, "defaultValue", truncatedEvent);
        this.$set(
          this[this.formConf.formModel],
          scheme.moduleIds + "-" + scheme.__vModel__,
          truncatedEvent
        );
      }
    }
    if (scheme.__slot__?.options && scheme.__slot__?.options.length) {
      if (!Array.isArray(event)) {
        let items = scheme.__slot__.options.find(
          (item) => item.value === event
        );
        console.log(items, "itemsitems");
        if (items) {
          this.$set(config, "defaultValueLabel", items.label);
        } else {
          this.$set(config, "defaultValueLabel", "");
        }
      } else {
        if (event && event.length) {
          let list = [];
          event.forEach((item) => {
            let items = scheme.__slot__.options.find(
              (item1) => item1.value === item
            );
            if (items) {
              list.push(items.label);
            }
          });
          this.$set(config, "defaultValueLabel", list.join(","));
        } else {
          this.$set(config, "defaultValueLabel", "");
        }
      }
    }
    this.$nextTick(() => {
      // 再次获取更新后的输入框元素
      if (
        document.querySelector(`.${"i" + scheme.moduleIds + scheme.__vModel__}`)
      ) {
        const updatedInputElement =
          document.querySelector(
            `.${
              "i" + scheme.moduleIds + scheme.__vModel__
            } .el-input .el-input__inner`
          ) ||
          document.querySelector(
            `.${
              "i" + scheme.moduleIds + scheme.__vModel__
            } .el-textarea .el-textarea__inner`
          );
        if (updatedInputElement && inputElement === document.activeElement) {
          if (updatedInputElement.type === "number") {
          } else {
            // 设置新的光标位置
            const newPos = Math.min(
              cursorPos,
              updatedInputElement.value.length
            ); // 确保不越界
            updatedInputElement.focus();
            updatedInputElement.setSelectionRange(newPos, newPos);
          }
        }
      }
    });
    // 计算字段信息组合功能
    if (this.formConfCopy.fieldsForm?.length) {
      // 获取跟当前字段匹配的组合“条件”数据
      let fieldsForms = this.formConfCopy.fieldsForm.filter((item) => {
        return item.condition.some(
          (item1) =>
            item1.moduleId === scheme.moduleIds &&
            item1.__vModel__ === scheme.__vModel__
        );
      });
      console.log(fieldsForms, "fieldsFormfieldsForm");
      if (fieldsForms.length) {
        // 计算条件信息组合
        fieldsForms.forEach((item, index) => {
          let stringValue;
          item.condition.forEach((item1, index1) => {
            this.formConfCopy.fields.forEach((item2) => {
              item2.__config__.children.forEach((item3) => {
                if (
                  item1.moduleId == item3.moduleIds &&
                  item1.__vModel__ == item3.__vModel__
                ) {
                  if (index1 === 0) {
                    if (item.isFixed) {
                      stringValue = item3.__config__.defaultValue
                        ? item3.__config__.defaultValue
                        : "";
                      if (!stringValue) return false;
                      if (item.rulesType == 1) {
                        stringValue += item.fixedValue ? item.fixedValue : "";
                      } else if (item.rulesType == 2) {
                        stringValue =
                          Number(stringValue) + Number(item.fixedValue);
                      } else if (item.rulesType == 3) {
                        stringValue -= item.fixedValue;
                      } else if (item.rulesType == 4) {
                        stringValue = item.fixedValue * stringValue;
                      } else if (item.rulesType == 5) {
                        stringValue = stringValue / item.fixedValue;
                      } else if (item.rulesType == 6) {
                        let originalDate = new Date(stringValue);
                        originalDate.setDate(
                          originalDate.getDate() + parseInt(item.fixedValue)
                        );
                        console.log(originalDate, "originalDate");
                        const year = originalDate.getFullYear();
                        let month = (originalDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        let day = originalDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");

                        stringValue = `${year}-${month}-${day}`;
                      } else if (item.rulesType == 7) {
                        console.log(stringValue, "stringValue");

                        let originalDate = new Date(stringValue);
                        originalDate.setDate(
                          originalDate.getDate() - parseInt(item.fixedValue)
                        );
                        console.log(originalDate, "originalDate");
                        const year = originalDate.getFullYear();
                        let month = (originalDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        let day = originalDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");

                        stringValue = `${year}-${month}-${day}`;
                      }
                    } else {
                      stringValue = item3.__config__.defaultValue
                        ? item3.__config__.defaultValue
                        : item.rulesType == 1
                        ? ""
                        : item.rulesType == 2
                        ? 0
                        : NaN;
                    }
                  } else {
                    if (item.rulesType == 1) {
                      stringValue += item3.__config__.defaultValueLabel
                        ? item3.__config__.defaultValueLabel
                        : item3.__config__.defaultValue
                        ? item3.__config__.defaultValue.toString()
                        : "";
                    } else if (item.rulesType == 2) {
                      stringValue += item3.__config__.defaultValue;
                    } else if (item.rulesType == 3) {
                      stringValue -= item3.__config__.defaultValue;
                    } else if (item.rulesType == 4) {
                      stringValue = item3.__config__.defaultValue * stringValue;
                    } else if (item.rulesType == 5) {
                      stringValue = stringValue / item3.__config__.defaultValue;
                    }
                  }
                }
              });
            });
          });
          if (
            typeof stringValue == "string"
              ? !stringValue.includes("undefined")
              : !isNaN(stringValue)
          ) {
            // 赋值给条件下的执行字段
            item.activeData.forEach((item1, index1) => {
              if (
                item1.moduleId == scheme.moduleIds &&
                item1.__vModel__ == scheme.__vModel__
              ) {
              } else {
                this.formConfCopy.fields.forEach((item2) => {
                  item2.__config__.children.forEach((item3) => {
                    if (
                      item1.moduleId == item3.moduleIds &&
                      item1.__vModel__ == item3.__vModel__
                    ) {
                      this.$set(item3.__config__, "defaultValue", stringValue);
                      this.$set(
                        this[this.formConf.formModel],
                        item3.moduleIds + "-" + item3.__vModel__,
                        stringValue
                      );
                    }
                  });
                });
              }
            });
          }
        });
      }
    }
    if (this.formConfCopy.rulesForm?.length && config.tag !== "el-input") {
      // 关联字段校验逻辑处理
      this.formConfCopy.rulesForm.forEach((item, index) => {
        let isTrue = false;
        let dynamicVariables = {};
        item.condition.forEach((item1, index1) => {
          dynamicVariables["index" + index1] = false;
          this.formConfCopy.fields.forEach((item2) => {
            item2.__config__.children.forEach((item3) => {
              if (
                item1.moduleId == item3.moduleIds &&
                item1.__vModel__ == item3.__vModel__
              ) {
                if (Array.isArray(item3.__config__.defaultValue)) {
                  dynamicVariables["index" + index1] =
                    !item3.__config__.defaultValue.some((element) =>
                      item1.conValue.includes(element)
                    );
                } else {
                  if (!Array.isArray(item1.conValue)) {
                    item1.conValue = item1.conValue?.split(",");
                  }
                  if (
                    item1.conValue.indexOf(item3.__config__.defaultValue) == -1
                  ) {
                    dynamicVariables["index" + index1] = true;
                  } else {
                    dynamicVariables["index" + index1] = false;
                  }
                }
              }
            });
          });
        });
        isTrue = !Object.values(dynamicVariables).every(
          (item) => item === false
        );
        item.activeData.forEach((item1) => {
          this.formConfCopy.fields.forEach((item2) => {
            item2.__config__.children.forEach((item3) => {
              if (
                item1.moduleId == item3.moduleIds &&
                item1.__vModel__ == item3.__vModel__
              ) {
                if (!isTrue) {
                  if (item1.actionValue == 2) {
                    if (!item3.__config__.isValue) {
                      this.$set(
                        item3.__config__,
                        "defaultValue",
                        item1.conValue
                      );
                      this.$set(
                        this[this.formConf.formModel],
                        item3.moduleIds + "-" + item3.__vModel__,
                        item1.conValue
                      );
                      this.$set(item3.__config__, "isValue", true);
                    }
                  } else {
                    if (item3.dynamicVariables) {
                      item3.dynamicVariables.isShow = false;
                      item3.dynamicVariables["isShow" + index] =
                        item1.actionValue == 1 ? true : false;
                    } else {
                      let dynamicVariables1 = {};
                      dynamicVariables1["isShow" + index] =
                        item1.actionValue == 1 ? true : false;
                      this.$set(item3, "dynamicVariables", dynamicVariables1);
                    }
                  }
                } else {
                  if (item1.actionValue == 2) {
                    if (item3.__config__.tag === "el-checkbox-group") {
                      this.$set(item3.__config__, "defaultValue", []);
                      this.$set(
                        this[this.formConf.formModel],
                        item3.moduleIds + "-" + item3.__vModel__,
                        []
                      );
                    } else {
                      this.$set(item3.__config__, "defaultValue", "");
                      this.$set(
                        this[this.formConf.formModel],
                        item3.moduleIds + "-" + item3.__vModel__,
                        ""
                      );
                    }
                    this.$set(item3.__config__, "isValue", false);
                  } else {
                    if (item3.dynamicVariables) {
                      item3.dynamicVariables.isShow = false;
                      item3.dynamicVariables["isShow" + index] =
                        item1.actionValue == 1 ? false : true;
                    } else {
                      let dynamicVariables1 = {};
                      dynamicVariables1["isShow" + index] =
                        item1.actionValue == 1 ? false : true;
                      this.$set(item3, "dynamicVariables", dynamicVariables1);
                    }
                  }
                }
              }
              // 校验不显示字段时赋值为空
              if (item3.dynamicVariables) {
                let isShow = !Object.values(item3.dynamicVariables).every(
                  (item4) => item4 === false
                );
                if (!isShow) {
                  if (item3.__config__.tag === "el-checkbox-group") {
                    Vue.set(
                      item3.__config__,
                      "defaultValue",
                      this.verifyAsEmpty(item3.__config__.defaultValues)
                        ? item3.__config__.defaultValues
                        : []
                    );
                  } else {
                    Vue.set(
                      item3.__config__,
                      "defaultValue",
                      this.verifyAsEmpty(item3.__config__.defaultValues)
                        ? item3.__config__.defaultValues
                        : ""
                    );
                  }
                }
              }
            });
          });
        });
      });
    }
    // 单选框并且是自动生成logo组件
    if (config.tag === "el-radio-group" && config.isAuto) {
      // 切换初始化赋值为空
      this.formConfCopy.fields.forEach((item, index) => {
        if (item.__config__.moduleId === scheme.moduleIds) {
          if (item.__config__.children && item.__config__.children.length) {
            item.__config__.children.forEach((childrenItem, childIndex) => {
              if (
                childrenItem.__config__.tag === "el-upload" &&
                childrenItem.__config__.isAuto
              ) {
                let target =
                  this.formConfCopy.fields[index].__config__.children[
                    childIndex
                  ].__config__;
                let propertyKey = "defaultValue";
                this.$set(target, propertyKey, "[]");
                this.$set(
                  this.formData,
                  childrenItem.moduleIds + "-" + childrenItem.__vModel__,
                  "[]"
                );
              }
            });
          }
        }
      });
      // 1:自主上传  2：自动生成
      if (event == 1) {
      } else {
        // 自动生成 获取绑定的上传框 赋值 接口返回的图片地址
        let uploadList = []; //上传图片数组
        let textContent = ""; //logo生成绑定的输入框值
        // 取输入框绑定的值
        this.formConfCopy.fields.forEach((item, index) => {
          if (item.__config__.moduleId === scheme.moduleIds) {
            if (item.__config__.children && item.__config__.children.length) {
              item.__config__.children.forEach((childrenItem, childIndex) => {
                // 获取输入框 并判断是否是logo生成 是则取值
                if (
                  childrenItem.__config__.tag === "el-input" &&
                  childrenItem.__config__.isAuto
                ) {
                  if (!childrenItem.__config__.defaultValue) {
                    this.$message.error("请输入商标名称后生成");
                    return;
                  }
                  textContent = childrenItem.__config__.defaultValue;
                }
              });
            }
          }
        });
        // 取引用字段绑定的值
        this.formConfCopy.fields.forEach((item, index) => {
          if (item.__config__.moduleId === scheme.moduleIds) {
            if (item.__config__.children && item.__config__.children.length) {
              item.__config__.children.forEach((childrenItem, childIndex) => {
                if (textContent) {
                  // 获取上传框 并判断是否是logo生成 是则赋值
                  if (
                    childrenItem.__config__.tag === "el-upload" &&
                    childrenItem.__config__.isAuto
                  ) {
                    sendTempUrlFromText(textContent.normalize("NFKC")).then((res) => {
                      if (res.code == 200 && res.msg) {
                        uploadList.push({
                          name: res.msg,
                          url: res.msg,
                        });
                        let value = JSON.stringify(uploadList);
                        let target =
                          this.formConfCopy.fields[index].__config__.children[
                            childIndex
                          ].__config__;
                        let propertyKey = "defaultValue";
                        this.$set(target, propertyKey, value);
                        this.$set(
                          this.formData,
                          childrenItem.moduleIds +
                            "-" +
                            childrenItem.__vModel__,
                          value
                        );
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    }
  });
}

function buildListeners(scheme) {
  const config = scheme.__config__;
  const methods = this.formConf?.__methods__ || {};
  const listeners = {};

  // 给__methods__中的方法绑定this和event
  Object.keys(methods).forEach((key) => {
    listeners[key] = (event) => methods[key].call(this, event);
  });

  // 响应 render.js 中的 vModel $emit('input', val)
  listeners.input = (event) => setValue.call(this, event, config, scheme);
  listeners.blur = (event) => setValues.call(this, event, config, scheme);
  // 上传表单元素组件的成功、移除和预览事件
  if (config.tag === "el-upload") {
    listeners.upload = (response, file, fileList) =>
      setUpload.call(this, config, scheme, response, file, fileList);

    listeners.deleteUpload = (file, fileList) =>
      deleteUpload.call(this, config, scheme, file, fileList);

    listeners.previewUpload = (file, fileList) =>
      previewUpload.call(this, config, scheme, file, fileList);
  }
  if (config.tag === "el-button") {
    scheme.disabled = false;
    listeners.click = (event) => downClick.call(this, event, config, scheme);
  }
  return listeners;
}

async function downClick(event, config, scheme) {
  console.log(event, config, scheme, "77777777777");
  if (config.tag === "el-button") {
    const recordId = parseInt(this.recordId);
    let param = {};
    scheme.downParameter?.split("&").forEach((item) => {
      param[item] = recordId;
    });
    console.log(param, "paramparam");
    let reqParam = {
      url: scheme.downAction,
      method: scheme.downMethod,
    };
    if (scheme.downMethod == "post") {
      reqParam.data = param;
    }
    if (scheme.downMethod == "get") {
      reqParam.params = param;
    }
    console.log(reqParam);
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    try {
      let response = await request(reqParam);
      console.log(response,'responseresponseresponse');
      if(response.data && response.data.length > 0) {
        let fileList = response.data
        for (let item of fileList) {
          if(item.fileUrl.includes('.pdf')) {
            let imgres = await this.pdfToPngs(item.fileUrl);
            item.imgUrl = imgres.data
          } else if (item.fileUrl.includes('.png') || item.fileUrl.includes('.jpg') || item.fileUrl.includes('.jpeg')) {
            item.imgUrl = item.fileUrl
          }
        }
        this.$emit('showFile',fileList,scheme.moduleids,scheme.__vmodel__,false)
      }
    } catch (error) {
      console.error("Request failed:", error);
    } finally {
      loading.close()
    }
  }
}

//获取上传表单元素组件 上传的文件
function setUpload(config, scheme, response, file, fileList) {
  if (response.code == -1) {
    this.$modal.msgError(response.msg);
    return false;
  }

  if (config.isIdentify) {
    for (let idfIndex = 0; idfIndex < config.identifyList.length; idfIndex++) {
      if (config.identifyList[idfIndex].__vModel__) {
        let findex = null;
        let cindex = null;

        for (const [index, item] of this.formConfCopy.fields.entries()) {
          if (
            item.__config__.moduleId === config.identifyList[idfIndex].moduleId
          ) {
            findex = index;

            for (const [ci, child] of item.__config__.children.entries()) {
              if (
                child.__vModel__ === config.identifyList[idfIndex].__vModel__
              ) {
                cindex = ci;
                break; // 找到索引后跳出循环
              }
            }

            if (cindex !== null) {
              break; // 找到索引后跳出循环
            }
          }
        }

        if (findex !== null && cindex !== null) {
          const targetConfig =
            this.formConfCopy.fields[findex].__config__.children[cindex]
              .__config__;

          if (
            config.identifyList[idfIndex].type &&
            config.identifyList[idfIndex].type == "time"
          ) {
            console.log(response.data.orcData);
            console.log(config.identifyList);
            this.$set(
              targetConfig,
              "defaultValue",
              response.data.orcData[config.identifyList[idfIndex].key]?.replace(
                /^(\d{4})(\d{2})(\d{2})$/,
                "$1-$2-$3"
              )
            );
            this.$set(
              this[this.formConf.formModel],
              config.identifyList[idfIndex].moduleIds +
                "-" +
                config.identifyList[idfIndex].__vModel__,
              response.data.orcData[config.identifyList[idfIndex].key]?.replace(
                /^(\d{4})(\d{2})(\d{2})$/,
                "$1-$2-$3"
              )
            );
          } else {
            console.log(
              response.data.orcData[config.identifyList[idfIndex].key]
            );
            this.$set(
              targetConfig,
              "defaultValue",
              response.data.orcData[config.identifyList[idfIndex].key]
            );
            this.$set(
              this[this.formConf.formModel],
              config.identifyList[idfIndex].moduleIds +
                "-" +
                config.identifyList[idfIndex].__vModel__,
              response.data.orcData[config.identifyList[idfIndex].key]
            );
          }
        }
      }
    }

    this.formData = JSON.parse(JSON.stringify(this.formData));
  }
  let oldValue = "";
  try {
    oldValue = JSON.parse(
      this[this.formConf.formModel][scheme.moduleIds + "-" + scheme.__vModel__]
    );
  } catch (err) {
    console.warn(err);
  }
  if (Array.isArray(oldValue) && oldValue.length) {
    let fileObj = {
      name: response.data.oldName ? response.data.oldName : response.data.name,
      url: response.data.url,
    };
    oldValue.push(fileObj);
  } else {
    oldValue = [
      {
        name: response.data.oldName
          ? response.data.oldName
          : response.data.name,
        url: response.data.url,
      },
    ];
  }
  console.log(oldValue, "oldValue2222");
  this.$set(config, "defaultValue", JSON.stringify(oldValue));
  this.$set(
    this[this.formConf.formModel],
    scheme.moduleIds + "-" + scheme.__vModel__,
    JSON.stringify(oldValue)
  );
  console.log("config", config);
}

//获取上传表单元素组件 删除文件后的文件列表
function deleteUpload(config, scheme, file, fileList) {
  let oldValue = this[this.formConf.formModel][
    scheme.moduleIds + "-" + scheme.__vModel__
  ]
    ? JSON.parse(
        this[this.formConf.formModel][
          scheme.moduleIds + "-" + scheme.__vModel__
        ]
      )
    : null;
  //file 删除的文件
  //过滤掉删除的文件
  let newValue = oldValue?.filter((item) => item.url !== file.url);
  console.log("删除newValue", newValue);
  if (newValue?.length == 0) {
    console.log("是空數組");
    this.$set(config, "defaultValue", "");
    this.$set(
      this[this.formConf.formModel],
      scheme.moduleIds + "-" + scheme.__vModel__,
      ""
    );
  } else {
    console.log("不是空數組");
    this.$set(config, "defaultValue", JSON.stringify(newValue));
    this.$set(
      this[this.formConf.formModel],
      scheme.moduleIds + "-" + scheme.__vModel__,
      JSON.stringify(newValue)
    );
  }
}

//预览文件
async function previewUpload(config, scheme, file, fileList) {
  console.log(file,'file');
  try {
    if(file && file.url){
      if(file.url.includes('.pdf') || file.url.includes('.png') || file.url.includes('.jpg') || file.url.includes('.jpeg')) {
        let showImgUrl = null
        let url = null
        if(file.url.includes('.pdf')) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          let imgres = await this.pdfToPngs(file.url);
          showImgUrl = imgres.data
          url= file.url
          loading.close();
        } else {
          showImgUrl = file.url
          url = file.url
        }
        if(showImgUrl) {
          let showImgPro = {
            url: url,
            imgUrl: showImgUrl,
            name: showImgUrl.split('/').pop()
          }
          this.$emit('openImg', showImgPro)
        }
      } else {
        window.open(file.url, "_blank");
      }
    }
  } catch (err) {
    
  }
}

export default {
  components: {
    render,
  },
  props: {
    formConf: {
      type: Object,
      required: true,
    },
    recordStatus: {
      type: Number,
      required: false,
    },
    originalEditable: {
      type: Array,
      required: false,
    },
    recordId: {
      type: String,
      required: false,
    },
    areaGroupId: {
      type: Number,
      required: false,
    },
  },
  data() {
    const data = {
      trIndex: null,
      identifyType: null,
      reflection: null,
      trademarkData: null,
      tradeselfData: null,
      formConfCopy: deepClone(this.formConf),
      [this.formConf.formModel]: {},
      [this.formConf.formRules]: {},
      formDatas: {},
      pdfUrl: "",
      moduleIndex: null,

      quoteModuleId: null, // 当前需要复制功能的模块

      kycZipList:[],
    };
    if (data.formConfCopy.fields) {
      this.initFormData(
        data.formConfCopy.fields,
        data[this.formConf.formModel]
      );
      this.buildRules(data.formConfCopy.fields, data[this.formConf.formRules]);
    }
    return data;
  },
  created() {
    document.addEventListener("click", this.handleClick);
    for (let key in this[this.formConf.formModel]) {
      // this.$set(this[this.formConf.formModel], key, null);
    }
    console.log(
      this[this.formConf.formRules],
      "[this.formConf.formRules]",
      this[this.formConf.formModel],
      this.formConfCopy
    );
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClick);
    this.$off();
  },
  methods: {
    //可以在表单里面塞值
    fillOutsideData(moduleIds, __vModel__, value) {
      this.$set(this.formData, moduleIds + "-" + __vModel__, value);
      for (let [oindex, obj] of Object.entries(this.formConfCopy.fields)) {
        if (obj.__config__ && obj.__config__.moduleId === moduleIds) {
          let odx = oindex;
          if (obj.__config__.children) {
            for (let [cindex, child] of Object.entries(
              obj.__config__.children
            )) {
              if (child.__vModel__ === __vModel__) {
                let cdx = cindex;

                let target =
                  this.formConfCopy.fields[odx].__config__.children[cdx]
                    .__config__;
                let propertyKey = "defaultValue";
                this[this.formConf.formModel][moduleIds + "-" + __vModel__] =
                  value;

                this.$set(target, propertyKey, value);
              }
            }
          }
        }
      }
      console.log(this.formData, "formData");
    },
    fillOutsideDataByName(type, value, vModel) {
      // this.$set(this.formData, moduleIds + "-" + __vModel__, value);
      for (let [oindex, obj] of Object.entries(this.formConfCopy.fields)) {
        if (obj.__config__) {
          let odx = oindex;
          if (obj.__config__.children) {
            for (let [cindex, child] of Object.entries(
              obj.__config__.children
            )) {
              let shouldUpdate = false;
              // 判断更新条件
              if (type === 'upload3' && child.__config__.tagIcon === type) {
                shouldUpdate = true;
              } else if (child.__vModel__ === vModel && obj.__config__.moduleId === type) {
                shouldUpdate = true;
              }

              // 如果满足条件，更新值
              if (shouldUpdate) {
                let cdx = cindex;
                let target = this.formConfCopy.fields[odx].__config__.children[cdx].__config__;
                let propertyKey = "defaultValue";

                // 设置表单模型的值
                this[this.formConf.formModel][obj.__config__.moduleId + "-" + child.__vModel__] = value;

                // 使用 $set 更新 target 对象
                this.$set(target, propertyKey, value);
              }

            }
          }
        }
      }
    },
    // 其他选项输入框输入
    otherInput(e, scheme) {
      this.$set(scheme.__config__, "otherInput", e);
      this.$forceUpdate();
      console.log(e, scheme, "schemescheme");
    },
    getValues(row) {
      if (row.__config__.defaultValue) {
        let tableValue = "";
        row.__config__.defaultValue.split(";").forEach((item, index) => {
          tableValue += `<tr><td>${index + 1}</td><td>${item}</td></tr>`;
        });
        return tableValue;
      }
    },
    getValue(row) {
      const config = row.__config__;
      if (config.tag === "el-checkbox-group") {
        const valueList = config.defaultValue;
        const selectedOptions = row.__slot__.options.filter((item) =>
          valueList.includes(item.value)
        );
        const labels = selectedOptions.map((item) => item.label);
        return labels.join(", ");
      } else if (config.tag === "el-upload") {
        if (Array.isArray(config.defaultValue)) {
          const fileList = config.defaultValue.map((item) => item.url);
          return fileList;
        } else {
          const fileList = JSON.parse(config.defaultValue || "[]").map(
            (item) => item.url
          );
          return fileList;
        }
      } else if (
        config.tag === "el-select" ||
        config.tag === "el-radio-group"
      ) {
        if (config.tag === "el-radio-group" && config.defaultValue === -1) {
          return config.otherInput ? config.otherInput : "--";
        } else {
          // console.log(config.defaultValueLabel,'config.defaultValueLabel');
          return config.defaultValueLabel
            ? config.defaultValueLabel
            : config.defaultValue === null ||
              config.defaultValue === undefined ||
              config.defaultValue === ""
            ? "--"
            : config.defaultValue;
        }
      } else if (config.tag === "el-cascader") {
        return typeof config.defaultValue == "array" &&
          config.defaultValue.length
          ? config.defaultValue.join(", ")
          : config.defaultValue;
      } else {
        return config.defaultValue == null ||
          config.defaultValue == undefined ||
          config.defaultValue == ""
          ? "--"
          : config.defaultValue;
      }
    },
    // 更新整个模块权限
    updateEditModule(scheme) {
      if (scheme.__config__.children) {
        let list1 = [];
        scheme.__config__.children.forEach((item1) => {
          list1.push(!item1.disabled);
        });
        console.log(list1, "list1list1");
        let editModule = list1.some((value) => value === true);
        this.$set(scheme.__config__, "editModule", editModule);
      }
      this.$forceUpdate();
    },
    // 引用同项目模块数据
    quoteModules(scheme) {
      console.log(scheme, "11111111");
      this.quoteModuleId = scheme.__config__.moduleId;
      this.$emit("changeQuote", scheme.__config__.moduleIdList);
    },
    copyModules(moduleId) {
      let copyModule = this.formConfCopy.fields.find(
        (item) => item.__config__.moduleId === moduleId
      );
      console.log(copyModule, "copyModule");
      if (copyModule) {
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.moduleId === this.quoteModuleId) {
            if (item.__config__.allowMultiple) {
              this.$set(item.__config__, "defaultValue", []);
              copyModule.__config__.defaultValue?.forEach((item1) => {
                let childFilds = { children: [] };
                item.__config__.children.forEach((item2) => {
                  let correspondingItem = item1.children.find(
                    (item3) =>
                      item3.__vModel__ === item2.__vModel__ &&
                      item3.__config__.tag === item2.__config__.tag
                  );
                  let param = {
                    disabled: item2.disabled,
                    isShow: item2.isShow,
                    moduleIds: item2.moduleIds,
                    __vModel__: item2.__vModel__,
                    __config__: {
                      beforeValue: correspondingItem
                        ? correspondingItem.__config__.defaultValue
                        : item2.__config__.beforeValue,
                      defaultValue: correspondingItem
                        ? correspondingItem.__config__.defaultValue
                        : item2.__config__.defaultValue,
                      label: item2.__config__.label,
                      required: item2.__config__.required,
                      tag: item2.__config__.tag,
                    },
                    __slot__: item2.__slot__,
                  };
                  if (
                    item2?.__config__.defaultValueLabel ||
                    correspondingItem?.__config__.defaultValueLabel
                  ) {
                    param.__config__.defaultValueLabel =
                      item2.__config__.defaultValueLabel ||
                      correspondingItem.__config__.defaultValueLabel;
                  }
                  childFilds.children.push(param);
                });
                if (!item.__config__.defaultValue) {
                  item.__config__.defaultValue = [childFilds];
                } else {
                  item.__config__.defaultValue.push(childFilds);
                }
              });
            } else {
              item.__config__.children.forEach((item1) => {
                let correspondingItem = copyModule.__config__.children.find(
                  (item2) =>
                    item1.__vModel__ === item2.__vModel__ &&
                    item1.__config__.tag === item2.__config__.tag
                );
                if (correspondingItem) {
                  this.$set(
                    item1.__config__,
                    "defaultValue",
                    correspondingItem.__config__.defaultValue
                  );
                  this.$set(
                    this[this.formConf.formModel],
                    item1.moduleIds + "-" + item1.__vModel__,
                    correspondingItem.__config__.defaultValue
                  );
                  if (correspondingItem.__config__.defaultValueLabel) {
                    this.$set(
                      item1.__config__,
                      "defaultValueLabel",
                      correspondingItem.__config__.defaultValueLabel
                    );
                  }
                }
              });
            }
          }
        });
      }
      this.$forceUpdate();
    },
    // 修改模块数据
    updateModule(scheme, i) {
      this.moduleIndex = i;
      let children = JSON.parse(
        JSON.stringify(scheme.__config__.defaultValue[i].children)
      );
      children.forEach((item) => {
        scheme.__config__.children.forEach((item1) => {
          if (item.__vModel__ == item1.__vModel__) {
            this.$set(
              item1.__config__,
              "defaultValue",
              item.__config__.defaultValue
            );
            if (item1.dynamicVariables) {
              Object.keys(item1.dynamicVariables).forEach((key) => {
                this.$set(item1.dynamicVariables, key, item.isShow);
              });
            }
            this.$set(
              this[this.formConf.formModel],
              item.moduleIds + "-" + item.__vModel__,
              item.__config__.defaultValue
            );
          }
        });
      });
      this.$set(scheme.__config__, "moduleShow", true);
      this.$forceUpdate();
    },
    // 删除模块数据
    delModule(scheme, i) {
      scheme.__config__.defaultValue.splice(i, 1);
      this.$forceUpdate();
    },
    cleanModule(scheme) {
      scheme.__config__.children.forEach((item) => {
        let config = item.__config__;
        if (item.__config__.tag == "el-checkbox-group") {
          this.$set(config, "defaultValue", []);
          this.$set(
            this[this.formConf.formModel],
            item.moduleIds + "-" + item.__vModel__,
            []
          );
        } else {
          this.$set(config, "defaultValue", "");
          this.$set(
            this[this.formConf.formModel],
            item.moduleIds + "-" + item.__vModel__,
            ""
          );
        }
      });
      this.$set(scheme.__config__, "moduleShow", false);
      this.moduleIndex = null;
      this.$forceUpdate();
    },
    // 添加多条模块数据
    async addModules(scheme) {
      console.log("scheme", scheme);
      await this.updateEditModule(scheme); // 更新整个模块权限
      const isAnyChildVisible = scheme.__config__.children.some(
        (child) => child.isShow
      );
      if (!isAnyChildVisible) {
        this.$message.error("当前情形无需填写模块信息");
        return false;
      } else {
        this.moduleIndex = null;
        this.$set(scheme.__config__, "moduleShow", true);
        scheme.__config__.children.forEach((item) => {
          if (item.__config__.beforeValue) {
            this.$set(
              item.__config__,
              "defaultValue",
              item.__config__.beforeValue
            );
            this.$set(
              this[this.formConf.formModel],
              item.moduleIds + "-" + item.__vModel__,
              item.__config__.beforeValue
            );
          }
        });
      }
    },
    async addModule(scheme) {
      let isShow = scheme.__config__.children.some(
        (item1) => item1.isShow === true
      );
      if (!isShow) {
        return;
      }
      let rulesList = Object.keys(this[this.formConf.formRules]);
      scheme.__config__.children.forEach((item1) => {
        if (
          (!item1.isShow &&
            rulesList.indexOf(item1.moduleIds + "-" + item1.__vModel__) !=
              -1) ||
          item1.__config__.isAuto
        ) {
          delete this[this.formConf.formRules][
            item1.moduleIds + "-" + item1.__vModel__
          ];
        }
      });
      let isRules = true;
      scheme.__config__.children.forEach((item) => {
        this.$refs.elForm.validateField(
          item.moduleIds + "-" + item.__vModel__,
          (error) => {
            if (error) {
              isRules = false;
            }
          }
        );
      });
      if (!isRules) {
        return;
      }

      let list = [];
      await Promise.all(
        scheme.__config__.children.map(async (item) => {
          let param = {
            disabled: item.disabled,
            isShow: item.isShow,
            moduleIds: item.moduleIds,
            __vModel__: item.__vModel__,
            __config__: {
              beforeValue: item.__config__.beforeValue,
              // defaultValue:item.__config__.defaultValue,
              label: item.__config__.label,
              required: item.__config__.required,
              tag: item.__config__.tag,
              span: item.__config__.span,
            },
            __slot__: item.__slot__,
          };
          if (
            item.__slot__ &&
            item.__slot__.append &&
            item.__config__.isAuto &&
            !item.__config__.defaultValue
          ) {
            try {
              await this.handleClick({
                target: { dataset: { id: "667", ...item } },
              });
            } catch (error) {
              console.error(error);
            }
          }
          param.__config__.defaultValue = item.__config__.defaultValue;

          // 给选项类型输入框赋值"defaultValueLabel"
          if (item.__slot__?.options && item.__slot__?.options.length) {
            if (!Array.isArray(item.__config__.defaultValue)) {
              let items = item.__slot__.options.find(
                (item1) => item1.value === item.__config__.defaultValue
              );
              console.log(items, "itemsitems");
              if (items) {
                this.$set(item.__config__, "defaultValueLabel", items.label);
              } else {
                this.$set(item.__config__, "defaultValueLabel", "");
              }
            } else {
              if (
                item.__config__.defaultValue &&
                item.__config__.defaultValue.length
              ) {
                let list = [];
                item.__config__.defaultValue.forEach((item1) => {
                  let items = item.__slot__.options.find(
                    (item2) => item2.value === item1
                  );
                  if (items) {
                    list.push(items.label);
                  }
                });
                this.$set(item.__config__, "defaultValueLabel", list.join(","));
              } else {
                this.$set(item.__config__, "defaultValueLabel", "");
              }
            }
          }

          if (item.__config__.defaultValueLabel) {
            param.__config__.defaultValueLabel =
              item.__config__.defaultValueLabel;
          }
          if (item.__config__.identifyType) {
            param.__config__.identifyType = item.__config__.identifyType;
          }
          if (item.__config__.otherInput) {
            param.__config__.otherInput = item.__config__.otherInput;
          }
          list.push(param);
        })
      );
      let childFilds = {
        children: list,
      };
      if (this.moduleIndex == null) {
        if (!scheme.__config__.defaultValue) {
          scheme.__config__.defaultValue = [childFilds];
        } else {
          scheme.__config__.defaultValue.push(childFilds);
        }
      } else {
        scheme.__config__.defaultValue[this.moduleIndex] = childFilds;
      }
      this.$forceUpdate();
      scheme.__config__.children.forEach((item) => {
        let config = item.__config__;
        if (item.__config__.tag == "el-checkbox-group") {
          this.$set(config, "defaultValue", []);
          this.$set(
            this[this.formConf.formModel],
            item.moduleIds + "-" + item.__vModel__,
            []
          );
        } else {
          this.$set(config, "defaultValue", "");
          this.$set(
            this[this.formConf.formModel],
            item.moduleIds + "-" + item.__vModel__,
            ""
          );
        }
        this.$set(config, "defaultValueLabel", "");
      });
      this.$set(scheme.__config__, "moduleShow", false);
      this.moduleIndex = null;
      console.log(scheme, "schemescheme11111");
    },
    handleClicks(event) {
      event.preventDefault();
      this.$emit("handleClicks", event);
    },
    async pdfToPngs(url) {
      return await pdfToPng({ contractFileUrl: url });
    },
    async handleClick(event) {
      // console.log(event);
      let fillData = event.target.dataset;
      if (fillData.id == "772") {
        if (fillData.sign) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          try {
            let res
            if(event.target.innerText.includes('kyc') || event.target.innerText.includes('KYC')) {
              res = await getHKCompanyKycZipDirect({recordId:this.$route.query.recordId})
            }
            if(event.target.innerText.includes('委托书') && fillData.cid) {
              let data = {};
              data.isPreview = true;
              data.pdfFile = true;
              data.recordId = this.$route.query.recordId;
              data.id = fillData.cid;
              data.formContent = JSON.stringify(this.formConfCopy);
              res = await getToContractSign(data)
            }
            if (res.code == 200) {
              this.kycZipList = res.data
              for (let item of this.kycZipList) {
                if(item.fileUrl.includes('.pdf')) {
                  let imgres = await pdfToPng({ contractFileUrl: item.fileUrl });
                  item.imgUrl = imgres.data
                } else if (item.fileUrl.includes('.png') || item.fileUrl.includes('.jpg') || item.fileUrl.includes('.jpeg')) {
                  item.imgUrl = item.fileUrl
                }
              }
              this.$emit('showFile',this.kycZipList,fillData.moduleids,fillData.__vmodel__,true)
              console.log(this.kycZipList,'this.kycZipList');
            }
          } catch (error) {
              
          } finally {
            loading.close()
          }
          return false
        }

        let filename = event.target.innerText.slice(2) + ".zip";
        let data = {};
        this.$confirm(
          "如需修改模板样式，请下载原始模板文件；修改模板信息不会同步至系统，请勿修改；",
          "提示",
          {
            confirmButtonText: "原始模板文件",
            cancelButtonText: "pdf版本",
            distinguishCancelAndClose: true,
          }
        )
          .then(() => {
            data.isPreview = false;
            data.recordId = this.$route.query.recordId;
            data.id = fillData.cid || "";
            data.formContent = JSON.stringify(this.formConfCopy);
            previewTemplateFile(data).then((response) => {
              const contentType = response.headers["content-type"];
              const disposition = response.headers["content-disposition"];
              const fileName = decodeURIComponent(
                disposition.split("filename=")[1].split(";")[0]
              );
              const binaryData = [];
              binaryData.push(response.data);
              const blob = new Blob(binaryData, {
                type: contentType,
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            });
          })
          .catch((action) => {
            console.log(action);
            if (action == "cancel") {
              data.isPreview = true;
              data.id = fillData.cid || "";
              data.formContent = JSON.stringify(this.formConfCopy);
              data.recordId = this.$route.query.recordId;
              previewTemplateFile(data).then((response) => {
                const contentType = response.headers["content-type"];
                const binaryData = [];
                binaryData.push(response.data);
                this.pdfUrl = window.URL.createObjectURL(
                  new Blob(binaryData, { type: contentType })
                );
                window.open(this.pdfUrl);
              });
            }
          });
      }
      //如果是数据填充功能
      if (fillData.id == "666") {

        let findObj = this.findObjects(
          this.formConfCopy.fields,
          fillData.moduleids,
          fillData.__vmodel__
        );
        console.log("findObj", findObj);
        console.log("formConfCopy", this.formConfCopy);
        console.log("fillData", fillData);
        this.reflection = findObj.identifyList;
        this.identifyType = findObj.identifyType;
        let searchData = {};

        let searchList = this.reflection?.filter(
          (obj) => obj.searchType == true
        );
        let that = this;
        searchList?.forEach((row) => {
          let rowItem = that.findObjects(
            that.formConfCopy.fields,
            row.moduleId,
            row.__vModel__
          );
          if (
            rowItem &&
            (typeof rowItem.defaultValue == "array"
              ? rowItem.defaultValue?.length
              : this.verifyAsEmpty(rowItem.defaultValue))
          ) {
            searchData[row.key] = rowItem.defaultValue;
          }
        });
        console.log(searchData, searchList, "searchDatasearchData");
        if (
          !Object.keys(searchData).length &&
          this.identifyType &&
          this.identifyType.includes("TRADEMARK")
        ) {
          this.$modal.msgError("请按顺序填写字段数据！");
          return false;
        }
        let echoData = findObj.defaultValue;
        // 根据禁用属性 控制弹窗是否打开
        let flag = true;
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.moduleId === fillData.moduleids) {
            if (item.__config__.children && item.__config__.children.length) {
              item.__config__.children.forEach((childrenItem) => {
                if (
                  fillData.__vmodel__ &&
                  fillData.__vmodel__ == childrenItem.__vModel__
                ) {
                  console.log("childrenItem", childrenItem);
                  flag = childrenItem.disabled;
                }
              });
            }
          }
        });
        console.log("flag", flag);
        // let res = await getVModelData({type:this.identifyType})
        if (!flag) {
          this.$emit(
            "openDataDialog",
            this.identifyType,
            this.reflection,
            searchData,
            echoData
          );
        }
      }
      // 自动编号
      if (fillData.id == "667") {
        
        let submitList = [];
        let type = "";
        if (!fillData.moduleids || !fillData.__vmodel__) {
          fillData.moduleids = fillData.moduleIds;
          fillData.__vmodel__ = fillData.__vModel__;
        }
        let findObj = this.findObjects(
          this.formConfCopy.fields,
          fillData.moduleids,
          fillData.__vmodel__
        );
        console.log("667----findObj", findObj);
        // 取引用字段绑定的值
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.moduleId === fillData.moduleids) {
            if (item.__config__.children && item.__config__.children.length) {
              item.__config__.children.forEach((childrenItem) => {
                findObj.autoList?.forEach((findItem) => {
                  if (
                    findItem.__vmodel__ &&
                    findItem.__vmodel__ == childrenItem.__vModel__
                  ) {
                    findItem.message = childrenItem.__config__.defaultValue;
                  }
                });
              });
            }
          }
        });
        // 封装提交数组
        findObj.autoList?.forEach((item) => {
          if (item.areaGroupId) {
            type = item.areaGroupId;
          } else {
            type = this.areaGroupId;
          }
          if (item.label == "编号") {
            submitList.push({ type: "编号", value: "" });
          } else if (item.label == "创建时间") {
            submitList.push({ type: "时间", value: "yyyyMMdd" });
          } else {
            submitList.push({ type: "字段", value: item.message });
          }
        });
        let res = await generateCode(type, submitList);
        // 将值回填回去
        this.formConfCopy.fields.forEach((item, index) => {
          if (item.__config__.moduleId === fillData.moduleids) {
            if (item.__config__.children && item.__config__.children.length) {
              item.__config__.children.forEach((childrenItem, childIndex) => {
                if (childrenItem.__vModel__ == fillData.__vmodel__) {
                  let target =
                    this.formConfCopy.fields[index].__config__.children[
                      childIndex
                    ].__config__;
                  let propertyKey = "defaultValue";
                  this.$set(target, propertyKey, res.data);
                  this.$set(
                    this[this.formConf.formModel],
                    fillData.moduleids + "-" + fillData.__vmodel__,
                    res.data
                  );
                }
              });
            }
          }
        });
      }
    },
    fillSelectData(data) {
      console.log("data[item.key]", data, this.reflection);
      let that = this;
      for (let item of this.reflection) {
        if (item.searchType) {
          continue;
        }
        if (
          item.moduleId &&
          item.__vModel__ &&
          (data[item.key] || data[item.key] == "")
        ) {
          for (let [oindex, obj] of Object.entries(this.formConfCopy.fields)) {
            if (obj.__config__ && obj.__config__.moduleId === item.moduleId) {
              let odx = oindex;
              if (obj.__config__.children) {
                for (let [cindex, child] of Object.entries(
                  obj.__config__.children
                )) {
                  if (child.__vModel__ === item.__vModel__) {
                    let cdx = cindex;

                    let target =
                      this.formConfCopy.fields[odx].__config__.children[cdx]
                        .__config__;
                    let propertyKey = "defaultValue";
                    this[this.formConf.formModel][
                      item.moduleId + "-" + item.__vModel__
                    ] = data[item.key];
                    console.log(item.key, data[item.key]);
                    this.$set(target, propertyKey, data[item.key]);
                  }
                }
              }
            }
          }
        }
      }
    },
    fillTrademarkData(data) {
      let that = this;
      for (let item of this.reflection) {
        if (item.moduleId && item.__vModel__) {
          for (let [oindex, obj] of Object.entries(this.formConfCopy.fields)) {
            if (obj.__config__ && obj.__config__.moduleId === item.moduleId) {
              let odx = oindex;
              if (obj.__config__.children) {
                for (let [cindex, child] of Object.entries(
                  obj.__config__.children
                )) {
                  if (child.__vModel__ === item.__vModel__) {
                    let cdx = cindex;

                    let target =
                      this.formConfCopy.fields[odx].__config__.defaultValue[
                        this.trIndex
                      ];
                    this.$set(target, item.__vModel__, data[item.key]);
                  }
                }
              }
            }
          }
        }
      }
    },

    findObjects(objArray, moduleId, vModel) {
      for (let obj of objArray) {
        if (obj.__config__ && obj.__config__.moduleId === moduleId) {
          if (obj.__config__.children) {
            for (let child of obj.__config__.children) {
              if (child.__vModel__ === vModel) {
                return child.__config__;
              }
            }
          }
        }
      }

      return null; // 如果未找到匹配的对象，则返回null
    },
    initFormData(componentList, formData) {
      componentList.forEach((cur) => {
        // if (cur.__config__.tag === "ts-sub-form") {
        //   const config = cur.__config__;
        //   formData[config.moduleId] = config.defaultValue;

        // } else {
        const config = cur.__config__;
        if (cur.__vModel__) {
          formData[cur.moduleIds + "-" + cur.__vModel__] =
            config.defaultValue === null ||
            config.defaultValue === undefined ||
            config.defaultValue === ""
              ? ""
              : config.defaultValue;
        }
        if (config.children) this.initFormData(config.children, formData);
        if (config.tag === "el-table") formData[cur.__vModel__] = cur.data;
        // }
      });
    },
    buildRules(componentList, rules) {
      // console.log(componentList,'componentListcomponentList');
      componentList.forEach((cur) => {
        if (!cur.disabled) {
          const config = cur.__config__;
          if (Array.isArray(config.regList)) {
            if (config.required) {
              const required = {
                required: config.required,
                message: cur.placeholder,
              };
              if (Array.isArray(config.defaultValue)) {
                required.type = "array";
                required.message = `请至少选择一个${config.label}`;
              }
              required.message === undefined &&
                (required.message = `${config.label}不能为空`);

              let exists = config.regList.some((item) => {
                // 判断两个对象是否具有相同的属性值
                return JSON.stringify(item) === JSON.stringify(required);
              });
              if (!exists) {
                config.regList.push(required);
              }
            }
            rules[cur.moduleIds + "-" + cur.__vModel__] = config.regList.map(
              (item) => {
                item.pattern && (item.pattern = eval(item.pattern));
                item.trigger = ruleTrigger && ruleTrigger[config.tag];
                return item;
              }
            );
          }
          if (config.children) this.buildRules(config.children, rules);
        }
      });
    },
    buildRules1(cur) {
      if (
        cur.moduleIds + "-" + cur.__vModel__ in this[this.formConf.formRules] ||
        cur.disabled
      ) {
        return;
      }
      const config = cur.__config__;
      if (Array.isArray(config.regList)) {
        if (config.required) {
          const required = {
            required: config.required,
            message: cur.placeholder,
          };
          if (Array.isArray(config.defaultValue)) {
            required.type = "array";
            required.message = `请至少选择一个${config.label}`;
          }
          required.message === undefined &&
            (required.message = `${config.label}不能为空`);

          let exists = config.regList.some((item) => {
            // 判断两个对象是否具有相同的属性值
            return JSON.stringify(item) === JSON.stringify(required);
          });
          if (!exists) {
            config.regList.push(required);
          }
        }
        this[this.formConf.formRules][cur.moduleIds + "-" + cur.__vModel__] =
          config.regList.map((item) => {
            item.pattern && (item.pattern = eval(item.pattern));
            item.trigger = ruleTrigger && ruleTrigger[config.tag];
            return item;
          });
      }
    },
    resetForm() {
      this.formConfCopy = deepClone(this.formConf);
      this.$refs[this.formConf.formRef].resetFields();
    },
    submitForm() {
      let rulesList = Object.keys(this[this.formConf.formRules]);
      this.formConfCopy.fields.forEach((item) => {
        item.__config__.children.forEach((item1) => {
          if (item.__config__.defaultValue?.length) {
            delete this[this.formConf.formRules][
              item1.moduleIds + "-" + item1.__vModel__
            ];
          } else if (
            !item1.isShow &&
            rulesList.indexOf(item1.moduleIds + "-" + item1.__vModel__) != -1
          ) {
            delete this[this.formConf.formRules][
              item1.moduleIds + "-" + item1.__vModel__
            ];
          }
        });
      });

      let flag;
      this.$refs[this.formConf.formRef].validate((valid, errors) => {
        // console.log(this[this.formConf.formModel],this[this.formConf.formRules]);
        if (Object.keys(errors).length) {
          let list = [];
          this.formConfCopy.fields.forEach((item) => {
            item.__config__.children.forEach((item1) => {
              if (
                Object.keys(errors).includes(
                  item1.moduleIds + "-" + item1.__vModel__
                )
              ) {
                list.push(
                  `<${item.__config__.componentName}>的"${item1.__config__.label}"`
                );
              }
            });
          });
          if (list.length > 5) {
            this.$modal.msgError(`请先填写完信息再提交！`);
          } else {
            this.$modal.msgError(`请先填写完${list.join("、")}再提交！`);
          }
        }

        if (!valid) {
          flag = false;
        } else {
          flag = true;
        }
        // 触发sumit事件
        // this.$emit('submit', this[this.formConf.formModel])
        // const params = {
        //   formData: this.formConfCopy,
        //   valData: this[this.formConf.formModel]
        // }
        // this.formDatas = params
        // this.$emit('submit', params)
      });
      return flag;
    },
    submitForms() {
      return this.formConfCopy;
    },
    // 传值给父组件
    getData() {
      this.$emit("getData", this[this.formConf.formModel]);
      // this.$emit('getData',this.formConfCopy)
    },
  },
  render(h) {
    return renderFrom.call(this, h);
  },
};
</script>
<style lang="scss" scoped>
.isrejred {
  ::v-deep .el-input {
    input {
      border-color: red;
    }
  }
}
::v-deep .el-input {
  min-width: 140px;
}
.boundary {
  ::v-deep .el-row {
    border: 1px #a0bcee dashed;
    padding: 10px 20px;
    margin: 10px 20px;
    line-height: 30px;
  }
}

::v-deep .el-form-item--medium .el-form-item__content {
  line-height: 20px !important;
}
</style>
