<template>
  <el-dialog v-bind="$attrs" width="980px" :append-to-body="true" :modal-append-to-body="false" v-on="$listeners" @open="onOpen" @close="onClose">
    <div v-if="type === -1">
      <el-tabs v-model="activeTab">
        <el-tab-pane v-for="(type, index) in identifyTypes" :key="index" :label="type.label" :name="type.value"></el-tab-pane>
      </el-tabs>
      <el-table :data="filteredTableData" border>
        <el-table-column label="识别字段">
          <template slot-scope="scope">
            <span>{{ scope.row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="模块">
          <template slot-scope="scope">
            <el-select placeholder="选择模块" filterable v-model="scope.row.moduleId" clearable>
              <el-option v-for="(item,index) in optionsList" :key="index" :label="item.label" :value="item.moduleId"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="映射字段">
          <template slot-scope="scope">
            <el-select placeholder="选择映射字段" filterable v-model="scope.row.__vModel__" clearable>
              <el-option v-for="(item,index) in getOptions(scope.row.moduleId)" :key="index" :label="item.__config__.label" :value="item.__vModel__"></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-table :data="tableData" border>
        <el-table-column label="识别字段">
          <template slot-scope="scope">
            <span>{{ scope.row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="模块">
          <template slot-scope="scope">
            <el-select placeholder="选择模块" filterable v-model="scope.row.moduleId" clearable>
              <el-option v-for="(item,index) in optionsList" :key="index" :label="item.label" :value="item.moduleId"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="映射字段">
          <template slot-scope="scope">
            <el-select placeholder="选择映射字段" filterable v-model="scope.row.__vModel__" clearable>
              <el-option v-for="(item,index) in getOptions(scope.row.moduleId)" :key="index" :label="item.__config__.label" :value="item.__vModel__"></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEnsure" type="primary">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["drawingList", "type", "identifyList"],
  created() {
    // console.log("this.drawingList", this.drawingList);
  },
  watch: {
    drawingList: {
      handler(val) {
        // console.log("vaddd", val);
        const hasConfig = val.some((obj) => "__config__" in obj);
        if (hasConfig) {
          let arr = [];
          for (let index = 0; index < val.length; index++) {
            const element = {};
            element.moduleId = val[index].__config__.moduleId;
            element.label = val[index].__config__.labelName;
            element.children = val[index].__config__.children;
            arr.push(element);
          }
          // console.log("arr", arr);
          this.optionsList = arr;
        }
      },
      deep: true,
    },

    type: {
      handler(val) {
        // console.log("type", val);
        if (val === -1) {
          const updatedTypeList = this.typeList.flatMap((item, index) => {
            return item.map((citem) => {
              // 为每个 citem 添加 identifyType 属性
              return {
                ...citem, // 保留其他属性
                identifyType: index + 1, // 设置 identifyType 为 index + 1
              };
            });
          });
          // 更新最终的 tableData
          this.tableData = updatedTypeList;
        } else {
          this.tableData = this.typeList[val - 1];
        }
      },
      deep: true,
    },
    identifyList: {
      handler(val) {
        // console.log("identifyList", val);
        if (val && val.length > 0) {
          this.tableData = val;
        }
      },
    },
  },
  created() {},
  data() {
    return {
      identifyTypes: [
        { label: "营业执照", value: 1 },
        { label: "身份证人像", value: 2 },
        { label: "身份证国徽", value: 3 },
        { label: "中国大陆护照", value: 4 },
        { label: "通行证", value: 5 },
        { label: "委托书", value: 6 },
        { label: "身份证正反面", value: 7 },
      ],
      tableData: [],

      typeList: [
        [
          {
            key: "entRegisterTime",
            value: "企业注册时间",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "entRegisterAddress",
            value: "企业注册地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entBusinessScope",
            value: "企业经营范围",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entCorporationName",
            value: "企业法人名称",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entSocialCode",
            value: "社会信用代码",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "entMakeUp", value: "组成形式", moduleId: "", __vModel__: "" },
          {
            key: "entRegistCapital",
            value: "注册资本",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entPapersNum",
            value: "企业证件编号",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entAddress",
            value: "企业地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entAddress2",
            value: "企业地址(去掉省)",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "province",
            value: "省份",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entOrganName",
            value: "企业单位名称",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entTermTime",
            value: "企业有效期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          { key: "entPaidUp", value: "实收资本", moduleId: "", __vModel__: "" },
          {
            key: "entFoundTime",
            value: "成立日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "entRegisterOrgan",
            value: "登记机关",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "entTaxId",
            value: "税务登记号",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "entType", value: "企业类型", moduleId: "", __vModel__: "" },
        ],
        [
          {
            key: "icardName",
            value: "身份证名字",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardSex",
            value: "身份证性别",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardNation",
            value: "身份证民族",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardTime",
            value: "身份证日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardAdress",
            value: "身份证地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardProvince",
            value: "身份证省",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardCity",
            value: "身份证市区",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardTown",
            value: "身份证街道",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardDetail",
            value: "身份证详细地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardAdress2",
            value: " 身份证地址(去掉省)",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardCode",
            value: "身份证号码",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
        ],
        [
          {
            key: "icardIssueTime",
            value: "身份证签发日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardFailureTime",
            value: "身份证失效日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardIssueOrgan",
            value: "身份证签发机关",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
        ],
        [
          {
            key: "psCountryCode",
            value: "护照国家码",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psPlaceIssue",
            value: "护照签发地点",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psMrzCode1",
            value: "护照MrzCode1",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psMrzCode2",
            value: "护照MrzCode2",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psTermTime",
            value: "护照有效期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "psIssueOrgan",
            value: "护照签发机关",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "psNumber", value: "护照号码", moduleId: "", __vModel__: "" },
          {
            key: "psIssueTime",
            value: "护照签发日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "psBrithPlace",
            value: "护照生出地点",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "psName", value: "护照姓名", moduleId: "", __vModel__: "" },
          {
            key: "psNamePhonetic",
            value: "护照姓名拼音",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psNationality",
            value: "护照国籍",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "psBirthday",
            value: "护照生日",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "psSex", value: "护照性别", moduleId: "", __vModel__: "" },
        ],
        [
          {
            key: "meAddress",
            value: "通行证地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "meBirthday",
            value: "通行证生日",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "meNumber",
            value: "通行证号码",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "meNameC",
            value: "通行证名字（中）",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "meNameE",
            value: "通行证名字（英）",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          { key: "meSex", value: "通行证性别", moduleId: "", __vModel__: "" },
          {
            key: "meTermTime",
            value: "通行证有效期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
        ],
        [],
        [
          {
            key: "icardName",
            value: "身份证名字",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardSex",
            value: "身份证性别",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardNation",
            value: "身份证民族",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardTime",
            value: "身份证日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardAdress",
            value: "身份证地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardProvince",
            value: "身份证省",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardCity",
            value: "身份证市区",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardTown",
            value: "身份证街道",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardDetail",
            value: "身份证详细地址",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardAdress2",
            value: " 身份证地址(去掉省)",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },

          {
            key: "icardCode",
            value: "身份证号码",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
          {
            key: "icardIssueTime",
            value: "身份证签发日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardFailureTime",
            value: "身份证失效日期",
            moduleId: "",
            __vModel__: "",
            type: "time",
          },
          {
            key: "icardIssueOrgan",
            value: "身份证签发机关",
            moduleId: "",
            __vModel__: "",
            type: "text",
          },
        ],
      ],

      optionsList: [],
      activeTab: 1,
    };
  },
  computed: {
    filteredTableData() {
      const selectedTypeValue = Number(this.activeTab);
      return this.tableData.filter(
        (item) => item.identifyType === selectedTypeValue
      );
    },
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$emit("update:visible", false);
    },
    handleEnsure() {
      // console.log("optionsList", this.optionsList);
      this.$emit("enSureIdentify", this.tableData);
      // 处理传递过来的 optionsList 值
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    getOptions(Id) {
      if (Id) {
        console.log(this.optionsList);
        let obj = this.optionsList.filter((item) => {
          console.log(Id);
          console.log(item.moduleId);
          if (item.moduleId == Id) {
            return item;
          }
        });
        console.log(obj);
        return obj[0].children || [];
      } else {
        return [];
      }
    },
  },
};
</script>
