<template>
  <div>
    <el-dialog :visible.sync="showSchedules" title="引用历史项目表单" width="80%">
      <search-list @handleSearch="handleSearch" :headData="headData" :headFixData="headFixData" :searchList="searchData"
        :searchData="otherData" @saveSearchHead="saveSearchHead"></search-list>
      <el-table border height="580" fit v-loading="loading" ref="table" :data="listData" stripe @current-change="handleSingleUserSelect" :header-cell-style="{ background: '#f2f3f5', color: '#7a7e83' }">
        <el-table-column width="55" align="center">
          <template slot-scope="scope">
            <el-radio @input="(event) => radioChange(event,scope.row.customerNo)" v-model="radioSelected" :label="scope.row.id">{{''}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column v-for="item in headFixData" v-if="headData.length == 0 || headData.includes(item.headField)"
          :label="item.headName" align="center" :prop="item.headField" :key="item.id">

          <template slot-scope="scope">

            <div v-if="item.htmlType == 'input'">{{ scope.row[item.headField] }}</div>
            <dict-tag v-else-if="item.htmlType == 'select'" :options="dict.type[item.dictType]"
              :value="scope.row[item.headField]" />

            <div v-else-if="item.htmlType == 'datetime'">{{ formatDates(scope.row[item.headField]) }}</div>
          </template>

        </el-table-column>

      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.current" :limit.sync="queryParams.size"
        @pagination="currentChange" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="showSchedules = false">取 消</el-button>
        <el-button type="primary" @click="choseSchedules">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixinSearch from "@/utils/mixinSearch";
import searchList from "@/components/SearchList";
import {formatDate} from "@/utils/index";

export default {
  name: "Project",
  props:{
    projectCode: {
      type: String,
      default() {
        return null
      }
    },
    customerNo: {
      type: String,
      default() {
        return null
      }
    },
  },
  watch: {
    projectCode: {
      handler(val) {
        console.log(val,'valval');
        this.queryParams.projectCode = val
      },
    },
    showSchedules: {
      handler(val) {
        this.radioSelected = null;
        if(val && this.queryParams.projectCode) {
          this.getList();
        }
      }
    },
  },
  mixins: [mixinSearch],
  components: {
    searchList,
  },
  dicts: [
    "bpm_record_status",
    "bpm_record_task_status",
    "bpm_record_task_type",
    "order_member_type"
  ],
  data() {
    return {
      showSchedules:false,
      radioSelected:null, // 选中项目表单ID
      selCustomerNo:null, // 选中项目关联的客户编码
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 项目发起记录表格数据
      projectList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        current: 1,
        size: 10,
        projectCode:null,
        vw_table: "vw_deliver_list",
        orders: [],
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},

      recordStatus: "1",
      unLockId: null,
      tableType: true
    };
  },
  created() {},
  methods: {
    choseSchedules() {
      if(!this.radioSelected) {
        this.$message.error("请先选择需要复制的项目！");
        return false
      }
      if(this.customerNo != this.selCustomerNo) {
        const h = this.$createElement;
        this.$msgbox({
          title: '引用信息提示',
          message: h('div', null, [
            h('p', null, '当前所选项目与引用项目客户编号不一致，请确认是否继续引用?'),
            h('p', { style: 'color: red' }, '注：不同客户编号的项目引用会清除客户信息')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('copyProject',this.radioSelected,false);
          this.showSchedules = false;
        }).catch(() => {

        });
      } else {
        this.$emit('copyProject',this.radioSelected,true);
        this.showSchedules = false;
      }
    },
    formatDates(time){
     return formatDate(time)
    },
    currentChange() {
      if (this.tableType) {
        this.getList()
      } else {
        this.pageAbnormalLog()
      }
    },
    radioChange(val,customerNo) {
      console.log(val,customerNo);
      this.selCustomerNo = customerNo;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        recordCode: null,
        recordName: null,
        recordStatus: null,
        projectId: null,
        areaGroupId: null,
        totalPrice: null,
        procInsId: null,
        overTime: null,
        schedule: null,
        remark: null,
        createId: null,
        createBy: null,
        createTime: null,
        updateId: null,
        updateBy: null,
        updateTime: null,
        delFlag: null,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.current = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSingleUserSelect(selection) {

    },
  },
};
</script>
<style lang="scss" scoped>
.ts_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tsf_tipe {
    font-size: 13px;
    color: #aba2a2;
    flex: 1;
    margin-right: 50px;
    text-align: left;
  }
}
</style>
