import request from '@/utils/request';

// 查询领域小组列表
export function listGroup(query) {
	return request({
		url: '/bpm/bpmAreaGroup',
		method: 'get',
		params: query
	});
}

// 查询领域小组详细
export function getGroup(id) {
	return request({
		url: '/bpm/bpmAreaGroup/' + id,
		method: 'get'
	});
}

// 新增领域小组
export function addGroup(data) {
	return request({
		url: '/bpm/bpmAreaGroup',
		method: 'post',
		data: data
	});
}

// 修改领域小组
export function updateGroup(data) {
	return request({
		url: '/bpm/bpmAreaGroup',
		method: 'put',
		data: data
	});
}

// 删除领域小组
export function delGroup(id) {
	return request({
		url: '/bpm/bpmAreaGroup/',
		method: 'delete',
		params: {idList:id}
	});
}
export function treeSelect(data) {
	return request({
		url: '/bpm/bpmAreaGroup/treeSelect',
		method: 'get',
		data: data
	});
}
