<template>
  <div> <el-dialog :modal="false" :title="toolname" :visible.sync="iframeVisible" width="1200px" height="800px">
      <iframe style="z-index:9999" :src="iframeSrc+`?token=${getToken()}`" frameborder="0" width="100%" height="800px"></iframe>
    </el-dialog>
    <div class="floating-ball" v-if="iconList.length>0" :style="{ top: ballTop + 'px', left: ballLeft + 'px' }" @mousedown.stop="handleMouseDown" @mousemove.prevent>
      <!-- 添加悬浮球内容 -->
      <el-dropdown @command="openIframe" :disabled='isDragging'>
        <div class="ball-content">
          <el-button style="box-shadow:2px 0 6px rgba(0, 21, 41, 0.35);" type="primary" icon="el-icon-s-tools" circle></el-button>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in iconList" :command="item" :icon="item.toolImg" :key="item.id">{{item.toolName}}</el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

import { getPersonTool } from "@/api/deliver/workbench";
export default {
  data() {
    return {
      getToken: getToken,
      toolname: "",
      iframeVisible: false,
      iframeSrc: "",
      isListOpen: false,
      isDragging: false,
      mouseX: 0,
      mouseY: 0,
      ballTop: 600,
      ballLeft: 200,
      initialTop: 0,
      initialLeft: 0,
      iconList: [],
    };
  },
  methods: {
    openIframe(item) {
      console.log(item);
      switch (item.isLink) {
        case "1":
          window.open(item.toolUrl, "_blank");
          break;
        case "3":
          this.$router.push(item.toolUrl);
          break;
        case "2":
          this.toolname = item.toolName;
          this.iframeVisible = true;
          this.iframeSrc = item.toolUrl;
          break;
        default:
          break;
      }
    },
    toggleList() {
      this.isListOpen = !this.isListOpen;
    },
    handleMouseDown(event) {
      this.isDragging = true;
      this.initialTop = this.ballTop;
      this.initialLeft = this.ballLeft;
      const rect = event.target.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        const maxTop = window.innerHeight - 50; // 最大的top值为浏览器窗口高度减去悬浮球高度
        const maxLeft = window.innerWidth - 50; // 最大的left值为浏览器窗口宽度减去悬浮球宽度

        // 限制拖动范围不超出浏览器窗口
        this.ballTop = Math.min(
          Math.max(event.clientY - this.mouseY, 0),
          maxTop
        );
        this.ballLeft = Math.min(
          Math.max(event.clientX - this.mouseX, 0),
          maxLeft
        );
      }
    },
    handleMouseUp() {
      this.isDragging = false;
    },
    async updateIcon() {
      let res = await getPersonTool();
      if (res.code == 200) {
        this.iconList = res.data;
      }
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
    this.updateIcon();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);
  },
};
</script>

<style scoped>
.floating-ball {
  position: fixed;
  width: 50px;
  height: 50px;
  cursor: pointer;
  user-select: none;
  z-index: 99999;
}

.ball-content {
  padding: 5px;
  color: white;
  text-align: center;
  font-size: 13px;
  line-height: 40px;
}

.list {
  list-style-type: none;
  background-color: #f2f2f2;
  padding: 10px;
  margin-top: 0px;
  opacity: 1;
  max-height: 500px;
  overflow: hidden;
  transition: all 0.3s;
}
</style>
