var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isView,
          expression: "isView",
        },
      ],
      staticClass: "flow-containers",
      class: { "view-mode": _vm.isView },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-header",
            {
              staticStyle: {
                "border-bottom": "1px solid rgb(218 218 218)",
                height: "auto",
                "padding-left": "0px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    padding: "10px 0px",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-upload",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            display: "inline-block",
                          },
                          attrs: { action: "", "before-upload": _vm.openBpmn },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "加载xml",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-folder-opened",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "新建",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-circle-plus",
                            },
                            on: { click: _vm.newDiagram },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "自适应屏幕",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { size: "mini", icon: "el-icon-rank" },
                            on: { click: _vm.fitViewport },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "放大",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { size: "mini", icon: "el-icon-zoom-in" },
                            on: {
                              click: function ($event) {
                                return _vm.zoomViewport(true)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "缩小",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { size: "mini", icon: "el-icon-zoom-out" },
                            on: {
                              click: function ($event) {
                                return _vm.zoomViewport(false)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "后退",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { size: "mini", icon: "el-icon-back" },
                            on: {
                              click: function ($event) {
                                _vm.modeler.get("commandStack").undo()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "前进",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { size: "mini", icon: "el-icon-right" },
                            on: {
                              click: function ($event) {
                                _vm.modeler.get("commandStack").redo()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["bpm:project:fieldPermissions"],
                              expression: "['bpm:project:fieldPermissions']",
                            },
                          ],
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.showForm },
                        },
                        [_vm._v("客户端字段显示设置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-view" },
                          on: { click: _vm.showXML },
                        },
                        [_vm._v("查看xml")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-download" },
                          on: {
                            click: function ($event) {
                              return _vm.saveXML(true)
                            },
                          },
                        },
                        [_vm._v("下载xml")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-picture" },
                          on: {
                            click: function ($event) {
                              return _vm.saveImg("svg", true)
                            },
                          },
                        },
                        [_vm._v("下载svg")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存模型")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "el-container",
            { staticStyle: { "align-items": "stretch" } },
            [
              _c("el-main", { staticStyle: { padding: "0" } }, [
                _c("div", { ref: "canvas", staticClass: "canvas" }),
              ]),
              _c(
                "el-aside",
                {
                  staticStyle: {
                    width: "400px",
                    "min-height": "650px",
                    "background-color": "#f0f2f5",
                  },
                },
                [
                  _vm.modeler
                    ? _c("panel", {
                        attrs: {
                          formName: _vm.formName,
                          formDatas: _vm.formDatas,
                          taskList: _vm.taskList,
                          projectId: _vm.projectId,
                          modeler: _vm.modeler,
                          users: _vm.users,
                          groups: _vm.groups,
                          exps: _vm.exps,
                          categorys: _vm.categorys,
                        },
                        on: { updateTask: _vm.updateTask },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑表单字段",
            visible: _vm.formVisible,
            width: "60%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "edit-form-field",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.formLists,
                "row-class-name": _vm.customRowClassName,
                "row-key": "renderKey",
                "default-expand-all": "",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "labelName", label: "字段名" },
              }),
              _c("el-table-column", {
                attrs: { label: "字段配置", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-checkbox-group",
                          {
                            on: {
                              input: function ($event) {
                                return _vm.handleCheckboxChange(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.checkList,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "checkList", $$v)
                              },
                              expression: "scope.row.checkList",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 2 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 3 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }