var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.toolname,
            visible: _vm.iframeVisible,
            width: "1200px",
            height: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.iframeVisible = $event
            },
          },
        },
        [
          _c("iframe", {
            staticStyle: { "z-index": "9999" },
            attrs: {
              src: _vm.iframeSrc + "?token=" + _vm.getToken(),
              frameborder: "0",
              width: "100%",
              height: "800px",
            },
          }),
        ]
      ),
      _vm.iconList.length > 0
        ? _c(
            "div",
            {
              staticClass: "floating-ball",
              style: { top: _vm.ballTop + "px", left: _vm.ballLeft + "px" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleMouseDown($event)
                },
                mousemove: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { disabled: _vm.isDragging },
                  on: { command: _vm.openIframe },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ball-content" },
                    [
                      _c("el-button", {
                        staticStyle: {
                          "box-shadow": "2px 0 6px rgba(0, 21, 41, 0.35)",
                        },
                        attrs: {
                          type: "primary",
                          icon: "el-icon-s-tools",
                          circle: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.iconList, function (item) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: item.id,
                          attrs: { command: item, icon: item.toolImg },
                        },
                        [_vm._v(_vm._s(item.toolName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }