<template>
  <div v-loading="isView" class="flow-containers" :class="{ 'view-mode': isView }">
    <el-container style="height: 100%">
      <el-header style="border-bottom: 1px solid rgb(218 218 218);height: auto;padding-left:0px">
        <div style="display: flex; padding: 10px 0px; justify-content: space-between;">
          <div>
            <el-upload action="" :before-upload="openBpmn" style="margin-right: 10px; display:inline-block;">
              <el-tooltip effect="dark" content="加载xml" placement="bottom">
                <el-button size="mini" icon="el-icon-folder-opened" />
              </el-tooltip>
            </el-upload>
            <el-tooltip effect="dark" content="新建" placement="bottom">
              <el-button size="mini" icon="el-icon-circle-plus" @click="newDiagram" />
            </el-tooltip>
            <el-tooltip effect="dark" content="自适应屏幕" placement="bottom">
              <el-button size="mini" icon="el-icon-rank" @click="fitViewport" />
            </el-tooltip>
            <el-tooltip effect="dark" content="放大" placement="bottom">
              <el-button size="mini" icon="el-icon-zoom-in" @click="zoomViewport(true)" />
            </el-tooltip>
            <el-tooltip effect="dark" content="缩小" placement="bottom">
              <el-button size="mini" icon="el-icon-zoom-out" @click="zoomViewport(false)" />
            </el-tooltip>
            <el-tooltip effect="dark" content="后退" placement="bottom">
              <el-button size="mini" icon="el-icon-back" @click="modeler.get('commandStack').undo()" />
            </el-tooltip>
            <el-tooltip effect="dark" content="前进" placement="bottom">
              <el-button size="mini" icon="el-icon-right" @click="modeler.get('commandStack').redo()" />
            </el-tooltip>
          </div>
          <div>
            <!--            <el-button size="mini" icon="el-icon-s-check" @click="verifyXML">校验xml</el-button>-->
            <el-button size="mini" v-hasPermi="['bpm:project:fieldPermissions']" type="primary" @click="showForm">客户端字段显示设置</el-button>
            <el-button size="mini" icon="el-icon-view" @click="showXML">查看xml</el-button>
            <el-button size="mini" icon="el-icon-download" @click="saveXML(true)">下载xml</el-button>
            <el-button size="mini" icon="el-icon-picture" @click="saveImg('svg', true)">下载svg</el-button>
            <el-button size="mini" type="primary" @click="save">保存模型</el-button>
          </div>
        </div>
      </el-header>
      <el-container style="align-items: stretch">
        <el-main style="padding: 0;">
          <div ref="canvas" class="canvas" />
        </el-main>
        <el-aside style="width: 400px; min-height: 650px; background-color: #f0f2f5">
          <panel v-if="modeler" :formName="formName" :formDatas="formDatas" @updateTask="updateTask" :taskList="taskList" :projectId="projectId" :modeler="modeler" :users="users" :groups="groups" :exps="exps" :categorys="categorys" />
        </el-aside>
      </el-container>
    </el-container>

    <!--编辑表单-->
    <el-dialog title="编辑表单字段" :visible.sync="formVisible" width="60%" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
      <el-table
        class="edit-form-field"
        :data="formLists"
        :row-class-name="customRowClassName"
        style="width: 100%;margin-bottom: 20px;"
        row-key="renderKey"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="labelName"
          label="字段名"
          >
        </el-table-column>
        <el-table-column
          label="字段配置"
          width="400">
          <template slot-scope="scope">
            <!-- <div>{{scope.row.checkList}}</div> -->
            <el-checkbox-group v-model="scope.row.checkList" @input="handleCheckboxChange($event,scope.row)">
              <el-checkbox :label="2" @change="changes($event,scope.row,2)">查看</el-checkbox>
              <el-checkbox :label="3" @change="changes($event,scope.row,3)">编辑</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 汉化
import customTranslate from "./common/customTranslate";
import lintModule from "bpmn-js-bpmnlint";
import Modeler from "bpmn-js/lib/Modeler";
// import bpmnlintConfig from './.bpmnlintrc';
import panel from "./PropertyPanel";
import getInitStr from "./flowable/init";
// 引入flowable的节点文件
import FlowableModule from "./flowable/flowable.json";
import customControlsModule from "./customPanel";
import { listItemTasks } from "@/api/system/task";
import { commonParse } from "./common/parseElement";
import { getConfig,setConfig } from "@/api/flowable/form";
import { log } from 'console';
export default {
  name: "WorkflowBpmnModeler",
  components: {
    panel,
  },
  props: {
    formDatas: {
      type: Array,
      default: () => [],
    },
    xml: {
      type: String,
      default: "",
    },
    formName: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
    users: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    categorys: {
      type: Array,
      default: () => [],
    },
    exps: {
      type: Array,
      default: () => [],
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modeler: null,
      zoom: 1,
      taskList:[],
      taskListNew:[],
      formLists:[],
      formListsOld:[],
      forms:{
        taskId:0,
        viewable:[],
        editable:[],
        struct:null
      },
      formVisible:false,
    };
  },
  watch: {
    xml: function (val) {
      if (val) {
        console.log(this.createNewDiagram(val),'this.createNewDiagram(val)');
        this.createNewDiagram(val);
      }
    },
    formDatas: function (val) {
      if (val) {
        this.creatConfig(val)
      }
    },
  },
  mounted() {
    console.log(customControlsModule,'实例实例实例实例',this.formDatas);
    // 生成实例
    this.modeler = new Modeler({
      container: this.$refs.canvas,
      additionalModules: [
        lintModule,
        customControlsModule,
        {
          //汉化
          translate: ["value", customTranslate],
        },
      ],
      // 去除流程校验器,有需求可自行添加,需要在package.json 加入 "bpmnlint-plugin-local": "file:bpmnlint-plugin-local"
      // linting: {
      //   bpmnlint: bpmnlintConfig
      // },
      moddleExtensions: {
        flowable: FlowableModule,
      },
    });
    window.addEventListener('removeElement', this.handleRemoveElement);
    this.getTaskList(this.projectId);
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听
    window.removeEventListener('removeElement', this.handleRemoveElement);
  },
  methods: {
    customRowClassName({ row, rowIndex }) {
      if (row.children) {
        return 'parent-row'; // 添加自定义样式类名
      }
      return ''; // 不需要额外样式时返回空字符串
    },
    // 展示表单字段设置弹窗
    showForm() {
      this.formLists = []
      console.log(this.formLists);
      getConfig({taskId:-3,projectId:this.projectId}).then(res => {
        if(res.data?.setting) {
          console.log(JSON.parse(res.data.setting),'111');
          console.log(JSON.parse(res.data.setting),'111');
          let list1 = JSON.parse(JSON.stringify(this.formListsOld));
          let list2 = JSON.parse(res.data.setting).struct;

          list1.forEach(item => {
            let matchingItem = list2.find(item1 => item.moduleId === item1.moduleId);
            if (matchingItem) {
              item.checkList = matchingItem.checkList || [];
              item.children.forEach(item2 => {
                let matchingChild = matchingItem.children.find(item3 => item2.__vModel__ === item3.__vModel__);
                if (matchingChild) {
                  item2.checkList = matchingChild.checkList || [];
                }
              });
            }
          });
          this.formLists = list1;
        } else {
          this.formLists = JSON.parse(JSON.stringify(this.formListsOld))
        }
        this.formVisible = true
      })
    },
    // 确认提交字段设置
    checkForm() {
      this.forms.viewable = []
      this.forms.editable = []
      console.log(this.formLists,'formListsformListsformLists');
      this.formLists.forEach(item => {
        item.children.forEach(item1 => {
          item1.checkList.forEach(item2 => {
            if(item2 == 2) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.viewable.push(param)
            }
            if(item2 == 3) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.editable.push(param)
            }
          })
        })
      })
      this.forms.struct = this.formLists
      setConfig({taskId:this.forms.taskId,projectId:this.projectId,setting:JSON.stringify(this.forms)}).then(res => {
        this.formVisible = false
      })
      console.log(this.forms,'this.forms');
    },
    // 字段父节点设置
    changes(e,row,i) {
      if(row.children) {
        console.log(e,row);
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          if(item.renderKey == row.renderKey) {
            indexs = index
            items = JSON.parse(JSON.stringify(item))
          }
        })
        if(e) {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) == -1) {
              item1.checkList.push(i)
            }
          })
        } else {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) != -1) {
              let index = item1.checkList.indexOf(i)
              console.log(i,'iiii');
              item1.checkList.splice(index,1)
            }
          })
        }
        this.$set(this.formLists,indexs,items)
      }
    },
    // 字段子节点设置
    handleCheckboxChange(checkedValues,row) {
      console.log(checkedValues,row,'checkedValues');
      if(!row.children) {
        row.checkList = checkedValues;
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          item.children.forEach((item1,index1) => {
            if (item1.renderKey == row.renderKey) {
              console.log(item1,'item1item1');
              this.$set(item1,'checkList',row.checkList)
              indexs = index
              items = item
            }
          })
        })
        this.$set(this.formLists,indexs,items)
        this.formLists.forEach((item,index) => {
          let list1 = JSON.parse(JSON.stringify(item.checkList))
          let isTrue1 = true
          let isTrue2 = true
          item.children.forEach(item1 => {
            if(item1.checkList.indexOf(2) == -1) {
              isTrue1 = false
            }
            if(item1.checkList.indexOf(3) == -1) {
              isTrue2 = false
            }
          })
          console.log(isTrue1,'isTrue1');
          if(isTrue1) {
            if(list1.indexOf(2) == -1) {
              list1.push(2)
            }
          } else {
            if(list1.indexOf(2) != -1) {
              let index = list1.indexOf(2)
              list1.splice(index,1)
            }
          }
          if(isTrue2) {
            if(list1.indexOf(3) == -1) {
              list1.push(3)
            }
          } else {
            if(list1.indexOf(3) != -1) {
              let index = list1.indexOf(3)
              list1.splice(index,1)
            }
          }
          this.$set(this.formLists[index],'checkList',list1)
        })
      }
      console.log(this.formLists,'this.formLists');
      this.$forceUpdate();
    },

    async creatConfig(val) {
      // 查询表单字段
      if(this.formDatas.length) {
        console.log(this.formDatas,"formDatas4444444");
        this.formDatas.forEach(item => {
          let obj = item.__config__
          obj.labelName = obj.componentName
          obj.checkList = []
          obj.children.forEach(item1 => {
            item1.renderKey = item1.__config__.renderKey
            item1.labelName = item1.__config__.label
            item1.checkList = []
          })
          this.formLists.push(obj)
        })
        this.formListsOld = JSON.parse(JSON.stringify(this.formLists))
        console.log(this.formDatas,"formDatas111",this.formLists,this.formListsOld);
      }
      await this.setConfigs(val,0)
      await this.setConfigs(val,-1)
      await this.setConfigs(val,-3)
    },
    async setConfigs(val, i) {
      try {
        this.forms.taskId = i;
        const res = await getConfig({ taskId: this.forms.taskId, projectId: this.projectId });
        if (res.data && res.data.setting) {
          return; // 如果设置已存在，则直接返回
        }
        val.forEach(item => {
          let obj = item.__config__;
          if (this.forms.taskId === 0) {
            obj.checkList = [2, 3];
          } else {
            obj.checkList = [2];
          }
          
          obj.children.forEach(item1 => {
            item1.renderKey = item1.__config__.renderKey;
            item1.labelName = item1.__config__.label;
            if (this.forms.taskId === 0) {
              item1.checkList = [2, 3];
            } else {
              item1.checkList = [2];
            }
          });
          this.formLists.push(obj);
        });
        this.formLists.forEach(item => {
          item.children.forEach(item1 => {
            let param = {
              __vModel__: item1.__vModel__,
              moduleId: item.moduleId
            };
            this.forms.viewable.push(param);
            if (this.forms.taskId === 0) {
              this.forms.editable.push(param);
            }
          });
        });

        this.forms.struct = this.formLists;
        await setConfig({ taskId: this.forms.taskId, projectId: this.projectId, setting: JSON.stringify(this.forms) });
      } catch (error) {
        console.error(error);
      }
    },
    handleRemoveElement(event) {
      let cache = commonParse(event.detail.element);
      console.log(this.taskList,cache,this.taskListNew,'xixixixiixixixixiixixiii');
      let list = this.taskListNew.filter(item => 
        cache.taskIds.split(',').indexOf(item.id) != -1
      )
      this.taskList = this.taskList.concat(list)
      console.log(list,'listlsitlistlist',this.taskList);
    },
    async getTaskList(projectId) {
      let res = await listItemTasks({ projectId: projectId,mustType:1 });
      if (res.code == 200) {
        this.taskList = res.data;
        this.taskListNew = JSON.parse(JSON.stringify(this.taskList))
        console.log(this.taskList);
      }
      // 新增流程定义
      if (!this.xml) {
        this.newDiagram();
      } else {
        this.createNewDiagram(this.xml);
      }
    },
    updateTask(list) {
      this.taskList = list;
      console.log(this.taskList,'hahahhhhaha1');
    },
    newDiagram() {
      this.createNewDiagram(getInitStr());
    },
    // 让图能自适应屏幕
    fitViewport() {
      this.zoom = this.modeler.get("canvas").zoom("fit-viewport");
      const bbox = document
        .querySelector(".flow-containers .viewport")
        .getBBox();
      const currentViewbox = this.modeler.get("canvas").viewbox();
      const elementMid = {
        x: bbox.x + bbox.width / 2 - 65,
        y: bbox.y + bbox.height / 2,
      };
      this.modeler.get("canvas").viewbox({
        x: elementMid.x - currentViewbox.width / 2,
        y: elementMid.y - currentViewbox.height / 2,
        width: currentViewbox.width,
        height: currentViewbox.height,
      });
      this.zoom = (bbox.width / currentViewbox.width) * 1.8;
    },
    // 放大缩小
    zoomViewport(zoomIn = true) {
      this.zoom = this.modeler.get("canvas").zoom();
      this.zoom += zoomIn ? 0.1 : -0.1;
      this.modeler.get("canvas").zoom(this.zoom);
    },
    async createNewDiagram(data) {
      // 将字符串转换成图显示出来
      // data = data.replace(/<!\[CDATA\[(.+?)]]>/g, '&lt;![CDATA[$1]]&gt;')
      data = data.replace(/<!\[CDATA\[(.+?)]]>/g, function (match, str) {
        return str.replace(/</g, "&lt;");
      });
      console.log(this.modeler.importXML(data),'datadatadatadata');
      try {
        await this.modeler.importXML(data);
        // this.adjustPalette()
        this.fitViewport();
      } catch (err) {
        console.error(err.message, err.warnings);
      }
      if(this.taskList.length) {
        this.modeler.importXML(data).then(res => {
          const tasksToRemove = res.warnings
            .filter(item => item.property === 'flowable:taskIds')
            .flatMap(item => item.value.split(','));

          if (tasksToRemove.length) {
            this.taskList = this.taskList.filter(item => !tasksToRemove.includes(item.id));
          }
          console.log(this.taskList, 'this.taskList');
        });
      }
    },
    // 对外 api
    getProcess() {
      const element = this.getProcessElement();
      return {
        id: element.id,
        name: element.name,
        category: element.$attrs["flowable:processCategory"],
      };
    },
    getProcessElement() {
      const rootElements = this.modeler.getDefinitions().rootElements;
      for (let i = 0; i < rootElements.length; i++) {
        if (rootElements[i].$type === "bpmn:Process") return rootElements[i];
      }
    },
    async verifyXML() {
      const linting = this.modeler.get("linting");
      linting.toggle();
    },
    async saveXML(download = false) {
      try {
        const { xml } = await this.modeler.saveXML({ format: true });
        if (download) {
          this.downloadFile(
            `${this.getProcessElement().name}.bpmn20.xml`,
            xml,
            "application/xml"
          );
        }
        return xml;
      } catch (err) {
        console.log(err);
      }
    },
    async showXML() {
      try {
        const xml = await this.saveXML();
        this.$emit("showXML", xml);
      } catch (err) {
        console.log(err);
      }
    },
    async saveImg(type = "svg", download = false) {
      try {
        const { svg } = await this.modeler.saveSVG({ format: true });
        if (download) {
          this.downloadFile(
            this.getProcessElement().name,
            svg,
            "image/svg+xml"
          );
        }
        return svg;
      } catch (err) {
        console.log(err);
      }
    },
    async save() {
      const process = this.getProcess();
      const xml = await this.saveXML();
      const svg = await this.saveImg();
      const result = { process, xml, svg };
      this.$emit("save", result);
      window.parent.postMessage(result, "*");
    },
    openBpmn(file) {
      const reader = new FileReader();
      reader.readAsText(file, "utf-8");
      reader.onload = () => {
        this.createNewDiagram(reader.result);
      };
      return false;
    },
    downloadFile(filename, data, type) {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([data], { type: type }));
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

<style lang="scss">
/*左边工具栏以及编辑节点的样式*/
@import "~bpmn-js/dist/assets/diagram-js.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
@import "~bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
//@import "~bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css";
.view-mode {
  .el-header,
  .el-aside,
  .djs-palette,
  .bjs-powered-by {
    display: none;
  }
  .el-loading-mask {
    background-color: initial;
  }
  .el-loading-spinner {
    display: none;
  }
}
.flow-containers {
  // background-color: #ffffff;
  width: 100%;
  height: 100%;
  .canvas {
    width: 100%;
    height: 100%;
    //flex: 1;
    //position: relative;
    //background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImEiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTTAgMTBoNDBNMTAgMHY0ME0wIDIwaDQwTTIwIDB2NDBNMCAzMGg0ME0zMCAwdjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNlMGUwZTAiIG9wYWNpdHk9Ii4yIi8+PHBhdGggZD0iTTQwIDBIMHY0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTBlMGUwIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2EpIi8+PC9zdmc+")
    //repeat !important;
    //div.toggle-mode {
    //  display: none;
    //}
  }
  .panel {
    position: absolute;
    right: 0;
    top: 50px;
    width: 300px;
  }
  .load {
    margin-right: 10px;
  }
  .el-form-item__label {
    font-size: 13px;
  }

  .djs-palette {
    left: 0px !important;
    top: 0px;
    border-top: none;
  }

  .djs-container svg {
    //min-height: 650px;
  }

  .highlight.djs-shape .djs-visual > :nth-child(1) {
    fill: green !important;
    stroke: green !important;
    fill-opacity: 0.2 !important;
  }
  .highlight.djs-shape .djs-visual > :nth-child(2) {
    fill: green !important;
  }
  .highlight.djs-shape .djs-visual > path {
    fill: green !important;
    fill-opacity: 0.2 !important;
    stroke: green !important;
  }
  .highlight.djs-connection > .djs-visual > path {
    stroke: green !important;
  }
  // .djs-connection > .djs-visual > path {
  //   stroke: orange !important;
  //   stroke-dasharray: 4px !important;
  //   fill-opacity: 0.2 !important;
  // }
  // .djs-shape .djs-visual > :nth-child(1) {
  //   fill: orange !important;
  //   stroke: orange !important;
  //   stroke-dasharray: 4px !important;
  //   fill-opacity: 0.2 !important;
  // }
  .highlight-todo.djs-connection > .djs-visual > path {
    stroke: orange !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }
  .highlight-todo.djs-shape .djs-visual > :nth-child(1) {
    fill: orange !important;
    stroke: orange !important;
    stroke-dasharray: 4px !important;
    fill-opacity: 0.2 !important;
  }
  .overlays-div {
    font-size: 10px;
    color: red;
    width: 100px;
    top: -20px !important;
  }
}
.edit-form-field {
  .el-table__body-wrapper {
    max-height: 520px;
    overflow-y: auto;
  }
}
</style>
