var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("x-form", {
        ref: "xForm",
        attrs: { config: _vm.formConfig },
        scopedSlots: _vm._u([
          {
            key: "checkSingleUser",
            fn: function () {
              return [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请选择人员" },
                    model: {
                      value: _vm.checkValues,
                      callback: function ($$v) {
                        _vm.checkValues = $$v
                      },
                      expression: "checkValues",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "append" },
                      [
                        _c("el-button", {
                          staticStyle: { "padding-left": "7px" },
                          attrs: { icon: "el-icon-user" },
                          on: { click: _vm.singleUserCheck },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "checkMultipleUser",
            fn: function () {
              return [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请选择候选用户" },
                    model: {
                      value: _vm.checkValues,
                      callback: function ($$v) {
                        _vm.checkValues = $$v
                      },
                      expression: "checkValues",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "append" },
                      [
                        _c("el-button", {
                          staticStyle: { "padding-left": "7px" },
                          attrs: { icon: "el-icon-user" },
                          on: { click: _vm.multipleUserCheck },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "checkRole",
            fn: function () {
              return [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请选择候选角色" },
                    model: {
                      value: _vm.checkValues,
                      callback: function ($$v) {
                        _vm.checkValues = $$v
                      },
                      expression: "checkValues",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "append" },
                      [
                        _c("el-button", {
                          staticStyle: { "padding-left": "7px" },
                          attrs: { icon: "el-icon-user" },
                          on: { click: _vm.multipleRoleCheck },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "taskIds",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { value: _vm.formData.taskIds.split(",").length } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.choseTaskId()
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "overStatus",
            fn: function () {
              return [
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.formData.overStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "overStatus", $$v)
                      },
                      expression: "formData.overStatus",
                    },
                  },
                  [
                    _c("el-option", {
                      key: "0",
                      attrs: { label: "否", value: "0" },
                    }),
                    _c("el-option", {
                      key: "1",
                      attrs: { label: "是", value: "1" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "executionListener",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { value: _vm.executionListenerLength } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogName = "executionListenerDialog"
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "taskListener",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { value: _vm.taskListenerLength } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogName = "taskListenerDialog"
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "multiInstance",
            fn: function () {
              return [
                _c(
                  "el-badge",
                  { attrs: { "is-dot": _vm.hasMultiInstance } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.dialogName = "multiInstanceDialog"
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _vm.dialogName === "executionListenerDialog"
        ? _c("executionListenerDialog", {
            attrs: { element: _vm.element, modeler: _vm.modeler },
            on: { close: _vm.finishExecutionListener },
          })
        : _vm._e(),
      _vm.dialogName === "taskListenerDialog"
        ? _c("taskListenerDialog", {
            attrs: { element: _vm.element, modeler: _vm.modeler },
            on: { close: _vm.finishTaskListener },
          })
        : _vm._e(),
      _vm.dialogName === "multiInstanceDialog"
        ? _c("multiInstanceDialog", {
            attrs: { element: _vm.element, modeler: _vm.modeler },
            on: { close: _vm.finishMultiInstance },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择人员",
            visible: _vm.userVisible,
            width: "60%",
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _c("flow-user", {
            attrs: { checkType: _vm.checkType, selectValues: _vm.selectValues },
            on: { handleUserSelect: _vm.handleUserSelect },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.checkUserComplete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择表达式",
            visible: _vm.expVisible,
            width: "60%",
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.expVisible = $event
            },
          },
        },
        [
          _c("flow-exp", {
            attrs: { selectValues: _vm.selectValues },
            on: { handleSingleExpSelect: _vm.handleSingleExpSelect },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.expVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.checkExpComplete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择候选角色",
            visible: _vm.roleVisible,
            width: "60%",
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleVisible = $event
            },
          },
        },
        [
          _c("flow-role", {
            attrs: { checkType: _vm.checkType, selectValues: _vm.selectValues },
            on: { handleRoleSelect: _vm.handleRoleSelect },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.checkRoleComplete },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定任务",
            visible: _vm.taskVisible,
            width: "60%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.taskVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.taskListNew, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange },
              model: {
                value: _vm.taskIds,
                callback: function ($$v) {
                  _vm.taskIds = $$v
                },
                expression: "taskIds",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "taskName", label: "任务名称", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "字段配置", align: "center", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showForm(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delTask(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkTask } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑表单字段",
            visible: _vm.formVisible,
            width: "60%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "edit-form-field",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.formLists,
                "row-class-name": _vm.customRowClassName,
                "row-key": "renderKey",
                "default-expand-all": "",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "labelName", label: "字段名" },
              }),
              _c("el-table-column", {
                attrs: { label: "字段配置", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-checkbox-group",
                          {
                            on: {
                              input: function ($event) {
                                return _vm.handleCheckboxChange(
                                  $event,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value: scope.row.checkList,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "checkList", $$v)
                              },
                              expression: "scope.row.checkList",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 2 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: 3 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changes($event, scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }