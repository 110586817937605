var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置信息组合",
            visible: _vm.fieldOpen,
            width: "800px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.fieldOpen = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("添加组合")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "line-height": "46px",
                display: "flex",
                "align-items": "center",
                "justify-content": "space-around",
                margin: "6px 0",
                "background-color": "#f8f8f9",
                "border-bottom": "1px solid #dfe6ec",
              },
            },
            [
              _c("div", {
                staticStyle: { width: "100px", "text-align": "center" },
              }),
              _c(
                "div",
                { staticStyle: { width: "480px", "text-align": "center" } },
                [_vm._v("组合名称")]
              ),
              _c(
                "div",
                { staticStyle: { width: "180px", "text-align": "center" } },
                [_vm._v("操作")]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.fieldsData,
                    animation: 340,
                    group: "selectItem",
                    handle: ".option-drag",
                  },
                },
                _vm._l(_vm.fieldsData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "select-item",
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-around",
                        margin: "12px 0",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select-line-icon option-drag",
                          staticStyle: {
                            width: "100px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-operation",
                            staticStyle: {
                              "font-size": "20px",
                              cursor: "move",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "480px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(_vm.getName(item.condition)))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "close-btn select-line-icon",
                          staticStyle: {
                            width: "166px",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCom(item, index)
                                },
                              },
                            },
                            [_vm._v("关联组合")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.delCom(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitFields } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "p_10",
            title: _vm.isUpdate ? "修改信息组合" : "添加信息组合",
            visible: _vm.rulesOpens,
            width: "600px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.rulesOpens = $event
            },
          },
        },
        [
          _c("div", { staticClass: "rule_tips" }, [
            _vm._v(
              "此功能用于自动组合填写信息或判断字段的设置【设置请清晰字段的类型】"
            ),
          ]),
          _c(
            "el-radio-group",
            {
              staticClass: "rule_select",
              on: { change: _vm.handleChange },
              model: {
                value: _vm.isFixed,
                callback: function ($$v) {
                  _vm.isFixed = $$v
                },
                expression: "isFixed",
              },
            },
            [
              _c(
                "el-radio-button",
                { staticClass: "half_width", attrs: { label: false } },
                [_vm._v("表单字段")]
              ),
              _c(
                "el-radio-button",
                { staticClass: "half_width", attrs: { label: true } },
                [_vm._v("固定字段")]
              ),
            ],
            1
          ),
          _vm.isFixed
            ? _c("div", { staticClass: "rule_tips" }, [
                _vm._v(
                  " 固定值组合规则提示：结果=字段条件+固定值（例：字符拼接《12=1+2》；数值相加《3=1+2》;相减《-1=1+2》；日期《2024.09.12=2024.09.10+2》）固定值在后，字段条件在前 "
                ),
              ])
            : _c("div", { staticClass: "rule_tips" }, [
                _vm._v(
                  " 字段组合规则提示：结果=字段条件+固定值（例：字符拼接《12=1+2》；数值相加《3=1+2》;相减《-1=1+2》； "
                ),
              ]),
          _c(
            "div",
            { staticClass: "rule_con" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("组合形式")]),
              _c(
                "el-radio-group",
                {
                  staticClass: "rule_select",
                  model: {
                    value: _vm.rulesType,
                    callback: function ($$v) {
                      _vm.rulesType = $$v
                    },
                    expression: "rulesType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("字符拼接")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("数值相加")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("数值相减")]),
                  _c("el-radio", { attrs: { label: 4 } }, [_vm._v("数值相乘")]),
                  _c("el-radio", { attrs: { label: 5 } }, [_vm._v("数值相除")]),
                  _vm.isFixed
                    ? _c("el-radio", { attrs: { label: 6 } }, [
                        _vm._v("日期相加"),
                      ])
                    : _vm._e(),
                  _vm.isFixed
                    ? _c("el-radio", { attrs: { label: 7 } }, [
                        _vm._v("日期相减"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "rule_tips" }, [
                _vm._v(
                  "数值的计算必须选择字段类型为“数字控件”，否则无法生效！"
                ),
                _c("br"),
                _vm._v(
                  "日期计算必须选择字段类型为“日期”（不可选择日期范围）！"
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rule_con" },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm._v("字段条件设置 "),
                  _c(
                    "el-button",
                    {
                      staticClass: "tle_btn",
                      attrs: {
                        disabled: _vm.condition.length > 0 && _vm.isFixed,
                        type: "text",
                        icon: "el-icon-plus",
                        plain: "",
                        size: "mini",
                      },
                      on: { click: _vm.conditionAdd },
                    },
                    [_vm._v("添加条件")]
                  ),
                ],
                1
              ),
              _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.condition,
                    animation: 340,
                    group: "selectItem",
                    handle: ".option-drag",
                  },
                },
                _vm._l(_vm.condition, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "conbox" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select-line-icon option-drag",
                          staticStyle: {
                            width: "60px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-operation",
                            staticStyle: {
                              "font-size": "20px",
                              cursor: "move",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择模块" },
                          on: {
                            change: function ($event) {
                              return _vm.moduleChange($event, index)
                            },
                          },
                          model: {
                            value: item.moduleId,
                            callback: function ($$v) {
                              _vm.$set(item, "moduleId", $$v)
                            },
                            expression: "item.moduleId",
                          },
                        },
                        _vm._l(_vm.formLists, function (item) {
                          return _c("el-option", {
                            key: item.moduleId,
                            attrs: {
                              label: item.componentName,
                              value: item.moduleId,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择字段" },
                          on: {
                            change: function ($event) {
                              return _vm.fieldChange($event, index)
                            },
                          },
                          model: {
                            value: item.__vModel__,
                            callback: function ($$v) {
                              _vm.$set(item, "__vModel__", $$v)
                            },
                            expression: "item.__vModel__",
                          },
                        },
                        _vm._l(item.fieldList, function (item) {
                          return _c("el-option", {
                            key: item.__vModel__,
                            attrs: {
                              label:
                                item.labelName + "(" + item.originalName + ")",
                              value: item.__vModel__,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.delCon(index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.isFixed
            ? _c(
                "div",
                { staticClass: "rule_con" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("固定值设置 "),
                    _c("span", { staticClass: "rule_tips tle_btn" }, [
                      _vm._v("若涉及计算，请输入数字，否则无法生效"),
                    ]),
                  ]),
                  _c(
                    "el-input",
                    {
                      staticClass: "rule_input",
                      attrs: { placeholder: "请输入固定值", clearable: "" },
                      model: {
                        value: _vm.fixedValue,
                        callback: function ($$v) {
                          _vm.fixedValue = $$v
                        },
                        expression: "fixedValue",
                      },
                    },
                    [_c("template", { slot: "prepend" }, [_vm._v("固定值")])],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "rule_con" },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm._v("结果映射"),
                  _c(
                    "el-button",
                    {
                      staticClass: "tle_btn",
                      attrs: {
                        type: "text",
                        icon: "el-icon-plus",
                        plain: "",
                        size: "mini",
                      },
                      on: { click: _vm.actionAdd },
                    },
                    [_vm._v("添加动作")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.activeData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "conbox" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { filterable: "", placeholder: "请选择模块" },
                        on: {
                          change: function ($event) {
                            return _vm.moduleChanges($event, index)
                          },
                        },
                        model: {
                          value: item.moduleId,
                          callback: function ($$v) {
                            _vm.$set(item, "moduleId", $$v)
                          },
                          expression: "item.moduleId",
                        },
                      },
                      _vm._l(_vm.formLists, function (item) {
                        return _c("el-option", {
                          key: item.moduleId,
                          attrs: {
                            label: item.componentName,
                            value: item.moduleId,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        attrs: { filterable: "", placeholder: "请选择字段" },
                        on: {
                          change: function ($event) {
                            return _vm.fieldChanges($event, index)
                          },
                        },
                        model: {
                          value: item.__vModel__,
                          callback: function ($$v) {
                            _vm.$set(item, "__vModel__", $$v)
                          },
                          expression: "item.__vModel__",
                        },
                      },
                      _vm._l(item.fieldList, function (item) {
                        return _c("el-option", {
                          key: item.__vModel__,
                          attrs: {
                            label:
                              item.labelName + "(" + item.originalName + ")",
                            value: item.__vModel__,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.delAct(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.rulesOpens = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }