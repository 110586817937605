var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.showlr
        ? _c(
            "div",
            { staticClass: "left-board" },
            [
              _c("div", { staticClass: "logo-wrapper" }, [
                _c("div", { staticClass: "logo" }, [
                  _c("img", { attrs: { src: _vm.logo, alt: "logo" } }),
                  _vm._v(" Form Generator "),
                ]),
              ]),
              _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
                _c(
                  "div",
                  { staticClass: "components-list" },
                  _vm._l(_vm.leftComponents, function (item, listIndex) {
                    return _c(
                      "div",
                      { key: listIndex },
                      [
                        _c(
                          "div",
                          { staticClass: "components-title" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "component" },
                            }),
                            _vm._v(" " + _vm._s(item.title) + " "),
                          ],
                          1
                        ),
                        _c(
                          "draggable",
                          {
                            staticClass: "components-draggable",
                            attrs: {
                              clone: _vm.cloneComponent,
                              group: {
                                name: "componentsGroup",
                                pull: "clone",
                                put: false,
                              },
                              list: item.list,
                              sort: false,
                              draggable: ".components-item",
                            },
                            on: { end: _vm.onEnd },
                          },
                          _vm._l(item.list, function (element, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "components-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.addComponent(element)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "components-body" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class":
                                          element.__config__.tagIcon,
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          element.__config__.label ||
                                            element.__config__.labelName
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { class: ["center-board", _vm.showlr ? "center-boards" : ""] },
        [
          !_vm.showlr
            ? _c(
                "div",
                { staticClass: "action-bar" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-edit", type: "text" },
                      on: { click: _vm.setRules },
                    },
                    [_vm._v(" 设置业务规则 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "delete-btn",
                      attrs: { icon: "el-icon-delete", type: "text" },
                      on: { click: _vm.empty },
                    },
                    [_vm._v(" 清空 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-scrollbar",
            { staticClass: "center-scrollbar" },
            [
              _c(
                "el-row",
                {
                  staticClass: "center-board-row",
                  attrs: { gutter: _vm.formConf.gutter },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: _vm.formConf.size,
                        "label-position": _vm.formConf.labelPosition,
                        disabled: _vm.formConf.disabled,
                        "label-width": _vm.formConf.labelWidth + "px",
                      },
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "drawing-board",
                          attrs: {
                            list: _vm.drawingList,
                            animation: 340,
                            group: "componentsGroup",
                          },
                        },
                        _vm._l(_vm.drawingList, function (item, index) {
                          return _c("draggable-item", {
                            key: item.renderKey,
                            attrs: {
                              "drawing-list": _vm.drawingList,
                              "current-item": item,
                              index: index,
                              "active-id": _vm.activeId,
                              "form-conf": _vm.formConf,
                            },
                            on: {
                              activeItem: _vm.activeFormItem,
                              copyItem: _vm.drawingItemCopy,
                              deleteItem: _vm.drawingItemDelete,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.drawingList.length,
                              expression: "!drawingList.length",
                            },
                          ],
                          staticClass: "empty-info",
                        },
                        [_vm._v(" 从左侧拖入或点选组件进行表单设计 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.showlr
        ? _c("right-panel", {
            attrs: {
              "active-data": _vm.activeData,
              "form-conf": _vm.formConf,
              "show-field": !!_vm.drawingList.length,
              drawingList: _vm.drawingList,
              isModule: true,
            },
            on: { "tag-change": _vm.tagChange, "fetch-data": _vm.fetchData },
          })
        : _vm._e(),
      _c("form-drawer", {
        attrs: {
          visible: _vm.drawerVisible,
          "form-data": _vm.formData,
          size: "100%",
          "generate-conf": _vm.generateConf,
        },
        on: {
          "update:visible": function ($event) {
            _vm.drawerVisible = $event
          },
        },
      }),
      _c("json-drawer", {
        attrs: {
          size: "60%",
          visible: _vm.jsonDrawerVisible,
          "json-str": JSON.stringify(_vm.formData),
        },
        on: {
          "update:visible": function ($event) {
            _vm.jsonDrawerVisible = $event
          },
          refresh: _vm.refreshJson,
        },
      }),
      _c("code-type-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          title: "选择生成类型",
          "show-file-name": _vm.showFileName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          confirm: _vm.generate,
        },
      }),
      _c("input", { attrs: { id: "copyNode", type: "hidden" } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formTitle,
            visible: _vm.formOpen,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "表单名称", prop: "formName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入表单名称" },
                    model: {
                      value: _vm.form.formName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "formName", $$v)
                      },
                      expression: "form.formName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("set-rules", {
        ref: "setRules",
        attrs: { formLists: _vm.formLists, rulesDatas: _vm.rulesDatas },
        on: { conRules: _vm.conRules },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }