import request from '@/utils/request'

// 查询流程表单列表
export function listForm(query) {
  return request({
    url: '/flowable/form/list',
    method: 'get',
    params: query
  })
}
export function listAllForm(query) {
  return request({
    url: '/flowable/form/formList',
    method: 'get',
    params: query
  })
}

// 查询流程表单详细
export function getForm(formId) {
  return request({
    url: '/flowable/form/' + formId,
    method: 'get'
  })
}

// 新增流程表单
export function addForm(data) {
  return request({
    url: '/flowable/form',
    method: 'post',
    data: data
  })
}

// 修改流程表单
export function updateForm(data) {
  return request({
    url: '/flowable/form',
    method: 'put',
    data: data
  })
}
// 挂载表单
export function addDeployForm(data) {
  return request({
    url: '/flowable/form/addDeployForm',
    method: 'post',
    data: data
  })
}

// 删除流程表单
export function delForm(formId) {
  return request({
    url: '/flowable/form/' + formId,
    method: 'delete'
  })
}

// 导出流程表单
export function exportForm(query) {
  return request({
    url: '/flowable/form/export',
    method: 'get',
    params: query
  })
}

// 流程配置节点绑定字段设置
export function setConfig(data) {
  return request({
    url: '/bpm/form/task/setConfig',
    method: 'post',
    data: data
  })
}

// 流程配置节点获取绑定字段设置
export function getConfig(query) {
  return request({
    url: '/bpm/form/task/getConfig',
    method: 'get',
    params: query
  })
}
// 流程配置节点获取绑定字段设置
export function getRecordTaskConfig(query) {
  return request({
    url: '/bpm/form/task/getRecordTaskConfig',
    method: 'get',
    params: query
  })
}
//取消子任务
export function cancelSubTask(data) {
  return request({
    url: '/bpm/bpmRecordProject/cancelSubTask',
    method: 'post',
    data: data
  })
}

//校验商标小类
export function checkTrademarkSub(data) {
  return request({
    url: '/analysis/bpmCloud/api/checkTrademarkSub',
    method: 'post',
    data: data
  })
}
