<template>
  <div>
    <x-form ref="xForm" v-model="formData" :config="formConfig">
      <template #taskIds>
        <el-button size="small" @click="choseTaskId()">编辑</el-button>
      </template>
      <template #executionListener>
        <el-badge :value="executionListenerLength">
          <el-button size="small" @click="dialogName = 'executionListenerDialog'">编辑</el-button>
        </el-badge>
      </template>
    </x-form>
    <executionListenerDialog
      v-if="dialogName === 'executionListenerDialog'"
      :element="element"
      :modeler="modeler"
      @close="finishExecutionListener"
    />

    <!--编辑表单-->
    <el-dialog title="编辑表单字段" :visible.sync="formVisible" width="60%" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
      <el-table
        class="edit-form-field"
        :data="formLists"
        :row-class-name="customRowClassName"
        style="width: 100%;margin-bottom: 20px;"
        row-key="renderKey"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="labelName"
          label="字段名"
          >
        </el-table-column>
        <el-table-column
          label="字段配置"
          width="400">
          <template slot-scope="scope">
            <!-- <div>{{scope.row.checkList}}</div> -->
            <el-checkbox-group v-model="scope.row.checkList" @input="handleCheckboxChange($event,scope.row)">
              <el-checkbox :label="2" @change="changes($event,scope.row,2)">查看</el-checkbox>
              <el-checkbox :label="3" @change="changes($event,scope.row,3)">编辑</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixinPanel from '../../common/mixinPanel'
import mixinExecutionListener from '../../common/mixinExecutionListener'
import { commonParse } from '../../common/parseElement'
import { listAllForm, setConfig, getConfig } from "@/api/flowable/form";
export default {
  mixins: [mixinPanel, mixinExecutionListener],
  props: {
    formDatas: {
      type: Array,
      required: true,
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {},
      executionListenerLength: 0,

      taskListNew:[],
      taskVisible:false, // 绑定任务
      taskIds:[],
      formVisible:false, // 字段配置
      defaultProps: {
        children: 'children',
        label: 'labelName'
      },
      formLists:[],
      formListsOld:[],
      forms:{
        taskId:null,
        viewable:[],
        editable:[],
        struct:null
      },
    }
  },
  computed: {
    formConfig() {
      const _this = this
      return {
        inline: false,
        item: [
          {
            xType: 'input',
            name: 'id',
            label: '节点 id',
            disabled:true,
            rules: [{ required: true, message: 'Id 不能为空' }]
          },
          {
            xType: 'input',
            name: 'name',
            label: '节点名称'
          },
          {
            xType: 'input',
            name: 'documentation',
            label: '节点描述'
          },
          {
            xType: "slot",
            name: "taskIds",
            label: "选择任务",
            rules: [{ required: true, message: '任务选择不能为空' }],
          },
          {
            xType: 'slot',
            name: 'executionListener',
            label: '执行监听器'
          },
          // {
          //   xType: 'input',
          //   name: 'initiator',
          //   label: '发起人',
          //   show: !!_this.showConfig.initiator
          // },
          // {
          //   xType: 'input',
          //   name: 'formKey',
          //   label: '表单标识key',
          //   show: !!_this.showConfig.formKey
          // }
        ]
      }
    }
  },
  watch: {
    'formData.initiator': function(val) {
      if (val === '') val = null
      // 默认设置流程发起人
      // if (val === '') val = 'INITIATOR'
      this.updateProperties({ 'flowable:initiator': val })
    },
    'formData.formKey': function(val) {
      if (val === '') val = null
      this.updateProperties({ 'flowable:formKey': val })
    },
  },
  created() {
    // this.updateProperties({ 'flowable:initiator': 'INITIATOR' })
    this.formData = commonParse(this.element)
    this.computedExecutionListenerLength();
    console.log(this.formData,'this.formData',this.formData.taskIds);
    if(this.formData.$type == "bpmn:StartEvent") {
      this.forms.taskId = 0
      this.formData.taskIds = 0
    }
    if(this.formData.$type == "bpmn:EndEvent") {
      this.forms.taskId = -1
      this.formData.taskIds = -1
    }
    if(this.formDatas.length) {
      console.log(this.formDatas,"formDatas");
      this.formDatas.forEach(item => {
        let obj = item.__config__
        obj.labelName = obj.componentName
        obj.checkList = []
        obj.children.forEach(item1 => {
          item1.renderKey = item1.__config__.renderKey
          item1.labelName = item1.__config__.label
          item1.checkList = []
        })
        this.formLists.push(obj)
      })
      this.formListsOld = JSON.parse(JSON.stringify(this.formLists))
      console.log(this.formDatas,"formDatas111",this.formLists,this.formListsOld);
    }
  },
  methods:{
    customRowClassName({ row, rowIndex }) {
      if (row.children) {
        return 'parent-row'; // 添加自定义样式类名
      }
      return ''; // 不需要额外样式时返回空字符串
    },
    // 展示绑定任务弹窗
    choseTaskId() {
      getConfig({taskId:this.forms.taskId,projectId:this.projectId}).then(res => {
        if(res.data?.setting) {
          console.log(JSON.parse(res.data.setting),'111');
          let list1 = JSON.parse(JSON.stringify(this.formListsOld));
          let list2 = JSON.parse(res.data.setting).struct;

          list1.forEach(item => {
            let matchingItem = list2.find(item1 => item.moduleId === item1.moduleId);
            if (matchingItem) {
              item.checkList = matchingItem.checkList || [];
              item.children.forEach(item2 => {
                let matchingChild = matchingItem.children.find(item3 => item2.__vModel__ === item3.__vModel__);
                if (matchingChild) {
                  item2.checkList = matchingChild.checkList || [];
                }
              });
            }
          });
          this.formLists = list1;
        } else {
          this.formLists = JSON.parse(JSON.stringify(this.formListsOld))
        }
      })
      this.formVisible = true
    },
    // 确认提交字段设置
    checkForm() {
      this.forms.viewable = []
      this.forms.editable = []
      console.log(this.formLists,'formListsformListsformLists');
      this.formLists.forEach(item => {
        item.children.forEach(item1 => {
          item1.checkList.forEach(item2 => {
            if(item2 == 2) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.viewable.push(param)
            }
            if(item2 == 3) {
              let param = {
                __vModel__:item1.__vModel__,
                moduleId:item.moduleId
              }
              this.forms.editable.push(param)
            }
          })
        })
      })
      this.forms.struct = this.formLists
      setConfig({taskId:this.forms.taskId,projectId:this.projectId,setting:JSON.stringify(this.forms)}).then(res => {
        this.formVisible = false
      })
      console.log(this.forms,'this.forms');
    },
    // 字段父节点设置
    changes(e,row,i) {
      if(row.children) {
        console.log(e,row);
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          if(item.renderKey == row.renderKey) {
            indexs = index
            items = JSON.parse(JSON.stringify(item))
          }
        })
        if(e) {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) == -1) {
              item1.checkList.push(i)
            }
          })
        } else {
          items.children.forEach(item1 => {
            if(item1.checkList.indexOf(i) != -1) {
              let index = item1.checkList.indexOf(i)
              console.log(i,'iiii');
              item1.checkList.splice(index,1)
            }
          })
        }
        this.$set(this.formLists,indexs,items)
      }
    },
    // 字段子节点设置
    handleCheckboxChange(checkedValues,row) {
      console.log(checkedValues,row,'checkedValues');
      if(!row.children) {
        row.checkList = checkedValues;
        let indexs
        let items
        this.formLists.forEach((item,index) => {
          item.children.forEach((item1,index1) => {
            if (item1.renderKey == row.renderKey) {
              console.log(item1,'item1item1');
              this.$set(item1,'checkList',row.checkList)
              indexs = index
              items = item
            }
          })
        })
        this.$set(this.formLists,indexs,items)
        this.formLists.forEach((item,index) => {
          let list1 = JSON.parse(JSON.stringify(item.checkList))
          let isTrue1 = true
          let isTrue2 = true
          item.children.forEach(item1 => {
            if(item1.checkList.indexOf(2) == -1) {
              isTrue1 = false
            }
            if(item1.checkList.indexOf(3) == -1) {
              isTrue2 = false
            }
          })
          console.log(isTrue1,'isTrue1');
          if(isTrue1) {
            if(list1.indexOf(2) == -1) {
              list1.push(2)
            }
          } else {
            if(list1.indexOf(2) != -1) {
              let index = list1.indexOf(2)
              list1.splice(index,1)
            }
          }
          if(isTrue2) {
            if(list1.indexOf(3) == -1) {
              list1.push(3)
            }
          } else {
            if(list1.indexOf(3) != -1) {
              let index = list1.indexOf(3)
              list1.splice(index,1)
            }
          }
          this.$set(this.formLists[index],'checkList',list1)
        })
      }
      console.log(this.formLists,'this.formLists');
      this.$forceUpdate();
    },

    computedExecutionListenerLength() {
      this.executionListenerLength = this.element.businessObject.extensionElements?.values
        ?.filter(item => item.$type === 'flowable:ExecutionListener').length ?? 0
    },
  }
}
</script>

<style lang="scss">
.edit-form-field {
  .el-table__body-wrapper {
    max-height: 520px;
    overflow-y: auto;
  }
}
</style>
