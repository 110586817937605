<template>
  <div class="app-container">
    <img src="https://oss.71go.com/bpm1207/userbg.png" style="width: 100%;" alt="">
    <el-row :gutter="20" style="margin-top: -80px;">
      <el-col :span="1"><div style="color: transparent;">11</div></el-col>
      <el-col :span="6" :xs="22">
        <div class="baseInfo" ref="userInfo">
          <!-- <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div> -->
          <div style="padding-top: 15px;">
            <div class="text-center" style="margin-bottom: 30px">
              <userAvatar />
            </div>
            <ul class="list-group list-group-striped" style="padding: 0 4px;">
              <li class="list-group-item">
                <div>
                  <svg-icon icon-class="user"/>
                  <span style="margin-left: 6px;">登录账号</span>
                </div>
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <div><svg-icon icon-class="phone" /><span style="margin-left: 6px;">手机号码</span></div>
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <li class="list-group-item">
                <div><svg-icon icon-class="email" /><span style="margin-left: 6px;">用户邮箱</span></div>
                <div class="pull-right">{{ user.email }}</div>
              </li>
              <li class="list-group-item">
                <div><svg-icon icon-class="tree" /><span style="margin-left: 6px;">所属部门</span></div>
                <div class="pull-right" v-if="user.dept">{{ user.dept.deptName }} / {{ postGroup }}</div>
              </li>
              <li class="list-group-item">
                <div><svg-icon icon-class="peoples" /><span style="margin-left: 6px;">所属角色</span></div>
                <div class="pull-right">{{ roleGroup }}</div>
              </li>
              <li class="list-group-item">
                <div><svg-icon icon-class="date" /><span style="margin-left: 6px;">创建日期</span></div>
                <div class="pull-right">{{ user.createTime }}</div>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="16" :xs="22">
        <div class="baseInfo" :style="{height: offsetHeight+'px'}">
          <!-- <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div> -->
          <el-tabs v-model="activeTab">
            <el-tab-pane label="基本资料" name="userinfo">
              <userInfo :user="user" />
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="resetPwd">
              <resetPwd />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="1"><div style="color: transparent;">22</div></el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import {getUserProfile} from "@/api/system/user";

export default {
  name: "Profile",
  components: { userAvatar, userInfo, resetPwd },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo",
      offsetHeight: 531
    };
  },
  created() {
    this.getUser();
    // this.changeBrowserZoom(1);
  },
  // beforeDestroy(){
  //   const wholePage = document.documentElement;
  //   let zoomLevel = ''
  //   // const devicePixelRatio = window.devicePixelRatio || 1;
  //   // if (devicePixelRatio == 1.5) {
  //   //   zoomLevel = 1;
  //   // }
  //   // if (devicePixelRatio == 1.25) {
  //   //   zoomLevel = 1;
  //   // }
  //   wholePage.style.zoom = zoomLevel;
  // },
  methods: {
    changeBrowserZoom(zoomLevel) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      console.log(devicePixelRatio);
      if (screenHeight > 800) {
        zoomLevel = 1;
      }
      if (devicePixelRatio == 1.5) {
        zoomLevel = 0.6;
      }
      if (devicePixelRatio == 1.25) {
        zoomLevel = 0.8;
      }
      const wholePage = document.documentElement;
      wholePage.style.zoom = zoomLevel;
    },
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data;
        this.roleGroup = response.roleGroup;
        this.postGroup = response.postGroup;
        let _this = this
        setTimeout(() => {
          _this.offsetHeight = _this.$refs.userInfo.offsetHeight;
        },100)
      });
    }
  }
};
</script>
<style scoped lang="scss">
.el-card{
  border-radius: 12px;
}
.list-group-item{
  padding: 20px 0;
  font-size: 16px;
  color: #999;
  border-top: none;
  border-color: #D7D7D7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .pull-right{
    color: #333;
  }
}
.list-group-item:last-child{
  border-bottom: none;
}
::v-deep .el-tabs__item{
  height: 60px;
  line-height: 60px;
}
.baseInfo{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #e6ebf5;
  background-color: #FFFFFF;
  color: #303133;
  padding: 15px 20px 20px 20px;
  height: 100%;
}
</style>
