<template>
  <div class="luckysheet_box">
    <div id="luckysheet" style="margin:0px;padding:0px;position:absolute;width:100%;height:100%;left: 0%;top: 0px;"></div>

  </div>

</template>

<script>
export default {
  props: {
    sheetParams: [],
  },
  data() {
    return {
      luckysheetOption: {},
      positionX: 0,
      positionY: 0,
    };
  },

  mounted() {
    console.log("this.sheetParams", this.sheetParams);
    this.initLuckysheet(this.sheetParams);
  },
  methods: {
    initLuckysheet(data) {
      var _this = this; //注意这里要重新指定下this对象。

      $(function () {
        //配置项
        let option = {
          container: "luckysheet", // 设定DOM容器的id
          title: "Luckysheet Demo", // 设定表格名称
          lang: "zh", // 设定表格语言
          autoWrap: true,
          plugins: [],
          data: [
            {
              name: "", //工作表名称
              color: "#b8db7c", //工作表(工作表名称底部边框线)颜色
              index: 0, //工作表索引(新增一个工作表时该值是一个随机字符串)
              status: 1, //激活状态
              order: 0, //工作表的下标
              hide: 0, //是否隐藏
              row: 50, //行数
              column: 20, //列数
              defaultRowHeight: 40, //自定义行高,单位px
              defaultColWidth: 150, //自定义列宽,单位px
              celldata: [], //初始化使用的单元格数据,r代表行，c代表列，v代表该单元格的值，最后展示的是value1，value2
              config: {
                merge: {}, //合并单元格
                rowlen: {}, //表格行高
                columnlen: {}, //表格列宽
                rowhidden: {}, //隐藏行
                colhidden: {}, //隐藏列
                borderInfo: [], //边框
                authority: {}, //工作表保护
              },

              scrollLeft: 0, //左右滚动条位置
              scrollTop: 0, //上下滚动条位置
              luckysheet_select_save: [], //选中的区域
              calcChain: [], //公式链
              isPivotTable: false, //是否数据透视表
              pivotTable: {}, //数据透视表设置
              filter_select: {}, //筛选范围
              filter: null, //筛选配置
              luckysheet_alternateformat_save: [], //交替颜色
              luckysheet_alternateformat_save_modelCustom: [], //自定义交替颜色
              luckysheet_conditionformat_save: {}, //条件格式
              frozen: {}, //冻结行列配置
              chart: [], //图表配置
              zoomRatio: 1, // 缩放比例
              image: [], //图片
              showGridLines: 1, //是否显示网格线
              dataVerification: {}, //数据验证配置
              hook: {},
            },
          ],
          showtoolbar: false,
          showtoolbarConfig: {
            undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
            paintFormat: true, //格式刷
            currencyFormat: true, //货币格式
            percentageFormat: true, //百分比格式
            numberDecrease: true, // '减少小数位数'
            numberIncrease: true, // '增加小数位数
            moreFormats: true, // '更多格式'
            font: true, // '字体'
            fontSize: true, // '字号大小'
            bold: true, // '粗体 (Ctrl+B)'
            italic: true, // '斜体 (Ctrl+I)'
            strikethrough: true, // '删除线 (Alt+Shift+5)'
            underline: true, // '下划线 (Alt+Shift+6)'
            textColor: true, // '文本颜色'
            fillColor: true, // '单元格颜色'
            border: true, // '边框'
            mergeCell: true, // '合并单元格'
            horizontalAlignMode: true, // '水平对齐方式'
            verticalAlignMode: true, // '垂直对齐方式'
            textWrapMode: true, // '换行方式'
            textRotateMode: true, // '文本旋转方式'
            image: true, // '插入图片'
            link: false, // '插入链接'
            chart: false, // '图表'（图标隐藏，但是如果配置了chart插件，右击仍然可以新建图表）
            postil: false, //'批注'
            pivotTable: false, //'数据透视表'
            function: false, // '公式'
            frozenMode: false, // '冻结方式'
            sortAndFilter: false, // '排序和筛选'
            conditionalFormat: false, // '条件格式'
            dataVerification: false, // '数据验证'
            splitColumn: false, // '分列'
            screenshot: false, // '截图'
            findAndReplace: false, // '查找替换'
            protection: false, // '工作表保护'
            print: false, // '打印'
          },
          showsheetbar: false, //是否显示底部sheet页按钮
          showsheetbarConfig: {
            add: false, //新增sheet
            menu: false, //sheet管理菜单
            sheet: false, //sheet页显示
          },
          showinfobar: false, //是否显示顶部信息栏
          showstatisticBar: false, //是否显示底部计数栏
          showstatisticBarConfig: {
            count: false, // 计数栏
            view: false, // 打印视图
            zoom: false, // 缩放
          },
          sheetFormulaBar: false, //是否显示公式栏
          allowCopy: false, //是否允许拷贝
          enableAddRow: false, //允许添加行
          hook: {
            cellDragStop: function (cell, postion, sheetFile, ctx, event) {
              // console.info(cell, postion, sheetFile, ctx, event);
            },
            rowTitleCellRenderBefore: function (rowNum, postion, ctx) {
              // console.log(rowNum);
            },
            rowTitleCellRenderAfter: function (rowNum, postion, ctx) {
              // console.log(ctx);
            },
            columnTitleCellRenderBefore: function (columnAbc, postion, ctx) {
              // console.log(columnAbc);
            },
            columnTitleCellRenderAfter: function (columnAbc, postion, ctx) {
              // console.log(postion);
            },
            cellRenderBefore: function (cell, postion, sheetFile, ctx) {
              // console.log(cell,postion,sheetFile,ctx);
            },
            cellRenderAfter: function (cell, postion, sheetFile, ctx) {
              // console.log(postion);
            },
            cellMousedownBefore: function (cell, postion, sheetFile, ctx) {},
            cellMousedown: function (cell, postion, sheetFile, ctx) {
              console.log(postion);
              _this.positionY = postion.c;
              _this.positionX = postion.r;
            },
            sheetMousemove: function (
              cell,
              postion,
              sheetFile,
              moveState,
              ctx
            ) {
              // console.log(cell,postion,sheetFile,moveState,ctx);
            },
            sheetMouseup: function (cell, postion, sheetFile, moveState, ctx) {
              // console.log(cell,postion,sheetFile,moveState,ctx);
            },
            cellAllRenderBefore: function (data, sheetFile, ctx) {
              // console.info(data,sheetFile,ctx)
            },
            updated: function (operate) {
              // console.info(operate)
            },
            cellUpdateBefore: function (r, c, value, isRefresh) {
              // console.info('cellUpdateBefore',r,c,value,isRefresh)
            },
            cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
              // console.info('cellUpdated',r,c,oldValue, newValue, isRefresh)
            },
            sheetActivate: function (index, isPivotInitial, isNewSheet) {
              // console.info(index, isPivotInitial, isNewSheet)
            },
            rangeSelect: function (index, sheet) {
              // console.info(index, sheet)
            },
            commentInsertBefore: function (r, c) {
              // console.info(r, c)
            },
            commentInsertAfter: function (r, c, cell) {
              // console.info(r, c, cell)
            },
            commentDeleteBefore: function (r, c, cell) {
              // console.info(r, c, cell)
            },
            commentDeleteAfter: function (r, c, cell) {
              // console.info(r, c, cell)
            },
            commentUpdateBefore: function (r, c, value) {
              // console.info(r, c, value)
            },
            commentUpdateAfter: function (r, c, oldCell, newCell) {
              // console.info(r, c, oldCell, newCell)
            },
            cellEditBefore: function (range) {
              // console.info(range)
            },
            workbookCreateAfter: function (json) {
              console.info("workbook create after!!!!====", json);
            },
            rangePasteBefore: function (range, data) {
              // console.info('rangePasteBefore',range,data)
              // return false; //Can intercept paste
            },
            rangeDeleteBefore: function (range, data) {
              console.dir(111);
            },
          },
        };
        if (data && data[0]) {
          // option.data[0].celldata = data[0].celldata;
          // option.data[0].config = data[0].config;
          option.data[0] = data[0];
          console.log(data, "data");
          console.log(option);
        }
        luckysheet.create(option);
      });

      // });
    },
    getExcelData() {
      var allSheetsData = luckysheet.getAllSheets(); // 获取所有工作表的数据
      this.$emit("saveData", allSheetsData);
    },
    reviewData() {
      var allSheetsData = luckysheet.getAllSheets(); // 获取所有工作表的数据
      this.$emit("review", allSheetsData);
    },
    setExcelData(row) {
      this.$nextTick(() => {
        console.log(this.positionX, this.positionY);
        luckysheet.setCellValue(this.positionX, this.positionY, row);
      });
    },
  },
};
</script>

<style>
.luckysheet-content {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 400px;
  min-width: 800px;
  overflow: auto;
}
.luckysheet_box {
  position: relative;
  height: 650px;
  width: 70%;
  margin-left: 20px;
}
</style>
