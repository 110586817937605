import request from '@/utils/request';

// 查询订单账单产品数据列表
export function listSub(query) {
	return request({
		url: '/order/orderBillSub/queryLaunchOrderProduct',
		method: 'get',
		params: query
	});
}

// 查询订单账单产品数据详细
export function getSub(id) {
	return request({
		url: '/order/orderBillSub/' + id,
		method: 'get'
	});
}

// 新增订单账单产品数据
export function addSub(data) {
	return request({
		url: '/order/orderBillSub',
		method: 'post',
		data: data
	});
}

// 修改订单账单产品数据
export function updateSub(data) {
	return request({
		url: '/order/orderBillSub',
		method: 'put',
		data: data
	});
}

// 删除订单账单产品数据
export function delSub(id) {
	return request({
		url: '/order/orderBillSub/' + id,
		method: 'delete'
	});
}

export function getCheckProject(query) {
	return request({
		url: '/order/orderBillSub/getCheckProject' ,
		method: 'post',
		data:query
	});
}

export function getOrderProdListByIds(id) {
	return request({
		url: '/order/orderBillSub/getOrderProdListByIds/' + id,
		method: 'get'
	});
}

export function getCheckProjectCache(query) {
	return request({
		url: '/order/orderBillSub/getCheckProjectCache/'+query ,
		method: 'get',
	});
}
export function batchSummitProject(data) {
	return request({
		url: '/bpm/bpmRecordProject/batchSummitProject' ,
		method: 'post',
		data:data
	});
}


export function removeLink(data) {
	return request({
		url: '/bpm/bpmRecordProject/removeLink' ,
		method: 'delete',
		params:data
	});
}


export function getLinkRecord(query) {
	return request({
		url: '/bpm/bpmRecordProject/getLinkRecord' ,
		method: 'get',
		params:query
	});
}
