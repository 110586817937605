<template>
  <div>
    <el-dialog title="设置信息组合" :visible.sync="fieldOpen" width="800px" append-to-body :close-on-click-modal="false">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">添加组合</el-button>
        </el-col>
      </el-row>
      <div style="width:100%;line-height: 46px;display: flex;align-items: center;justify-content: space-around;margin: 6px 0;background-color: #f8f8f9;border-bottom:1px solid #dfe6ec;">
        <div style="width: 100px;text-align: center;"></div>
        <div style="width: 480px;text-align: center;">组合名称</div>
        <div style="width: 180px;text-align: center;">操作</div>
      </div>
      <div>
        <draggable :list="fieldsData" :animation="340" group="selectItem" handle=".option-drag">
          <div style="width:100%;display: flex;align-items: center;justify-content: space-around;margin: 12px 0;" v-for="(item, index) in fieldsData" :key="index" class="select-item">
            <div class="select-line-icon option-drag" style="width: 100px;text-align: center;">
              <i style="font-size:20px;cursor:move;" class="el-icon-s-operation" />
            </div>
            <div style="width: 480px;text-align: center;">{{ getName(item.condition) }}</div>
            <div class="close-btn select-line-icon" style="width: 166px;display: flex;align-items: center;justify-content: space-around;">
              <el-button type="primary" size="small" @click="setCom(item,index)">关联组合</el-button>
              <el-button type="danger" size="mini" @click="delCom(index)">删除</el-button>
            </div>
          </div>
        </draggable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFields">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog custom-class="p_10" :title="isUpdate ? '修改信息组合':'添加信息组合'" :visible.sync="rulesOpens" width="600px" append-to-body :close-on-click-modal="false">
      <div class="rule_tips">此功能用于自动组合填写信息或判断字段的设置【设置请清晰字段的类型】</div>
      <el-radio-group v-model="isFixed" class="rule_select" @change="handleChange">
        <el-radio-button class="half_width" :label="false">表单字段</el-radio-button>
        <el-radio-button class="half_width" :label="true">固定字段</el-radio-button>
      </el-radio-group>

      <div class="rule_tips" v-if="isFixed ">
        固定值组合规则提示：结果=字段条件+固定值（例：字符拼接《12=1+2》；数值相加《3=1+2》;相减《-1=1+2》；日期《2024.09.12=2024.09.10+2》）固定值在后，字段条件在前
      </div>
      <div class="rule_tips" v-else>
        字段组合规则提示：结果=字段条件+固定值（例：字符拼接《12=1+2》；数值相加《3=1+2》;相减《-1=1+2》；
      </div>
      <div class="rule_con">
        <div class="title">组合形式</div>
        <el-radio-group v-model="rulesType" class="rule_select">
          <el-radio :label="1">字符拼接</el-radio>
          <el-radio :label="2">数值相加</el-radio>
          <el-radio :label="3">数值相减</el-radio>
          <el-radio :label="4">数值相乘</el-radio>
          <el-radio :label="5">数值相除</el-radio>
          <el-radio :label="6" v-if="isFixed">日期相加</el-radio>
          <el-radio :label="7" v-if="isFixed">日期相减</el-radio>
        </el-radio-group>

        <div class="rule_tips">数值的计算必须选择字段类型为“数字控件”，否则无法生效！<br />日期计算必须选择字段类型为“日期”（不可选择日期范围）！</div>
      </div>
      <div class="rule_con">
        <div class="title">字段条件设置
          <el-button class="tle_btn" :disabled="condition.length > 0 && isFixed" type="text" icon="el-icon-plus" plain size="mini" @click="conditionAdd">添加条件</el-button>
        </div>
        <draggable :list="condition" :animation="340" group="selectItem" handle=".option-drag">
          <div class="conbox" v-for="(item,index) in condition" :key="index">
            <div class="select-line-icon option-drag" style="width: 60px;text-align: center;">
              <i style="font-size:20px;cursor:move;" class="el-icon-s-operation" />
            </div>
            <el-select v-model="item.moduleId" @change="moduleChange($event,index)" filterable placeholder="请选择模块">
              <el-option v-for="item in formLists" :key="item.moduleId" :label="item.componentName" :value="item.moduleId">
              </el-option>
            </el-select>
            <el-select v-model="item.__vModel__" @change="fieldChange($event,index)" filterable placeholder="请选择字段">
              <el-option v-for="item in item.fieldList" :key="item.__vModel__" :label="item.labelName+'(' + item.originalName + ')'" :value="item.__vModel__">
              </el-option>
            </el-select>
            <el-button type="danger" size="mini" @click="delCon(index)">删除</el-button>
          </div>
        </draggable>
      </div>

      <div class="rule_con" v-if="isFixed ">
        <div class="title">固定值设置 <span class="rule_tips tle_btn">若涉及计算，请输入数字，否则无法生效</span></div>
        <el-input class="rule_input" placeholder="请输入固定值" v-model="fixedValue" clearable>
          <template slot="prepend">固定值</template>
        </el-input>
      </div>
      <div class="rule_con">
        <div class="title">结果映射<el-button class="tle_btn" type="text" icon="el-icon-plus" plain size="mini" @click="actionAdd">添加动作</el-button></div>
        <div class="conbox" v-for="(item,index) in activeData" :key="index">

          <el-select v-model="item.moduleId" @change="moduleChanges($event,index)" filterable placeholder="请选择模块">
            <el-option v-for="item in formLists" :key="item.moduleId" :label="item.componentName" :value="item.moduleId">
            </el-option>
          </el-select>
          <el-select v-model="item.__vModel__" @change="fieldChanges($event,index)" filterable placeholder="请选择字段">
            <el-option v-for="item in item.fieldList" :key="item.__vModel__" :label="item.labelName+'(' + item.originalName + ')'" :value="item.__vModel__">
            </el-option>
          </el-select>
          <el-button type="danger" size="mini" @click="delAct(index)">删除</el-button>

        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="rulesOpens = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { treeSelect } from "@/api/system/group";
import { addProject, updateProject } from "@/api/system/project";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: {
    formLists: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldsDatas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    fieldsDatas: {
      handler(val) {
        console.log(val, "valllll", this.fieldsData);
        if (val) this.fieldsData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
    },
    formLists: {
      handler(val) {
        this.formListAll = JSON.parse(JSON.stringify(val));
        console.log(val, "valllll", this.formListAll);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isUpdate: false,
      updateIndex: null,
      formListAll: [],
      fieldOpen: false,
      rulesOpens: false,
      options: [
        { value: 1, label: "显示" },
        { value: 0, label: "隐藏" },
        { value: 2, label: "等于" },
      ],
      fieldsData: [],
      condition: [],
      activeData: [],
      rulesType: 1,
      isFixed: false,
      fixedValue: "",
    };
  },
  created() {},
  methods: {
    handleChange(val) {
        if (val) {
          this.condition = [];
        }
    },
    getName(list) {
      let name = [];
      list.forEach((item) => {
        name.push(item.field);
      });
      return name.join(",");
    },
    customRowClassName({ row, rowIndex }) {
      if (row.children) {
        return "parent-row"; // 添加自定义样式类名
      }
      return ""; // 不需要额外样式时返回空字符串
    },
    handleAdd() {
      this.isUpdate = false;
      this.condition = [];
      this.activeData = [];
      this.rulesOpens = true;
    },
    setCom(row, i) {
      this.isUpdate = true;
      this.updateIndex = i;
      this.condition = row.condition;
      this.activeData = row.activeData;
      this.rulesType = row.rulesType;
      this.isFixed = row.isFixed;
      this.fixedValue = row.fixedValue;
      this.rulesOpens = true;
    },
    delCom(index) {
      this.fieldsData.splice(index, 1);
    },
    // 添加条件
    conditionAdd() {
      let param = {
        moduleId: null,
        fieldList: [],
        __vModel__: null,
      };
      this.condition.push(param);
    },
    // 模块切换
    moduleChange(value, index) {
      console.log(value, index);
      this.$set(this.condition[index], "__vModel__", null);
      this.$set(this.condition[index], "field", null);
      let items = this.formLists.find((item) => item.moduleId == value);
      this.$set(
        this.condition[index],
        "fieldList",
        items?.children?.map((item) => ({
          __vModel__: item.__vModel__,
          labelName: item.labelName,
          originalName: item.originalName,
          __slot__: item.__slot__,
        })) || []
      );
      console.log(this.condition, "1111");
    },
    // 字段切换
    fieldChange(value, index) {
      console.log(value, index, this.condition);
      let items = this.condition[index].fieldList.find(
        (item) => item.__vModel__ == value
      );
      this.$set(this.condition[index], "field", items.labelName);
    },
    valueChange(value) {
      console.log(value, "valuevalue");
    },
    delCon(index) {
      this.condition.splice(index, 1);
    },
    // 添加动作
    actionAdd() {
      let param = {
        moduleId: null,
        fieldList: [],
        __vModel__: null,
      };

      this.activeData.push(param);
    },
    moduleChanges(value, index) {
      console.log(value, index);
      this.$set(this.activeData[index], "__vModel__", null);
      this.$set(this.activeData[index], "field", null);
      let items = this.formLists.find((item) => item.moduleId == value);
      this.$set(
        this.activeData[index],
        "fieldList",
        items?.children?.map((item) => ({
          __vModel__: item.__vModel__,
          labelName: item.labelName,
          originalName: item.originalName,
          __slot__: item.__slot__,
        })) || []
      );
      console.log(this.activeData, "1111");
    },
    fieldChanges(value, index) {
      console.log(value, index, this.activeData);
      let items = this.activeData[index].fieldList.find(
        (item) => item.__vModel__ == value
      );
      this.$set(this.activeData[index], "field", items.labelName);
    },
    delAct(index) {
      this.activeData.splice(index, 1);
    },
    submitForm() {
      if (this.isUpdate) {
        this.$set(
          this.fieldsData[this.updateIndex],
          "condition",
          this.condition
        );
        this.$set(
          this.fieldsData[this.updateIndex],
          "activeData",
          this.activeData
        );
        this.$set(
          this.fieldsData[this.updateIndex],
          "rulesType",
          this.rulesType
        );
        this.$set(
          this.fieldsData[this.updateIndex],
          "fixedValue",
          this.fixedValue
        );
        this.$set(this.fieldsData[this.updateIndex], "isFixed", this.isFixed);
      } else {
        this.fieldsData.push({
          condition: this.condition,
          activeData: this.activeData,
          rulesType: this.rulesType,
          fixedValue: this.fixedValue,
          isFixed: this.isFixed,
        });
      }

      console.log(
        "this.condition, this.activeData, this.rulesType",
        this.fieldsData
      );
      this.rulesOpens = false;
    },
    submitFields() {
      this.fieldOpen = false;
      this.$emit("conFields", this.fieldsData);
    },
    cancel() {
      this.fieldOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.step_con {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  .el-form {
    margin: 0 auto;
  }
}

.form-group {
  margin-right: 20px;
  margin-bottom: 20px;
}

.conbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px;
  .el-select {
    width: 200px;
  }
  .el-input {
    width: 200px;
  }
}
.half_width {
  width: 50%;
  ::v-deep .el-radio-button__inner {
    width: 100%;
  }
}
.rule_select {
  padding: 16px 5px 6px;
  width: 100%;
  line-height: 30px;
}
.cb_tle {
  color: #000000; /* 一级标题颜色 */
  font-size: 17px; /* 一级标题字号 */
}
.rule_tips {
  color: #999999; /* 一级标题颜色 */
  font-size: 13px; /* 一级标题字号 */
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding: 0 10px;
}
.rule_con {
  background-color: #f7f7f7;
  padding: 10px;
  margin: 15px 0;
  border-radius: 10px;
  .rule_input {
    margin: 10px 0;
    width: 100%;
  }
  .title {
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    text-indent: 1px;
    text-align:left;
    margin-bottom: 0;
    .tle_btn {
      margin-left: 10px;
    }
  }
}
 .p_10 {
    padding: 10px;
  }
</style>
