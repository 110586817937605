<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/utils/validate'

export default {
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.to)
    },
    type() {
      if (this.isExternal) {
        return 'a'
      }
      return 'router-link'
    }
  },
  methods: {
    linkProps(to) {
      if (this.isExternal) {
        let regex = /{{(.*?)}}/g;
        // 存储提取到的参数名
        let placeholders = [];
        let match;
        // 使用正则表达式的 exec 方法来找到所有匹配项
        while ((match = regex.exec(to)) !== null) {
          // match[1] 匹配的第一个捕获组，即参数名（比如 userName 或 nickName）
          placeholders.push(match[1]);
        }
        let replacedParams = {};
        let userInfo = this.$store.state.user.userInfo
        // 遍历参数名数组，从 userInfo 中取出对应的值，存储到 replacedParams 中
        placeholders.forEach(param => {
            if (userInfo[param] !== undefined) {
              replacedParams[param] = userInfo[param];
            }
        });
        // 将替换后的参数填充回到原始 URL 的 {{}} 中
        to = to.replace(regex, (match, paramName) => {
            return replacedParams[paramName] || match; // 如果找不到对应值，则保持原样
        });
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        to: to
      }
    }
  }
}
</script>
