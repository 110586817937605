// 表单属性【右面板】
export const formConf = {
	formRef: 'elForm',
	formModel: 'formData',
	size: 'medium',
	labelPosition: 'right',
	labelWidth: 160,
	formRules: 'rules',
	gutter: 15,
	disabled: false,
	span: 12,
	formBtns: true
};

// 输入型组件 【左面板】
export const inputComponents = [
	{
		// 组件的自定义配置
		__config__: {
			label: '单行文本',
			labelWidth: null,
			showLabel: true,
			changeTag: true,
			tag: 'el-input',
			tagIcon: 'input',
			defaultValue: undefined,
			required: true,
			layout: 'colFormItem',
			span: 12,
			document: 'https://element.eleme.cn/#/zh-CN/component/input',
			// 正则校验规则
			regList: [],
			isAuto:false, // 商标logo自动生成组件 true:是 false：否
		},
		// 组件的插槽属性
		__slot__: {
			prepend: '',
			append: ''
		},
		// 其余的为可直接写在组件标签上的属性
		placeholder: '请输入',
		style: { width: '100%' },
		clearable: true,
		'prefix-icon': '',
		'suffix-icon': '',
		maxlength: null,
		'show-word-limit': false,
		readonly: false,
		disabled: false,
		
	},
  {
		// 组件的自定义配置
		__config__: {
			label: '数字控件',
			labelWidth: null,
			showLabel: true,
			changeTag: true,
			tag: 'el-input',
			tagIcon: 'number',
			defaultValue: undefined,
			required: true,
			layout: 'colFormItem',
			span: 24,
			document: 'https://element.eleme.cn/#/zh-CN/component/input',
			// 正则校验规则
			regList: [],
			isAuto:false, // 商标logo自动生成组件 true:是 false：否
		},
    type: 'number',
    conversion:false,
		// 组件的插槽属性
		__slot__: {
			prepend: '',
			append: ''
		},
		// 其余的为可直接写在组件标签上的属性
		placeholder: '请输入',
		style: { width: '100%' },
		clearable: true,
		'prefix-icon': '',
		'suffix-icon': '',
		maxlength: null,
		'show-word-limit': false,
		readonly: false,
		disabled: false,
		
	},
	{
		// 组件的自定义配置
		__config__: {
			label: '数据引入',
			labelWidth: null,
			showLabel: true,
			changeTag: true,
			tag: 'el-input',
			tagIcon: 'input1',
			defaultValue: undefined,
			required: true,
			layout: 'colFormItem',
			span: 12,
			document: 'https://element.eleme.cn/#/zh-CN/component/input',
			// 正则校验规则
			regList: [],
			identifyList: [],
			isFillin:true,
			identifyType: null
		},
		// 组件的插槽属性
		__slot__: {
			prepend: '',
			append: ''
		},
		// 其余的为可直接写在组件标签上的属性
		placeholder: '请输入',
		style: { width: '100%' },
		clearable: true,
		'prefix-icon': '',
		'suffix-icon': '',
		maxlength: null,
		'show-word-limit': false,
		readonly: true,
		disabled: false,
		
	},
  {
		__config__: {
			label: '下拉选择',
			showLabel: true,
			labelWidth: null,
			tag: 'el-select',
			tagIcon: 'select',
			layout: 'colFormItem',
			span: 12,
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/select'
		},
		__slot__: {
			options: [
				{
					label: '选项一',
					value: 0
				},
				{
					label: '选项二',
					value: 1
				}
			]
		},
		placeholder: '请选择',
		style: { width: '100%' },
		clearable: true,
		disabled: false,
		filterable: false,
		multiple: false
	},
  {
		__config__: {
			label: '单选框组',
			labelWidth: null,
			showLabel: true,
			tag: 'el-radio-group',
			tagIcon: 'radio',
			changeTag: true,
			defaultValue: undefined,
			layout: 'colFormItem',
			span: 12,
			optionType: 'default',
			regList: [],
			required: true,
			border: false,
			document: 'https://element.eleme.cn/#/zh-CN/component/radio',
			isAuto:false, // 商标logo自动生成组件 true:是 false：否
		},
		__slot__: {
			options: [
				{
					label: '选项一',
					value: 0
				},
				{
					label: '选项二',
					value: 1
				}
			]
		},
		style: {},
		size: 'medium',
		disabled: false
	},
	{
		__config__: {
			label: '多选框组',
			tag: 'el-checkbox-group',
			tagIcon: 'checkbox',
			defaultValue: [],
			span: 12,
			showLabel: true,
			labelWidth: null,
			layout: 'colFormItem',
			optionType: 'default',
			required: true,
			regList: [],
			changeTag: true,
			border: false,
			document: 'https://element.eleme.cn/#/zh-CN/component/checkbox'
		},
		__slot__: {
			options: [
				{
					label: '选项一',
					value: 0
				},
				{
					label: '选项二',
					value: 1
				}
			]
		},
		style: {},
		size: 'medium',
		min: null,
		max: null,
		disabled: false
	},
	{
		__config__: {
			label: '多行文本',
			labelWidth: null,
			showLabel: true,
			tag: 'el-input',
			tagIcon: 'textarea',
			defaultValue: undefined,
			required: true,
			layout: 'colFormItem',
			span: 12,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/input'
		},
		type: 'textarea',
		placeholder: '请输入',
		autosize: {
			minRows: 4,
			maxRows: 4
		},
		style: { width: '100%' },
		maxlength: null,
		'show-word-limit': false,
		readonly: false,
		disabled: false
	},
	{
		__config__: {
			label: '上传',
			tag: 'el-upload',
			tagIcon: 'upload',
			layout: 'colFormItem',
			defaultValue: '',
			showLabel: true,
			labelWidth: null,
			required: true,
			span: 12,
			showTip: false,
			buttonText: '点击上传',
			regList: [],
			changeTag: true,
			fileSize: 2,
			sizeUnit: 'MB',
			document: 'https://element.eleme.cn/#/zh-CN/component/upload',
			isAuto:false, // 商标logo自动生成组件 true:是 false：否
      limit:5
		},
		__slot__: {
			'list-type': true
		},
		action: '/api/file/upload',
    actionType: '1',
		disabled: false,
		accept: '',
		name: 'file',
		'auto-upload': true,
		'list-type': 'text',
		multiple: false
	},
	{
		__config__: {
			label: '识别上传',
			tag: 'el-upload',
			tagIcon: 'upload2',
			layout: 'colFormItem',
			defaultValue: '',
			showLabel: true,
			labelWidth: null,
			required: true,
			span: 12,
			showTip: false,
			buttonText: '点击上传',
			regList: [],
			identifyList: [],
			changeTag: true,
			fileSize: 2,
			sizeUnit: 'MB',
			document: 'https://element.eleme.cn/#/zh-CN/component/upload',
			isIdentify: true,
			identifyType: 7,
		  limit:1

		},
		__slot__: {
			'list-type': true
		},
		action: '/api/file/uploadOrc?type=7',
		actionType: '1',
		disabled: false,
		accept: '1',
		name: 'file',
		'auto-upload': true,
		'list-type': 'text',
		multiple: false
	},
	{
		__config__: {
			infoName:"",
			infoId:null,
			label: '信息表上传',
			tag: 'el-upload',
			tagIcon: 'upload3',
			layout: 'colFormItem',
			defaultValue: '',
			showLabel: true,
			labelWidth: null,
			required: true,
			span: 12,
			showTip: false,
			isInfo:true,
			electronSign:false,
			InfoList:[],
			buttonText: '点击上传',
			regList: [],
			changeTag: true,
			fileSize: 2,
			sizeUnit: 'MB',
			document: 'https://element.eleme.cn/#/zh-CN/component/upload',
      limit:5
		},
		__slot__: {
			'list-type': true
		},
		action: '/api/file/upload',
		actionType: '1',
		disabled: false,
		accept: '',
		name: 'file',
		'auto-upload': true,
		'list-type': 'text',
		multiple: false
	},
  {
		__config__: {
			label: '时间选择',
			tag: 'el-time-picker',
			tagIcon: 'time',
			defaultValue: null,
			span: 12,
			showLabel: true,
			layout: 'colFormItem',
			labelWidth: null,
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/time-picker'
		},
		placeholder: '请选择',
		style: { width: '100%' },
		disabled: false,
		clearable: true,
		'picker-options': {
			selectableRange: '00:00:00-23:59:59'
		},
		format: 'HH:mm:ss',
		'value-format': 'HH:mm:ss'
	},
  {
		__config__: {
			label: '日期选择',
			tag: 'el-date-picker',
			tagIcon: 'date',
			defaultValue: null,
			showLabel: true,
			labelWidth: null,
			span: 12,
			layout: 'colFormItem',
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/date-picker'
		},
		placeholder: '请选择',
		type: 'date',
		style: { width: '100%' },
		disabled: false,
		clearable: true,
		format: 'yyyy-MM-dd',
		'value-format': 'yyyy-MM-dd',
		readonly: false
	},
	{
		// 组件的自定义配置
		__config__: {
			label: '自动编号',
			labelWidth: null,
			showLabel: true,
			changeTag: true,
			tag: 'el-input',
			tagIcon: 'input2',
			defaultValue: undefined,
			required: true,
			layout: 'colFormItem',
			span: 12,
			document: 'https://element.eleme.cn/#/zh-CN/component/input',
			// // 正则校验规则
			regList: [],
			// 自动生成规则
			autoList: [
				{
					pattern: '',
					label: '编号',
					message: "",
					__vmodel__:''
				},
				{
					pattern: 'yyyyMMdd',
					label: '创建时间',
					message: "",
					__vmodel__:''
				},
			],
			isAuto:true
		},
		// 组件的插槽属性
		__slot__: {
			prepend: '',
			append: '生成'
		},
		// 其余的为可直接写在组件标签上的属性
		placeholder: '请输入',
		style: { width: '100%' },
		clearable: true,
		'prefix-icon': '',
		'suffix-icon': '',
		maxlength: null,
		'show-word-limit': false,
		readonly: false,
		disabled: false,
		
	},
  {
		__config__: {
			label: '下载',
			tag: 'el-button',
			tagIcon: 'download',
			layout: 'colFormItem',
			showLabel: true,
			labelWidth: null,
			span: 12,
			showTip: false,
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/upload',
		},
		__slot__: {
      default: '点击下载',
		},
    type:'primary',
		downAction: '',
    downMethod: '',
		downParameter: '',
    icon: 'el-icon-download',
    round: false,
    size: 'medium',
    plain: false,
    circle: false,
    disabled: false
	},
];

// 选择型组件 【左面板】
export const selectComponents = [
	{
		__config__: {
			label: '密码',
			showLabel: true,
			labelWidth: null,
			changeTag: true,
			tag: 'el-input',
			tagIcon: 'password',
			defaultValue: undefined,
			layout: 'colFormItem',
			span: 12,
			required: true,
			regList: [],
			document: 'https://element.eleme.cn/#/zh-CN/component/input'
		},
		__slot__: {
			prepend: '',
			append: ''
		},
		placeholder: '请输入',
		'show-password': true,
		style: { width: '100%' },
		clearable: true,
		'prefix-icon': '',
		'suffix-icon': '',
		maxlength: null,
		'show-word-limit': false,
		readonly: false,
		disabled: false
	},
	{
		__config__: {
			label: '计数器',
			showLabel: true,
			changeTag: true,
			labelWidth: null,
			tag: 'el-input-number',
			tagIcon: 'number1',
			defaultValue: undefined,
			span: 12,
			layout: 'colFormItem',
			required: true,
			regList: [],
			document: 'https://element.eleme.cn/#/zh-CN/component/input-number'
		},
		placeholder: '',
		min: undefined,
		max: undefined,
		step: 1,
		'step-strictly': false,
		precision: undefined,
		'controls-position': '',
		disabled: false
	},
	{
		__config__: {
			label: '编辑器',
			showLabel: true,
			changeTag: true,
			labelWidth: null,
			tag: 'tinymce',
			tagIcon: 'rich-text',
			defaultValue: null,
			span: 12,
			layout: 'colFormItem',
			required: true,
			regList: [],
			document: 'http://tinymce.ax-z.cn'
		},
		placeholder: '请输入',
		height: 300, // 编辑器高度
		branding: false // 隐藏右下角品牌烙印
	},
	{
		__config__: {
			label: '级联选择',
			url: 'https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/cascaderList',
			method: 'get',
			dataPath: 'list',
			dataConsumer: 'options',
			showLabel: true,
			labelWidth: null,
			tag: 'el-cascader',
			tagIcon: 'cascader',
			layout: 'colFormItem',
			defaultValue: [],
			dataType: 'dynamic',
			span: 12,
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/cascader'
		},
		options: [
			{
				id: 1,
				value: 1,
				label: '选项1',
				children: [
					{
						id: 2,
						value: 2,
						label: '选项1-1'
					}
				]
			}
		],
		placeholder: '请选择',
		style: { width: '100%' },
		props: {
			props: {
				multiple: false,
				label: 'label',
				value: 'value',
				children: 'children'
			}
		},
		'show-all-levels': true,
		disabled: false,
		clearable: true,
		filterable: false,
		separator: '/',
		limit:2,
	},
	
	{
		__config__: {
			label: '开关',
			tag: 'el-switch',
			tagIcon: 'switch',
			defaultValue: false,
			span: 12,
			showLabel: true,
			labelWidth: null,
			layout: 'colFormItem',
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/switch'
		},
		style: {},
		disabled: false,
		'active-text': '',
		'inactive-text': '',
		'active-color': null,
		'inactive-color': null,
		'active-value': true,
		'inactive-value': false
	},
	{
		__config__: {
			label: '滑块',
			tag: 'el-slider',
			tagIcon: 'slider',
			defaultValue: null,
			span: 12,
			showLabel: true,
			layout: 'colFormItem',
			labelWidth: null,
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/slider'
		},
		disabled: false,
		min: 0,
		max: 100,
		step: 1,
		'show-stops': false,
		range: false
	},
	{
		__config__: {
			label: '时间范围',
			tag: 'el-time-picker',
			tagIcon: 'time-range',
			span: 12,
			showLabel: true,
			labelWidth: null,
			layout: 'colFormItem',
			defaultValue: null,
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/time-picker'
		},
		style: { width: '100%' },
		disabled: false,
		clearable: true,
		'is-range': true,
		'range-separator': '至',
		'start-placeholder': '开始时间',
		'end-placeholder': '结束时间',
		format: 'HH:mm:ss',
		'value-format': 'HH:mm:ss'
	},
	{
		__config__: {
			label: '日期范围',
			tag: 'el-date-picker',
			tagIcon: 'date-range',
			defaultValue: null,
			span: 12,
			showLabel: true,
			labelWidth: null,
			required: true,
			layout: 'colFormItem',
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/date-picker'
		},
		style: { width: '100%' },
		type: 'daterange',
		'range-separator': '至',
		'start-placeholder': '开始日期',
		'end-placeholder': '结束日期',
		disabled: false,
		clearable: true,
		format: 'yyyy-MM-dd',
		'value-format': 'yyyy-MM-dd',
		readonly: false
	},
	{
		__config__: {
			label: '评分',
			tag: 'el-rate',
			tagIcon: 'rate',
			defaultValue: 0,
			span: 12,
			showLabel: true,
			labelWidth: null,
			layout: 'colFormItem',
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/rate'
		},
		style: {},
		max: 5,
		'allow-half': false,
		'show-text': false,
		'show-score': false,
		disabled: false
	},
	{
		__config__: {
			label: '颜色选择',
			tag: 'el-color-picker',
			tagIcon: 'color',
			span: 12,
			defaultValue: null,
			showLabel: true,
			labelWidth: null,
			layout: 'colFormItem',
			required: true,
			regList: [],
			changeTag: true,
			document: 'https://element.eleme.cn/#/zh-CN/component/color-picker'
		},
		'show-alpha': false,
		'color-format': '',
		disabled: false,
		size: 'medium'
	}
];

import request from '@/utils/request';
let list = [
	{
		__config__: {
			layout: 'tsSubform',
			tagIcon: 'row',
			tag: 'ts-sub-form',
			label: '子表单',
			showLabel: false,
			defaultValue: [],
			children: [
				{
					__config__: {
						label: '姓名',
						labelWidth: null,
						showLabel: true,
						changeTag: true,
						tag: 'el-input',
						tagIcon: 'input',
						required: true,
						layout: 'colFormItem',
						span: 12,
						document: 'https://element.eleme.cn/#/zh-CN/component/input',
						regList: [],
						renderKey: '1031700557043823'
					},
					__slot__: {
						prepend: '',
						append: ''
					},
					placeholder: '请输入姓名',
					style: {
						width: '100%'
					},
					clearable: true,
					'prefix-icon': '',
					'suffix-icon': '',
					maxlength: null,
					'show-word-limit': false,
					readonly: false,
					disabled: false,
					__vModel__: '15535a17a25e4ef68dd9b1d413a131db'
				}
			]
		},
		addButton: true, // 默认可添加
		deleteButton: true, // 默认可删除
		canEdit: true // 默认可编辑，为false时添加和删除都不可用，并且表单为disabled状态
	}
	// {
	//   __config__: {
	//       layout: 'rowFormItem',
	//       tagIcon: 'row',
	//       label: '法人组件111',
	//       labelName:'法人信息',
	//       layoutTree: true,
	//       document: 'https://element.eleme.cn/#/zh-CN/component/layout#row-attributes',
	//       children: [
	//         {
	//             __config__: {
	//                 label: "姓名",
	//                 labelWidth: null,
	//                 showLabel: true,
	//                 changeTag: true,
	//                 tag: "el-input",
	//                 tagIcon: "input",
	//                 required: true,
	//                 layout: "colFormItem",
	//                 span: 12,
	//                 document: "https://element.eleme.cn/#/zh-CN/component/input",
	//                 regList: [],
	//                 formId: 103,
	//                 renderKey: "1031700557043823"
	//             },
	//             __slot__: {
	//                 prepend: "",
	//                 append: ""
	//             },
	//             placeholder: "请输入姓名",
	//             style: {
	//                 width: "100%"
	//             },
	//             "clearable": true,
	//             "prefix-icon": "",
	//             "suffix-icon": "",
	//             "maxlength": null,
	//             "show-word-limit": false,
	//             "readonly": false,
	//             "disabled": false,
	//             __vModel__: "15535a17a25e4ef68dd9b1d413a131db"
	//         },
	//       ]
	//   },
	//   type: 'default',
	//   justify: 'start',
	//   align: 'top',

	// },
];
// request({
// 	url: '/bpm/form/module/page',
// 	method: 'get'
// }).then((res) => {
// 	console.log(res, 'resres');
// 	if (res.code == 200 && res.data.length) {
// 		res.data.forEach((item) => {
// 			if (item.rowForm || item.subForm) {
// 				// console.log(JSON.parse(item.rowForm));
//         let param = {
//           __config__: {
// 						layout: item.moduleLayout ? item.moduleLayout : "rowFormItem",
// 						tagIcon: 'row',
// 						label: item.name,
// 						labelName: item.name,
// 						labelId: item.id,
// 						layoutTree: true,
// 						moduleId: item.moduleId,
// 						document: 'https://element.eleme.cn/#/zh-CN/component/layout#row-attributes',
//             children: item.moduleLayout == "rowFormItem" ? JSON.parse(item.rowForm).fields : JSON.parse(item.subForm).fields
// 					},
// 					type: 'default',
// 					justify: 'start',
// 					align: 'top'
//         }
// 				list.push(param);
// 			} else {
// 				list.push({
// 					__config__: {
// 						layout: item.moduleLayout ? item.moduleLayout : "rowFormItem",
// 						tagIcon: 'row',
// 						label: item.name,
// 						labelName: item.name,
// 						labelId: item.id,
// 						layoutTree: true,
// 						moduleId: item.moduleId,
// 						document: 'https://element.eleme.cn/#/zh-CN/component/layout#row-attributes'
// 					},
// 					type: 'default',
// 					justify: 'start',
// 					align: 'top'
// 				});
// 			}
// 		});
// 	}
// });
console.log(list, 'listlistlist');
export const layoutComponents = list;
// 布局型组件 【左面板】
// export const layoutComponents = [
//     {
//         __config__: {
//             layout: 'rowFormItem',
//             tagIcon: 'row',
//             label: '行容器',
//             labelName:null,
//             layoutTree: true,
//             document: 'https://element.eleme.cn/#/zh-CN/component/layout#row-attributes'
//         },
//         type: 'default',
//         justify: 'start',
//         align: 'top'
//     },
//     {
//       __config__: {
//           layout: 'rowFormItem',
//           tagIcon: 'row',
//           label: '法人组件',
//           labelName:'法人信息',
//           layoutTree: true,
//           document: 'https://element.eleme.cn/#/zh-CN/component/layout#row-attributes'
//       },
//       type: 'default',
//       justify: 'start',
//       align: 'top'
//     },
//     {
//         __config__: {
//             label: '按钮',
//             showLabel: true,
//             changeTag: true,
//             labelWidth: null,
//             tag: 'el-button',
//             tagIcon: 'button',
//             span: 12,
//             layout: 'colFormItem',
//             document: 'https://element.eleme.cn/#/zh-CN/component/button'
//         },
//         __slot__: {
//             default: '主要按钮'
//         },
//         type: 'primary',
//         icon: 'el-icon-search',
//         round: false,
//         size: 'medium',
//         plain: false,
//         circle: false,
//         disabled: false
//     },
//     // {
//     //   __config__: {
//     //     layout: 'colFormItem',
//     //     tagIcon: 'table',
//     //     tag: 'el-table',
//     //     document: 'https://element.eleme.cn/#/zh-CN/component/table',
//     //     span: 12,
//     //     formId: 101,
//     //     renderKey: 1595761764203,
//     //     componentName: 'row101',
//     //     showLabel: true,
//     //     changeTag: true,
//     //     labelWidth: null,
//     //     label: '表格[开发中]',
//     //     dataType: 'dynamic',
//     //     method: 'get',
//     //     dataPath: 'list',
//     //     dataConsumer: 'data',
//     //     url: 'https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/tableData',
//     //     children: [{
//     //       __config__: {
//     //         layout: 'raw',
//     //         tag: 'el-table-column',
//     //         renderKey: 15957617660153
//     //       },
//     //       prop: 'date',
//     //       label: '日期'
//     //     }, {
//     //       __config__: {
//     //         layout: 'raw',
//     //         tag: 'el-table-column',
//     //         renderKey: 15957617660152
//     //       },
//     //       prop: 'address',
//     //       label: '地址'
//     //     }, {
//     //       __config__: {
//     //         layout: 'raw',
//     //         tag: 'el-table-column',
//     //         renderKey: 15957617660151
//     //       },
//     //       prop: 'name',
//     //       label: '名称'
//     //     }, {
//     //       __config__: {
//     //         layout: 'raw',
//     //         tag: 'el-table-column',
//     //         renderKey: 1595774496335,
//     //         children: [
//     //           {
//     //             __config__: {
//     //               label: '按钮',
//     //               tag: 'el-button',
//     //               tagIcon: 'button',
//     //               layout: 'raw',
//     //               renderKey: 1595779809901
//     //             },
//     //             __slot__: {
//     //               default: '主要按钮'
//     //             },
//     //             type: 'primary',
//     //             icon: 'el-icon-search',
//     //             round: false,
//     //             size: 'medium'
//     //           }
//     //         ]
//     //       },
//     //       label: '操作'
//     //     }]
//     //   },
//     //   data: [],
//     //   directives: [{
//     //     name: 'loading',
//     //     value: true
//     //   }],
//     //   border: true,
//     //   type: 'default',
//     //   justify: 'start',
//     //   align: 'top'
//     // }
// ]
