var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "containers main-box" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "success", size: "small", icon: "el-icon-zoom-in" },
          on: {
            click: function ($event) {
              return _vm.zoomViewport(true)
            },
          },
        },
        [_vm._v("放大")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "warning", size: "small", icon: "el-icon-zoom-out" },
          on: {
            click: function ($event) {
              return _vm.zoomViewport(false)
            },
          },
        },
        [_vm._v("缩小")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "info", size: "small", icon: "el-icon-rank" },
          on: { click: _vm.fitViewport },
        },
        [_vm._v("适中")]
      ),
      _c("div", { ref: "flowCanvas", staticClass: "canvas" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }