<template>
  <div class="container">
    <div class="left-board">
      <div class="logo-wrapper">
        <div class="logo">
          <img :src="logo" alt="logo"> Form Generator
        </div>
      </div>
      <el-scrollbar class="left-scrollbar">
        <div class="components-list">
          <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
            <div class="components-title">
              <svg-icon icon-class="component" />
              {{ item.title }}
            </div>
            <el-select v-model="queryParams1.type" placeholder="请选择" @change="typeChange">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <div class="serach-box" style="display:flex;align-items: center;justify-content: space-between;    margin: 10px 0;" v-if="item.isSer">
              <el-input v-model="queryParams1.keyword" @input="changeInput" placeholder="请输入模块名称" style="flex:1;margin-right: 10px;" clearable @keyup.enter.native="handleQuery1" />
              <el-button style="width:26px;height: 30px;padding: 7px;" type="primary" icon="el-icon-search" size="mini" @click="handleQuery1"></el-button>
              <el-button style="width:26px;height: 30px;padding: 7px;" icon="el-icon-refresh" size="mini" @click="resetQuery1"></el-button>
            </div>
            <draggable v-loading="loadingModule" :clone="cloneComponent" :group="{ name: 'componentsGroup', pull: 'clone', put: false }" :list="item.list" :sort="false" class="components-draggable components-draggables" draggable=".components-item" @end="onEnd">
              <div v-for="(element, index) in item.list" :key="index" class="components-item" @click="addComponent(element)">
                <div class="components-body">
                  <div class="components-box">
                    <svg-icon :icon-class="element.__config__.tagIcon" />
                    <div style="flex:1;" v-html="getModule(element.__config__.componentName || element.__config__.labelName)"></div>
                    <i class="el-icon-view" @click.stop="showModules(element)"></i>
                  </div>
                  <div class="field-box" v-if="isSerach && getField(element.__config__.children).length && queryParams1.keyword && (queryParams1.type === 1 ? false : true)">
                    <div style="line-height: 22px;">包含字段：</div>
                    <div class="field-lis">
                      <div v-for="(item1, index1) in getField(element.__config__.children,element.__config__.isMore)" :key="index1" v-html="item1"></div>
                      <template v-if="getField(element.__config__.children).length > 3">
                        <div style="color:#409eff;padding:8px;" @click.stop="showMore(element)" v-if="!element.__config__.isMore">更多<i class="el-icon-arrow-down"></i></div>
                        <div style="color:#409eff;padding:8px;" @click.stop="showMore(element)" v-if="element.__config__.isMore">收起<i class="el-icon-arrow-up"></i></div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <div class="center-board">
      <div class="action-bar">
        <el-button icon="el-icon-view" type="text" @click="runView">
          预览
        </el-button>
        <el-button icon="el-icon-document-add" type="text" @click="refForm">
          引用表单
        </el-button>
        <el-button icon="el-icon-edit" type="text" @click="setRules">
          设置业务规则
        </el-button>
        <el-button icon="el-icon-edit" type="text" @click="setFields">
          设置信息组合
        </el-button>
        <el-button icon="el-icon-edit" type="text" @click="setTitles">
          设置标题
        </el-button>
        <!-- <el-button icon="el-icon-plus" type="text" @click="handleForm">
          保存
        </el-button> -->
        <!-- <el-button icon="el-icon-view" type="text" @click="showJson">
          查看json
        </el-button> -->
        <!--        <el-button icon="el-icon-download" type="text" @click="download">
                  导出vue文件
                </el-button>
                <el-button class="copy-btn-main" icon="el-icon-document-copy" type="text" @click="copy">
                  复制代码
                </el-button>-->
        <!-- <el-button class="delete-btn" icon="el-icon-delete" type="text" @click="empty">
          清空
        </el-button> -->
      </div>
      <el-scrollbar class="center-scrollbar">
        <el-row class="center-board-row" :gutter="formConf.gutter">
          <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled" :label-width="formConf.labelWidth + 'px'">
            <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup">
              <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey" :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId" :form-conf="formConf"
                @activeItem="activeFormItem" @copyItem="drawingItemCopy" @updateItem="drawingItemUpdate" @deleteItem="drawingItemDelete" />
            </draggable>
            <div v-show="!drawingList.length" class="empty-info">
              从左侧拖入或点选组件进行表单设计
            </div>
          </el-form>
        </el-row>
      </el-scrollbar>
    </div>

    <right-panel :active-data="activeData" :form-conf="formConf" :drawingList="drawingList" :fieldComList="fieldComList" :show-field="!!drawingList.length" @tag-change="tagChange" @fetch-data="fetchData" :areaGroupId="areaGroupId" />

    <form-drawer :visible.sync="drawerVisible" :form-data="formData" size="100%" :generate-conf="generateConf" />
    <json-drawer size="60%" :visible.sync="jsonDrawerVisible" :json-str="JSON.stringify(formData)" @refresh="refreshJson" />
    <code-type-dialog :visible.sync="dialogVisible" title="选择生成类型" :show-file-name="showFileName" @confirm="generate" />
    <input id="copyNode" type="hidden">
    <!--表单配置详情-->
    <el-dialog :title="formTitle" :visible.sync="formOpen" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="表单名称" prop="formName">
          <el-input v-model="form.formName" placeholder="请输入表单名称" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!--项目标题配置详情-->
    <el-dialog title="项目标题配置" :visible.sync="titleOpen" width="600px" style="top:16%" append-to-body>
      <div class="t-box">
        <div>项目标题等于：<el-button type="primary" size="mini" @click="addCon()">添加</el-button></div>
        <div>
          <draggable :list="titleList" :animation="340" group="selectItem" handle=".option-drag">
            <div style="width:500px;display: flex;align-items: center;justify-content: space-around;margin: 6px 0;" v-for="(item, index) in titleList" :key="index" class="select-item">
              <div class="select-line-icon option-drag">
                <i style="font-size:20px;cursor:move;" class="el-icon-s-operation" />
              </div>
              <el-select style="width:200px;" v-model="item.moduleId" @change="moduleChange($event,index)" filterable placeholder="请选择模块">
                <el-option v-for="item in formListsOld" :key="item.moduleId" :label="item.componentName" :value="item.moduleId">
                </el-option>
              </el-select>
              <el-select style="width:200px;" v-model="item.__vModel__" @change="fieldChange($event,index)" filterable placeholder="请选择字段">
                <el-option v-for="item in item.fieldList" :key="item.__vModel__" :label="item.labelName" :value="item.__vModel__">
                </el-option>
              </el-select>
              <div class="close-btn select-line-icon" @click="titleList.splice(index, 1)">
                <i style="font-size:20px;color:#f56c6c;cursor:pointer;" class="el-icon-remove-outline" />
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setTitle">确 定</el-button>
        <el-button @click="titleOpen = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 模块数据更新至项目 -->
    <el-dialog title="选择需要同步至当前模块的字段" :visible.sync="formListOpen" width="800px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
      <el-table :data="formList" @selection-change="formListChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="字段名称" align="center" prop="formName">
          <template slot-scope="scope">
            {{ scope.row.__config__.label }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="创建时间" align="center" prop="createTime" /> -->
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFormSele">确 定</el-button>
        <el-button @click="seleCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!--表单配置详情-->
    <el-dialog title="引用历史项目表单" :visible.sync="projectOpen" width="1000px" append-to-body>
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
        <el-form-item label="项目编号" prop="projectCode">
          <el-input v-model="queryParams.projectCode" placeholder="请输入项目编号" clearable @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="queryParams.projectName" placeholder="请输入项目名称" clearable @keyup.enter.native="handleQuery" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table class="pro-table" v-loading="loading" :data="projectList" highlight-current-row @current-change="handleSelectionChange">
        <el-table-column width="55" align="center">
          <template slot-scope="scope">
            <el-radio v-model="radioSelected" :label="scope.row.id">{{''}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column align="center" label="项目编号" prop="projectCode" />
        <el-table-column align="center" label="项目名称" prop="projectName" />
        <el-table-column align="center" label="领域名称" prop="areaName" />
        <el-table-column align="center" label="领域小组名称" prop="areaGroupName" />
        <el-table-column align="center" label="项目备注" prop="remark" />
        <el-table-column align="center" label="项目版本" prop="version" />
        <el-table-column align="center" label="禁用状态" prop="status">
          <template #default="{ row }">
            <dict-tag :options="dict.type.sys_normal_disable" :value="row.status" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建者" prop="createBy" />
        <el-table-column align="center" label="更新者" prop="updateBy" />
      </el-table>

      <pagination v-show="total>0" :total="total" :page.sync="queryParams.current" :limit.sync="queryParams.size" @pagination="getList" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPro">确 定</el-button>
        <el-button @click="cancelPro">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="表单预览" :visible.sync="viewOpen" width="1000px" append-to-body>
      <div class="form-boxs">
        <el-tabs tab-position="left" class="form-tabs" v-model="fromTab" @tab-click="handleTabClick">
          <el-tab-pane :label="item.__config__.labelName" :name="item.__config__.moduleId" v-for="(item,index) in formConf.fields" :key="index"></el-tab-pane>
        </el-tabs>
        <div class="form-right">
          <parser v-if="isRequestCompleted" :form-conf="formConf" :recordStatus="0" ref="parser" @getData="getData" :key="parserKey" />
        </div>
      </div>
    </el-dialog>

    <set-rules ref="setRules" :formLists="formLists" :rulesDatas="rulesDatas" @conRules="conRules"></set-rules>
    <set-fields ref="setFields" :formLists="formLists" :fieldsDatas="fieldsDatas" @conFields="conFields"></set-fields>

    <!-- 编辑模块表单 -->
    <el-dialog title="查看模块表单" class="formOpenBox" :visible.sync="moduleFormOpen" width="80%" append-to-body >
      <div class="module-box">
        <build-index v-if="!isloading" ref="build" :showlr="true" :moduleLayout="moduleForm.__config__.allowMultiple ? 'tsSubform' : 'rowFormItem'" :formcont="moduleFormcont"></build-index>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitBuild">添加至表单</el-button>
        <el-button @click="formOpenCanlc">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {debounce} from 'throttle-debounce'
import {saveAs} from 'file-saver'
import ClipboardJS from 'clipboard'
import render from '@/components/render/render'
import FormDrawer from './FormDrawer'
import JsonDrawer from './JsonDrawer'
import RightPanel from './RightPanel'
import {formConf, inputComponents,  selectComponents} from '@/utils/generator/config'
import {beautifierConf, deepClone, isObjectObject, titleCase} from '@/utils/index'
import {cssStyle, makeUpHtml, vueScript, vueTemplate} from '@/utils/generator/html'
import {makeUpJs} from '@/utils/generator/js'
import {makeUpCss} from '@/utils/generator/css'
import drawingDefault from '@/utils/generator/drawingDefault'
import logo from '@/assets/logo/logo.png'
import CodeTypeDialog from './CodeTypeDialog'
import DraggableItem from './DraggableItem'
import {getDrawingList, getFormConf, getIdGlobal, saveDrawingList, saveIdGlobal} from '@/utils/db'
import loadBeautifier from '@/utils/loadBeautifier'
import {addForm, getForm, updateForm} from "@/api/flowable/form";
import { bpmProjectForm, listProject, getForms } from "@/api/system/project";
import {updateTemplate,getModules,getTemplate,searchModuleField} from "@/api/project/fieldCom";
import Parser from "@/components/parser/Parser";
import { number } from 'echarts';
import SetRules from './setRules.vue';
import SetFields from './setFields.vue';
import buildIndex from "@/views/tool/build/moduleIndex";
let beautifier
const emptyActiveData = {style: {}, autosize: {}}
let oldActiveId
let tempActiveData
const idGlobal = getIdGlobal()

export default {
  dicts: ["sys_normal_disable"],
  components: {
    draggable,
    render,
    FormDrawer,
    JsonDrawer,
    RightPanel,
    CodeTypeDialog,
    DraggableItem,
    SetRules,
    SetFields,
    Parser,
    buildIndex
  },
  props: {
    formId: {
      type: Number,
      default() {
        return null;
      },
    },
    projectId: {
      type: String,
      default() {
        return null;
      },
    },
    areaGroupId: {
      type: String,
      default() {
        return null;
      },
    },
    formName: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      logo,
      idGlobal,
      formConf,
      formConfOld:null,
      inputComponents,
      selectComponents,
      layoutComponents: [],
      fieldComList: [],
      labelWidth: 100,
      drawingList: drawingDefault,
      isFixed: false,
      drawingData: {},
      activeId: drawingDefault[0].formId,
      drawerVisible: false,
      formData: {},
      dialogVisible: false,
      jsonDrawerVisible: false,
      generateConf: null,
      showFileName: false,
      activeData: drawingDefault[0],
      saveIdGlobalDebounce: debounce(340, saveIdGlobal),
      leftComponents: [
        // {
        //   title: "输入型组件",
        //   list: inputComponents,
        // },
        {
          title: "布局型模块",
          isSer: true,
          list: [],
        },
        // {
        //   title: "其他组件",
        //   list: selectComponents,
        // },
      ],
      loadingModule: false,
      formOpen: false,
      formTitle: "",
      // 表单参数
      form: {
        formId: null,
        formName: null,
        formContent: null,
        remark: null,
      },
      // 表单校验
      rules: {},
      // projectId:'',

      rulesDatas: [],
      fieldsDatas:[],
      formLists: [],
      formListsOld: [],

      titleOpen: false,
      titleList: [
        {
          moduleId: null,
          __vModel__: null,
          fieldList: [],
        },
      ],

      formListOpen: false,
      objectId: null,
      formList: [],
      formListSelect: [],
      queryParams1: {
        keyword: null,
        type:0
      },
      options1:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'模块'
        },
        {
          value:2,
          label:'字段'
        },
      ],
      isSerach:false,

      // 查询参数
      queryParams: {
        current: 1,
        size: 10,
        projectCode: null,
        projectName: null,
      },
      total: 0,
      loading: true,
      projectOpen: false,
      projectList: [],
      radioSelected: null, // 单选框传值

      viewOpen:false,
      isRequestCompleted:false,
      parserKey:0,
      fromTab: null,

      moduleFormOpen:false, // 查看模块表单相关
      isloading:true,
      moduleForm: null,
      moduleFormcont:null,
    }
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line func-names
    "activeData.__config__.label": function (val, oldVal) {
      if (
        this.activeData.placeholder === undefined ||
        !this.activeData.__config__.tag ||
        oldActiveId !== this.activeId
      ) {
        return;
      }
      this.activeData.placeholder =
        this.activeData.placeholder.replace(oldVal, "") + val;
    },
    activeId: {
      handler(val) {
        oldActiveId = val;
      },
      immediate: true,
    },
    layoutComponents: {
      handler(val) {
        // oldActiveId = val
      },
      immediate: true,
    },
    drawingList: {
      handler(val) {
        if (val.length === 0) this.idGlobal = 1000;
      },
      deep: true,
    },
    idGlobal: {
      handler(val) {
        this.saveIdGlobalDebounce(val);
      },
      immediate: true,
    },
  },
  created() {
    // this.projectId = this.$route.query.projectId
  },
  mounted() {
    const that = this;
    that.drawingList = drawingDefault;

    this.activeFormItem(that.drawingList[0]);
    that.drawingList = [];
    let formIds = this.formId || this.$route.query.formId;
    if (formIds) {
      getForm(formIds).then((res) => {
        that.formConf = JSON.parse(res.data.formContent);
        that.formConfOld = JSON.parse(res.data.formContent);
        if (that.formConf.fields.length) {
          let formIdCon = 1
          let formIdCon1 = 10000
          that.formConf.fields.forEach(item => {
            item.__config__.formId = formIdCon1++
            item.__config__.children.forEach(item1 => {
              item1.__config__.formId = formIdCon++
            })
          })
          that.drawingList = that.formConf.fields;
        } else {
          this.isFixed = true;
        }
        that.form = res.data;
      });
    } else {
      // 初始化表单设计器
      // that.formConf = {};
      this.isFixed = true;
    }
    // 获取模块数据
    this.getCom();
    console.log(that.drawingList,that.formConf, "that.drawingList");
    loadBeautifier((btf) => {
      beautifier = btf;
    });
    const clipboard = new ClipboardJS("#copyNode", {
      text: (trigger) => {
        const codeStr = this.generateCode();
        this.$notify({
          title: '成功',
          message: '代码已复制到剪切板，可粘贴。',
          type: 'success'
        })
        return codeStr
      }
    })
    clipboard.on('error', e => {
      this.$message.error('代码复制失败')
    })
  },
  methods: {
    // 表单预览
    runView() {
      if(!this.subCheck()) {
        return false
      }
      this.parserKey++
      this.isRequestCompleted = false
      // 设置关联字段规则
      this.$nextTick(() => {
        this.formConf.fields.forEach((item, index) => {
          item.__config__.showModule = true
          item.__config__.editModule = true
          item.__config__.children.forEach((items) => {
            items.__config__.beforeValue =
              items.__config__.defaultValue || "";
            this.$set(items, "isShow", true);
            this.$set(items, "moduleIds", item.__config__.moduleId);
            if (this.formConf?.rulesForm) {
              this.formConf.rulesForm.forEach((item1, index1) => {
                item1.activeData.forEach((item2) => {
                  if (
                    item2.moduleId == item.__config__.moduleId &&
                    item2.__vModel__ == items.__vModel__
                  ) {
                    this.$set(items, "isShow", item2.actionValue === 0);
                  }
                });
                item1.condition.forEach((item3) => {
                  if (
                    item3.moduleId == item.__config__.moduleId &&
                    item3.__vModel__ == items.__vModel__
                  ) {
                    if (
                      item3.conValue.includes(items.__config__.defaultValue)
                    ) {
                      this.$set(items, "isShow", true);
                    }
                  }
                });
              });
            }
          });
        });
      });
      this.viewOpen = true
      let _this = this;
      setTimeout(function () {
        _this.isRequestCompleted = true;
      }, 500);
    },
    handleTabClick(tab) {
      let activeTab = tab.name;
      const elements = document.getElementsByClassName(activeTab);
      if (elements.length > 0) {
        elements[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    /** 接收子组件传的值 */
    getData(data) {
      if (data) {
        const variables = [];
        data.fields.forEach((item) => {
          let variableData = {};
          variableData.label = item.__config__.label;
          // 表单值为多个选项时
          if (item.__config__.defaultValue instanceof Array) {
            const array = [];
            item.__config__.defaultValue.forEach((val) => {
              array.push(val);
            });
            variableData.val = array;
          } else {
            variableData.val = item.__config__.defaultValue;
          }
          variables.push(variableData);
        });
        this.variables = variables;
      }
    },
    // 引用历史项目表单
    async refForm() {
      await this.getList();
      this.radioSelected = null;
      this.projectOpen = true;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.current = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    getList() {
      this.loading = true;
      listProject(this.queryParams).then((response) => {
        this.projectList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    handleSelectionChange(selection) {
      if (selection) {
        this.radioSelected = selection.id;
      }
    },
    submitPro() {
      if (!this.radioSelected) {
        this.$modal.msgError("请先选择需要引用的项目！");
        return false;
      }
      getForms({ projectId: this.radioSelected }).then((res) => {
        if (res.code == 200 && res.data) {
          this.formConf = JSON.parse(res.data.formContent);
          if (this.formConf.fields.length) {
            this.drawingList = this.formConf.fields;
          } else {
            this.isFixed = true;
          }
          this.form = res.data;
        }
        this.projectOpen = false;
      });
    },
    cancelPro() {
      this.projectOpen = false;
      this.radioSelected = null;
    },
    // 模块搜索
    handleQuery1() {
      this.getList1();
    },
    /** 查询模块列表列表 */
    async getList1() {
      this.loadingModule = true
      let res = await searchModuleField(this.queryParams1).then();
      if (res.code == 200 && res.data.length) {
        this.leftComponents[0].list = this.comList(res);
        this.isSerach = true;
        console.log(this.leftComponents[0].list,'this.leftComponents[0].list1111');
      } else {
        this.leftComponents[0].list = []
      }
      this.loadingModule = false
    },
    // 查询搜索匹配模块
    getModule(name) {
      if(this.queryParams1.keyword && this.isSerach) {
        let regex = new RegExp(`[${this.queryParams1.keyword}]`, 'g');
        // 使用 `replace` 方法来替换匹配的字符
        let highlightedName = name.replace(regex, match => {
            return `<span style="color:red;">${match}</span>`;
        });
        // console.log(highlightedName,'highlightedName2highlightedName2highlightedName2');
        return highlightedName
      } else {
        return name
      }
    },
    // 获取查询匹配字段
    getField(children,t) {
      let regex = new RegExp(`[${this.queryParams1.keyword}]`, 'g');
      let list = children.filter(item =>
        regex.test(item.__config__.label) || regex.test(item.originalName)
      )
      let nameList = []
      list.forEach(item => {
        nameList.push(this.getModule(item.originalName) + (item.__config__.label != item.originalName ? '('+ this.getModule(item.__config__.label) +')' : ''))
      })
      // console.log(children,list,nameList);
      if(t === undefined) {
        return nameList
      } else {
        if(t) {
          return nameList
        } else {
          return nameList.slice(0, 2)
        }
      }
    },
    showMore(element) {
      this.$set(element.__config__,"isMore",!element.__config__.isMore)
    },
    // 预览模块字段
    async showModules(element) {
      this.moduleFormcont = null
      this.moduleForm = element
      let param = {
        objectId:element.__config__.moduleId,
        objectType:'module',
        layout: !element.__config__.allowMultiple ? 'rowFormItem' : 'tsSubform',
      }
      let res = await getTemplate(param).then(res)
      if(res.data) {
        this.moduleFormcont = {
          formRef: "elForm",
          formModel: "formData",
          size: "medium",
          labelPosition: "right",
          labelWidth: 120,
          formRules: "rules",
          gutter: 15,
          disabled: false,
          span: 12,
          formBtns: true,
          fields:[]
        }
        this.$set(this.moduleFormcont,'fields',[JSON.parse(res.data.content)])
      } else {
        this.moduleFormcont = {
          formRef: "elForm",
          formModel: "formData",
          size: "medium",
          labelPosition: "right",
          labelWidth: 120,
          formRules: "rules",
          gutter: 15,
          disabled: false,
          span: 12,
          formBtns: true,
          fields:[
            {
              __config__: {
                layout: 'rowFormItem',
                tagIcon: "row",
                componentName: element.__config__.componentName,
                labelName: element.__config__.labelName,
                labelId: 13,
                layoutTree: true,
                moduleId: element.__config__.moduleId,
                span: 24,
                document: "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
                children: [],
                allowMultiple: element.__config__.allowMultiple
              },
              type: "default",
              justify: "start",
              align: "top"
            }
          ]
        }
      }
      this.isloading = false
      this.moduleFormOpen = true
      console.log(this.moduleFormcont);
    },
    // 模块添加至表单
    submitBuild() {
      this.addComponent(this.moduleForm)
    },
    formOpenCanlc() {
      this.moduleFormOpen = false
      this.isloading = true
      this.moduleFormcont = null
      this.moduleForm = null
    },
    async changeInput(e) {
      if(!e) {
        await this.getList1()
        this.isSerach = false;
      }
    },
    typeChange(e) {
      this.getList1();
    },
    /** 重置按钮操作 */
    async resetQuery1() {
      this.queryParams1.keyword = null;
      this.queryParams1.type = 0;
      await this.getList1();
      this.isSerach = false;
    },
    comList(res) {
      let list = [];
      res.data = res.data.filter((item) => item.name != "项目信息");
      // 获取模块列表信息
      res.data.forEach((item) => {
        if (item.moduleLayout == "rowFormItem") {
          if (item.rowForm && JSON.parse(item.rowForm).align) {
            let param = JSON.parse(item.rowForm)
            this.$set(param.__config__,'isMore',false)
            list.push(param);
          } else {
            let param = {
              __config__: {
                isMore: false,
                layout: "rowFormItem",
                tagIcon: "row",
                label: item.name,
                labelName: item.name,
                labelId: item.id,
                layoutTree: true,
                moduleId: item.moduleId,
                document:
                  "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
                span: item.rowForm ? JSON.parse(item.rowForm).span : 24,
                children: item.rowForm ? JSON.parse(item.rowForm).fields : [],
              },
              type: "default",
              justify: "start",
              align: "top",
            };
            list.push(param);
          }
        }
        if (item.moduleLayout == "tsSubform") {
          if (item.rowForm && JSON.parse(item.rowForm).align) {
            let param = JSON.parse(item.rowForm)
            this.$set(param.__config__,'isMore',false)
            list.push(param);
          } else {
            let param = {
              __config__: {
                allowMultiple:true,
                isMore: false,
                layout: "rowFormItem",
                tagIcon: "row",
                label: item.name,
                labelName: item.name,
                labelId: item.id,
                layoutTree: true,
                moduleId: item.moduleId,
                document:
                  "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
                span: item.rowForm ? JSON.parse(item.rowForm).span : 24,
                children: item.rowForm ? JSON.parse(item.rowForm).fields : [],
              },
              type: "default",
              justify: "start",
              align: "top",
            };
            list.push(param);
          }
        }
      });
      return list;
    },
    async getCom() {
      // 获取模块数据
      // let list = [
      //   {
      //     __config__: {
      //       layout: 'tsSubform',
      //       tagIcon: 'row',
      //       tag: 'ts-sub-form',
      //       label: '子表单',
      //       showLabel: false,
      //       defaultValue: [],
      //       children: []
      //     },
      //     addButton: true, // 默认可添加
      //     deleteButton: true, // 默认可删除
      //     canEdit: true // 默认可编辑，为false时添加和删除都不可用，并且表单为disabled状态
      //   }
      // ]
      this.loadingModule = true
      let res = await searchModuleField().then();
      if (res.code == 200 && res.data.length) {
        this.fieldComList = JSON.parse(JSON.stringify(res.data));
        if (this.isFixed) {
          // 获取固定模块和字段
          let params = this.fieldComList.filter(
            (item) => item.name == "项目信息"
          )[0];
          if (params?.moduleId) {
            let fields = JSON.parse(params.rowForm).__config__.children;
            fields?.forEach((item) => {
              item.isFixedFields = true;
            });
            this.drawingList = [
              {
                __config__: {
                  layout: params.moduleLayout
                    ? params.moduleLayout
                    : "rowFormItem",
                  tagIcon: "row",
                  componentName: params.name,
                  labelName: params.name,
                  labelId: params.id,
                  layoutTree: true,
                  moduleId: params.moduleId,
                  span: 24,
                  document:
                    "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes",
                  children: fields,
                },
                isFixedFields: true,
                type: "default",
                justify: "start",
                align: "top",
              },
            ];
          }
          this.idGlobal = 1100;
        }
        this.layoutComponents = this.comList(res);
        this.leftComponents[0].list = this.comList(res);
        this.loadingModule = false
        console.log(this.leftComponents[0].list,'this.leftComponents[0].list');
      }
    },
    // 设置项目标题
    setTitles() {
      if(!this.subCheck()) {
        return false
      }
      if (this.formConf.titleList) {
        this.titleList = this.formConf.titleList;
      }
      this.formListsOld = [];
      this.drawingList.forEach((item) => {
        let obj = item.__config__;
        obj.children.forEach((item1) => {
          item1.renderKey = item1.__config__.renderKey;
          item1.labelName = item1.__config__.label;
        });
        if (obj.layout != "tsSubform") {
          this.formListsOld.push(obj);
        }
      });
      this.titleOpen = true;
    },
    setTitle() {
      this.formConf.titleList = this.titleList;
      this.titleOpen = false;
    },
    addCon() {
      this.titleList.push({
        moduleId: null,
        __vModel__: null,
        fieldList: [],
      });
    },
    delCon(index) {
      this.titleList.splice(index, 1);
    },
    // 模块切换
    moduleChange(value, index) {
      this.$set(this.titleList[index], '__vModel__',null)
      let items = this.formListsOld.find((item) => item.moduleId == value);
      this.$set(this.titleList[index], 'fieldList', items?.children?.map(item => ({ __vModel__: item.__vModel__, labelName: item.labelName })) || []);
      console.log(this.titleList,'this.titleList');
    },
    // 字段切换
    fieldChange(value, index) {
      // let items = this.titleList[index].fieldList.filter(item => item.__vModel__ == value)
      // this.$set(this.titleList[index],'conValue',null)
      // this.$set(this.titleList[index],'field',items[0].labelName)
      // this.$set(this.titleList[index],'options',items[0].__slot__.options || [])
    },
    // 设置字段之间相互赋值
    setFields() {
      if(!this.subCheck()) {
        return false
      }
      this.getFormList()
      if (this.formConf.fieldsForm) {
        this.fieldsDatas = this.formConf.fieldsForm;
      }
      this.$refs.setFields.fieldOpen = true;
    },
    conFields(value) {
      this.formConf.fieldsForm = value;
    },
    getFormList() {
      this.formLists = [];
      this.drawingList.forEach((item) => {
        let obj = item.__config__;
        if (
          obj.layout == "tsSubform" &&
          obj.defaultValue.length &&
          obj.defaultValue
        ) {
          obj.children.forEach((item2) => {
            item2.__config__.defaultValue = [];
          });
          obj.defaultValue.forEach((item1, index1) => {
            let list = [];
            obj.children.forEach((item2) => {
              let indexs = Object.keys(item1).indexOf(item2.__vModel__);
              if (indexs != -1) {
                item2.__config__.defaultValue.push(
                  item1[Object.keys(item1)[indexs]]
                );
              }
            });
          });
        }
        obj.children.forEach((item1) => {
          item1.renderKey = item1.__config__.renderKey;
          item1.labelName = item1.__config__.label;
        });
        this.formLists.push(obj);
      });
    },
    // 设置业务规则
    setRules() {
      if(!this.subCheck()) {
        return false
      }
      this.getFormList()
      if (this.formConf.rulesForm) {
        this.rulesDatas = this.formConf.rulesForm;
      }
      this.$refs.setRules.rulesOpen = true;
    },
    conRules(value) {
      this.formConf.rulesForm = value;
    },
    setObjectValueReduce(obj, strKeys, data) {
      const arr = strKeys.split(".");
      arr.reduce((pre, item, i) => {
        if (arr.length === i + 1) {
          pre[item] = data;
        } else if (!isObjectObject(pre[item])) {
          pre[item] = {};
        }
        return pre[item];
      }, obj);
    },
    setRespData(component, resp) {
      const { dataPath, renderKey, dataConsumer } = component.__config__;
      if (!dataPath || !dataConsumer) return;
      const respData = dataPath
        .split(".")
        .reduce((pre, item) => pre[item], resp);

      // 将请求回来的数据，赋值到指定属性。
      // 以el-tabel为例，根据Element文档，应该将数据赋值给el-tabel的data属性，所以dataConsumer的值应为'data';
      // 此时赋值代码可写成 component[dataConsumer] = respData；
      // 但为支持更深层级的赋值（如：dataConsumer的值为'options.data'）,使用setObjectValueReduce
      this.setObjectValueReduce(component, dataConsumer, respData);
      const i = this.drawingList.findIndex(
        (item) => item.__config__.renderKey === renderKey
      );
      if (i > -1) this.$set(this.drawingList, i, component);
    },
    fetchData(component) {
      if(component) {
        const { dataType, method, url } = component.__config__;
        if (dataType === "dynamic" && method && url) {
          // this.setLoading(component, true);
          // this.$axios({
          //   method,
          //   url,
          // }).then((resp) => {
          //   this.setLoading(component, false);
          //   this.setRespData(component, resp.data);
          // });
          console.log(component);
        }
      }
    },
    setLoading(component, val) {
      const { directives } = component;
      if (Array.isArray(directives)) {
        const t = directives.find((d) => d.name === "loading");
        if (t) t.value = val;
      }
    },
    activeFormItem(currentItem) {
      if(currentItem) {
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      }
    },
    onEnd(obj) {
      this.drawingList = this.drawingList.filter(item => item.__config__)
      this.formConf.fields = this.drawingList
      this.drawingList.forEach((item) => {
        if (item.__config__.rulesForm && item.__config__.rulesForm.length) {
          item.__config__.rulesForm.forEach((item1) => {
            if (this.formConf.rulesForm) {
              if (
                !this.formConf.rulesForm.some((item2) => item2.id === item1.id)
              ) {
                this.formConf.rulesForm.push(item1);
              }
            } else {
              this.formConf.rulesForm = [];
              this.formConf.rulesForm.push(item1);
            }
          });
        }
      });
      if (obj.from !== obj.to && tempActiveData) {
        this.fetchData(tempActiveData);
        this.activeData = tempActiveData;
        this.activeId = this.idGlobal;
      }
    },
    addComponent(item) {
      console.log(item,'11111111111');
      const clone1 = deepClone(item);
      let isLike = false
      if(this.formConf?.fields?.length) {
        let index =this.formConf.fields.findIndex(item => item.__config__.moduleId == clone1.__config__.moduleId)
        if(index != -1) {
          isLike = true
        }
      }
      if(isLike) {
        this.$modal.msgError("请勿重复添加相同的模块！");
        return false
      }
      this.$confirm('是否确认将此模块加入表单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const clone = this.cloneComponent(item);
        this.fetchData(clone);
        this.drawingList.push(clone);
        this.drawingList = this.drawingList.filter(item => item.__config__)
        this.formConf.fields = this.drawingList
        this.drawingList.forEach((item) => {
          if (item.__config__.rulesForm && item.__config__.rulesForm.length) {
            item.__config__.rulesForm.forEach((item1) => {
              if (this.formConf.rulesForm) {
                if (
                  !this.formConf.rulesForm.some((item2) => item2.id === item1.id)
                ) {
                  this.formConf.rulesForm.push(item1);
                }
              } else {
                this.formConf.rulesForm = [];
                this.formConf.rulesForm.push(item1);
              }
            });
          }
        });
        this.activeFormItem(clone);

        this.formOpenCanlc()
      }).catch(() => {
           
      });
    },
    cloneComponent(origin) {
      console.log('444444444444');
      const clone = deepClone(origin);
      if(this.formConf?.fields?.length) {
        let index =this.formConf.fields.findIndex(item => item.__config__.moduleId == clone.__config__.moduleId)
        if(index != -1) {
          console.log('22222222222');
          this.$modal.msgError("请勿重复添加相同的模块！");
          return false
        }
      }
      console.log('3333333333');
      const config = clone.__config__;
      // config.span = this.formConf.span // 生成代码时，会根据span做精简判断
      this.createIdAndKey(clone);
      clone.placeholder !== undefined && (clone.placeholder += config.label);
      tempActiveData = clone;
      return tempActiveData;
    },
    createIdAndKey(item) {
      const config = item.__config__;
      config.formId = ++this.idGlobal;
      config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
      if (config.layout === "colFormItem") {
        if (!item.__vModel__) {
          item.__vModel__ = "";
          item.__config__.label = "请绑定字段";
        }
      } else if (
        config.layout === "rowFormItem" ||
        config.layout === "tsSubform"
      ) {
        config.componentName = config.labelName
          ? config.labelName
          : `row${this.idGlobal}`;
        !Array.isArray(config.children) && (config.children = []);
        delete config.label; // rowFormItem无需配置label属性
      }
      if (Array.isArray(config.children)) {
        config.children = config.children.map((childItem) =>
          this.createIdAndKey(childItem)
        );
      }
      return item;
    },
    AssembleFormData() {
      this.formData = {
        ...this.formConf,
      };
      this.formData.fields = deepClone(this.drawingList);
    },
    generate(data) {
      const func = this[`exec${titleCase(this.operationType)}`];
      this.generateConf = data;
      func && func(data);
    },
    execRun(data) {
      this.AssembleFormData();
      this.drawerVisible = true;
    },
    execDownload(data) {
      const codeStr = this.generateCode();
      const blob = new Blob([codeStr], { type: "text/plain;charset=utf-8" });
      saveAs(blob, data.fileName);
    },
    execCopy(data) {
      document.getElementById("copyNode").click();
    },
    empty() {
      this.$confirm("确定要清空所有组件吗？", "提示", { type: "warning" }).then(
        () => {
          this.drawingList = [];
          this.idGlobal = 1000;
        }
      );
    },
    // 模块数据更新
    drawingItemUpdate(item, list) {
      console.log(item,'itemitem');
      this.objectId = item.__config__.moduleId;
      getTemplate({
        objectId: item.__config__.moduleId,
        objectType: "module",
        layout: item.__config__.allowMultiple ? 'tsSubform' : 'rowFormItem',
      }).then((res) => {
        if (res.code == 200) {
          this.formListOpen = true;
          this.formList = JSON.parse(
            res.data.content
          ).__config__.children.filter(
            (item2) =>
              !item.__config__.children.some(
                (item1) => item1.__vModel__ === item2.__vModel__
              )
          );
        }
      });
    },
    // 模块数据更新至项目
    formListChange(selection) {
      this.formListSelect = selection;
    },
    submitFormSele() {
      if (!this.formListSelect.length) {
        this.$modal.msgError("请先选择需要同步更新的字段！");
        return false;
      }
      this.drawingList.forEach((item) => {
        if (item.__config__.moduleId == this.objectId) {
          this.formListSelect.forEach(item1 => {
            item1.moduleIds = item.__config__.moduleId
            item1.isShow = true
            item1.renderKey = item1.__config__.renderKey
            item1.labelName = item1.__config__.label
            item1.__config__.formId = ++this.idGlobal
          })
          item.__config__.children = item.__config__.children.concat(
            this.formListSelect
          );
        }
      });
      this.seleCancel();
    },
    seleCancel() {
      this.formListOpen = false;
      this.objectId = null;
      this.formList = [];
      this.formListSelect = [];
    },
    drawingItemCopy(item, list) {
      let clone = deepClone(item);
      clone = this.createIdAndKey(clone);
      list.push(clone);
      this.activeFormItem(clone);
    },
    drawingItemDelete(currentItem, index, list) {
      console.log(currentItem, index, list,'index, list');
      if(currentItem.__vModel__) {
        let moduleId = this.drawingList.find(item => JSON.stringify(item.__config__.children) == JSON.stringify(list)).__config__.moduleId
        console.log(moduleId,'moduleIdmoduleIdmoduleIdmoduleId'); 
        // 标题字段删除校验
        let isDel = this.formConf?.titleList?.some(item =>
          item.__vModel__ === currentItem.__vModel__ && item.moduleId === moduleId
        );
        if(isDel) {
          this.$confirm('该字段删除后会影响到项目标题的生成, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            list.splice(index, 1);
          }).catch(() => {
            
          });
        } else {
          list.splice(index, 1);
        }
      } else {
        list.splice(index, 1);
      }
      this.$nextTick(() => {
        const len = this.drawingList.length;
        if (len) {
          this.activeFormItem(this.drawingList[len - 1]);
        }
      });
    },
    generateCode() {
      const { type } = this.generateConf;
      this.AssembleFormData();
      const script = vueScript(makeUpJs(this.formData, type));
      const html = vueTemplate(makeUpHtml(this.formData, type));
      const css = cssStyle(makeUpCss(this.formData));
      return beautifier.html(html + script + css, beautifierConf.html);
    },
    showJson() {
      this.AssembleFormData();
      this.jsonDrawerVisible = true;
    },
    download() {
      this.dialogVisible = true;
      this.showFileName = true;
      this.operationType = "download";
    },
    run() {
      this.dialogVisible = true;
      this.showFileName = false;
      this.operationType = "run";
    },
    copy() {
      this.dialogVisible = true;
      this.showFileName = false;
      this.operationType = "copy";
    },
    // 切换字段的组件类型
    tagChange(newTag) {
      newTag = this.cloneComponent(newTag);
      const newData =  {
        ...newTag,
        "__config__": {
            ...newTag.__config__,
            "label": this.activeData.__config__.label,
            "span": this.activeData.__config__.span,
            // "regList": this.activeData.__config__.regList || [],
            "renderKey": this.activeData.__config__.renderKey,
            "formId": this.activeData.__config__.formId,
            "required": this.activeData.__config__.required,
            "tag": newTag.__config__.tag,
            "tagIcon": newTag.__config__.tagIcon,
        },
        "labelName": this.activeData.labelName,
        "originalName": this.activeData.originalName,
        "placeholder": this.activeData.placeholder,
        "disabled": this.activeData.disabled,
        "renderKey": this.activeData.renderKey,
        "__vModel__": this.activeData.__vModel__
      };
      if(newTag.__config__.defaultValue instanceof Array) {
        this.activeData.__config__.defaultValue = []
      }
      this.activeData = newData
      this.updateDrawingList(newData, this.drawingList)
    },
    updateDrawingList(newTag, list) {
      const index = list.findIndex(
        (item) => item.__config__.formId === this.activeId
      );
      if (index > -1) {
        list.splice(index, 1, newTag);
      } else {
        list.forEach((item) => {
          if (Array.isArray(item.__config__.children))
            this.updateDrawingList(newTag, item.__config__.children);
        });
      }
    },
    refreshJson(data) {
      this.drawingList = deepClone(data.fields);
      delete data.fields;
      this.formConf = data;
    },
    dynamicMethod() {
      this.handleForm();
    },
    // 判断表单是否符合正常格式
    subCheck() {
      let isTrue = true
      let isFiled = this.drawingList.every(
        (obj) => obj.__config__ && obj.__config__.children
      );
      if (!isFiled) {
        this.$modal.msgError("请删除模块外的字段");
        isTrue = false;
        return false
      }
      let nameList = []
      let isBind = true
      this.drawingList.forEach(item => {
        item.__config__.children.forEach(item1 => {
          if(!item1.__vModel__) {
            isBind = false
            nameList.push(item.__config__.componentName || item.__config__.labelName)
          }
        })
      })
      if (!isBind) {
        this.$modal.msgError(`请确定  “${nameList.join('、')}”  模块内的字段都已完成绑定！`);
        isTrue = false;
      }
      return isTrue
    },
    /** 表单基本信息 */
    handleForm() {
      this.formConf.titleList = this.formConf.titleList?.filter(item1 => {
        const foundField = this.drawingList.find(item => item.__config__.moduleId === item1.moduleId);
        if (foundField) {
          const index = foundField.__config__.children.findIndex(item2 => item2.__vModel__ === item1.__vModel__);
          if (index === -1) {
            return false; // 过滤掉不符合条件的元素
          }
        }
        return true;
      });
      if (!this.formConf.titleList?.length) {
        this.setTitles();
        this.$modal.msgError("请先设置项目标题！");
        return false;
      }
      this.formData = {
        ...this.formConf,
      };
      if(!this.subCheck()) {
        return false
      }
      this.formData.fields = deepClone(this.drawingList);
      this.form.formContent = JSON.stringify(this.formData);
      this.form.formName = this.formName;
      this.submitForm();
      // this.formOpen = true;
      // this.formTitle = "添加表单";
    },
    // 表单重置
    reset() {
      this.form = {
        formId: null,
        formName: null,
        formContent: null,
        remark: null,
      };
      this.resetForm("form");
    },
    // 取消按钮
    cancel() {
      this.formOpen = false;
      this.reset();
    },
    /** 保存表单信息 */
    submitForm() {
      // this.$refs["form"].validate(valid => {
      //   if (valid) {
          // if (this.form.formId != null) {
          //   updateForm(this.form).then(response => {
          //     updateTemplate().then(res => {})
          //     this.$modal.msgSuccess("修改成功");
          //     if(this.projectId) {
          //       bpmProjectForm({projectId:this.projectId,formId:this.form.formId}).then(res => {})
          //       this.$emit('nextStep')
          //       // const obj = { path: "/project/addProject", query: { proId: this.projectId,step:'2'} };
          //       // this.$tab.closeOpenPage(obj);
          //     } else {
          //       const obj = { path: "/flowable/form", query: { t: Date.now()} };
          //       this.$tab.closeOpenPage(obj);
          //     }
              
          //   });
          //   // const obj = { path: "/flowable/form", query: { t: Date.now()} };
          //   // this.$tab.closeOpenPage(obj);
          // } else {
            console.log(this.formId,this.form,'ppppppppppppppp');
            if(JSON.stringify(this.formData) != JSON.stringify(this.formConfOld)) {
              addForm(this.form).then(response => {
                // updateTemplate({formId:response.data.formId}).then(res => {})
                // this.$modal.msgSuccess("新增成功");
                if(this.projectId) {
                  bpmProjectForm({projectId:this.projectId,formId:response.data.formId}).then(res => {})
                  this.$emit('nextStep',response.data.formId)
                  // const obj = { path: "/project/addProject", query: { projectId: this.projectId,step:'2'} };
                  // this.$tab.closeOpenPage(obj);
                } else {
                  const obj = { path: "/flowable/form", query: { t: Date.now()} };
                  this.$tab.closeOpenPage(obj);
                }
              });
            } else {
              this.$emit('nextStep')
            }
          // }
          this.drawingList = []
          this.idGlobal = 1000
          this.open = false;

          // 绑定项目
          
          
      //   }
      // });
    },
  },
};
</script>

<style lang='scss'>
.form-boxs {
  height: 100%;
  display: flex;
  .form-tabs {
    width: 180px;
  }
  .form-right {
    flex: 1;
    height: 70vh;
    overflow-y: auto;
    .el-row {
      margin: 10px 20px !important;
    }
  }
}
.editor-tabs {
  background: #121315;
  .el-tabs__header {
    margin: 0;
    border-bottom-color: #121315;
    .el-tabs__nav {
      border-color: #121315;
    }
  }
  .el-tabs__item {
    height: 32px;
    line-height: 32px;
    color: #888a8e;
    border-left: 1px solid #121315 !important;
    background: #363636;
    margin-right: 5px;
    user-select: none;
  }
  .el-tabs__item.is-active {
    background: #1e1e1e;
    border-bottom-color: #1e1e1e !important;
    color: #fff;
  }
  .el-icon-edit {
    color: #f1fa8c;
  }
  .el-icon-document {
    color: #a95812;
  }
}
// home
.right-scrollbar {
  .el-scrollbar__view {
    padding: 12px 18px 15px 15px;
  }
}
.left-scrollbar .el-scrollbar__wrap {
  box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.center-tabs {
  .el-tabs__header {
    margin-bottom: 0 !important;
  }
  .el-tabs__item {
    width: 50%;
    text-align: center;
  }
  .el-tabs__nav {
    width: 100%;
  }
}
.reg-item {
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
  .close-btn {
    position: absolute;
    right: -6px;
    top: -6px;
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: rgba(210, 23, 23, 0.5);
    }
  }
  & + .reg-item {
    margin-top: 18px;
  }
}
.action-bar {
  & .el-button + .el-button {
    margin-left: 15px;
  }
  & i {
    font-size: 20px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}

.custom-tree-node {
  width: 100%;
  font-size: 14px;
  .node-operation {
    float: right;
  }
  i[class*="el-icon"] + i[class*="el-icon"] {
    margin-left: 6px;
  }
  .el-icon-plus {
    color: #409eff;
  }
  .el-icon-delete {
    color: #157a0c;
  }
}

.left-scrollbar .el-scrollbar__view {
  overflow-x: hidden;
}

.el-rate {
  display: inline-block;
  vertical-align: text-top;
}
.el-upload__tip {
  line-height: 1.2;
}

$selectedColor: #f6f7ff;
$lighterBlue: #409eff;

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.components-list {
  padding: 8px;
  box-sizing: border-box;
  height: 100%;
  .components-item {
    display: inline-block;
    width: 48%;
    margin: 1%;
    transition: transform 0ms !important;
  }
}
.components-draggable {
  padding-bottom: 20px;
}
.components-title {
  font-size: 14px;
  color: #222;
  margin: 6px 2px;
  .svg-icon {
    color: #666;
    font-size: 18px;
  }
}

.components-body {
  padding: 8px 10px;
  background: $selectedColor;
  font-size: 14px;
  cursor: move;
  border: 1px dashed $selectedColor;
  border-radius: 3px;
  .components-box {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .svg-icon {
    color: #777;
    font-size: 15px;
    margin-right: 6px;
  }
  &:hover {
    border: 1px dashed #787be8;
    color: #787be8;
    .svg-icon {
      color: #787be8;
    }
  }
}

.field-box {
  font-size: 12px;
  .field-lis {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &>div {
      margin: 0 4px 4px 0;
      padding: 4px;
      background: #e9e9e9;
      border: 1px dashed #e9e9e9;
      border-radius: 6px;
    }
  }
}

.components-draggables {
  .components-item {
    width: 100%;
  }
}

.left-board {
  width: 260px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.left-scrollbar {
  // height: calc(100vh - 128px);
  // overflow: hidden;
  height: calc(100% - 42px);
  overflow-y: auto;
}
.center-scrollbar {
  // height: calc(100vh - 128px);
  height: calc(100% - 42px);
  overflow: hidden;
  border-left: 1px solid #f1e8e8;
  border-right: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.center-board {
  // height: 100vh;
  height: 100%;
  width: auto;
  margin: 0 350px 0 260px;
  box-sizing: border-box;
}
.empty-info {
  position: absolute;
  top: 46%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #ccb1ea;
  letter-spacing: 4px;
}
.action-bar {
  position: relative;
  height: 42px;
  text-align: right;
  padding: 0 15px;
  box-sizing: border-box;
  border: 1px solid #f1e8e8;
  border-top: none;
  border-left: none;
  .delete-btn {
    color: #f56c6c;
  }
}
.logo-wrapper {
  position: relative;
  height: 42px;
  background: #fff;
  border-bottom: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.logo {
  position: absolute;
  left: 12px;
  top: 6px;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
  > img {
    width: 30px;
    height: 30px;
    vertical-align: top;
  }
  .github {
    display: inline-block;
    vertical-align: sub;
    margin-left: 15px;
    > img {
      height: 22px;
    }
  }
}

.center-board-row {
  padding: 12px 12px 15px 12px;
  box-sizing: border-box;
  & > .el-form {
    // 69 = 12+15+42
    height: calc(100vh - 69px);
  }
}
.drawing-board {
  height: 100%;
  position: relative;
  .components-body {
    padding: 0;
    margin: 0;
    font-size: 0;
  }
  .sortable-ghost {
    position: relative;
    display: block;
    overflow: hidden;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 3px;
      background: rgb(89, 89, 223);
      z-index: 2;
    }
  }
  .components-item.sortable-ghost {
    width: 100%;
    height: 60px;
    background-color: $selectedColor;
  }
  .active-from-item {
    & > .el-form-item {
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy,
    & > .drawing-item-delete {
      display: initial;
    }
    & > .component-name {
      color: $lighterBlue;
    }
  }
  .el-form-item {
    margin-bottom: 15px;
  }
}
.drawing-item {
  position: relative;
  cursor: move;
  &.unfocus-bordered:not(.activeFromItem) > div:first-child {
    border: 1px dashed #ccc;
  }
  .el-form-item {
    padding: 12px 10px;
  }
}
.drawing-row-item {
  position: relative;
  cursor: move;
  box-sizing: border-box;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 50px 28px;
  margin-bottom: 15px;
  .drawing-row-item {
    margin-bottom: 2px;
  }
  .el-col {
    margin-top: 22px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .drag-wrapper {
    display: table;
    width: 100%;
  }
  &.active-from-item {
    border: 1px dashed $lighterBlue;
  }
  .component-name {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #bbb;
    display: inline-block;
    padding: 0 6px;
  }
}
.drawing-item,
.drawing-row-item {
  &:hover {
    & > .el-form-item {
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy,
    & > .drawing-item-delete {
      display: initial;
    }
  }
  & > .drawing-item-copy,
  & > .drawing-item-delete {
    display: none;
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid;
    cursor: pointer;
    z-index: 1;
  }
  & > .drawing-item-copy {
    right: 56px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover {
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-copys {
    right: 86px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover {
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-delete {
    right: 24px;
    border-color: #f56c6c;
    color: #f56c6c;
    background: #fff;
    &:hover {
      background: #f56c6c;
      color: #fff;
    }
  }
}
.drawing-row-items {
  position: relative;
  cursor: move;
  box-sizing: border-box;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 0 2px;
  margin-bottom: 15px;
  .drawing-row-item {
    margin-bottom: 2px;
  }
  .el-col {
    margin-top: 22px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .drag-wrapper {
     display: table;
     width: 100%;
  }
  &.active-from-item {
    border: 1px dashed $lighterBlue;
  }
  .component-name {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #bbb;
    display: inline-block;
    padding: 0 6px;
  }
}
.drawing-item,
.drawing-row-items {
  &:hover {
    & > .el-form-item {
      background: $selectedColor;
      border-radius: 6px;
    }
    & > .drawing-item-copy,
    & > .drawing-item-delete {
      display: initial;
    }
  }
  & > .drawing-item-copy,
  & > .drawing-item-delete {
    display: none;
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid;
    cursor: pointer;
    z-index: 1;
  }
  & > .drawing-item-copy {
    right: 56px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover {
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-copys {
    right: 86px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;
    &:hover {
      background: $lighterBlue;
      color: #fff;
    }
  }
  & > .drawing-item-delete {
    right: 24px;
    border-color: #f56c6c;
    color: #f56c6c;
    background: #fff;
    &:hover {
      background: #f56c6c;
      color: #fff;
    }
  }
}
.moudle-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 15px 15px 36px;
  background: #f7f7f7;
  .moudle-li {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 8px;
    font-size: 14px;
    .span1 {
      color: #999999;
      width: 160px;
      text-align: right;
    }
    .span2 {
      margin-left: 4px;
      color: #333;
    }
    .img {
      display: flex;
      img {
        width: 40px;
      }
    }
  }
}
.module-form {
  display: inline-block;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .form-box {
    padding: 10px 12px 20px;
    font-size: 14px;
    display: inline-block;
    // border: 1px dashed rgb(64, 158, 255);
    border-radius: 10px;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 80vh;
    overflow-y: auto;
    .form-title {
      display: flex;
      align-items: center;
      padding: 0 5px;
      height: 48px;
      border-bottom: 1px solid #dadada;
      margin-bottom: 28px;
      span {
        flex: 1;
        font-size: 16px;
        color: #333;
        font-weight: 700;
      }
      i {
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .form-boxs {
      display: inline-block;
    }
    .btnbox {
      width: 160px;
      margin: 0 auto;
    }
  }
}
.module-box {
  height: 72vh;
  .drawing-board .drawing-item-copy {
    display: none !important;
  }
  .drawing-board .drawing-item-delete{
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.pro-table {
  ::v-deep .el-table__body-wrapper {
    height: 400px;
    overflow-y: auto;
  }
}
</style>