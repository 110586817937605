<template>
  <div class="login" style="display: flex;position: relative;">
    <div style="position: absolute;z-index: 11;width: 100%;height: 100%;">
      <!-- <img src="https://oss.71go.com/bpm1207/logoGif.gif" style="width: 100%;height: 100%;" alt=""> -->
      <video src="https://oss.71go.com/bpm1207/logoVideo.mp4" style="object-fit: fill;width: 100%;height: 100%;position: absolute;top: 0;" class="tpm-hero2__item-video" muted autoplay loop></video>
    </div>
    <div style="z-index: 99;display: flex;width: 100%;height: 100%;">
      <div class="leftGif">
        <!-- <img src="https://oss.71go.com/qyg-pc/logofff.png" style="width: 240px;height: 70px;margin: 72px 0 0 84px;" alt=""> -->
      </div>
      <div class="right-box">
        <h1>欢迎来到！</h1>
        <div class="name">司盟企服工单管理系统</div>
        <div class="iconALl">
          <div class="one">
            <img src="https://oss.71go.com/qyg-pc/choosed.png" alt="">
            实现全流程互联网订单管理
          </div>
          <div class="one">
            <img src="https://oss.71go.com/qyg-pc/choosed.png" alt="">
            整合行业交付资源，实现人才共享
          </div>
          <div class="one">
            <img src="https://oss.71go.com/qyg-pc/choosed.png" alt="">
            建设标准化专业交付管理体系
          </div>
        </div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <!-- <h3 class="title">{{ title }}</h3> -->
        <div class="label">账号：</div>
        <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              placeholder="请输入账号"
            >
              <img slot="prefix" src="https://oss.71go.com/qyg-pc/p-icon.png" alt="">
              <!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
            </el-input>
          </el-form-item>
          <div class="label" style="margin-top: 20px;">密码：</div>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              @keyup.enter.native="handleLogin"
            >
              <img slot="prefix" src="https://oss.71go.com/qyg-pc/m-icon.png" alt="">
              <!-- <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" /> -->
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaEnabled">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              placeholder="请输入验证码"
              style="width: 52%"
              @keyup.enter.native="handleLogin"
            >
              <img slot="prefix" src="https://oss.71go.com/bpm1207/y-icon.png" alt="">
              <!-- <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" /> -->
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img"/>
            </div>
          </el-form-item>
          <div class="remmber" @click="loginForm.rememberMe = !loginForm.rememberMe">
            <img v-if="!loginForm.rememberMe" src="https://oss.71go.com/qyg-pc/remmend.png" alt="">
            <img v-else src="https://oss.71go.com/qyg-pc/remmended.png" alt="">
            <div :style="{color: loginForm.rememberMe ? '#386EF6' : '#999'}">记住密码</div>
          </div>
          <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
          <el-form-item style="width:100%;">
            <el-button
              :loading="loading"
              size="medium"
              type="primary"
              class="loginBtn" style="font-size: 22px;"
              @click.native.prevent="handleLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
            <div style="float: right;" v-if="register">
              <router-link class="link-type" :to="'/register'">立即注册</router-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!--  底部  -->
      <div class="el-login-footer">
        <span>Copyright © 2024 深圳市中港星互联网科技有限公司 All Rights Reserved</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getCodeImg} from "@/api/login";
import Cookies from "js-cookie";
import {decrypt, encrypt} from '@/utils/jsencrypt'
import {setToken} from "@/utils/auth";

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      title: process.env.VUE_APP_TITLE,
      loginForm: {
        username: "",
        password: "",
        rememberMe: true,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
      path:null,
      token:null
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.getCode();
    this.getCookie();
    // this.changeBrowserZoom(1);
  },
  mounted() {
    this.token = this.getQueryString("token");
    this.path = this.getQueryString("redirect");
    // if (this.token != null && this.token != "" && this.token != undefined) {
    //   this.handLoginFast();
    // }
  },
  // beforeDestroy(){
  //   const wholePage = document.documentElement;
  //   let zoomLevel = ''
  //   // const devicePixelRatio = window.devicePixelRatio || 1;
  //   // if (devicePixelRatio == 1.5) {
  //   //   zoomLevel = 1;
  //   // }
  //   // if (devicePixelRatio == 1.25) {
  //   //   zoomLevel = 1;
  //   // }
  //   wholePage.style.zoom = zoomLevel;
  // },
  methods: {
    //获取浏览器参数
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = decodeURI(top.location.search);
      r = r.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // handLoginFast() {
    //   setToken(this.token);
    //   if(this.path){
    //     this.$router.push(this.path);
    //   }else {
    //     this.$router.push({ path: this.redirect || "/" }).catch(() => {});
    //   }
    // },
    changeBrowserZoom(zoomLevel) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      console.log(devicePixelRatio);
      if (screenHeight > 800) {
        zoomLevel = 1;
      }
      if (devicePixelRatio == 1.5) {
        zoomLevel = 0.6;
      }
      if (devicePixelRatio == 1.25) {
        zoomLevel = 0.75;
      }
      const wholePage = document.documentElement;
      wholePage.style.zoom = zoomLevel;
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
            if (this.captchaEnabled) {
              this.getCode();
            }
          });
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  height: 100%;
  // background-image: url("https://oss.71go.com/qyg-pc/logoGif.gif");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}
.leftGif{
  width: 70%;
  // height: 100%;
  // background-image: url("https://oss.71go.com/qyg-pc/logoGif.gif");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-repeat: no-repeat;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.right-box{
  width: 30%;
  padding: 0 4% 0 5%;
  height: 100%;
  background-image: url("https://oss.71go.com/qyg-pc/loginbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    font-weight: bold;
    font-size: 38px;
    color: #333333;
    margin: 10px 0;
  }
  .name{
    font-size: 38px;
    color: #386EF6;
    line-height: 48px;
    margin-top: 10px;
  }
  .iconALl{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;
    .one{
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #818181;
      margin-bottom: 10px;
      img{
        margin-right: 4px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.login-form {
  // border-radius: 6px;
  // background: #ffffff;
  margin-right: 20px;
  width: 100%;
  // padding: 25px 25px 5px 25px;
  .el-input {
    height: 60px;
    font-size: 20px;
    input {
      height: 60px;
    }
    .el-input__prefix{
      top: 13px;
      left: 15px;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
  .el-input--prefix .el-input__inner{
    padding-left: 50px!important;
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
  .label{
    font-size: 20px;
    color: #999999;
    margin-bottom: 10px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 44%;
  height: 60px;
  float: right;
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 58px;
}
.loginBtn{
  width:100%;
  height: 60px;
  border-radius: 6px;
}
.remmber{
  margin:0px 0px 40px 0px;display: flex;align-items: center;cursor: pointer;
  img{
    width: 16px;
    height: 16px;
  }
  div{
    font-size: 16px;
    margin-left: 10px;
  }
}
</style>
