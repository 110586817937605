var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { center: "", visible: _vm.dialogVisible, title: "选择数据" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.brList } },
        [
          _c("el-table-column", {
            attrs: { width: "200px", label: "项目标题", prop: "recordTitle" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.recordTitle))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "项目名称", prop: "recordName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.recordName))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "项目编号", prop: "recordCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.recordCode))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "提交状态", prop: "submitState" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.submitState ? "成功" : "失败") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.goInitiate(1)
                },
              },
            },
            [_vm._v("去发起")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goInitiate(2)
                },
              },
            },
            [_vm._v("查看我发起的")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }