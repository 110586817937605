import request from '@/utils/request';

// 分页查询任务列表所有数据
export function orderTop(data) {
	return request({
		url: '/order/orderStaging/orderTop',
		method: 'get',
		params: data
	});
}

export function savePersonTool(data) {
	return request({
		url: '/order/orderStaging/getSaveOrUpdatePersonTool',
		method: 'post',
		data: data
	});
}
export function delPersonTool(data) {
	return request({
		url: '/order/orderStaging/getDeletePersonTool/' + data.id,
		method: 'delete'
	});
}

export function getUpdatePersonTools(data) {
	return request({
		url: '/order/orderStaging/getUpdatePersonTools',
		method: 'post',
		data: data
	});
}

export function getPersonTool(data) {
	return request({
		url: '/order/orderStaging/getPersonTool',
		method: 'get'
	});
}
