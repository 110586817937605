var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "set-tag",
          attrs: {
            title: "设置个人标签",
            visible: _vm.addDialog,
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "add-tag" }, [
            _c("div", { staticClass: "tips" }, [
              _vm._v("注：设置标签仅供个人查看"),
            ]),
            _c(
              "div",
              { staticClass: "set-content" },
              [
                _vm._v(" 设置标签： "),
                _vm.isEditTag
                  ? _c("el-input", {
                      directives: [{ name: "trim", rawName: "v-trim" }],
                      attrs: { placeholder: "请输入内容", clearable: "" },
                      model: {
                        value: _vm.tagInput,
                        callback: function ($$v) {
                          _vm.tagInput = $$v
                        },
                        expression: "tagInput",
                      },
                    })
                  : _c("el-autocomplete", {
                      attrs: {
                        "fetch-suggestions": _vm.querySearchAsync,
                        placeholder: "请输入内容",
                        clearable: "",
                      },
                      on: { select: _vm.handleSelect },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.isTitle
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "16px",
                                        color: "#333",
                                        "font-weight": "bold",
                                        padding: "4px 0",
                                      },
                                    },
                                    [_vm._v(" 历史标签： ")]
                                  )
                                : _c("div", [
                                    _vm._v(" " + _vm._s(item.tagValue) + " "),
                                  ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.tagInput,
                        callback: function ($$v) {
                          _vm.tagInput = $$v
                        },
                        expression: "tagInput",
                      },
                    }),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.addTag },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm.dynamicTags.length || _vm.dynamicTagss.length
              ? _c("div", { staticClass: "tag-box" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("已创建标签：")]),
                  !_vm.isEditTag
                    ? _c(
                        "div",
                        { staticClass: "tag-list" },
                        _vm._l(_vm.dynamicTags, function (tag) {
                          return _c(
                            "el-tag",
                            {
                              key: tag.id,
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.deleteTag(tag, true)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag.tagValue) + " ")]
                          )
                        }),
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "tag-list" },
                        _vm._l(_vm.dynamicTagss, function (tag) {
                          return _c(
                            "el-tag",
                            {
                              key: tag.id,
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.deleteTag(tag, false)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag.tagValue) + " ")]
                          )
                        }),
                        1
                      ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "tip-content" }, [
              _vm._v(
                " 删除已创建标签，对应设置了该标签的项目将会同步取消标签设置 "
              ),
              _c("span", [_vm._v("且无法恢复")]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "我的自定义标签项目",
            visible: _vm.showDialog,
            "append-to-body": "",
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _vm.showDialog
            ? _c("div", { staticClass: "tag-list-box" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.tagList,
                        props: _vm.defaultProps,
                        "default-expand-all": true,
                        "highlight-current": true,
                        "expand-on-click-node": false,
                        "node-key": "id",
                      },
                      on: { "node-click": _vm.handleNodeClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(node.label)),
                                    data.total
                                      ? _c("span", [
                                          _vm._v(
                                            "(" + _vm._s(data.total) + ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        4222603083
                      ),
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          icon: "el-icon-edit",
                          size: "small",
                        },
                        on: { click: _vm.editTag },
                      },
                      [_vm._v("编辑标签")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _c("search-list", {
                      attrs: {
                        headData: _vm.headData,
                        headFixData: _vm.headFixData,
                        searchList: _vm.searchData,
                        searchData: _vm.otherData,
                      },
                      on: {
                        handleSearch: _vm.handleSearch,
                        saveSearchHead: _vm.saveSearchHead,
                      },
                    }),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "table",
                        attrs: {
                          border: "",
                          height: "580",
                          fit: "",
                          data: _vm.listData,
                          stripe: "",
                          "header-cell-style": {
                            background: "#f2f3f5",
                            color: "#7a7e83",
                          },
                        },
                        on: { "sort-change": _vm.handleSortChange },
                      },
                      [
                        _vm._l(_vm.filteredHeadFixData, function (item) {
                          return _c("custom-table-column", {
                            key: item.id,
                            attrs: {
                              item: item,
                              dict: _vm.dict.type[item.dictType],
                            },
                          })
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            "class-name": "small-padding fixed-width",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "deliver:entire:detial",
                                              "deliver:draft:detial",
                                              "deliver:processing:detial",
                                              "deliver:completed:detial",
                                              "deliver:abnormal:detial",
                                              "deliver:cancel:detial",
                                              "deliver:lock:detial",
                                              "deliver:send:detial",
                                              "deliver:reject:detial",
                                            ],
                                            expression:
                                              "[\n                  'deliver:entire:detial',\n                  'deliver:draft:detial',\n                  'deliver:processing:detial',\n                  'deliver:completed:detial',\n                  'deliver:abnormal:detial',\n                  'deliver:cancel:detial',\n                  'deliver:lock:detial',\n                  'deliver:send:detial',\n                  'deliver:reject:detial'\n                ]",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-view",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goTasksend(
                                              scope.row.recordId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("项目详情")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1860344937
                          ),
                        }),
                      ],
                      2
                    ),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        page: _vm.queryParams.current,
                        limit: _vm.queryParams.size,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "current", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "size", $event)
                        },
                        pagination: _vm.currentChange,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }