var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择需要复制的模块",
            visible: _vm.openQuote,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openQuote = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "dataTable",
              attrs: { "row-key": _vm.getRowKey, data: _vm.quoteList },
              on: { "current-change": _vm.handleSingleUserSelect },
            },
            [
              _c("el-table-column", {
                attrs: { width: "55", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.moduleId },
                            model: {
                              value: _vm.radioSelected,
                              callback: function ($$v) {
                                _vm.radioSelected = $$v
                              },
                              expression: "radioSelected",
                            },
                          },
                          [_vm._v(_vm._s(""))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块名称",
                  align: "center",
                  prop: "componentName",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.openQuote = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.choseQuote } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }