export default {
  prepend(h, conf, key, type) {
    if(conf.__slot__ && conf.__slot__.prepend && !type) {
      return <template slot="prepend">{conf.__slot__[key]}</template>
    }
  },
  append(h, conf, key, type) {
    // 是否是自动生成编号组件
    if(conf.__slot__ && conf.__slot__.append && !type) {
      if (conf.__config__.isAuto) {
        return <template slot="append"><span style="cursor: pointer;" data-moduleIds={conf.moduleIds} data-__vModel__={conf.__vModel__} data-id="667">{conf.__slot__[key]}</span> </template>
      }else{
        return <template slot="append"><span style="cursor: pointer;" data-moduleIds={conf.moduleIds} data-__vModel__={conf.__vModel__} data-id="666">{conf.__slot__[key]}</span> </template>
      }
    }
  },
  showWordLimit(h, conf, key) {
    if(conf['show-word-limit'] && !conf.maxlength) {
      return <template slot="suffix"><span style="line-height:36px;color:#909399;">{conf.__config__.defaultValue ? conf.__config__.defaultValue.length : ''}</span></template>
    }
  }
}
