import request from '@/utils/request'

// 查询模块列表
export function moduleList(query) {
  return request({
    url: '/bpm/form/module/page',
    method: 'get',
    params: query
  })
}

// 查询模块详细
export function getModule(query) {
  return request({
    url: '/bpm/form/module/get',
    method: 'get',
    params: query
  })
}

// 查询模块
export function getModules(query) {
  return request({
    url: '/bpm/form/module/search',
    method: 'get',
    params: query
  })
}

// 查询模块-新
export function searchModuleField(query) {
  return request({
    url: '/bpm/form/searchModuleField',
    method: 'get',
    params: query
  })
}

// 新增模块
export function addModule(data) {
  return request({
    url: '/bpm/form/module/save',
    method: 'post',
    data: data
  })
}

// 修改模块
export function updateModule(data) {
  return request({
    url: '/bpm/form/module/update',
    method: 'put',
    data: data
  })
}

// 删除模块
export function delModule(query) {
  return request({
    url: '/bpm/form/module/delete',
    method: 'delete',
    params: query
  })
}

// 获取模块模板
export function getTemplate(data) {
  return request({
    url: '/bpm/form/template/getTemplate',
    method: 'post',
    data: data
  })
}

// 更新模板并集，会更新整个数据库，只应该在更新和添加项目表单的时候异步调用，其余不能调用
export function updateTemplate(query) {
  return request({
    url: '/bpm/form/module/updateTemplate',
    method: 'get',
    params: query
  })
}

// 查询模块组分页列表
export function moduleGroupList(query) {
  return request({
    url: '/bpm/form/moduleGroup/page',
    method: 'get',
    params: query
  })
}

// 新增/修改模块组
export function saveOrUpdate(data) {
  return request({
    url: '/bpm/form/moduleGroup/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 删除模块组
export function moduleDelete(query) {
  return request({
    url: '/bpm/form/moduleGroup/delete',
    method: 'get',
    params: query
  })
}

// 获取模块组数据
export function moduleGet(query) {
  return request({
    url: '/bpm/form/moduleGroup/get',
    method: 'get',
    params: query
  })
}

// 获取已绑定模块
export function getBindModule(query) {
  return request({
    url: '/bpm/form/moduleGroup/getBindModule',
    method: 'get',
    params: query
  })
}

// 获取可绑定模块
export function getUnbindModule(query) {
  return request({
    url: '/bpm/form/moduleGroup/getUnbindModule',
    method: 'get',
    params: query
  })
}

// 绑定模块
export function groupBind(data) {
  return request({
    url: '/bpm/form/moduleGroup/bind',
    method: 'post',
    data: data
  })
}

// 保存模块模板
export function saveTemplate(data) {
  return request({
    url: '/bpm/form/template/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 查询包含当前模块的项目列表
export function templateList(data) {
  return request({
    url: '/bpm/form/template/list',
    method: 'post',
    params: data
  })
}

export function saveMaterialList(data) {
  return request({
    url: '/bpm/form/module/saveMaterialList',
    method: 'post',
    data: data
  })
}
