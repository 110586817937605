import request from '@/utils/request';

// 根据项目类型和项目Id获取对应的任务节点
export function getCommonProjectTask(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/getCommonProjectTask',
		method: 'post',
		data: query
	});
}

// 通过主键查询单条数据 --- 订单
export function orderBillRefundProject(id) {
	return request({
		url: '/order/orderBillRefundProject/'+id,
		method: 'get'
	});
}

// 修改数据 --- 订单
export function updateOrderBillRefundProject(data) {
	return request({
		url: '/order/orderBillRefundProject',
		method: 'put',
        data: data
	});
}

//流转到下一个节点或者当前任务提交 ---- 校验
export function getCommonProjectTaskNext(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/getCommonProjectTaskNext',
		method: 'post',
		data: query
	});
}

//流转到下一个节点或者当前任务提交
export function submitCommonProjectTaskNext(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/submitCommonProjectTaskNext',
		method: 'post',
		data: query
	});
}

//驳回时，获取之前的任务节点 ------------- 校验
export function getCommonProjectTaskBefore(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/getCommonProjectTaskBefore',
		method: 'post',
		data: query
	});
}

//驳回时，获取之前的任务节点 ------------- 提交
export function rejectCommonTask(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/rejectCommonTask',
		method: 'post',
		data: query
	});
}

//驳回提交 ------- 驳回后提交
export function rejectSubmitCommonTask(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/rejectSubmitCommonTask',
		method: 'post',
        data: query
	});
}

//作废处理
export function invalidatedCommonTask(query) {
	return request({
		url: '/bpm/bpmCommonProjectTask/invalidatedCommonTask',
		method: 'post',
        data: query
	});
}

// 删除产品
export function removeRefundProjectById(id) {
	return request({
		url: '/order/orderBillRefundProject/removeRefundProjectById/'+id,
		method: 'delete',
	});
}

// 通过主键查询单条数据 --- 订单
export function orderBillWater(id) {
	return request({
		url: '/order/orderBillWater/'+id,
		method: 'get'
	});
}

// 修改数据 --- 流水订单
export function updateOrderBillWater(data) {
	return request({
		url: '/order/orderApplyContractProject',
		method: 'put',
    data: data
	});
}

// 通过主键查询单条数据 --- 合同
export function orderApplyContractProject(id) {
	return request({
		url: '/order/orderApplyContractProject/get/'+id,
		method: 'get'
	});
}