import request from '@/utils/request'

// 查询当前用户预警任务事项数量
export function countUserTaskWarning(query) {
  return request({
    url: '/bpm/bpmRecordTaskWarning/countUserTaskWarning',
    method: 'get',
    params: query
  })
}
// 查询当前用户预警任务事项看板数据
export function panelUserTaskWarning(query) {
  return request({
    url: '/bpm/bpmRecordTaskWarning/panelUserTaskWarning',
    method: 'get',
    params: query
  })
}
// 分页查询当前用户预警任务事项列表数据
export function pageRecordTaskWarning(query) {
  return request({
    url: '/bpm/bpmRecordTaskWarning/pageRecordTaskWarning',
    method: 'get',
    params: query
  })
}
// 更新预警截止日期
export function updateTaskWarningTime(data) {
  return request({
    url: '/bpm/bpmRecordTaskWarning/updateTaskWarningTime',
    method: 'post',
    data: data
  })
}
