<template>
  <div class="search_box">
    <el-form ref="searchForm" v-show="showSearch" :model="searchModel" inline>
      <div class="search_con">
        <el-form-item class="search-item" v-for="(item, index) in searchItems" :key="item.value" :label="item.label" :prop="item.value">
          <template v-if="item.type === 'select'">
            <el-select size="small" collapse-tags v-model="searchModel[item.value]" multiple :placeholder="item.label" filterable>
              <el-option v-for="(dict, i) in item.options" :key="i" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
              <el-option key="为空" label="为空" value="为空"></el-option>
              <el-option key="不为空" label="不为空" value="不为空"></el-option>
            </el-select>
          </template>
          <template v-else-if="item.type === 'input'">
            <el-autocomplete @keyup.enter.native="search" :clearable="true" v-model="searchModel[item.value]" :fetch-suggestions="queryOption" placeholder="请输入内容" v-trim>
              <template slot-scope="{ item }">
                <div class="name">{{ item.label }}</div>
              </template>
            </el-autocomplete>

          </template>

          <template v-else-if="item.type === 'datetime'">
            <el-date-picker size="small" v-model="searchModel[item.value]" :clearable="false" type="datetimerange" range-separator="至" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </template>

          <template v-else-if="item.type === 'date'">
            <el-date-picker size="small" v-model="searchModel[item.value]" :clearable="false" type="daterange" range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </template>
          <el-button size="small" v-if="!searchModel[item.value] || searchModel[item.value].length == 0" type="text" icon="el-icon-error" @click="removeSearchItem(index, item.value)" class="delete-button"></el-button>
          <el-button v-else type="text" icon="el-icon-remove" @click="removeSearchItem(index, item.value)" class="delete-button"></el-button>
        </el-form-item>
      </div>
    </el-form>

    <div class="search_tle">

      <div class="search_tle_left">
        <el-select v-show="showSearch" filterable size="mini"  v-model="selectedType" @change="addSearchItem" placeholder="添加搜索项" class="search_select">
          <el-option v-for="(item, index) in searchData" :label="item.label" :value="item.value" :key="index" :disabled="isDisabled(item.value)"></el-option>
        </el-select>
        <el-button v-show="showSearch" size="mini" type="primary" @click="search">搜索</el-button>
        <el-button v-show="showSearch" size="mini" type="primary" @click="reset" plain>重置</el-button>
        <el-popover v-show="showSearch" placement="bottom" width="225" v-model="visible" @after-leave="saveHeadData" @show="showDefaultColOptions">
          <div class="popover_center">
            <el-checkbox-group v-model="colOptions">
              <draggable handle=".el-icon-sort" ghostClass="ghost" chosenClass="chosen" :options="{animation: 150}" v-model="defaultOptions" group="checkboxGroup" @end="updateColOptions">

                  <el-checkbox  v-for="(item, index) in defaultOptions" :label="item.headField" :key="index">
                    <div class="check_box"> {{ item.headName }} <i class="el-icon-sort"></i> </div>
                  </el-checkbox>

              </draggable>
            </el-checkbox-group>

          </div>
          <el-button size="mini" @click.stop="" slot="reference" class="ml_10"  type="success">自定义表头</el-button>

        </el-popover>
        <div class="search_btnList" v-if="dynamicBtnList && dynamicBtnList.length != 0">
          <el-button v-for="(item, index) in dynamicBtnList" :key="index" size="mini" type="warning" @click="propBtn(item.methods)" plain>{{ item.label }}</el-button>
        </div>
      </div>

      <div class="search_tle_right">

        <right-toolbar :showSearch.sync="showSearch" @queryTable="search"></right-toolbar>
      </div>

    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SearchList",
  components: { draggable },
  props: {
    searchData: { type: Array, default: [] },
    searchList: { type: Array, default: [] },
    headData: { type: Array, default: [] },
    headFixData: { type: Array, default: [] },
    btnList: { type: Array, default: () => [] },
  },
  computed: {
    dynamicBtnList() {
      // 检查 btnList 是否为空，如果为空则返回一个默认的按钮列表
      if (this.btnList.length === 0) {
        return [];
      } else {
        return this.btnList;
      }
    },
  },
  data() {
    return {
      showSearch: true,
      visible: false,
      selectedType: "", // 用户选择的搜索项类型
      searchItems: [
        // 动态添加的搜索项数组，具体数据示例
      ],
      searchModel: {}, // 存储搜索项的值
      colSelect: [],
      colOptions: [],
      defaultOptions: [],
    };
  },
  mounted() {
    // console.log('searchData', this.searchData);
    if (this.btnList && this.btnList.length) {
      console.log("btnList", this.btnList);
    }
  },
  watch: {
    searchData: {
      handler(newVal) {
        this.searchItems = newVal;
        // console.log('searchData-newVal', this.searchData);
      },
      immediate: false,
      deep: true,
    },
    searchList: {
      handler(newVal) {
        this.searchItems = newVal;
        // console.log('searchItems', this.searchItems);
      },
      immediate: false,
      deep: true,
    },
    headData: {
      handler(newVal) {
        if (newVal.length == 0) {
          for (const iterator of this.headFixData) {
            this.colOptions.push(iterator.headField);
          }
        } else {
          this.colOptions = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updatedModel(key, value) {
      this.$set(this.searchModel,key,value)
    },
    showDefaultColOptions() {
      this.defaultOptions = this.headFixData;
    },
    updateColOptions(event) {},
    queryOption(queryString, cb) {
      var restaurants = [
        {value: "为空", label: "为空"},
        {value: "不为空", label: "不为空",},
      ];
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    addSearchItem(e,t) {
      if (this.searchItems.length > 0) {
        const findObj = this.searchItems.find(
          (obj) => obj.value === this.selectedType
        );
        if (findObj) {
          this.$modal.msgError("已经存在搜索条件");
          this.selectedType = "";
          return false;
        }
      }

      if (this.selectedType) {
        const foundObj = this.searchData.find(
          (obj) => obj.value === this.selectedType
        );
        let newItem = {
          type: foundObj.type,
          label: "",
          key: "",
          options: [],
        };
        switch (foundObj.type) {
          case "input":
            newItem.label = foundObj.label;
            newItem.key = `input${this.searchItems.length + 1}`;
            newItem.value = foundObj.value;
            break;
          case "select":
            newItem.label = foundObj.label;
            newItem.key = `select${this.searchItems.length + 1}`;
            newItem.value = foundObj.value;
            newItem.options = foundObj.dictData;
            break;
          case "checkbox":
            newItem.label = "多选框";
            newItem.key = `checkbox${this.searchItems.length + 1}`;
            newItem.options = [
              { label: "选项1", value: "option1" },
              { label: "选项2", value: "option2" },
            ];
            break;
          case "datetime":
            newItem.label = foundObj.label;
            newItem.key = `date${this.searchItems.length + 1}`;
            newItem.value = foundObj.value;
            break;
          case "date":
            newItem.label = foundObj.label;
            newItem.key = `date${this.searchItems.length + 1}`;
            newItem.value = foundObj.value;
            break;
          default:
            break;
        }

        this.searchItems.push(newItem);
        this.searchItems = [...new Set(this.searchItems)];
        this.$set(this.searchModel, newItem.value, ""); // 动态添加搜索项到searchModel对象中
        this.selectedType = "";
        if(!t) {
          this.search()
        }
      }
    },
    removeSearchItem(index, name) {
      if (this.searchModel[name] && this.searchModel[name].length == 0) {
        const item = this.searchItems[index];
        delete this.searchModel[item.key]; // 从searchModel对象中删除对应的搜索项
        this.searchItems.splice(index, 1);
        return false;
      }
      if (this.searchModel[name]) {
        this.searchModel[name] = "";
      } else {
        const item = this.searchItems[index];
        delete this.searchModel[item.key]; // 从searchModel对象中删除对应的搜索项
        this.searchItems.splice(index, 1);
      }
    },
    search() {
      this.$emit("handleSearch", this.searchModel, this.searchItems);
    },
    saveHeadData() {
      let flag = this.isModified(this.defaultOptions, this.colOptions);
      if (flag) {
        const endArr = this.defaultOptions.map((option) => {
          if (!this.colOptions.includes(option.headField)) {
            console.log(option.headField);
          }

          return {
            ...option,
            value: option.headField,
            showField: this.colOptions.includes(option.headField),
            // 判断是否在 colOptions 中
          };
        });
        this.$emit("saveSearchHead", endArr);
      }

      this.visible = false;
    },
    reset() {
      Object.keys(this.searchModel).forEach((key) => {
        this.searchModel[key] = "";
      });
      this.$emit("handleSearch", this.searchModel, this.searchItems);
    },
    isDisabled(item) {
      const list = this.searchItems.map((e) => e.value);
      // 判断下拉项是否再列表里，存在则禁用
      return list.includes(item);
    },
    isModified(originalArray, currentArray) {
      // 比较原始数组和当前数组的长度
      if (originalArray.length !== currentArray.length) {
        return true;
      }

      // 比较原始数组和当前数组的每个元素
      for (let i = 0; i < originalArray.length; i++) {
        if (originalArray[i].headField !== currentArray[i]) {
          return true;
        }
      }

      return false;
    },
    propBtn(method) {
      console.log("触发了", method);
      this.$emit(method);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover_center {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  ::v-deep .el-checkbox{
    margin-right: 0;
  }
  .chosen {
    background-color: #fff !important;
    border: 1px #a0bcee dashed;
    border-radius: 2px;
    padding-left: 3px;
    box-sizing: border-box;
    transition: all 0.1s;
  }

  .check_box {
    box-sizing: border-box;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 154px;
  }
}

.search_box {
  border: none;
  padding: 10px;

  .search_select {
    width: 120px;
    margin-right: 10px;
  }

  .search_tle {
    margin-bottom: 10px;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    .select-label {
      font-weight: 500;
      margin-right: 10px;
      font-size: 16px;
    }
    .search_tle_left {
      display: flex;
      justify-content: space-between;
      .search_btnList {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .search_con {
    display: flex;
    flex-wrap: wrap;
  }

  .search-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .example-component {
    /* 其他组件示例样式 */
  }

  .search-item .el-form-item {
    margin-bottom: 0;
    padding: 0;
    position: relative;
  }

  .search-item .delete-button {
    display: none;
    position: absolute;
    top: -18px;
    right: -8px;
    cursor: pointer;
  }

  .search-item:hover .delete-button {
    display: block;
  }
}
.ml_10 {
  margin-left: 10px;
}
</style>
