var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "propertyPanel", staticClass: "property-panel" },
    [
      _vm.nodeName
        ? _c("div", { staticClass: "node-name" }, [
            _vm._v(_vm._s(_vm.nodeName)),
          ])
        : _vm._e(),
      _vm.element
        ? _c(_vm.getComponent, {
            tag: "component",
            attrs: {
              projectId: _vm.projectId,
              formDatas: _vm.formDatas,
              element: _vm.element,
              modeler: _vm.modeler,
              users: _vm.users,
              groups: _vm.groups,
              taskList: _vm.taskList,
              exps: _vm.exps,
              categorys: _vm.categorys,
            },
            on: { updateTask: _vm.updateTask },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }