import router from './router'
import store from './store'
import {Message} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken, setToken} from '@/utils/auth'
import {isRelogin} from '@/utils/request'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  NProgress.start()
  // console.log(to, from, next,'to, from, next');
  if (to.path === '/manage/tasksend') {

  } else {
    document.title = to.meta.title || '司盟企服智能工单';
  }
  window.addEventListener('message', (event) => {
    if (event.origin === 'https://tc.simengmall.com') {
      console.log('Received message from parent:', event.data);
      setToken(event.data.token)
      router.push(event.data.path);
    }
  });
  if (to.query?.accessToken) {
    console.log(to.query.accessToken,'to.query.accessToken');
    setToken(to.query.accessToken)
    to.query['accessToken'] = undefined
  }
  if (getToken()) {

    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    /*getSysUserNotice({size:1,status:0,current: 1}).then(res =>{
      if (res.code == 200) {
       if (res.data.records.length>0) {
         store.dispatch('settings/setInfoNoticeNum', res.data.total)
       }else{
         store.dispatch('settings/setInfoNoticeNum', 0)
       }
      }
     })*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${encodeURIComponent(to.fullPath)}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
