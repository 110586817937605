<template>
  <div>
    <x-form ref="xForm" v-model="formData" :config="formConfig">
      <template #executionListener>
        <el-badge :value="executionListenerLength">
          <el-button size="small" @click="dialogName = 'executionListenerDialog'">编辑</el-button>
        </el-badge>
      </template>
    </x-form>
    <executionListenerDialog
      v-if="dialogName === 'executionListenerDialog'"
      :element="element"
      :modeler="modeler"
      @close="finishExecutionListener"
    />
  </div>
</template>

<script>
import mixinPanel from '../../common/mixinPanel'
import {StrUtil} from '@/utils/StrUtil'
import mixinExecutionListener from '../../common/mixinExecutionListener'
import { commonParse, conditionExpressionParse } from '../../common/parseElement'
export default {
  mixins: [mixinPanel, mixinExecutionListener],
  data() {
    return {
      formData: {},
      executionListenerLength: 0
    }
  },
  computed: {
    formConfig() {
      return {
        inline: false,
        item: [
          {
            xType: 'input',
            name: 'id',
            label: '节点 id',
            disabled:true,
            rules: [{ required: true, message: 'Id 不能为空' }]
          },
          {
            xType: 'input',
            name: 'name',
            label: '节点名称'
          },
          {
            xType: 'input',
            name: 'documentation',
            label: '节点描述'
          },
          {
            xType: 'slot',
            name: 'executionListener',
            label: '执行监听器'
          },
          {
            xType: 'input',
            name: 'conditionExpression',
            label: '跳转条件'
          },
          {
            xType: 'input',
            name: 'skipExpression',
            label: '跳过表达式'
          }
        ]
      }
    }
  },
  watch: {
    'formData.conditionExpression': function(val) {
      if (StrUtil.isNotBlank(val)) {
        const newCondition = this.modeler.get('moddle').create('bpmn:FormalExpression', { body: val })
        this.updateProperties({ conditionExpression: newCondition })
      }
      // else {
      //   this.updateProperties({ conditionExpression: null })
      // }
    },
    'formData.skipExpression': function(val) {
      if (StrUtil.isNotBlank(val)) {
        this.updateProperties({'flowable:skipExpression': val})
      } else {
        delete this.element.businessObject.$attrs[`flowable:skipExpression`]
      }
    }
  },
  created() {
    let cache = commonParse(this.element)
    cache = conditionExpressionParse(cache)
    this.formData = cache;
    this.computedExecutionListenerLength();
  },
  methods:{
    computedExecutionListenerLength() {
      this.executionListenerLength = this.element.businessObject.extensionElements?.values
        ?.filter(item => item.$type === 'flowable:ExecutionListener').length ?? 0
    },
  }
}
</script>

<style></style>
