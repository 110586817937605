<template>
  <div>
    <div v-loading="!isRequestCompleted">
      <div class="head-title">{{projectData.projectName}}</div>
      <div class="label-tip"><span>办理流程</span></div>
      <div class="step-boxs">
        <div class="top">
          <div class="step"><img src="https://oss.71go.com/qyg/xcx/mtasksend1.png" alt=""></div>
          <div class="line"></div>
          <div class="step"><img src="https://oss.71go.com/qyg/xcx/mtasksend3.png" alt=""></div>
          <div class="line"></div>
          <div class="step"><img src="https://oss.71go.com/qyg/xcx/mtasksend2.png" alt=""></div>
        </div>
        <div class="bot">
          <div class="title">填写资料</div>
          <div class="title">确认提交</div>
          <div class="title">审核办理</div>
        </div>
      </div>
      <div class="label-tip"><span>填写资料</span></div>
      <mparser v-if="isRequestCompleted" :form-conf="formConf" :recordStatus="projectData.recordStatus" @openDataDialog="openDataDialog" ref="parser" @getData="getData" />
      <div style="display:flex;justify-content: space-between;width: 50%;margin: 50px auto;">
        <el-button :loading="isSave" size="medium" type="info" @click="stagingForms">暂 存</el-button>
        <el-button size="medium" type="primary" @click="submitPros">提 交</el-button>
      </div>
    </div>
    <selectFillData ref="fillData" @select="handleSelectData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import selectFillData from "@/components/SelectFillData";
import showBackRecord from "@/components/showBackRecord";
import {
  listSub,
  getCheckProjectCache,
  batchSummitProject,
  removeLink,
  getLinkRecord,
  getCheckProject,
} from "@/api/service/sub";
import mparser from "@/components/parser/mParser";
import { getConfig, setConfig, cancelSubTask } from "@/api/flowable/form";
import { definitionStart } from "@/api/flowable/definition";
import JSZip from "@/utils/jsZip.js";
import axios from "axios";
import {
  abnormalLog,
  assignTask,
  bpmRecordProject,
  bpmRecordProjectDetail,
  bpmRecordProjectUpdate,
  cancelProject,
  createTask,
  getDraft,
  recGetDraft,
  recGetDrafts,
  recordProject,
  recordProjectUpdate,
  recSummitProject,
  getNextFlowNodeByStart,
  lockProject,
  nextTaskPersonnel,
  overProject,
  recordLog,
  recordLogBatch,
  rejectedLog,
  rejectTasks,
  shareTask,
  submitNextTasks,
  summitProject,
  summitTask,
  insertBatch,
  bpmRecordFileHistory,
  updateNewType,
} from "@/api/flowable/todo";
import { listItemTasks } from "@/api/system/task";
import FlowUser from "@/components/flow/User";
import FlowRole from "@/components/flow/Role";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "tasksend",
  components: {
    mparser,
    selectFillData,
    showBackRecord,
  },
  dicts: [
    "bpm_record_task_over_status",
    "bpm_record_task_type",
    "bpm_record_status",
    "record_sub_type",
    "action_file_type",
    "bpm_record_task_operation_type",
  ],
  computed: {
    ...mapGetters(["sidebar"]),
  },
  data() {
    return {
      summarySync: false,
      nickName: this.$store.state.user.nickName,
      showProcess: true,
      taskRemark: "",
      taskLabel: "",
      clickType: null,
      taskTips: "",
      nextTask: "",
      taskTle: "发起项目",
      tasksave: false,
      process: null,
      tabStatus: 0,
      fromTab: "form-order",
      remark: null, // 项目备注
      projectForm: {
        name: this.$store.state.user.name,
        projectName: "sdagdsafgsdgsdf",
        date: "2023-11-30",
      },
      btnShow: false,
      btnShows: false,
      userId: null,
      nowUserId: null,
      // 模型xml数据
      flowData: {},
      flowDatas: {},
      activeName: "1", // 切换tab标签
      defaultProps: {
        children: "children",
        label: "label",
      },
      // 查询参数
      queryParams: {
        deptId: undefined,
      },
      // 遮罩层
      loading: true,
      recordId: null,
      taskId: null,
      choseTask: false,
      choseTaskList: [],
      choseTaskform: {},
      choseTaskforms: {},
      choseTaskrules: {
        id: [{ required: true, message: "任务不能为空", trigger: "blur" }],
      },
      chilTaskId: null,
      chilTaskName: null,
      deployId: "", // 流程定义编号
      procDefId: "", // 流程实例编号
      isRequestCompleted: false,
      formConf: {}, // 默认表单数据
      formConfOld: {},
      formConfOld1: {},
      formConfCopy: {},
      variables: [], // 流程变量数据
      taskTitle: null,
      taskOpen: false,
      checkSendUser: false, // 是否展示人员选择模块
      checkSendRole: false, // 是否展示角色选择模块
      checkType: "", // 选择类型
      checkValues: null, // 选中任务接收人员数据
      formData: {}, // 填写的表单数据,
      recordTitle: null,
      multiInstanceVars: "", // 会签节点,
      projectCode: null, //订单code合集
      orderProdId: null,
      orderList: [],
      tableData: [], // 订单数据
      taskStatus: 0,
      projectData: {
        id: null,
        recordTitle: null,
        recordName: null,
        recordCode: null,
      }, // 项目数据
      taskData: [], // 流程节点数据
      taskRecordss: [],
      taskItem: {},
      taskActive: null,
      flowOpen: false,

      rejOpen: false,
      rejform: {},
      rejforms: {
        taskId: null,
        viewable: [],
        editable: [],
        struct: null,
      },
      rejrules: {
        opinion: [
          { required: true, message: "任务节点不能为空", trigger: "blur" },
        ],
      },

      subList: [],
      ctaskOpen: false,
      taskform: {},
      taskItems: {},
      taskrules: {
        opinion: [
          { required: true, message: "任务节点不能为空", trigger: "blur" },
        ],
      },

      userVisible: false,
      userType: null,
      // 数据回显
      selectValues: null,
      selectionUser: {},

      // 驳回字段相关
      formLists: [],
      formListsOld: [],
      loading2: true,
      //下一流程节点列表相关
      appointOpen: false,
      appointData: [],
      overTask: [],
      taskUserList: [],
      handIndex: null,
      taskRecordsAll: [],
      rejectProFlag: null, // 是否允许修改产品
      proVisible: false,
      choseProVisible: false,
      // 产品列表加载相关
      loading1: true,
      total1: 0,
      queryParams1: {
        current: 1,
        size: 10,
        productName: null,
        productId: null,
        orderSn: null,
        customerPhone: null,
        customerNo: null,
        orderProdIds: [],
        projectCode: null,
      },
      tableDatas: [],
      selectProList: [], // 选择产品

      recordList: [], //日志列表
      abnormalList: [], // 异常日志列表

      fileLoading: false,
      fileListss: [], //项目文件列表
      fileLists: [], //项目文件列表
      fileCheck: [],

      queryParams2: {
        current: 1,
        size: 10,
      },
      total2: 0,

      isOpened: null,
      multiProject: null,
      mpList: [],
      mpindex: 0,
      recordIdLink: [],
      ctTableData: [],
      linkKey: "",
      topHeight: "",
      isSave: false,
    };
  },
  mounted() {
    this.setHtmlFontSize();
    window.addEventListener('resize', this.setHtmlFontSize);
  },
  async created() {
    this.userId = this.$store.state.user.id;
    this.recordId = this.$route.query && this.$route.query.recordId;
    this.projectCode = this.$route.query && this.$route.query.projectCode
    this.orderProdId = this.$route.query && this.$route.query.orderProdId
    // // 初始化表单
    // if (this.$route.query.multiProject) {
    //   this.multiProject = this.$route.query.multiProject;
    //   this.getCache(this.$route.query.multiProject);
    // } else {
    //   this.projectCode = this.$route.query && this.$route.query.projectCode;
    //   this.orderProdId = this.$route.query && this.$route.query.orderProdId;
    // }
    await this.getFlowFormDatas();
  },
  methods: {
    /** 流程表单数据 */
    async getFlowFormDatas() {
      let res;
      let formRes;
      if (!this.recordId) {
        let param = {
          projectCode:this.projectCode,
          orderProdId:this.orderProdId
        }
        res = await recGetDraft(param)
      } else {
        res = await recGetDrafts(this.recordId);
      }
      
      if(res.code == 200) {
        if(res.data.products && res.data.products.length && this.recordId) {
          let param = {
            projectCode: res.data.projectCode,
            orderProdId: res.data.products[0].orderProductId,
          };
          formRes = await recGetDraft(param);
        }
        this.projectData = res.data; // 项目数据

        if (formRes?.code == 200 && res.data.formId && formRes?.data.formData?.formId != res.data.formId && formRes?.data.formData?.formContent && res.data.recordStatus === 0) {
          let forms1 = JSON.parse(formRes.data.formData.formContent)
          let forms2 = res.data.variables
          forms1.fields.forEach(item1 => {
            forms2.fields.forEach(itemx => {
              if(item1.__config__.moduleId === itemx.__config__.moduleId) {
                if(item1.__config__.allowMultiple && itemx.__config__.allowMultiple) {
                  if(itemx.__config__.defaultValue && itemx.__config__.defaultValue.length) {
                    this.$set(item1.__config__,'defaultValue',itemx.__config__.defaultValue)
                  }
                } else {
                  item1.__config__.children.forEach(item2 => {
                    itemx.__config__.children.forEach(itemy => {
                      if(item2.__vModel__ === itemy.__vModel__ && item2.__config__.tag === itemy.__config__.tag && itemy.__config__.defaultValue) {
                        this.$set(item2.__config__,'defaultValue',itemy.__config__.defaultValue)
                      }
                    })
                  })
                }
              }
            })
          })
          this.formConfOld1 = JSON.parse(JSON.stringify(forms1))
          this.projectData.formId = formRes.data.formData.formId
        } else {
          this.formConfOld1 = this.projectData.variables
            ? JSON.parse(JSON.stringify(this.projectData.variables))
            : JSON.parse(this.projectData.formData.formContent);
        }

        this.recordTitle = this.projectData.recordTitle;
        this.projectData.recordStatus = this.projectData.recordStatus
          ? this.projectData.recordStatus
          : 0;
        this.remark = this.projectData.remark;
        await this.getFlowFormDatas1();
      }
    },
    async getFlowFormDatas1() {
      console.log("rrrrrrrrr");
      let response = await getConfig({
        taskId:-3,
        projectId: this.projectData.projectId,
      });
      if (response.data) {
        let viewable = JSON.parse(response.data.setting).viewable; // 查看权限字段
        let editable = JSON.parse(response.data.setting).editable; // 编辑权限字段

        let formDatas = JSON.parse(JSON.stringify(this.formConfOld1))

        formDatas.fields.forEach((item,index) => {
          if (item.__config__.children) {
            this.$set(item.__config__, "moduleShow", false);
            this.$set(item.__config__, "icon", `https://oss.71go.com/qyg-pc/bpm/information${index + 1}.png`);
            let childrens = JSON.parse(
              JSON.stringify(item.__config__.children)
            );
            let list1 = [];
            let list2 = [];
            childrens.forEach((item1) => {
              let isDisabled = editable.some(
                (item2) =>
                  item1.__vModel__ === item2.__vModel__ &&
                  item.__config__.moduleId === item2.moduleId
              );
              let isShow = viewable.some(
                (item2) =>
                  item1.__vModel__ === item2.__vModel__ &&
                  item.__config__.moduleId === item2.moduleId
              );
              list1.push(isDisabled);
              list2.push(isShow);
              this.$set(item1, "disabled", !isDisabled);
              this.$set(item1, "isShow", isShow);
            });
            item.__config__.children = childrens;
            item.__config__.editModule = list1.some((value) => value === true);
            item.__config__.showModule = list2.some((value) => value === true);
            // console.log(list1, list2, item, "888888");
          }
        });
        this.formConf = JSON.parse(JSON.stringify(formDatas));
        if (editable.length) {
          this.$set(this.formConf, "disabled", false);
        }
        console.log(formDatas, "2222");
      } else {
        // 未设置权限直接赋值并禁止编辑
        this.formConf = JSON.parse(JSON.stringify(this.formConfOld1));
        this.formConf.fields.forEach((item) => {
          item.__config__.showModule = true;
        });
        if (this.process != 1) {
          this.$set(this.formConf, "disabled", true);
        }
      }
      
      // 设置关联字段规则
      this.$nextTick(() => {
        this.formConf.fields.forEach((item, index) => {
          item.__config__.children.forEach((items) => {
            items.__config__.span = 24
            items.__config__.beforeValue = items.__config__.defaultValue || "";
            // this.$set(items, "isShow", true);
            this.$set(items, "moduleIds", item.__config__.moduleId);
            if (this.formConf?.rulesForm) {
              this.formConf.rulesForm.forEach((item1, index1) => {
                item1.activeData.forEach((item2) => {
                  if (
                    item2.moduleId == item.__config__.moduleId &&
                    item2.__vModel__ == items.__vModel__
                  ) {
                    this.$set(items, "isShow", item2.actionValue === 0);
                  }
                });
                item1.condition.forEach((item3) => {
                  if (
                    item3.moduleId == item.__config__.moduleId &&
                    item3.__vModel__ == items.__vModel__
                  ) {
                    if (
                      item3.conValue?.includes(items.__config__.defaultValue)
                    ) {
                      this.$set(items, "isShow", true);
                    }
                  }
                });
              });
            }
          });
        });
        this.formConf?.rulesForm?.forEach((item, index) => {
          let isTrue = false;
          let dynamicVariables = {};
          item.condition.forEach((item1, index1) => {
            dynamicVariables["index" + index1] = false;
            this.formConf.fields.forEach((item2) => {
              item2.__config__.children.forEach((item3) => {
                if (
                  item1.moduleId == item3.moduleIds &&
                  item1.__vModel__ == item3.__vModel__
                ) {
                  if (Array.isArray(item3.__config__.defaultValue)) {
                    dynamicVariables["index" + index1] =
                      !item3.__config__.defaultValue.some((element) =>
                        item1.conValue.includes(element)
                      );
                  } else {
                    if (!Array.isArray(item1.conValue)) {
                      item1.conValue = item1.conValue.split(",");
                    }
                    if (
                      item1.conValue.indexOf(item3.__config__.defaultValue) == -1
                    ) {
                      dynamicVariables["index" + index1] = true;
                    } else {
                      dynamicVariables["index" + index1] = false;
                    }
                  }
                }
              });
            });
          });
          isTrue = !Object.values(dynamicVariables).every(
            (item) => item === false
          );
          item.activeData.forEach((item1) => {
            this.formConf.fields.forEach((item2) => {
              item2.__config__.children.forEach((item3) => {
                if (
                  item1.moduleId == item3.moduleIds &&
                  item1.__vModel__ == item3.__vModel__
                ) {
                  if (!isTrue) {
                    if (item1.actionValue == 2) {
                      if (!item3.__config__.isValue) {
                        this.$set(
                          item3.__config__,
                          "defaultValue",
                          item1.conValue
                        );
                        this.$set(item3.__config__, "isValue", true);
                      }
                    } else {
                      if (item3.dynamicVariables) {
                        item3.dynamicVariables.isShow = false;
                        item3.dynamicVariables["isShow" + index] =
                          item1.actionValue == 1 ? true : false;
                      } else {
                        let dynamicVariables1 = {};
                        dynamicVariables1["isShow" + index] =
                          item1.actionValue == 1 ? true : false;
                        this.$set(item3, "dynamicVariables", dynamicVariables1);
                      }
                    }
                  } else {
                    if (item1.actionValue == 2) {
                      this.$set(item3.__config__, "isValue", false);
                    } else {
                      if (item3.dynamicVariables) {
                        item3.dynamicVariables.isShow = false;
                        item3.dynamicVariables["isShow" + index] =
                          item1.actionValue == 1 ? false : true;
                      } else {
                        let dynamicVariables1 = {};
                        dynamicVariables1["isShow" + index] =
                          item1.actionValue == 1 ? false : true;
                        this.$set(item3, "dynamicVariables", dynamicVariables1);
                      }
                    }
                  }
                }
              });
            });
          });
        })
      });
      this.formConf.labelPosition = "top"
      console.log(this.formConf, "1111");
      let _this = this;
      setTimeout(function () {
        _this.isRequestCompleted = true;
      }, 500);
      // 暂存表单数据
      this.formConfOld = JSON.parse(JSON.stringify(this.formConf));

      // this.taskRecordss = this.projectData.rejectTaskRecords || []; // 驳回项目前面的任务节点数据
      // this.taskRecordsAll = this.projectData.taskRecords || [];
      // 判断当前办理提交按钮的显示权限
      // this.projectData.taskRecords?.forEach((item) => {
      //   if (item.status == 1 && item.handleId == this.userId) {
      //     this.btnShow = true;
      //   }
      // });
      // this.taskData = this.projectData.projectTaskRecords || []; // 任务历史进度数据
      // this.taskActive = this.taskData.findIndex(
      //   (item) => item.status == 0 && item.userTaskId.indexOf("Event_") == -1
      // );
      // if (this.taskActive == -1) {
      //   this.taskActive = this.taskData.length;
      // } else {
      //   // 判断当前流程节点的所有任务进度
      //   this.overTask = this.taskData[this.taskActive].children.filter(
      //     (item) => item.status == 0 && item.userTaskId.indexOf("start_") == -1
      //   );
      // }
      // this.nowUserId = this.taskData[this.taskActive]?.createId || this.userId;

      if (this.projectData.procDefDiagram) {
        this.projectData.procDefDiagram.nodeData =
          this.projectData.procDefDiagram.flowElements;
        this.flowDatas = this.projectData.procDefDiagram; // 流程图数据
      }
      this.tableData = JSON.parse(JSON.stringify(this.projectData.products)); // 订单数据
      let currentQueryParams = { ...this.$route.query };

      // 添加新的查询参数
      currentQueryParams["customerNo"] =
        this.projectData.products[0].orderProduct.customerNo;

      // 使用 $router.replace 更新带有新参数的当前路由
      this.$router.replace({ query: currentQueryParams });
      this.tableData.forEach((item) => {
        item.handleNumber = 1
      });
      // if (this.orderList.length) {
      //   this.tableData.forEach((item) => {
      //     item.handleNumber = this.orderList.filter(
      //       (item1) => item1.orderProdId == item.orderProductId
      //     )[0].takeNum;
      //   });
      // }
      if (this.formConf.rejectPro && this.projectData.recordStatus == 6) {
        this.rejectProFlag = this.formConf.rejectPro; // 驳回修改产品状态
      }
    },
    // 提交项目
    async submitPros() {
      console.log(this.formConfOld, this.$refs.parser.submitForms());
      if (this.projectData.recordStatus === 6) {
        const submitFields = this.$refs.parser.submitForms().fields;
        // 检查是否有未修改完的驳回信息
        if (
          this.formConfOld.fields.some((item) => {
            return submitFields.some((item1) => {
              return (
                item.__config__.moduleId === item1.__config__.moduleId &&
                item.__config__.children.some((items) =>
                  item1.__config__.children.some(
                    (items1) =>
                      items.isReject &&
                      items1.__vModel__ === items.__vModel__ &&
                      items1.__config__.defaultValue ===
                        items.__config__.defaultValue
                  )
                )
              );
            });
          })
        ) {
          this.$modal.msgError("请先修改完驳回信息再重新提交！");
          return false;
        }
        // 检查是否有多条数据中未修改完的驳回信息
        let nameList = [];
        if (
          this.formConfOld.fields.some((item) => {
            return submitFields.some((item1) => {
              if (
                item.__config__.allowMultiple &&
                item.__config__.rejModule &&
                item.__config__.moduleId === item1.__config__.moduleId
              ) {
                if (
                  JSON.stringify(item.__config__.defaultValue) ===
                  JSON.stringify(item1.__config__.defaultValue)
                ) {
                  nameList.push(item.__config__.componentName);
                  return true;
                }
              }
              return false;
            });
          })
        ) {
          this.$modal.msgError(
            `请先修改完< ${nameList.join("、")} >内的驳回信息再重新提交！`
          );
          return false;
        }
      }

      let valid = this.$refs.parser.submitForm();
      if (!valid) {
        this.$nextTick(() => {
          const errorFields = document.querySelectorAll(".is-error");
          // console.log("errorFields第一个校验失败的元素", errorFields);
          if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            firstErrorField.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
        return false;
      }
      this.submitPro()
      // this.appointOpen = true;
      // this.nextTask = this.appointData.length
      //   ? this.appointData[0].userTaskName
      //   : "" || "";
      // if (this.recordId && this.appointData.length && this.taskId) {
      //   let param = {
      //     taskId: this.taskId,
      //     recordId: parseInt(this.recordId),
      //     nextUserTaskId: this.appointData.length
      //       ? this.appointData[0].userTaskId
      //       : "",
      //   };
      //   // 查询下节点任务办理人员
      //   nextTaskPersonnel(param).then((res) => {
      //     if (res.code == 200 && res.data.length) {
      //       this.taskUserList = res.data;
      //       if (this.appointData.length) {
      //         this.appointData.forEach((item) => {
      //           this.$set(item, "handleId", this.taskUserList[0].userId);
      //           this.$set(item, "handleBy", this.taskUserList[0].nickName);
      //         });
      //       }
      //     }
      //   });
      // }
    },
    async submitPro() {
      if (this.isSave) {
        return false;
      }
      this.isSave = true;
      if (this.process == 1 || this.projectData.recordStatus == 0) {
        let valid = this.$refs.parser.submitForm();
        if (!valid) {
          return false;
        }
        let id = await this.stagingForm();
        this.commitRecord();

        // 记录项目上传文件相关
        let fileList = [];
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.allowMultiple) {
            if (
              item.__config__.defaultValue &&
              item.__config__.defaultValue.length
            ) {
              item.__config__.defaultValue.forEach((item1, index1) => {
                item1.children.forEach((item2) => {
                  if (item2.__config__.tag == "el-upload") {
                    if (item2.__config__.defaultValue) {
                      JSON.parse(item2.__config__.defaultValue).forEach(
                        (item3) => {
                          let param = {
                            recordId: id,
                            newType: 1,
                            fieldId:
                              item.__config__.moduleId +
                              "_" +
                              item2.__vModel__ +
                              "_" +
                              (index1 + 1),
                            fieldName:
                              item.__config__.componentName +
                              "-" +
                              item2.__config__.label +
                              (index1 + 1),
                            fileType: item2.actionType || "1",
                            fileName: item3.name,
                            fileUrl: item3.url,
                          };
                          fileList.push(param);
                        }
                      );
                    }
                  }
                });
              });
            }
          } else {
            item.__config__.children.forEach((item1) => {
              if (item1.__config__.tag == "el-upload") {
                if (item1.__config__.defaultValue) {
                  JSON.parse(item1.__config__.defaultValue).forEach((item2) => {
                    let param = {
                      recordId: id,
                      newType: 1,
                      fieldId:
                        item.__config__.moduleId + "_" + item1.__vModel__,
                      fieldName:
                        item.__config__.componentName +
                        "-" +
                        item1.__config__.label,
                      fileType: item1.actionType || "1",
                      fileName: item2.name,
                      fileUrl: item2.url,
                    };
                    fileList.push(param);
                  });
                }
              }
            });
          }
        });
        console.log(fileList, "fileListfileList");
        if (fileList.length) {
          insertBatch(fileList).then((res) => {}); // 记录项目文件上传数据
        }
        // 项目发起提交
        let params = {
          id: id,
          remark: this.taskTips,
        };

        recSummitProject(params).then((res) => {
          if (res.code == 200) {
            this.$modal.msgSuccess("提交成功！");
            this.isSave = false;
            // this.appointOpen = false;
            // this.goBack();
          }
        });
      } else {
        let id = await this.stagingForm();
        this.commitRecord();

        // 记录项目上传文件相关
        let fileList = [];
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.allowMultiple) {
            if (
              item.__config__.defaultValue &&
              item.__config__.defaultValue.length
            ) {
              item.__config__.defaultValue.forEach((item1, index1) => {
                item1.children.forEach((item2) => {
                  if (
                    item2.__config__.tag === "el-upload" &&
                    item2.__config__.defaultValue !=
                      item2.__config__.beforeValue
                  ) {
                    let list1 = item2.__config__.defaultValue
                      ? JSON.parse(item2.__config__.defaultValue)
                      : [];
                    let list2 = item2.__config__.beforeValue
                      ? JSON.parse(item2.__config__.beforeValue)
                      : [];
                    // 删除的元素
                    let deletedItems = list2.filter(
                      (itemA) =>
                        !list1.some(
                          (itemB) =>
                            itemB.name === itemA.name && itemB.url === itemA.url
                        )
                    );
                    // 新增的元素
                    let addedItems = list1.filter(
                      (itemB) =>
                        !list2.some(
                          (itemA) =>
                            itemA.name === itemB.name && itemA.url === itemB.url
                        )
                    );
                    if (deletedItems.length) {
                      let delList = this.fileListss
                        .filter((items) =>
                          deletedItems.some(
                            (itemss) =>
                              itemss.name === items.fileName &&
                              itemss.url === items.fileUrl
                          )
                        )
                        .map((itemsss) => itemsss.id);
                      updateNewType(delList).then((res) => {});
                    }
                    console.log(addedItems, "addedItemsaddedItems");
                    if (addedItems.length) {
                      addedItems.forEach((items) => {
                        let param = {
                          recordId: id,
                          newType: 1,
                          fieldId:
                            item.__config__.moduleId +
                            "_" +
                            item2.__vModel__ +
                            "_" +
                            (index1 + 1),
                          fieldName:
                            item.__config__.componentName +
                            "-" +
                            item2.__config__.label +
                            (index1 + 1),
                          fileType: item2.actionType || "1",
                          fileName: items.name,
                          fileUrl: items.url,
                        };
                        fileList.push(param);
                      });
                    }
                  }
                });
              });
            }
          } else {
            item.__config__.children
              .filter((item1) => item1.__config__.tag === "el-upload")
              .forEach((item1) => {
                if (
                  item1.__config__.defaultValue != item1.__config__.beforeValue
                ) {
                  let list1 = item1.__config__.defaultValue
                    ? JSON.parse(item1.__config__.defaultValue)
                    : [];
                  let list2 = item1.__config__.beforeValue
                    ? JSON.parse(item1.__config__.beforeValue)
                    : [];
                  // 删除的元素
                  let deletedItems = list2.filter(
                    (itemA) =>
                      !list1.some(
                        (itemB) =>
                          itemB.name === itemA.name && itemB.url === itemA.url
                      )
                  );
                  // 新增的元素
                  let addedItems = list1.filter(
                    (itemB) =>
                      !list2.some(
                        (itemA) =>
                          itemA.name === itemB.name && itemA.url === itemB.url
                      )
                  );
                  if (deletedItems.length) {
                    let delList = this.fileListss
                      .filter((items) =>
                        deletedItems.some(
                          (itemss) =>
                            itemss.name === items.fileName &&
                            itemss.url === items.fileUrl
                        )
                      )
                      .map((itemsss) => itemsss.id);
                    updateNewType(delList).then((res) => {});
                  }
                  if (addedItems.length) {
                    addedItems.forEach((items) => {
                      let param = {
                        recordId: id,
                        newType: 1,
                        fieldId:
                          item.__config__.moduleId + "_" + item1.__vModel__,
                        fieldName:
                          item.__config__.componentName +
                          "-" +
                          item1.__config__.label,
                        fileType: item1.actionType || "1",
                        fileName: items.name,
                        fileUrl: items.url,
                      };
                      fileList.push(param);
                    });
                  }
                }
              });
          }
        });
        console.log(fileList, "fileListfileList");
        if (fileList.length) {
          insertBatch(fileList).then((res) => {}); // 记录项目文件上传数据
        }

        if (
          this.taskId &&
          this.overTask.length == 1 &&
          this.appointData.length &&
          this.projectData.recordStatus != 6
        ) {
          let list = [];
          this.appointData.forEach((item) => {
            let param = {
              taskId: item.taskId,
              handleId: item.handleId,
              handleBy: item.handleBy,
            };
            list.push(param);
          });
          submitNextTasks({
            taskId: this.taskId,
            submitNextTasks: list,
            remark: this.taskTips,
          }).then((res) => {
            if (res.code == 200) {
              this.$modal.msgSuccess("提交成功！");
              this.goBack();
            }
          });
        } else {
          //驳回
          summitTask({ taskId: this.taskId, remark: this.taskTips }).then(
            (res) => {
              if (res.code == 200) {
                this.$modal.msgSuccess("提交成功！");
                this.goBack();
              }
            }
          );
        }
      }
    },
    async stagingForms(end) {
      if (this.isSave) {
        return false;
      }
      this.isSave = true;
      let id = await this.stagingForm();
      if (id) {
        if (this.multiProject) {
          this.mpList[this.mpindex].recordId = id;
        }
        if (this.preVisible) {
          this.$modal.msgSuccess("暂存成功,跳转中");
        } else {
          this.$modal.msgSuccess("暂存成功！");
        }

        this.recordId = id;
        // await this.getRecord();
        // await this.getFileHistory();
        await this.getFlowFormDatas();
        this.isSave = false;
        let currentQueryParams = { ...this.$route.query };
        currentQueryParams["recordId"] = this.recordId;
        this.$router.replace({ query: currentQueryParams });
      }
    },
    async stagingForm(form) {
      this.tableData.forEach((item) => {
        if (item.orderProduct) {
          item.orderProductId = item.orderProduct.id;
          item.handlePrice =
            (item.handleNumber * item.orderProduct.productPrice) /
            item.orderProduct.takeTotal;
          item.handleNumber = item.handleNumber;
        }
      });
      this.formConfCopy = form ? form : this.$refs.parser.submitForms();

      // 为子表的字段数据赋"beforeValue"值
      this.formConfOld.fields.forEach((item) => {
        const matchingField = this.formConfCopy.fields.find(
          (item1) =>
            item.__config__.allowMultiple &&
            item1.__config__.allowMultiple &&
            item.__config__.moduleId == item1.__config__.moduleId
        );

        if (matchingField) {
          item.__config__.defaultValue?.forEach((item2, index) => {
            const correspondingItem =
              matchingField.__config__.defaultValue[index];
            if (correspondingItem) {
              item2.children.forEach((item4, index2) => {
                const correspondingChild = correspondingItem.children[index2];
                if (
                  correspondingChild &&
                  item4.__vModel__ === correspondingChild.__vModel__
                ) {
                  this.$set(
                    correspondingChild.__config__,
                    "beforeValue",
                    item4.__config__.defaultValue
                  );
                }
              });
            }
          });
        }
      });

      if (this.projectData.recordStatus == 6) {
        this.formConfCopy.fields.forEach((item) => {
          if (item.__config__.allowMultiple && item.__config__.rejModule) {
            item.__config__.rejModule = false;
            item.__config__.rejectReasons = null;
          }
          item.__config__.children.forEach((items) => {
            items.isReject = false;
            items.rejectReason = null;
          });
        });
      }

      let res;
      if (this.projectData.id) {
        let params = {
          id: this.projectData.id,
          recordName: this.projectData.recordName,
          recordTitle: this.setTitle(),
          recordStatus: this.projectData.recordStatus,
          projectId: this.projectData.projectId,
          areaGroupId: this.projectData.areaGroupId,
          variables: this.formConfCopy,
          products: this.tableData,
          remark: this.remark,
          version: this.projectData.version,
        };

        try {
          res = await recordProjectUpdate(params);
        } catch (err) {
          this.isSave = false;
        }
      } else {
        let params = {
          recordName: this.projectData.projectVO.projectName,
          recordTitle: this.setTitle(),
          recordStatus: 0,
          projectId: this.projectData.projectVO.id,
          areaGroupId: this.projectData.projectVO.areaGroupId,
          variables: this.formConfCopy,
          products: this.tableData,
          remark: this.remark,
          linkKey: this.multiProject ? this.multiProject : this.linkKey,
        };

        try {
          res = await recordProject(params);
        } catch (err) {
          this.isSave = false;
        }
      }
      if (res && res.code == 200) {
        this.recordId = res.data.id;
        this.projectData.version = res.data.version;
        return res.data.id;
      } else {
        return false;
      }
    },
    // 设置项目标题
    setTitle() {
      let form = this.$refs.parser.submitForms();
      let title = [];
      if (form.titleList) {
        // 项目标题设置相关
        form.titleList.forEach((item) => {
          form.fields.forEach((item1) => {
            if (item.moduleId == item1.__config__.moduleId) {
              item1.__config__.children.forEach((item2) => {
                if (item.__vModel__ == item2.__vModel__) {
                  title.push(item2.__config__.defaultValue);
                }
              });
            }
          });
        });
      }
      return title.join("+");
    },
    setHtmlFontSize() {
      const html = document.documentElement;
      const width = html.clientWidth;

      if (width > 750) {
        html.style.fontSize = '1px'; // 对应750px设计尺寸
      } else {
        html.style.fontSize = width / 750 + 'px'; // 1rem = 100rpx
      }
    },
    async getCache(id) {
      let res = await getCheckProjectCache(id);
      if (res.code == 200) {
        console.log(res);
        this.mpList = res.data;
        if (this.mpList.length == 1) {
          this.linkKey = JSON.parse(JSON.stringify(this.multiProject));
          this.multiProject = false;
        }
        this.projectCode = this.mpList[this.mpindex].projectCode;
        this.recordId = this.mpList[this.mpindex].recordId;

        this.orderList = this.mpList[this.mpindex].transactList.map((item) => {
          return {
            orderProdId: item.orderProductId,
            takeNum: item.handleNumber,
          };
        });
        const productIdArray = this.orderList.map((order) => order.orderProdId);
        this.orderProdId =
          productIdArray.length === 1
            ? productIdArray[0]
            : productIdArray.join(",");

        this.process = 1;
        await this.getFlowFormDatas();
      }
    },
    /** 接收子组件传的值 */
    getData(data) {
      if (data) {
        const variables = [];
        data.fields.forEach((item) => {
          let variableData = {};
          variableData.label = item.__config__.label;
          // 表单值为多个选项时
          if (item.__config__.defaultValue instanceof Array) {
            const array = [];
            item.__config__.defaultValue.forEach((val) => {
              array.push(val);
            });
            variableData.val = array;
          } else {
            variableData.val = item.__config__.defaultValue;
          }
          variables.push(variableData);
        });
        this.variables = variables;
      }
    },
    openDataDialog(identifyType, reflection, searchData, echoData) {
      // 打开弹窗
      this.$refs.fillData.fetchData(
        identifyType,
        reflection,
        searchData,
        echoData
      );
    },
    handleSelectData(data) {
      this.$refs.parser.fillSelectData(data);
    },
  },
}
</script>

<style lang="scss">
  .head-title {
    width: 100%;
    height: 80rem;
    background:linear-gradient(to right, #FD7925, #FD992E);
    font-size: 32rem;
    color: #fff;
    line-height: 80rem;
    text-align: center;
  }
  .label-tip {
    width: 100%;
    height: 86rem;
    background-color: #F2F2F2;
    padding-left: 32rem;
    line-height: 86rem;
    span {
      font-size: 32rem;
      font-weight: 700;
      color: #333;
      border-left: 6rem solid #FF7828;
      padding-left: 8rem;
    }
  }
  .step-boxs {
    padding: 20rem 0;
    .top {
      display: flex;
      align-items: center;
      padding: 0 120rem;
      .step {
        width: 80rem;
        height: 80rem;
        border-radius: 40rem;
        border: 4rem solid #FB7717;
        display: flex;
        align-items: center;
        img {
          width: 50rem;
          height: 50rem;
          margin: 0 auto;
        }
      }
      .line {
        flex: 1;
        border: 1rem dashed #FB7717;
      }
    }
    .bot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10rem;
      padding: 0 108rem;
      .title {
        font-size: 26rem;
        line-height: 30rem;
      }
    }
  }
  .sidebar-container {
    display: none;
  }
  .navbar {
    display: none;
  }
  .tags-view-container {
    display: none;
  }
  .main-container {
    margin-left: 0 !important;
  }
  .boundary {
    width: 100%;
    margin: 0 auto !important;
    .el-row {
      border: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      line-height: 30rem !important;
    }
    .el-col {
      padding: 0 !important;
    }
    .formBoxs {
      width: 100%;
      display: inline-block;
      padding: 0 46rem;
    }
    .el-form-item__label {
      font-size: 28rem;
      font-weight: 400 !important;
      line-height: 28rem !important;
      padding: 10rem 0 !important;
    }
    .el-form-item {
      margin-bottom: 35rem !important;
    }
    .el-form-item__error {
      font-size: 24rem;
    }
  }
  .module-title {
    display:flex;
    align-items:center;
    // background-color: #F2F2F2;
    min-height: 80rem;
    margin-bottom: 8rem;
    padding: 0 20rem;
    .quote {
      display: none;
    }
    .img {
      width: 68rem;
      height: 68rem;
      background-color: #F2F2F2;
      display: flex;
      align-items: center;
      border-radius: 12rem;
      img {
        display: inline-block;
        width: 50rem;
        margin: 0 auto;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      border-bottom: 1rem solid #f5f5f5;
      .el-button {
        border: 1rem solid #FB7717;
        border-radius: 20rem;
        background: #fff;
        color: #FB7717;
      }
    }
    .title-box {
      flex: 1;
    }
    .title {
      font-size:28rem;
      // line-height: 30rem;
      color:#333;
      margin-left: 20rem;
      padding-left: 20rem;
    }
  }
  .moudle-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    margin: 0 auto 8rem;
    padding: 15rem 15rem 60rem;
    // background: #f7f7f7;
    box-shadow: 2rem 2rem 6rem 3rem #e1e1e1;
    .moudle-li {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 8rem;
      font-size: 18rem;
      .span1 {
        color: #999999;
        width: 160rem;
        text-align: right;
      }
      .span2 {
        flex: 1;
        word-break: break-all;
        margin-left: 4rem;
        color: #333;
      }
      .img {
        display: flex;
        img {
          width: 40rem;
        }
      }
      .cona {
        width: 100rem;
        text-align: center;
        span {
          width: 100%;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .module-form {
    display: inline-block;
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .form-box {
      width: 90%;
      padding: 10rem 12rem 20rem;
      font-size: 14rem;
      display: inline-block;
      // border: 1rem dashed rgb(64, 158, 255);
      border-radius: 10rem;
      position: fixed;
      z-index: 999;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 80vh;
      overflow-y: auto;
      .el-col {
        display: inline-block;
      }
      .form-title {
        display: flex;
        align-items: center;
        padding: 0 5rem;
        height: 48rem;
        border-bottom: 1rem solid #dadada;
        margin-bottom: 28rem;
        span {
          flex: 1;
          font-size: 28rem;
          color: #333;
          font-weight: 700;
        }
        i {
          font-size: 36rem;
          font-weight: 700;
          cursor: pointer;
        }
      }
      .form-boxs {
        display: inline-block;
      }
      .btnbox {
        width: 306rem;
        margin: 0 auto;
        display: flex;
      }
    }
  }
  .el-dialog {
    width: 90% !important;
    overflow: hidden;
    .el-form {
      .el-col-12 {
        width: 100% !important;
      }
    }
  }
  .select_list .elt_item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-pagination {
    overflow-y: auto;
    .el-pagination__total {
      font-size: 12rem !important;
    }
    .el-pagination__sizes {
      width: 50px !important;
      .el-input {
        width: 100% !important;
      }
    }
    .el-pagination__jump {
      margin-left: 0 !important;
    }
  }
  .el-date-range-picker {
    width: 90%;
    left: 50% !important;
    transform: translateX(-50%);
    overflow-x: auto;
  }
  .subBtn {
    width: 300rem;
    height: 68rem;
    display: block;
    background-color: #FB7717;
    border: 1px solid #FB7717;
    margin: 0 auto 60rem;
  }
</style>