<template>
  <div>
    <el-dialog title="请选择需要复制的模块" :visible.sync="openQuote" width="500px" append-to-body>
      <el-table ref="dataTable" :row-key="getRowKey" :data="quoteList" @current-change="handleSingleUserSelect">
        <el-table-column width="55" align="center">
          <template slot-scope="scope">
            <el-radio v-model="radioSelected" :label="scope.row.moduleId">{{''}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="模块名称" align="center" prop="componentName" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openQuote = false">取 消</el-button>
        <el-button type="primary" @click="choseQuote">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    quoteList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  watch: {
    openQuote: {
      handler(val) {
        this.radioSelected = null;
      },
    }
  },
  data() {
    return {
      openQuote:false,
      radioSelected:null,
    }
  },
  methods: {
    getRowKey(row) {
      return row.moduleId;
    },
    handleSingleUserSelect(selection) {},
    choseQuote() {
      console.log(this.radioSelected,'radioSelected');
      if(!this.radioSelected) {
        this.$message.error("请先选择需要复制的模块！");
        return false
      }
      this.$confirm('此操作将覆盖当前模块填写值, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.openQuote = false 
        this.$emit("choseQuotes",this.radioSelected)
      }).catch(() => {
        this.openQuote = false         
      });
    },
  }
}
</script>

<style>

</style>