var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login",
      staticStyle: { display: "flex", position: "relative" },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            "z-index": "11",
            width: "100%",
            height: "100%",
          },
        },
        [
          _c("video", {
            staticClass: "tpm-hero2__item-video",
            staticStyle: {
              "object-fit": "fill",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
            },
            attrs: {
              src: "https://oss.71go.com/bpm1207/logoVideo.mp4",
              muted: "",
              autoplay: "",
              loop: "",
            },
            domProps: { muted: true },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "z-index": "99",
            display: "flex",
            width: "100%",
            height: "100%",
          },
        },
        [
          _c("div", { staticClass: "leftGif" }),
          _c(
            "div",
            { staticClass: "right-box" },
            [
              _c("h1", [_vm._v("欢迎来到！")]),
              _c("div", { staticClass: "name" }, [
                _vm._v("司盟企服工单管理系统"),
              ]),
              _vm._m(0),
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: { model: _vm.loginForm, rules: _vm.loginRules },
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("账号：")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "text",
                            "auto-complete": "off",
                            placeholder: "请输入账号",
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              slot: "prefix",
                              src: "https://oss.71go.com/qyg-pc/p-icon.png",
                              alt: "",
                            },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "label",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [_vm._v("密码：")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "password",
                            "auto-complete": "off",
                            placeholder: "请输入密码",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              slot: "prefix",
                              src: "https://oss.71go.com/qyg-pc/m-icon.png",
                              alt: "",
                            },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.captchaEnabled
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "52%" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "请输入验证码",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  slot: "prefix",
                                  src: "https://oss.71go.com/bpm1207/y-icon.png",
                                  alt: "",
                                },
                                slot: "prefix",
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "login-code" }, [
                            _c("img", {
                              staticClass: "login-code-img",
                              attrs: { src: _vm.codeUrl },
                              on: { click: _vm.getCode },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "remmber",
                      on: {
                        click: function ($event) {
                          _vm.loginForm.rememberMe = !_vm.loginForm.rememberMe
                        },
                      },
                    },
                    [
                      !_vm.loginForm.rememberMe
                        ? _c("img", {
                            attrs: {
                              src: "https://oss.71go.com/qyg-pc/remmend.png",
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: "https://oss.71go.com/qyg-pc/remmended.png",
                              alt: "",
                            },
                          }),
                      _c(
                        "div",
                        {
                          style: {
                            color: _vm.loginForm.rememberMe
                              ? "#386EF6"
                              : "#999",
                          },
                        },
                        [_vm._v("记住密码")]
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "loginBtn",
                          staticStyle: { "font-size": "22px" },
                          attrs: {
                            loading: _vm.loading,
                            size: "medium",
                            type: "primary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [
                          !_vm.loading
                            ? _c("span", [_vm._v("登 录")])
                            : _c("span", [_vm._v("登 录 中...")]),
                        ]
                      ),
                      _vm.register
                        ? _c(
                            "div",
                            { staticStyle: { float: "right" } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "link-type",
                                  attrs: { to: "/register" },
                                },
                                [_vm._v("立即注册")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(1),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconALl" }, [
      _c("div", { staticClass: "one" }, [
        _c("img", {
          attrs: { src: "https://oss.71go.com/qyg-pc/choosed.png", alt: "" },
        }),
        _vm._v(" 实现全流程互联网订单管理 "),
      ]),
      _c("div", { staticClass: "one" }, [
        _c("img", {
          attrs: { src: "https://oss.71go.com/qyg-pc/choosed.png", alt: "" },
        }),
        _vm._v(" 整合行业交付资源，实现人才共享 "),
      ]),
      _c("div", { staticClass: "one" }, [
        _c("img", {
          attrs: { src: "https://oss.71go.com/qyg-pc/choosed.png", alt: "" },
        }),
        _vm._v(" 建设标准化专业交付管理体系 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [
        _vm._v(
          "Copyright © 2024 深圳市中港星互联网科技有限公司 All Rights Reserved"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }