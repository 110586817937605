var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", { staticClass: "el-icon-document" }, [
                _vm._v("发起任务"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "top" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "表单信息", name: "1" } },
                [
                  _c("el-col", { attrs: { span: 16, offset: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "test-form" },
                      [
                        _c("parser", {
                          key: new Date().getTime(),
                          ref: "parser",
                          attrs: { "form-conf": _vm.formConf },
                          on: { submit: _vm.submitForm, getData: _vm.getData },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "流程图", name: "2" } },
                [_c("flow", { attrs: { flowData: _vm.flowData } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.taskTitle,
                visible: _vm.taskOpen,
                width: "65%",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.taskOpen = $event
                },
              },
            },
            [
              _vm.checkSendUser
                ? _c("flow-user", {
                    attrs: { checkType: _vm.checkType },
                    on: { handleUserSelect: _vm.handleUserSelect },
                  })
                : _vm._e(),
              _vm.checkSendRole
                ? _c("flow-role", {
                    on: { handleRoleSelect: _vm.handleRoleSelect },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.taskOpen = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitTask },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }