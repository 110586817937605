var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                title: "外部资源引用",
                width: "600px",
                "close-on-click-modal": false,
              },
              on: { open: _vm.onOpen, close: _vm.onClose },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(_vm.resources, function (item, index) {
            return _c(
              "el-input",
              {
                key: index,
                staticClass: "url-item",
                attrs: {
                  placeholder: "请输入 css 或 js 资源路径",
                  "prefix-icon": "el-icon-link",
                  clearable: "",
                },
                model: {
                  value: _vm.resources[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.resources, index, $$v)
                  },
                  expression: "resources[index]",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-delete" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteOne(index)
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            )
          }),
          _c(
            "el-button-group",
            { staticClass: "add-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addOne(
                        "https://lib.baomitu.com/jquery/1.8.3/jquery.min.js"
                      )
                    },
                  },
                },
                [_vm._v(" jQuery1.8.3 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addOne("https://unpkg.com/http-vue-loader")
                    },
                  },
                },
                [_vm._v(" http-vue-loader ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-circle-plus-outline", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.addOne("")
                    },
                  },
                },
                [_vm._v(" 添加其他 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }