<template>
  <el-dialog v-bind="$attrs" width="980px" :modal-append-to-body="false" :append-to-body="true" v-on="$listeners" @open="onOpen" @close="onClose">
    <div style="margin-bottom: 10px;display: flex;">
      <span style="margin-right: 8px;">弹窗数据选择方式:</span>
      <el-switch
        v-model="isSingles"
        active-text="多选"
        inactive-text="单选">
      </el-switch>
    </div>
    <el-table :data="tableData" border>
      <el-table-column label="识别字段">
        <template slot-scope="scope">
          <span>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否为参数">
        <template slot-scope="scope">
          <span>{{ scope.row.searchType?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否为选中字段">
        <template slot-scope="scope">
          <span>{{ scope.row.showType?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模块">
        <template slot-scope="scope">
          <el-select placeholder="选择模块" filterable v-model="scope.row.moduleId" clearable>
            <el-option v-for="(item,index) in optionsList" :key="index" :label="item.label" :value="item.moduleId"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="映射字段">
        <template slot-scope="scope">
          <el-select placeholder="选择映射字段" filterable v-model="scope.row.__vModel__" clearable>
            <el-option v-for="(item,index) in getOptions(scope.row.moduleId)" :key="index" :label="item.__config__.label" :value="item.__vModel__"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEnsure" type="primary">确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["drawingList", "type", "identifyList", "typeList"],
  created() {
    // console.log("this.drawingList", this.drawingList);
  },
  watch: {
    drawingList: {
      handler(val) {
        const hasConfig = val.some((obj) => "__config__" in obj);
        if (hasConfig) {
          let arr = [];
          for (let index = 0; index < val.length; index++) {
            const element = {};
            element.moduleId = val[index].__config__.moduleId;
            element.label = val[index].__config__.labelName;
            element.children = val[index].__config__.children;
            arr.push(element);
          }
          // console.log("arr", arr);
          this.optionsList = arr;
        }
      },
      deep: true,
    },

    typeList: {
      handler(val) {
        // console.log("type", val);
        this.tableData = val;
      },
    },
    identifyList: {
      handler(val) {
        // console.log("identifyList", val);
        if (val && val.length > 0) {
          this.tableData = val;
        }
      },
    },
  },
  created() {
    this.tableData = this.typeList;
  },
  data() {
    return {
      tableData: [],

      optionsList: [],
      isSingles:null,
    };
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$emit("update:visible", false);
    },
    handleEnsure() {
      // console.log("optionsList", this.optionsList);
      this.$emit("enSureIdentify", this.tableData, !this.isSingles);
      // 处理传递过来的 optionsList 值
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    getOptions(Id) {
      if (Id) {
        console.log(this.optionsList);
        let obj = this.optionsList.filter((item) => {
          console.log(Id);
          console.log(item.moduleId);
          if (item.moduleId == Id) {
            return item;
          }
        });
        console.log(obj);
        return obj[0].children || [];
      } else {
        return [];
      }
    },
  },
};
</script>
